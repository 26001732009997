import { Field, getFieldLabel } from '@creative-kit/shared';
import { useStore } from 'logic';

// Encapsulates logic for getting the label for a Formula Field
export function useFieldLabel() {
  const {
    sessionsStore: { currentSession },
    sourcesStore: { sources },
  } = useStore();
  const {
    data: { modules },
  } = currentSession!;
  const getLabel = (field: Field) => {
    const fieldModule = 'moduleId' in field ? modules[field.moduleId] : null;
    const source = fieldModule ? sources[fieldModule.sourceId] : null;
    return fieldModule ? source?.data.fieldNames[field.field] : getFieldLabel(field);
  };
  return getLabel;
}
