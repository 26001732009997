import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { matchMetaShortcutToEvent } from 'logic/ui-keyboard-shortcuts';
import { Control, useController } from 'react-hook-form';

export interface SourceCreationForm {
  sourceName: '';
  automaticModuleCreation: boolean;
}

export const SourceNameInput = ({
  label,
  control,
  onSubmit,
}: {
  label: string;
  control: Control<SourceCreationForm>;
  onSubmit: () => void;
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name: 'sourceName', control, rules: { required: true } });
  return (
    <TextField
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      inputRef={ref}
      error={!!error}
      helperText={error?.message}
      autoFocus
      fullWidth
      type="text"
      onKeyDown={(event) => {
        if (matchMetaShortcutToEvent(event, 'Enter')) {
          onSubmit();
        }
      }}
      label={label}
    />
  );
};

export const AutomaticModuleCreationCheckbox = ({
  control,
}: {
  control: Control<SourceCreationForm>;
}) => {
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({ name: 'automaticModuleCreation', control });
  return (
    <FormGroup sx={{ alignItems: 'center' }}>
      <FormControlLabel
        control={<Checkbox checked={value} onChange={onChange} onBlur={onBlur} ref={ref} />}
        label="Create Bucket for this Library"
      />
    </FormGroup>
  );
};
