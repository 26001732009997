import type { RootStore } from 'logic';
import { action, computed, makeObservable, observable } from 'mobx';

export enum DownloadFolderNaming {
  CARD_TITLE = 'cardtitle',
  FILENAME = 'filename',
}

async function createFolder(rootDirEntry: FileSystemDirectoryHandle, folders: string[]) {
  const promises: Array<Promise<unknown>> = [];
  folders.forEach((folderName) => {
    // remove the bad folder name like .
    if (folders[0] === '.' || folders[0] === '') {
      return;
    }
    promises.push(
      rootDirEntry.getDirectoryHandle(folderName, { create: true }).catch((e) => {
        console.error(`Failed to create folder for ${folderName}:`, e);
      })
    );
  });

  return Promise.allSettled(promises);
}
export class UiDownloadFoldersStore {
  private rootStore: RootStore;
  folder: FileSystemDirectoryHandle | undefined;
  folderNaming: DownloadFolderNaming | null = null;
  cardsToUse: {
    done: boolean;
    inProgress: boolean;
  } = {
    inProgress: true,
    done: false,
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      cardsToUse: observable,
      folder: observable,
      folderNaming: observable,
      isDialogOpen: computed,
      selectedFolderName: computed,
      selectFolder: action,
      foldersToCreate: computed,
      selectDownloadFolderName: action.bound,
    });
  }

  get isDialogOpen() {
    return !!this.folderNaming;
  }

  // eslint-disable-next-line class-methods-use-this
  get canUseDownloadFolders() {
    return typeof window.showDirectoryPicker === 'function';
  }

  get selectedFolderName() {
    return this.folder ? this.folder.name : undefined;
  }

  get selectedCards() {
    const { doneVisibleCardIds, inProgressCardIds } = this.rootStore.sessionsStore.currentSession!;
    let cardIds: string[] = [];
    if (this.cardsToUse.done) {
      cardIds = [...cardIds, ...doneVisibleCardIds];
    }
    if (this.cardsToUse.inProgress) {
      cardIds = [...cardIds, ...inProgressCardIds];
    }
    return cardIds;
  }

  get foldersToCreate() {
    const cards = this.rootStore.sessionsStore.currentSession?.getVisible(this.selectedCards) ?? [];
    const folderNames = (
      this.folderNaming === DownloadFolderNaming.CARD_TITLE
        ? cards.filter((c) => c.labelInfo.errors.length === 0).map((card) => card.mainLabel)
        : cards.filter((c) => !c.filenameNeedsAttention).map((card) => card.filename)
    ).filter((name) => !!name && name.length > 0);
    return folderNames;
  }

  selectDownloadFolderName = (title: DownloadFolderNaming) => {
    this.folderNaming = title;
  };

  closeDialog = () => {
    this.folderNaming = null;
  };

  setCardsToUse = (type: 'done' | 'inProgress', value: boolean) => {
    this.cardsToUse[type] = value;
  };

  selectFolder = async () => {
    const openPicker = window.showDirectoryPicker;
    try {
      this.folder = await openPicker();
    } catch (e) {
      console.error(e);
    }
  };

  createFolders = async () => {
    if (!this.folder) {
      alert('A folder must be selected');
      return;
    }

    const results = await createFolder(this.folder, this.foldersToCreate);
    const successFolders = results.filter((r) => r.status === 'fulfilled');
    if (this.foldersToCreate.length > successFolders.length) {
      this.rootStore.uiSnackbarStore.setSnackbarDetails({
        description: `Successfully created ${successFolders.length} folders. Failed to create ${
          this.foldersToCreate.length - successFolders.length
        } folders.`,
      });
    } else if (this.foldersToCreate.length > 0) {
      this.rootStore.uiSnackbarStore.setSnackbarDetails({
        description: `Successfully created ${successFolders.length} folders`,
      });
    } else {
      this.rootStore.uiSnackbarStore.setSnackbarDetails({
        description: 'There were no folders to create',
      });
    }
  };
}
