import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RouterView } from 'mobx-state-router';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { useStore } from 'logic';
import { getTheme } from 'theme';
import { ApplicationBar } from 'ui/ApplicationBar';
import { AppSnackbar } from 'ui/AppSnackbar';
import { RouteNames } from 'logic/routes';
import { ActiveSessionView } from 'views/ActiveSessionView';
import { TourView } from 'views/TourView';
import { ImportCSVView } from 'views/ImportCSVView';
import { ImportListView } from 'views/ImportListView';
import { ImportBlankSpreadsheetView } from 'views/ImportBlankSpreadsheetView';
import { ImportImageView } from 'views/ImportImageView';
import { HomeView } from 'views/HomeView';
import { CustomKbar } from 'ui/CustomKbar';
import { ConfirmProvider } from 'material-ui-confirm';
import { WelcomeView } from 'views/WelcomeView';

const viewMap: Record<RouteNames, React.ReactNode> = {
  home: <HomeView />,
  importcsv: <ImportCSVView />,
  importlist: <ImportListView />,
  importspreadsheet: <ImportBlankSpreadsheetView />,
  importimages: <ImportImageView />,
  session: <ActiveSessionView />,
  card: <ActiveSessionView />,
  tour: <TourView />,
};

export const App = Sentry.withProfiler(
  observer(() => {
    const {
      uiThemeStore: { darkMode },
      authStore: { user, userLoading, setSignInRedirect },
      routerStore,
    } = useStore();

    useEffect(() => {
      if (
        userLoading === false &&
        !user &&
        routerStore.getCurrentRoute()?.name !== RouteNames.HOME
      ) {
        setSignInRedirect(routerStore.routerState);
        routerStore.goTo(RouteNames.HOME);
      }
    }, [user, userLoading, routerStore, setSignInRedirect]);

    return (
      <Sentry.ErrorBoundary
        fallback={
          <p>
            An error occurred, and our team has been notified. Please refresh the page to continue.
          </p>
        }
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={getTheme(darkMode)}>
            <ConfirmProvider>
              <CustomKbar>
                <AppView />
              </CustomKbar>
            </ConfirmProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Sentry.ErrorBoundary>
    );
  })
);

const useStyles = makeStyles((theme) => ({
  root: {
    transition: theme.transitions.create('background-color'),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  },
  mainView: {
    flex: 1,
    overflow: 'hidden',
  },
  '@global': {
    '.firebase-emulator-warning': {
      display: 'none',
    },
    a: {
      color: theme.palette.primary.light,
    },
  },
}));

const AppView = observer(() => {
  const styles = useStyles();
  const {
    authStore: { user },
  } = useStore();

  return (
    <>
      <main className={styles.root}>
        <ApplicationBar />
        <div className={styles.mainView}>
          {user === undefined || (user !== null && user.canUseApp === undefined) ? (
            <div>loading...</div>
          ) : user && !user.canUseApp ? (
            <WelcomeView />
          ) : (
            <RouterView viewMap={viewMap} />
          )}
        </div>
      </main>
      <AppSnackbar />
    </>
  );
});
