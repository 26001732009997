import { Box, Button, Paper } from '@mui/material';
import { useKBar } from 'kbar';
import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { ModuleManager } from 'ui/ModuleManager';
import { FocusedCard } from 'ui/QueueCard/FocusedCard';
import BackIcon from '@mui/icons-material/West';
import { TabsTitle } from 'ui/TabsTitle';
import { useEffect } from 'react';

export const CardView = observer(
  ({ renderActionBar }: { renderActionBar: () => JSX.Element | undefined }) => {
    const {
      cardsStore: { cards },
      uiCardView: { exitCardFocus, selectedCardId },
    } = useStore();

    const kbar = useKBar();
    const selectedCard = cards[selectedCardId];

    useEffect(() => {
      const handleEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape' && selectedCardId) {
          exitCardFocus();
        }
      };
      // don't use mousetrap for shortcut because we want this to fire no matter what
      document.addEventListener('keydown', handleEsc);
      return () => document.removeEventListener('keydown', handleEsc);
    }, [selectedCardId, exitCardFocus]);

    if (!selectedCard) return null;

    return (
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          zIndex: (t) => t.zIndex.drawer,
          background: (t) => t.palette.background.default,
        }}
      >
        <Paper square sx={{ display: 'flex' }}>
          <TabsTitle label={''} />

          <Box sx={{ flexGrow: 1 }}>
            <Button
              variant="contained"
              size="small"
              startIcon={<BackIcon />}
              onClick={() => {
                exitCardFocus();
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => kbar.query.toggle()}
              sx={{ margin: (t) => t.spacing(1, 2) }}
            >
              Take Action
            </Button>
          </Box>
          <ModuleManager />
        </Paper>
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>{renderActionBar()}</div>
        <FocusedCard id={selectedCardId} />
      </Box>
    );
  }
);
