import { Chip, alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useStore } from 'logic';
import { SessionModule } from '@creative-kit/shared';
import { Card } from 'logic/card';
import { observer } from 'mobx-react-lite';
import { useSourceDrop } from 'ui/useSourceDrop';

interface ModuleDropTargetProps {
  module: SessionModule;
  card?: Card;
  position?: 'start' | 'end';
  afterCardId?: string;
}

const useStyles = makeStyles((_theme) => ({
  root: {
    flexBasis: 0,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
  },
}));

export const ModuleDropTarget = observer(
  ({ module, card, position, afterCardId }: ModuleDropTargetProps) => {
    const styles = useStyles();

    const {
      uiModulePowerColumnStore: { getSourceModulePowerColumn },
      uiImageSourceUpload: { uploadImageToSource },
      sessionsStore: { currentSession },
      uiSnackbarStore: { setSnackbarDetails },
      uiActiveSessionStore: { setSelectedSource },
    } = useStore();

    const { addConnection } = currentSession!;

    const powerColumn = getSourceModulePowerColumn(module.sourceId);
    const [, drop] = useSourceDrop((item) => {
      if ('sourceId' in item) {
        addConnection({
          rowIds: item.rowId,
          moduleId: module.id,
          card,
          position,
          afterCardId,
        });
      } else if ('files' in item) {
        // Upload files to module's source and add to module
        let { files } = item;
        if (!module.multi && files.length > 1) {
          files = files.slice(0, 1);
          setSnackbarDetails({
            description: 'Only the first dragged image was uploaded to this "Single" Bucket',
          });
        }
        const rowIds = files.map((file) => {
          const rowId = uploadImageToSource(module.sourceId, file);
          return rowId;
        });

        addConnection({
          rowIds,
          moduleId: module.id,
          card,
          position,
          afterCardId,
        });
      }
      // Set source after adding, since we could've dropped images
      setSelectedSource(module.sourceId);
      // Select module's power column after adding collection
      powerColumn.setSelectedModule(module.id);
    });

    let dropTitle = '';
    if (module.multi) {
      dropTitle = `Add to ${module.name}`;
    } else if (card && card.data.connections[module.id]) {
      dropTitle = `Replace ${module.name}`;
    } else {
      dropTitle = `Set ${module.name}`;
    }

    return (
      <div
        style={{
          backgroundColor: alpha(module.color, 0.8),
        }}
        ref={drop}
        className={styles.root}
      >
        <Chip label={dropTitle} />
      </div>
    );
  }
);
