import type { RootStore } from 'logic';
import { action, computed, makeObservable, observable } from 'mobx';

export class UiSessionNameStore {
  private rootStore: RootStore;
  inputName?: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      inputName: observable,
      setInputName: action.bound,
      savedName: computed,
    });
  }

  get savedName() {
    return this.rootStore.sessionsStore.currentSession?.data.name;
  }

  setInputName(newName?: string) {
    this.inputName = newName;
  }

  saveName = async () => {
    await this.rootStore.sessionsStore.currentSession!.setName(
      this.inputName || 'Untitled Session'
    );
  };
}
