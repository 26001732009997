import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { UploadProgress } from 'logic/ui-image-source-upload';
import { observer } from 'mobx-react-lite';
import ClearIcon from '@mui/icons-material/Clear';
import { CopiableField } from 'ui/CopiableField';

interface ImageUploadProgressProps {
  progress: UploadProgress;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  filenameProgress: {
    fontSize: 14,
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor: theme.palette.primary.light,
    zIndex: 0,
  },
  filename: {
    position: 'relative',
    zIndex: 1,
  },
  error: {
    marginTop: theme.spacing(0.5),
    fontWeight: 'bold',
    color: theme.palette.error.main,
  },
}));

export const ImageUploadProgress = observer((props: ImageUploadProgressProps) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <IconButton color="error" size="small" onClick={props.progress.abort}>
        <ClearIcon />
      </IconButton>
      <div className={styles.filenameProgress}>
        <span className={styles.filename}>
          <CopiableField text={props.progress.filename} />
        </span>
        {props.progress.error ? (
          <div className={styles.error}>{props.progress.error}</div>
        ) : (
          <div className={styles.progress} style={{ width: `${props.progress.progress * 100}%` }} />
        )}
      </div>
    </div>
  );
});
