import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { SessionModule } from '@creative-kit/shared';
import { useStore } from 'logic';
import { useMemo, useState, useEffect } from 'react';
import { ModuleEditorForm, ModuleFormState } from './ModuleEditorForm';

const ConfirmPopover = ({
  children,
  message,
  onConfirm,
}: {
  message: string;
  children?: React.ReactNode;
  onConfirm: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  return (
    <>
      <div
        style={{ display: 'inline-block' }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        aria-hidden="true"
      >
        {children}
      </div>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ style: { maxWidth: 400 } }}
        style={{ display: 'flex' }}
      >
        <div style={{ padding: '16px' }}>{message}</div>
        <DialogActions>
          <Button onClick={() => setAnchorEl(null)}>Cancel</Button>
          <Button onClick={onConfirm} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Popover>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  newModuleDialog: {
    width: 500,
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
}));

interface EditModuleDialogProps {
  module: SessionModule;
  open: boolean;
  onClose: () => void;
}

export const EditModuleDialog = observer(({ open, onClose, module }: EditModuleDialogProps) => {
  const {
    sessionsStore: { currentSession },
  } = useStore();

  const { upsertModule, deleteModule, removeModuleConnectionsFromCards } = currentSession!;

  const styles = useStyles();
  const defaultValues = useMemo(
    () => ({
      name: module.name ?? '',
      color: module.color ?? '',
      sourceId: module.sourceId ?? '',
      type: module.multi === true ? ('multi' as const) : ('single' as const),
      moduleFields: module.fields,
    }),
    [module.name, module.color, module.sourceId, module.multi, module.fields]
  );

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
    getValues,
  } = useForm<ModuleFormState>({
    reValidateMode: 'onChange',
    defaultValues,
  });
  const noEditsToForm = isDirty === false;
  /**
   * Module links need to be removed if
   * - switching to a different source
   * - going from multi connection to single connection
   */
  const isSwitchingModuleSource = getValues('sourceId') !== module.sourceId;
  const isSwitchingModuleFromMultiToSingle =
    getValues('type') === 'single' && module.multi === true;
  const areModuleConnectionsBeingRemoved =
    isSwitchingModuleSource || isSwitchingModuleFromMultiToSingle;
  const saveConfirmationMessage = isSwitchingModuleSource
    ? 'Changing the Source Connected to Modules will remove all existing connections to the original source – this action cannot be undone'
    : 'Changing the Module from Multi Connection to Single Connection will remove all existing connections to this module – this action cannot be undone';

  // Reset the form state whenever module values change to ensure editing module multiple times works as expected
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleUpdateModule = ({ name, sourceId, type, color, moduleFields }: ModuleFormState) => {
    const { id } = module;
    if (id && name && sourceId && type && color) {
      upsertModule({
        moduleId: id,
        name,
        sourceId,
        multi: type === 'multi',
        table: true,
        color,
        fields: moduleFields ?? module.fields,
      });
      // should this happen in data layer as a side effect of update?
      if (areModuleConnectionsBeingRemoved) {
        removeModuleConnectionsFromCards({ moduleId: id });
      }
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} disableEscapeKeyDown>
      <div className={styles.newModuleDialog}>
        <DialogTitle className={styles.dialogTitle}>Bucket Settings</DialogTitle>
        <DialogContent>
          <ModuleEditorForm control={control} />
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          {/* Confirm flow? */}
          <ConfirmPopover
            message="Deleting this module will remove the module and connections from all cards in this session – this action cannot be undone"
            onConfirm={() => deleteModule({ moduleId: module.id })}
          >
            <Button color="secondary">Delete</Button>
          </ConfirmPopover>
          <div>
            <Button onClick={handleClose}>Cancel</Button>
            {areModuleConnectionsBeingRemoved ? (
              <ConfirmPopover
                onConfirm={handleSubmit(handleUpdateModule)}
                message={saveConfirmationMessage}
              >
                <Button color="primary" disabled={noEditsToForm}>
                  Save
                </Button>
              </ConfirmPopover>
            ) : (
              <Button
                onClick={handleSubmit(handleUpdateModule)}
                color="primary"
                disabled={noEditsToForm}
              >
                Save
              </Button>
            )}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
});
