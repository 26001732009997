import { plural } from '@creative-kit/shared';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useStore } from 'logic';
import { createMetaShortcut, matchMetaShortcutToEvent } from 'logic/ui-keyboard-shortcuts';
import { observer } from 'mobx-react-lite';
import { KeyboardEvent, useReducer } from 'react';
import { NumericTextField } from 'ui/NumericTextField';
import ExpandedIcon from '@mui/icons-material/PlayArrow';
import { ModuleSelectionDropdown } from './ModuleSelectionDropdown';

interface AddCardsModalProps {
  container: HTMLElement | null;
}

export const AddCardsModal = observer(({ container }: AddCardsModalProps) => {
  const {
    uiBatchCardsStore: {
      showDialog,
      batchSettings,
      switchBatchMode,
      deactivateBatchMode,
      generatedCards,
      setNumCards,
      createCards,
      moduleOptions,
      setModuleId,
      setNumVariations,
      visibleSuffixes,
      setSuffix,
    },
  } = useStore();

  const [showVariationIdentfiers, toggleVariationIdentifiers] = useReducer((show) => !show, false);
  const onTabChange = (_: unknown, newValue: string) => switchBatchMode(newValue);

  const renderBatchContent = () => {
    if (batchSettings?.mode === 'blank') {
      return (
        <>
          <DialogContent
            sx={{
              m: 4,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                fontSize: 24,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Add &nbsp;
              <NumericTextField
                TextFieldProps={{
                  size: 'small',
                  type: 'number',
                  sx: { width: '4ch' },
                  InputProps: {
                    sx: {
                      fontSize: 24,
                      fontWeight: 'bold',
                    },
                  },
                  inputProps: {
                    min: 1,
                  },
                  autoFocus: true,
                }}
                value={batchSettings.numCards}
                onChange={setNumCards}
              />
              &nbsp; Asset
              <span
                style={{
                  visibility: batchSettings.numCards !== 1 ? 'visible' : 'hidden',
                }}
              >
                s
              </span>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={deactivateBatchMode}>Cancel</Button>
            <Button onClick={createCards} color="primary">
              CONTINUE({createMetaShortcut('Enter')})
            </Button>
          </DialogActions>
        </>
      );
    }

    if (batchSettings?.mode === 'source') {
      return (
        <>
          <DialogContent>
            <FormControl margin="normal">
              <FormLabel sx={{ marginBottom: (t) => t.spacing(1) }}>Bucket to populate</FormLabel>
              <ModuleSelectionDropdown
                modules={moduleOptions}
                selected={batchSettings.moduleId}
                onSelect={(m) => setModuleId(m.id)}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                Create &nbsp;
                <NumericTextField
                  TextFieldProps={{
                    autoFocus: true,
                    size: 'small',
                    type: 'number',
                    sx: { width: '4ch' },
                    inputProps: {
                      min: 1,
                    },
                  }}
                  value={batchSettings.numVariations}
                  onChange={setNumVariations}
                />
                &nbsp; {plural(batchSettings.numVariations, 'asset')}{' '}
                {plural(batchSettings.numVariations, 'variation')} per row in Library
              </Box>
            </FormControl>
            <FormControl fullWidth component="fieldset" margin="normal">
              <FormLabel
                onClick={toggleVariationIdentifiers}
                component="legend"
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  cursor: 'pointer',
                }}
              >
                <ExpandedIcon
                  sx={{
                    marginRight: (t) => t.spacing(2),
                    transform: showVariationIdentfiers ? 'rotate(90deg)' : '',
                  }}
                />
                Add variation identifiers:
              </FormLabel>
              <Collapse in={showVariationIdentfiers}>
                {visibleSuffixes.map((suffix, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <FormControl
                    margin="normal"
                    key={idx}
                    sx={{ flexDirection: 'row', alignItems: 'center' }}
                  >
                    <FormLabel sx={{ mr: 2 }}>Variation {idx + 1} ID</FormLabel>
                    <TextField
                      size="small"
                      value={suffix}
                      onChange={(e) => setSuffix(idx, e.target.value)}
                    />
                  </FormControl>
                ))}
              </Collapse>
            </FormControl>
            <Typography variant="h5" sx={{ textAlign: 'center', my: 2 }}>
              <u>
                {generatedCards.length} Asset
                {generatedCards.length !== 1 ? 's' : ''}
              </u>{' '}
              will be generated
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={deactivateBatchMode} color="error">
              Cancel
            </Button>
            <Button onClick={createCards} color="primary">
              Add Assets ({createMetaShortcut('enter')})
            </Button>
          </DialogActions>
        </>
      );
    }

    return null;
  };

  const handleKeyDownSubmit = (event: KeyboardEvent) => {
    if (matchMetaShortcutToEvent(event, 'Enter')) {
      createCards();
    }
  };

  return (
    <Dialog
      disablePortal
      disableEnforceFocus
      onClose={deactivateBatchMode}
      onKeyDown={handleKeyDownSubmit}
      open={showDialog}
      container={container}
      sx={{ position: 'absolute' }}
      BackdropProps={{
        sx: {
          position: 'absolute',
        },
      }}
    >
      {batchSettings ? (
        <>
          <DialogTitle>Add Asset Options</DialogTitle>
          <Tabs value={batchSettings?.mode} onChange={onTabChange}>
            <Tab value="blank" label="Basic" />
            <Tab value="source" label={<span>Advanced</span>} />
          </Tabs>
          {renderBatchContent()}
        </>
      ) : null}
    </Dialog>
  );
});
