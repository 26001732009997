import React, { useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Card } from 'logic/card';
import {
  Box,
  Button,
  CardContent,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CopiableField } from 'ui/CopiableField';
import StarIcon from '@mui/icons-material/Stars';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import MuiWarningIcon from '@mui/icons-material/Warning';
import { FindChunks, Chunk } from 'react-highlight-words';
import { useStore } from 'logic';
import { serializeField } from '@creative-kit/shared';
import { FormulaField } from 'ui/Formula/FormulaField';
import { Highlight } from 'ui/Highlight';
import { WarningIcon } from 'ui/WarningIcon';
import { TooltipList } from 'ui/TooltipList';
import { FormulaIcon } from 'ui/FormulaIcon';
import { ModuleWrapper } from './ModuleWrapper';

const findChunksForReverseMatches = ({ searchWords, textToHighlight }: FindChunks) => {
  const chunks: Chunk[] = [];
  const textLow = textToHighlight.toLowerCase();

  // Add chunks for every searchWord
  searchWords.forEach((sw) => {
    const swLow = (sw as string).toLowerCase();

    // Add support for highlighting reverse matches
    // searchWord="Taylor Swift 555" will match "Taylor Swift"
    if (swLow.startsWith(textLow)) {
      const start = 0;
      const end = textLow.length;
      chunks.push({
        start,
        end,
      });
    }

    // The complete word including whitespace should also be handled, e.g.
    // searchWord='Angela Mer' should be highlighted in 'Angela Merkel'
    if (textLow.startsWith(swLow)) {
      const start = 0;
      const end = swLow.length;
      chunks.push({
        start,
        end,
      });
    }
  });

  return chunks;
};

interface QueueCardFilenameProps {
  card: Card;
}

const useStyles = makeStyles((theme) => ({
  filenameInfo: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:first-child)': {
      marginTop: theme.spacing(1),
    },
  },
  filename: {
    fontWeight: 600,
  },
  filenameField: {
    border: `2px dashed ${theme.palette.divider}`,
    marginLeft: theme.spacing(1),
    padding: '0 3px',
    fontWeight: 400,
    display: 'flex',
    minWidth: 0,
  },
  suffix: {
    paddingLeft: theme.spacing(1),
  },
  moreIcon: {
    display: 'block',
  },
  suffixForm: {
    width: 90,
  },
  suffixInput: {
    padding: 0,
  },
  tooltip: {
    maxWidth: 600,
  },
  filenamePreview: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  settingsButton: {
    padding: 0,
    marginLeft: theme.spacing(1),
  },
  filenameErrors: {
    textTransform: 'uppercase',
  },
  filenameBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    color: theme.palette.text.primary,
    fontSize: '1.3rem',
    fontWeight: 300,
  },
}));

export const QueueCardFilename = observer(({ card }: QueueCardFilenameProps) => {
  const {
    sessionsStore: { currentSession },
    uiPermissions: { cannotEditCurrentSession },
    uiCardSearchStore: { hasSearch, searchTerms },
    uiFilenameStore: { init: initFilenameUi, cleanFormula, openDialog: openFilenameDialog },
  } = useStore();
  const { isFilenameVisible, switchFilenameVisibility } = currentSession!;
  const theme = useTheme();
  const styles = useStyles();
  const isFilenameEmpty = card.filename.length === 0;
  const filenameErrors = card.filenameInfo.errors;
  const suffixInputRef = useRef<HTMLInputElement>(null);
  const [showSuffix, setShowSuffix] = useState(false);
  const revealSuffix = () => setShowSuffix(true);
  const hideSuffix = () => {
    if (document.activeElement !== suffixInputRef.current) {
      setShowSuffix(false);
    }
  };
  const changeSuffix = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (suffixInputRef.current) {
        card.setSuffix(suffixInputRef.current.value);
        suffixInputRef.current.blur();
      }
    },
    [card]
  );

  const [contextAnchorEl, setContextAnchorEl] = useState<HTMLElement | null>(null);
  const handleContextMenuClose = () => setContextAnchorEl(null);

  const getFilenameDisplay = () => {
    if (filenameErrors.length > 0) {
      return (
        <Tooltip
          title={
            <>
              Generate this Filename by adding a connection to starred Buckets:
              <TooltipList>
                {filenameErrors.map((error) => (
                  <li key={error}>{error}</li>
                ))}
              </TooltipList>
            </>
          }
        >
          <span>
            <StarIcon sx={{ verticalAlign: 'text-top', fontSize: 'inherit' }} /> Connect Data to
            Starred Buckets for Filename
          </span>
        </Tooltip>
      );
    }
    if (isFilenameEmpty) {
      return (
        <Button
          variant="outlined"
          onClick={() => openFilenameDialog(card)}
          fullWidth
          disabled={cannotEditCurrentSession}
        >
          Build Filenames
        </Button>
      );
    }

    return (
      <div className={styles.filenameInfo}>
        {card.filenameNeedsAttention && <span>Original:</span>}
        <div className={styles.filenameField}>
          <CopiableField
            alwaysShowCopy
            oneLine
            text={card.filename}
            textElement={
              hasSearch ? (
                <Highlight
                  searchWords={searchTerms}
                  textToHighlight={card.filename}
                  findChunks={findChunksForReverseMatches}
                />
              ) : undefined
            }
          />
        </div>
        {card.isEditable && (
          <>
            <div className={styles.suffix} onMouseEnter={revealSuffix} onMouseLeave={hideSuffix}>
              {showSuffix || card.hasDuplicates ? (
                <form className={styles.suffixForm} onSubmit={changeSuffix}>
                  <InputBase
                    inputProps={{
                      className: styles.suffixInput,
                    }}
                    inputRef={suffixInputRef}
                    placeholder="Add Suffix"
                    onBlur={changeSuffix}
                    defaultValue={card.suffix}
                  />
                </form>
              ) : (
                <MoreIcon className={styles.moreIcon} />
              )}
            </div>
            {card.hasDuplicates && (
              <Tooltip title="It looks like this filename is a duplicate – add a suffix if you need to make this filename unique!">
                <MuiWarningIcon color="error" />
              </Tooltip>
            )}
          </>
        )}
      </div>
    );
  };

  if (isFilenameVisible === false && !card.doesSearchMatchFilename) {
    return null;
  }

  return (
    <ModuleWrapper
      header={
        <>
          <Tooltip
            classes={{ tooltip: styles.tooltip }}
            onOpen={initFilenameUi}
            title={
              <>
                <Typography align="center" variant="h5">
                  Filename Preview
                </Typography>
                {/* TODO: fix this */}
                <div className={styles.filenamePreview}>
                  {cleanFormula.map((f, idx) => (
                    <FormulaField key={serializeField(f)} field={f} index={idx} readOnly />
                  ))}
                </div>
              </>
            }
          >
            <Box pr={1}>
              <FormulaIcon color={theme.palette.text.primary} />
            </Box>
          </Tooltip>
          <div className={styles.filenameBox}>
            <div>Filename</div>
            <IconButton
              className={styles.settingsButton}
              size="small"
              onClick={(e) => setContextAnchorEl(e.currentTarget)}
            >
              <MoreIcon fontSize="small" color="primary" />
            </IconButton>
            <Menu
              anchorEl={contextAnchorEl}
              open={Boolean(contextAnchorEl)}
              onClose={handleContextMenuClose}
            >
              <MenuItem
                onClick={() => {
                  handleContextMenuClose();
                  switchFilenameVisibility(false);
                }}
              >
                Hide
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleContextMenuClose();
                  openFilenameDialog(card);
                }}
              >
                Filename Settings
              </MenuItem>
            </Menu>
          </div>
        </>
      }
    >
      <CardContent>
        {getFilenameDisplay()}
        {card.filenameNeedsAttention && (
          <div className={styles.filenameInfo}>
            <span style={{ whiteSpace: 'nowrap' }}>
              <WarningIcon /> New:
            </span>
            <div className={styles.filenameField}>
              <CopiableField alwaysShowCopy oneLine text={card.filename} />
            </div>
            <Button onClick={card.acceptNewFilename}>Update</Button>
            <Button onClick={card.ignoreNewFilename}>Ignore</Button>
          </div>
        )}
      </CardContent>
    </ModuleWrapper>
  );
});
