import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { Card } from 'logic/card';
import { Box, CardContent, IconButton, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import VisiblityOffIcon from '@mui/icons-material/VisibilityOff';
import { ModuleDropTarget } from 'ui/ModuleDropTargets/ModuleDropTarget';
import { ConnectionTooltip } from 'ui/ModuleIndicators/ConnectionTooltip';
import { useState } from 'react';
import { EditModuleDialog } from 'ui/ModuleManager/EditModuleDialog';
import { Row } from 'logic/row';
import { useSourceDrop } from 'ui/useSourceDrop';
import { ModuleWrapper } from './ModuleWrapper';
import { ModuleHeaderTooltip } from './ModuleHeaderTooltip';
import { QueueModuleBottomActions } from './QueueModuleBottomActions';
import { GhostModuleRow } from './GhostModuleRow';
import {
  ModuleBucketSize,
  ModuleConnectionDisplayMode,
  ModuleConnectionSize,
  SessionModule,
} from '@creative-kit/shared';

import ViewCompactRoundedIcon from '@mui/icons-material/ViewCompactRounded';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SettingsIcon from '@mui/icons-material/Settings';
import GridIcon from '@mui/icons-material/GridOn';
import CarouselIcon from '@mui/icons-material/ViewCarousel';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import GridOnIcon from '@mui/icons-material/GridOn';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { MenuItemData, NestedSettingsMenu } from '../NestedSettingsMenu';
import { ModuleConnectionsWrapper } from './ModuleConnectionsWrapper';
import { getPercentageForSize } from 'logic/ui-module-connection-display';

interface QueueCardModuleProps {
  moduleId: string;
  card: Card;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5px 8px 5px 8px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    background: 'transparent',
    height: '100%',
  },
  dropTargetWrapper: {
    position: 'absolute',
    display: 'flex',
    zIndex: 1,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  dropTargetChip: {
    justifyContent: 'center',
  },
  moduleHeader: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    flexGrow: 1,
    maxHeight: 24,
    fontWeight: 300,
    fontSize: '1.3rem',
  },
  settingsButton: {
    padding: 0,
    marginLeft: theme.spacing(1),
  },
  imageContainer: {
    display: 'flex',
    overflow: 'auto',
  },
}));

export const QueueCardModule = observer(({ moduleId, card }: QueueCardModuleProps) => {
  const {
    sessionsStore: { currentSession },
    sourcesStore: { sources },
    uiModuleConnectionDisplayStore: {
      setConnectionDisplayMode: setConnectionsDisplayMode,
      getConnectionDisplayMode,
      setConnectionsSize,
      getConnectionsSize,
      getModuleBucketSize,
      setModuleBucketSize,
    },
    uiActiveSessionStore: {
      deselectAllCardsOtherThan,
      toggleSelectedCard,
      selectModuleSource,
      currentSourceTab,
      selectedCardIds,
    },
    uiModulePowerColumnStore: { getSourceModulePowerColumn },
  } = useStore();
  const {
    toggleModuleVisibility,
    getIsModuleVisible,
    data: { modules },
  } = currentSession!;

  const module = modules[moduleId];
  const source = sources[module.sourceId];
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const connectionsDisplayMode = getConnectionDisplayMode(module.id);
  const moduleBucketSize = getModuleBucketSize(module.id);
  const connectionsSize = getConnectionsSize(module.id);

  const [menuItems, setMenuItems] = useState<MenuItemData[]>([
    {
      label: 'Bucket Size',
      icon: <AspectRatioIcon />,
      children: [
        {
          label: 'Full',
          icon: <FullscreenIcon />,
          onClick: () => {
            setModuleBucketSize(module, ModuleBucketSize.Full);
            handleMenuItemClick('Full', 'Bucket Size');
            handleModuleSettingsClose();
          },
          enabled: moduleBucketSize === ModuleBucketSize.Full,
        },
        {
          label: '2/3rds',
          icon: <DashboardIcon />,
          onClick: () => {
            setModuleBucketSize(module, ModuleBucketSize.TwoThirds);
            handleMenuItemClick('2/3rds', 'Bucket Size');
            handleModuleSettingsClose();
          },
          enabled: moduleBucketSize === ModuleBucketSize.TwoThirds,
        },
        {
          label: 'Half',
          icon: <ViewHeadlineIcon />,
          onClick: () => {
            setModuleBucketSize(module, ModuleBucketSize.Half);
            handleMenuItemClick('Half', 'Bucket Size');
            handleModuleSettingsClose();
          },
          enabled: moduleBucketSize === ModuleBucketSize.Half,
        },
        {
          label: 'Compact',
          icon: <GridOnIcon />,
          onClick: () => {
            setModuleBucketSize(module, ModuleBucketSize.Compact);
            handleMenuItemClick('Compact', 'Bucket Size');
            handleModuleSettingsClose();
          },
          enabled: moduleBucketSize === ModuleBucketSize.Compact,
        },
      ],
    },
    {
      label: 'Content Layout',
      icon: <ViewCompactRoundedIcon />,
      children: [
        {
          label: 'Grid',
          icon: <GridIcon />,
          onClick: () => {
            setConnectionsDisplayMode(module, ModuleConnectionDisplayMode.Grid);
            handleMenuItemClick('Grid', 'Content Layout');
          },
          enabled: connectionsDisplayMode === ModuleConnectionDisplayMode.Grid,
        },
        {
          label: 'Carousel',
          icon: <CarouselIcon />,
          onClick: () => {
            setConnectionsDisplayMode(module, ModuleConnectionDisplayMode.Carousel);
            handleMenuItemClick('Carousel', 'Content Layout');
          },
          enabled: connectionsDisplayMode == ModuleConnectionDisplayMode.Carousel,
        },
      ],
    },
    ...(source.type !== 'image'
      ? [
          {
            label: 'Content Size',
            icon: <GridIcon />,
            children: [
              {
                label: 'Full',
                icon: <FullscreenIcon />,
                onClick: () => {
                  setConnectionsSize(module, ModuleConnectionSize.Full);
                  handleMenuItemClick('Full', 'Content Size');
                },
                enabled: connectionsSize === ModuleConnectionSize.Full,
              },
              {
                label: 'Half',
                icon: <ViewHeadlineIcon />,
                onClick: () => {
                  setConnectionsSize(module, ModuleConnectionSize.Half);
                  handleMenuItemClick('Half', 'Content Size');
                },
                enabled: connectionsSize === ModuleConnectionSize.Half,
              },
              {
                label: 'Compact',
                icon: <GridOnIcon />,
                onClick: () => {
                  setConnectionsSize(module, ModuleConnectionSize.Compact);
                  handleMenuItemClick('Compact', 'Content Size');
                },
                enabled: connectionsSize === ModuleConnectionSize.Compact,
              },
            ],
          },
        ]
      : []),
    { label: 'Settings', icon: <SettingsIcon />, onClick: () => setModuleSettingsDialogOpen(true) },
    { label: 'Hide', icon: <VisibilityOffIcon />, onClick: () => toggleModuleVisibility(moduleId) },
  ]);

  const handleModuleSettingsOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleModuleSettingsClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (label: string, parentLabel: string) => {
    setMenuItems((prevItems) =>
      prevItems.map((item) => ({
        ...item,
        children:
          item.label === parentLabel
            ? item.children?.map((child: MenuItemData) => ({
                ...child,
                enabled: child.label === label,
              }))
            : item.children,
      }))
    );
  };

  const powerColumn = getSourceModulePowerColumn(module.sourceId);
  const [moduleSettingsDialogOpen, setModuleSettingsDialogOpen] = useState(false);

  const [{ item, isHovering, isDraggingSource }, drop] = useSourceDrop();

  const isSameSource =
    item &&
    (('sourceId' in item && item.sourceId === module.sourceId) ||
      ('files' in item && source.type === 'image'));
  const isDisabled = isDraggingSource && !isSameSource;
  const shouldShowDropPrompt = isHovering && isSameSource;
  const isModuleVisible = getIsModuleVisible(module.id);

  const connections = card.getModuleConnections(moduleId) as Row[];
  const hasConnection = connections.length > 0;

  const styles = useStyles();
  const theme = useTheme();
  const isModulePowerColumnSelected = powerColumn?.selectedModule?.id === module.id;
  const isModuleSourceSelected = currentSourceTab === source?.id;
  const isModuleActive = isModulePowerColumnSelected && isModuleSourceSelected;
  const moreThanOneSelected = selectedCardIds.length > 1;
  const handleModuleFocus = () => {
    selectModuleSource(module.id);
    powerColumn.setSelectedModule(module.id);

    deselectAllCardsOtherThan(card.id);
    if (!card.isSelected) {
      toggleSelectedCard(card.id);
    }
  };

  const addPrompt = (
    <Box
      pt={1}
      sx={{
        opacity: moreThanOneSelected ? 0 : 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <QueueModuleBottomActions hasConnection={hasConnection} card={card} module={module} />
    </Box>
  );

  const moduleBorder = (module: SessionModule) => {
    if (isModuleActive && card.isSelected) {
      return `1px solid ${module.color}`;
    }
  };

  return (
    <div
      ref={drop}
      style={{
        width: `${getPercentageForSize(moduleBucketSize)}%`,
        alignSelf: 'stetch',
      }}
    >
      <ModuleWrapper
        className={styles.root}
        style={{
          border: moduleBorder(module),
        }}
        isSelected={isModuleActive}
        headerStyle={{
          cursor: isDisabled ? 'none' : 'initial',
          // opacity: isDisabled ? 0.33 : 1,
          // Use box shadow for outline to avoid affecting the round corners of module card
          boxShadow:
            isHovering && !isDisabled
              ? `0px 0px 0px 3px ${theme.palette.primary.light}`
              : undefined,
        }}
        header={
          <>
            <ModuleHeaderTooltip module={module} />
            <div
              className={styles.moduleHeader}
              onClick={handleModuleFocus}
              onKeyDown={handleModuleFocus}
              role="menu"
              tabIndex={0}
            >
              <Box
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {module.multi ? `${module.name} (${connections.length})` : module.name}
              </Box>
              <Box display="flex" alignItems="center" ml={0.5}>
                {!isModuleVisible && (
                  <VisiblityOffIcon
                    sx={{
                      marginRight: (t) => t.spacing(0.5),
                      fontSize: 'inherit',
                      alignSelf: 'baseline',
                    }}
                  />
                )}
                <ConnectionTooltip module={module} />
                <IconButton
                  className={styles.settingsButton}
                  size="small"
                  onClick={handleModuleSettingsOpen}
                >
                  <MoreIcon fontSize="small" />
                </IconButton>
                <NestedSettingsMenu
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  onClose={handleModuleSettingsClose}
                  menuItems={menuItems}
                />
              </Box>
            </div>
          </>
        }
      >
        <EditModuleDialog
          open={moduleSettingsDialogOpen}
          onClose={() => setModuleSettingsDialogOpen(false)}
          module={module}
        />
        {shouldShowDropPrompt && (
          <div className={styles.dropTargetWrapper}>
            <ModuleDropTarget card={card} module={module} />
          </div>
        )}
        <CardContent
          sx={{
            height: '100%',
            '&:last-child': {
              paddingBottom: theme.spacing(1),
            },
          }}
          onClick={() => {
            /**
             * Only focus module on click when:
             * - No rows selected for module
             */
            handleModuleFocus();
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center', // Add this line
              width: '100%',
              height: '100%',
            }}
          >
            {hasConnection ? (
              <ModuleConnectionsWrapper
                module={module}
                source={source}
                card={card}
                connections={connections}
              />
            ) : (
              <GhostModuleRow card={card} module={module} />
            )}
            <Box sx={{ textAlign: 'right', textTransform: 'uppercase' }}>{addPrompt}</Box>
          </div>
        </CardContent>
      </ModuleWrapper>
    </div>
  );
});
