import { Action } from 'kbar';

export interface KbarSection {
  section: string;
  keywords?: string;
  isVisible?: boolean;
  actions: Action[];
}

export const expandKbarActions = (sectionActions: KbarSection[]): Action[] =>
  sectionActions.flatMap(({ section, actions, keywords, isVisible }) =>
    isVisible !== undefined && isVisible === false
      ? []
      : actions.map((action) => ({
          ...action,
          section,
          keywords: `${keywords ?? ''} ${action.keywords ?? ''}`,
        }))
  );
