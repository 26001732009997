import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { ulid } from 'ulid';

interface ImageUploadButtonProps {
  children: React.ReactNode;
  multiple?: boolean;
  sourceId: string;
  onUploadSuccess: (rowIds: string[]) => void;
}

/**
 * Wraps some content to allow for popping up a file dialog to upload files to
 * source
 */
export const ImageUploadButton = observer(
  ({ children, sourceId, onUploadSuccess, multiple }: ImageUploadButtonProps) => {
    const {
      uiImageSourceUpload: { uploadImageToSource },
    } = useStore();

    const fileInput = useRef<HTMLInputElement>(null);
    const fileInputId = useRef(`file-input-${ulid()}`);
    const handleAddImageRow = (files: FileList | null) => {
      if (!files) return;

      const fileArray = Array.from(files);
      const rowIds = fileArray.map((file) => uploadImageToSource(sourceId, file));

      onUploadSuccess(rowIds);
    };

    return (
      <>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id={fileInputId.current}
          type="file"
          multiple={multiple}
          onChange={(e) => {
            const { files } = e.target;
            handleAddImageRow(files);
          }}
          ref={fileInput}
        />
        {/* eslint-disable-next-line */}
        <span onClick={() => fileInput.current?.click()}>{children}</span>
      </>
    );
  }
);
