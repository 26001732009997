/**
 * This data is stored at /sessions/{sessionId}/userSettings/{userId}
 * Contains user-specific settings for a given session
 */

export enum ModuleConnectionSize {
  Full = 'Full',
  Half = 'Half',
  Compact = 'Compact',
}

interface SourceSearchAll {
  type: 'all';
}

interface SourceSearchVisible {
  type: 'visible';
}

interface SourceSearchCustomFields {
  type: 'custom';
  fieldIds: string[];
}

export type SourceSearchSetting = SourceSearchAll | SourceSearchVisible | SourceSearchCustomFields;

interface QueueSearchAll {
  type: 'all';
  messages: boolean;
}

interface QueueSearchVisible {
  type: 'visible';
  messages: boolean;
}

interface QueueSearchModule {
  type: 'module';
  moduleId: string;
  fieldIds: string[];
}

export type QueueSearchSetting = QueueSearchAll | QueueSearchVisible | QueueSearchModule;

export interface FirestoreUserSettings {
  sourceSearch?: Record<string, SourceSearchSetting>;
  queueSearch?: QueueSearchSetting;
  connectionDisplaySettings?: Record<string, ModuleConnectionSize>;
}

export interface FirestoreUserApproval {
  canUseApp: 'True' | 'False';
  userId: string;
}

export interface FirestoreUser {
  canUseApp: boolean;
  userId: string;
  email: string;
  name: string;
  photoURL: string | null;
}
