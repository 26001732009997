import type { RootStore } from 'logic';
import { action, computed, makeObservable } from 'mobx';
import { QueueTabName } from './ui-active-session';

export class UiQueueSelectorStore {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable<this>(this, {
      areAllCardsSelected: computed,
      filteredCardIdsForTab: computed,
      toggleAllCardsInTab: action.bound,
    });
  }

  get filteredCardIdsForTab() {
    const currentSession = this.rootStore.sessionsStore.currentSession;
    if (!currentSession) return [];

    return this.rootStore.uiActiveSessionStore.selectedQueueTab == QueueTabName.InProgress
      ? currentSession.inProgressVisibleCardIds
      : currentSession.doneVisibleCardIds;
  }

  toggleAllCardsInTab() {
    Object.entries(this.rootStore.cardsStore.cards)
      .filter(([cid, _card]) => this.filteredCardIdsForTab.includes(cid))
      .forEach(([_id, card]) => card.toggleSelection());
  }

  get areAllCardsSelected() {
    return (
      this.filteredCardIdsForTab.length ==
      this.rootStore.uiActiveSessionStore.selectedCardIds.length
    );
  }

  get isQueueSelectorChecked() {
    return (
      this.rootStore.uiCardView.isCardFocused ||
      this.areAllCardsSelected ||
      this.rootStore.uiActiveSessionStore.selectedTabActive
    );
  }

  get isQueueSelectorDisabled() {
    return this.rootStore.uiCardView.isCardFocused;
  }

  get isQueueSelectorIndeterminate() {
    return !this.isQueueSelectorChecked && this.rootStore.uiActiveSessionStore.hasSelectedCards;
  }
}
