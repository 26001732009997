import type { RootStore } from 'logic';
import { Route, RouterState, RouterStore } from 'mobx-state-router';

export enum RouteNames {
  HOME = 'home',
  IMPORT_CSV = 'importcsv',
  IMPORT_LIST = 'importlist',
  IMPORT_SPREADSHEET = 'importspreadsheet',
  IMPORT_IMAGES = 'importimages',
  TOUR = 'tour',
  SESSION = 'session',
  CARD = 'card',
}

export const routes: Route[] = [
  {
    name: RouteNames.HOME,
    pattern: '/',
  },
  {
    name: RouteNames.IMPORT_CSV,
    pattern: '/import/csv',
  },
  {
    name: RouteNames.IMPORT_LIST,
    pattern: '/import/list',
  },
  {
    name: RouteNames.IMPORT_SPREADSHEET,
    pattern: '/import/custom',
  },
  {
    name: RouteNames.IMPORT_IMAGES,
    pattern: '/import/images',
  },
  {
    name: RouteNames.TOUR,
    pattern: '/tour',
  },
  {
    /*
      Data loading and watching for this route happens as reactions in stores:
        - When user is changed, sessions for user are watched in sessionsStore
        - When sessionsStore's currentSession changes:
          - cardsStore watches cardList for current session
          - commentsStore watches comments for current session
          - sourcesStore watches sources for current session
            - each source watches its rows collection
    */
    name: RouteNames.SESSION,
    pattern: '/session/:sessionId',
  },
  {
    name: RouteNames.CARD,
    pattern: '/session/:sessionId/card/:cardId',
  },
];

export class UiRouterStore extends RouterStore {
  constructor(rootStore: RootStore, routerRoutes: Route[], notFoundState: RouterState) {
    super(routerRoutes, notFoundState, { rootStore });
  }
  public goTo(
    path: RouteNames,
    options?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    }
  ) {
    return super.goTo(path, options);
  }
}
