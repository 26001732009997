import { Paper, Tab, Tabs, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { SourceView } from 'ui/SourceView';
import { SourceTab } from 'ui/SourceTab';

import { observer } from 'mobx-react-lite';
import { useStore } from 'logic';
import { NEW_SOURCE_TAB } from 'logic/ui-active-session';
import { TabsTitle } from './TabsTitle';
import { ImportSource } from './ImportSource';

export const SourcePane = observer(() => {
  const {
    sessionsStore: { currentSession },
    uiPermissions: { canEditCurrentSession },
    uiActiveSessionStore: { currentSourceTab, setSelectedSource },
    uiSourceTabDragDrop: { sourceTabDragStatus, reorderedSources },
  } = useStore();

  const renderCurrentSource = () =>
    currentSourceTab === NEW_SOURCE_TAB ? <ImportSource /> : <SourceView id={currentSourceTab} />;

  if (!currentSession) {
    return null;
  }

  const {
    data: { sourceIds },
  } = currentSession;
  const ids = sourceTabDragStatus.targetId ? reorderedSources : sourceIds;

  return (
    <>
      <Paper
        sx={(theme) => ({ display: 'flex', backgroundColor: theme.palette.background.paper })}
        square
      >
        <TabsTitle label="Library" />
        <Box display="flex" sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <Tabs
            // ref={rootRef}
            value={currentSourceTab}
            scrollButtons="auto"
            variant="scrollable"
            onChange={(_, value) => {
              setSelectedSource(value);
            }}
            sx={{
              marginRight: 'auto',
              '& .MuiTabs-flexContainer': {
                height: '100%',
              },
            }}
          >
            {ids.map((sourceId) => (
              <SourceTab value={sourceId} key={sourceId} sourceId={sourceId} />
            ))}
            {canEditCurrentSession && (
              <Tab
                icon={<AddIcon sx={{ fontSize: 'inherit' }} />}
                iconPosition="start"
                label="NEW SOURCE"
                style={{
                  minHeight: 0,
                }}
                value={NEW_SOURCE_TAB}
              />
            )}
          </Tabs>
        </Box>
      </Paper>
      {renderCurrentSource()}
    </>
  );
});
