import { observer } from 'mobx-react-lite';
import { useStore } from 'logic';
import { createMetaShortcut } from 'logic/ui-keyboard-shortcuts';

import { Button, Box, Chip, Badge, Tooltip } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';
import { ButtonPopover } from './ButtonPopover';

export const QueueFilterMenu = observer(() => {
  const {
    uiModulePowerColumnStore: {
      clearModuleFilters,
      rowSelectionsByModuleId,
      clearAllModuleFilters,
    },
    uiActiveSessionStore: { deselectAllCards },
    uiCardSearchStore: { hasSearch, setCurrentSearch },
    sessionsStore: { currentSession },
  } = useStore();

  const numFilters = Object.values(rowSelectionsByModuleId).length + (hasSearch ? 1 : 0);

  if (!currentSession) {
    return null;
  }

  const handleClearFilters = () => {
    setCurrentSearch(undefined);
    clearAllModuleFilters();
    deselectAllCards();
  };

  return (
    <>
      {currentSession.isFilteringInProgress && (
        <Tooltip title={createMetaShortcut('Esc')}>
          <Button
            size="small"
            onClick={handleClearFilters}
            sx={{
              marginRight: (t) => t.spacing(1),
            }}
          >
            Clear
          </Button>
        </Tooltip>
      )}
      <ButtonPopover
        button={
          <Badge
            badgeContent={numFilters}
            color="error"
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'top',
            }}
          >
            <Button variant="outlined" size="small" startIcon={<FilterListIcon />}>
              FILTERS
            </Button>
          </Badge>
        }
        icon={<FilterListIcon />}
        title="Active Filters"
      >
        <Box py={2}>
          {numFilters === 0 && !hasSearch && 'No active filters'}
          {Object.entries(rowSelectionsByModuleId).map(([moduleId, selections]) => {
            const module = currentSession.data.modules[moduleId];

            return (
              <Chip
                sx={{
                  backgroundColor: module.color,
                  color: (t) => t.palette.getContrastText(module.color),
                  textTransform: 'uppercase',
                  marginBottom: 1,
                  '&:not(:last-child)': {
                    marginRight: 1,
                  },
                }}
                size="small"
                key={moduleId}
                onDelete={() => clearModuleFilters(moduleId)}
                label={`(${selections.size}) ${module.name}`}
              />
            );
          })}
          {hasSearch && (
            <Chip
              sx={{
                marginBottom: 1,
                '&:not(:last-child)': {
                  marginRight: 1,
                },
              }}
              size="small"
              onDelete={() => setCurrentSearch(undefined)}
              label="CURRENT SEARCH"
            />
          )}
        </Box>
        <Box py={2} textAlign="right">
          <Button onClick={handleClearFilters}>Clear All ({createMetaShortcut('Esc')})</Button>
        </Box>
      </ButtonPopover>
    </>
  );
});
