import { FirestoreSourceRow } from '@creative-kit/shared';
import { action, makeObservable, observable } from 'mobx';
import { CloudinaryImage } from '@cloudinary/url-gen';

export class UiImageModal {
  image?: FirestoreSourceRow['image'];

  constructor() {
    makeObservable(this, {
      image: observable,
      setImage: action.bound,
      clearImage: action.bound,
    });
  }

  setImage(image: FirestoreSourceRow['image']) {
    this.image = image;
  }

  clearImage() {
    this.image = undefined;
  }

  downloadImage = () => {
    if (this.image) {
      const imageSource = new CloudinaryImage(
        this.image.storage.publicId,
        { cloudName: this.image.storage.cloudName },
        { secure: true }
      ).addFlag('attachment');

      const a = document.createElement('a');
      a.href = imageSource.toURL();
      a.download = this.image.originalFilename;
      a.click();
    }
  };
}
