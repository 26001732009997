import { lighten } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useStore } from 'logic';
import { ItemTypes } from 'logic/dragdrop';
import { observer } from 'mobx-react-lite';
import { RowRendererProps, Row as DataGridRow } from '@creative-kit/react-data-grid';
import { useDrag } from 'react-dnd';
import { Row } from 'logic/row';
import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  deleted: {
    '&&&&': {
      // Override the styles of RDG
      backgroundColor: lighten(theme.palette.error.light, 0.2),
    },
    '& .rdg-cell-selected': {
      boxShadow: 'none',
    },
  },
}));

export const SourceRow = observer((props: RowRendererProps<Row>) => {
  const {
    uiPermissions: { cannotEditCurrentSession },
  } = useStore();
  const sourceRef = useRef(null);
  const [, drag] = useDrag({
    type: ItemTypes.SourceEntry,
    item: {
      rowId: props.row.id,
      sourceId: props.row.sourceId,
    },
  });

  const { sourcesStore } = useStore();
  const source = sourcesStore.sources[props.row.sourceId];
  const isDeleted = source.isRowMarkedForDeletion(props.row.id) || props.row.isDeleted;

  if (isDeleted || cannotEditCurrentSession) {
    drag(null);
  } else {
    drag(sourceRef);
  }

  const styles = useStyles();
  return (
    <DataGridRow ref={sourceRef} {...props} className={isDeleted ? styles.deleted : undefined} />
  );
});
