import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { SessionModule } from '@creative-kit/shared';
import { Card } from 'logic/card';
import { AddModuleRowButton } from './AddModuleRowButton';

interface QueueModuleBottomActionsProps {
  card: Card;
  actionColor?: string;
  module: SessionModule;
  hasConnection: boolean;
}

export const QueueModuleBottomActions = observer(
  ({ module, card, actionColor }: QueueModuleBottomActionsProps) => {
    const {
      uiActiveSessionStore: { currentSourceTab },
      uiPermissions: { cannotEditCurrentSession },
    } = useStore();
    const isModuleSourceOpen = module.sourceId === currentSourceTab;

    if (cannotEditCurrentSession) {
      return null;
    }

    if (isModuleSourceOpen) {
      return (
        <>
          <AddModuleRowButton card={card} module={module} buttonSx={{ color: actionColor }} />
        </>
      );
    }

    return (
      <>
        <AddModuleRowButton card={card} module={module} buttonSx={{ color: actionColor }} />
      </>
    );
  }
);
