import React, { useState } from 'react';
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreIcon from '@mui/icons-material/MoreVert';
import ArchiveIcon from '@mui/icons-material/Archive';
import DuplicateIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import type { Session } from 'logic/session';

interface SessionItemProps {
  session: Session;
  onClick: () => void;
  archiveSession: (sessionId: string) => void;
  deleteSession: (sessionId: string) => void;
  duplicateSession: (sessionId: string) => void;
}

export const SessionItem = ({
  session,
  onClick,
  archiveSession,
  deleteSession,
  duplicateSession,
}: SessionItemProps) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const toggleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(menuAnchor ? null : e.currentTarget);
  };
  const closeMenu = () => setMenuAnchor(null);

  return (
    <ListItem button dense onClick={onClick}>
      {session.data.isArchived && !session.data.deletedAt && (
        <ListItemIcon>
          <CheckCircleIcon htmlColor="#008466" />
        </ListItemIcon>
      )}
      <ListItemText>{session.data.name}</ListItemText>
      {!session.data.deletedAt && (
        <ListItemSecondaryAction>
          <IconButton size="small" onClick={toggleMenu}>
            <MoreIcon />
          </IconButton>
          <Menu
            open={!!menuAnchor}
            anchorEl={menuAnchor}
            onClose={closeMenu}
            anchorReference="anchorEl"
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
          >
            <MenuItem onClick={() => duplicateSession(session.id)}>
              <ListItemIcon>
                <DuplicateIcon />
              </ListItemIcon>
              <ListItemText>Duplicate Project</ListItemText>
            </MenuItem>
            {!session.data.isArchived && (
              <MenuItem onClick={() => archiveSession(session.id)}>
                <ListItemIcon>
                  <ArchiveIcon />
                </ListItemIcon>
                <ListItemText>Archive Project</ListItemText>
              </MenuItem>
            )}
            <MenuItem onClick={() => deleteSession(session.id)}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Trash Project</ListItemText>
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
