import { action, computed, makeObservable, observable } from 'mobx';
import type ft from 'firebase';
import { db as firestoreDb, FieldValue } from 'firebase-modules';
import { monotonicFactory, ulid } from 'ulid';

import type { RootStore } from 'logic';
import {
  BaseSource,
  FirestoreSource,
  FirestoreSourceRow,
  RowData,
  safeBatch,
  SourceSearchSetting,
} from '@creative-kit/shared';

import { db } from './data';
import { Row } from './row';

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const ADD_NEW_COLUMN_KEY = '__CQ_ADD_FIELD_COLUMN';
export const DEFAULT_NEW_COLUMN_NAME = 'New Column';

export const MODULE_POWER_COLUMN_KEY = '__CQ_MODULE_POWER_COLUMN';

export const DELETE_ROW_COLUMN_KEY = '__CQ_DELETE_ROW_COLUMN';

export const IMAGE_COLUMN_KEY = '__CQ_IMAGE_COLUMN';

export interface FieldDescription {
  fieldId: string;
  fieldName: string;
}
export class Source extends BaseSource {
  private stopListeningToRows = () => {};
  private rootStore: RootStore;
  private ref: ft.firestore.DocumentReference<FirestoreSource>;
  rows: Record<string, Row> = {};

  constructor(id: string, sessionId: string, data: FirestoreSource, rootStore: RootStore) {
    super(id, sessionId, data);

    makeObservable(this, {
      data: observable.struct,
      rows: observable,
      updateData: action,
      updateRows: action.bound,
      deleteColumn: action.bound,
      orderedRows: computed,
      filteredRows: computed,
      visibleColumns: computed,
      sourceCount: computed,
      showPowerColumn: computed,
      getAddedColumns: action.bound,
      fields: computed,
      searchResults: computed,
      fieldIdsWithResults: computed,
    });

    this.rootStore = rootStore;
    this.ref = db.sessionSources(this.sessionId).doc(this.id);
  }

  getFieldInfo = (fieldId: string): FieldDescription => {
    if (fieldId === MODULE_POWER_COLUMN_KEY) {
      return {
        fieldId: MODULE_POWER_COLUMN_KEY,
        fieldName: 'Power Column',
      };
    }

    return {
      fieldId,
      fieldName: this.data.fieldNames[fieldId],
    };
  };

  get orderedRows() {
    return Object.values(this.rows)
      .filter((r) => !r.isDeleted)
      .sort((r1, r2) => (r1.id > r2.id ? 1 : -1));
  }

  get searchResults() {
    return this.getSearchResults();
  }

  get filteredRowsWithAllFields() {
    return this.getSearchResults(true).filteredRows;
  }

  get hasFilteredRowsWithAllFields() {
    return this.filteredRowsWithAllFields.length > 0;
  }

  getSearchResults(searchAll?: boolean) {
    const terms = this.rootStore.uiSourceSearchStore.getSearchTerms(this.id);
    if (!terms.length) {
      return {
        filteredRows: this.orderedRows,
        fieldIdsWithResults: [],
      };
    }

    const regex = new RegExp(terms.map((t) => escapeRegExp(t.trim())).join('|'), 'i');
    const powerColumn = this.rootStore.uiModulePowerColumnStore.getSourceModulePowerColumn(this.id);

    const fieldIdsWithResults = new Set<string>();

    const filteredRows = this.orderedRows.filter((row) => {
      let rowMatches = false;

      this.getSearchColumns(searchAll).forEach((col) => {
        if (col.fieldId === MODULE_POWER_COLUMN_KEY) {
          const countString = (
            powerColumn?.getCurrentModuleLinkCount(row.id)?.total || 0
          ).toString();
          if (terms.includes(countString)) {
            rowMatches = true;
            fieldIdsWithResults.add(col.fieldId);
          }
        } else if (regex.test(row.getValue(col.fieldId) ?? '')) {
          rowMatches = true;
          fieldIdsWithResults.add(col.fieldId);
        }
      });

      return rowMatches;
    });

    return {
      filteredRows,
      fieldIdsWithResults: Array.from(fieldIdsWithResults),
    };
  }

  get filteredRows() {
    return this.searchResults.filteredRows;
  }

  get fieldIdsWithResults() {
    return this.searchResults.fieldIdsWithResults;
  }

  get searchSetting(): SourceSearchSetting {
    const { userSettings } = this.rootStore.userSettingsStore;
    return userSettings.sourceSearch?.[this.id] || { type: 'visible' };
  }

  getSearchColumns(searchAll?: boolean) {
    if (searchAll || this.searchSetting.type === 'all') {
      return this.data.fieldIds.concat([MODULE_POWER_COLUMN_KEY]).map(this.getFieldInfo);
    }

    if (this.searchSetting.type === 'visible') {
      return [...this.activeFields, ...(this.showPowerColumn ? [MODULE_POWER_COLUMN_KEY] : [])].map(
        this.getFieldInfo
      );
    }

    return this.searchSetting.fieldIds.map(this.getFieldInfo);
  }

  get ownFields() {
    return this.data.fieldIds.map(this.getFieldInfo);
  }

  get fields() {
    return [MODULE_POWER_COLUMN_KEY, ...this.data.fieldIds].map(this.getFieldInfo);
  }

  get activeFields() {
    const { currentSession } = this.rootStore.sessionsStore;
    const { fieldsById } = this.rootStore.uiSourceFieldsStore;
    const activeFields =
      (currentSession && fieldsById[currentSession.id]?.[this.id]?.fields) || this.data.fieldIds;
    return this.data.fieldIds.filter((f) => activeFields.includes(f));
  }

  get visibleFields() {
    const { currentSession } = this.rootStore.sessionsStore;
    const { fieldsById } = this.rootStore.uiSourceFieldsStore;
    const activeFields =
      (currentSession && fieldsById[currentSession.id]?.[this.id]?.fields) || this.data.fieldIds;
    return this.data.fieldIds.filter(
      (f) => activeFields.includes(f) || this.fieldIdsWithResults.includes(f)
    );
  }

  get visibleColumns() {
    return this.visibleFields.map(this.getFieldInfo);
  }

  get showPowerColumn(): boolean {
    const { currentSession } = this.rootStore.sessionsStore;
    const { fieldsById } = this.rootStore.uiSourceFieldsStore;
    const currentShowPowerColumn = currentSession
      ? fieldsById[currentSession.id]?.[this.id]?.showPowerColumn
      : undefined;
    if (currentShowPowerColumn === undefined) {
      return true;
    }

    return currentShowPowerColumn || this.fieldIdsWithResults.includes(MODULE_POWER_COLUMN_KEY);
  }

  get sourceCount() {
    const {
      uiSourceSearchStore: { getHasSearch },
    } = this.rootStore;

    const allRows = this.orderedRows.length;

    if (!getHasSearch(this.id)) {
      return `${allRows}`;
    }

    const visibleRows = this.filteredRows.length;
    return `${visibleRows}/${allRows}`;
  }

  updateData(newData: FirestoreSource) {
    this.data = newData;
  }

  startListening() {
    this.stopListeningToRows();
    this.stopListeningToRows = db
      .sessionSourceRows(this.sessionId, this.id)
      .onSnapshot(this.updateRows);
  }

  stopListening() {
    this.stopListeningToRows();
  }

  updateRows(snapshot: ft.firestore.QuerySnapshot<FirestoreSourceRow>) {
    snapshot.docChanges().forEach((change) => {
      const changeType = change.type;
      const changeDoc = change.doc;
      if (changeType === 'added' || changeType === 'modified') {
        this.rows[changeDoc.id] = new Row(
          changeDoc.id,
          this.id,
          this.sessionId,
          changeDoc.data(),
          this.rootStore
        );
      } else {
        delete this.rows[changeDoc.id];
      }
    });
  }

  async addNewRows(
    rows: RowData[],
    { addEmptyRows }: { addEmptyRows: boolean } = { addEmptyRows: false }
  ) {
    const BATCH_LIMIT = 250;
    const batches = [];
    const ulidFactory = monotonicFactory();
    const rowsCollection = db.sessionSourceRows(this.sessionId, this.id);

    const finalRows = rows
      .filter((row) => Object.values(row).some((d) => typeof d === 'string' && d.length > 0))
      .map((row) =>
        Object.entries(row).reduce(
          (finalRow, [key, value]) => ({
            ...finalRow,
            ...(value !== undefined ? { [key]: value } : {}),
          }),
          {} as RowData
        )
      );

    while (finalRows.length > 0) {
      const slice = finalRows.splice(0, BATCH_LIMIT);
      const batch = firestoreDb.batch();
      batches.push(batch);
      for (const row of slice) {
        const rowDoc = rowsCollection.doc(ulidFactory());
        const isRowEmpty = Object.values(row).every((value) => value === '');
        if (addEmptyRows || !isRowEmpty) {
          batch.set(rowDoc, { fieldValues: row });
        }
      }
    }
    return Promise.all(batches.map((b) => b.commit()));
  }

  async deleteFieldId(deleteFieldId: string) {
    // Remove column value from every source row
    const originalColumnValues: Record<string, string> = {};
    const originalModuleFields: Record<string, string[]> = {};

    await safeBatch(firestoreDb, Object.values(this.rows), (batch, row) => {
      originalColumnValues[row.id] = row.getFieldValues()[deleteFieldId];

      const rowRef = db.sessionSourceRows(this.sessionId, this.id).doc(row.id);
      batch.set(
        rowRef,
        {
          fieldValues: {
            ...row.getFieldValues(),
            [deleteFieldId]: FieldValue.delete() as unknown as string,
          },
        },
        { merge: true }
      );
    });

    const { currentSession } = this.rootStore.sessionsStore;
    const { getModulesForSource, upsertModule } = currentSession!;

    // Go through modules for this source and delete any that reference this column
    getModulesForSource(this.id).forEach((module) => {
      originalModuleFields[module.id] = module.fields;
      const updatedFields = module.fields.filter((id) => id !== deleteFieldId);
      upsertModule({
        ...module,
        moduleId: module.id,
        fields: updatedFields,
      });
    });

    // Delete column from this source
    const originalFieldIds = [...this.data.fieldIds];
    const originalFieldNames = { ...this.data.fieldNames };
    const fieldIds = originalFieldIds.filter((id) => id !== deleteFieldId);
    const fieldNames = { ...this.data.fieldNames };
    delete fieldNames[deleteFieldId];

    this.ref.set(
      {
        fieldIds,
        fieldNames,
      },
      { merge: true }
    );

    this.rootStore.uiSnackbarStore.setUndoDetails(async () => {
      this.ref.set(
        {
          fieldIds: originalFieldIds,
          fieldNames: originalFieldNames,
        },
        { merge: true }
      );

      await safeBatch(firestoreDb, Object.values(this.rows), (batch, row) => {
        const rowRef = db.sessionSourceRows(this.sessionId, this.id).doc(row.id);
        batch.set(
          rowRef,
          {
            fieldValues: {
              ...row.getFieldValues(),
              [deleteFieldId]: originalColumnValues[row.id],
            },
          },
          { merge: true }
        );
      });

      getModulesForSource(this.id).forEach((module) => {
        const originalFields = originalModuleFields[module.id];
        upsertModule({
          ...module,
          moduleId: module.id,
          fields: originalFields,
        });
      });
    }, `Column ${originalFieldNames[deleteFieldId]} has been deleted from ${this.data.name}`);
  }

  addEmptyRow() {
    const rowsCollection = db.sessionSourceRows(this.sessionId, this.id);
    const newRow = rowsCollection.doc(ulid());
    newRow.set({
      fieldValues: {},
    });
    return newRow.id;
  }

  addEmptyImageRow() {
    const rowsCollection = db.sessionSourceRows(this.sessionId, this.id);
    const newRow = rowsCollection.doc(ulid());
    newRow.set({
      fieldValues: {},
    });
    return newRow.id;
  }

  renameSource(newName: string) {
    return this.ref.set(
      {
        name: newName,
      },
      { merge: true }
    );
  }

  get isEditModeActive() {
    return !!this.data.editMode;
  }

  get hasEdits() {
    const { editMode } = this.data;
    if (!editMode) {
      return false;
    }

    return (
      this.numFieldsAdded > 0 ||
      this.numRowsDeleted > 0 ||
      this.numCellsModified > 0 ||
      this.numFieldsRenamed > 0 ||
      this.numColumnsDeleted > 0
    );
  }

  get columnsDeleted() {
    return this.data.editMode?.deletedFieldIds ?? [];
  }

  get numColumnsDeleted() {
    return this.columnsDeleted.length;
  }

  get rowsDeleted() {
    return this.data.editMode?.deletedRowIds || [];
  }

  get numRowsDeleted() {
    return this.rowsDeleted.length;
  }

  get rowsModified() {
    return Object.entries(this.data.editMode?.editedRows || {})
      .filter(([rowId, row]) => !this.rowsDeleted.includes(rowId) && Object.keys(row).length > 0)
      .reduce(
        (rows, [rowId, row]) => ({
          ...rows,
          [rowId]: row,
        }),
        {} as Record<string, RowData>
      );
  }

  get numRowsModified() {
    return Object.values(this.rowsModified).length;
  }

  get numCellsModified() {
    return Object.values(this.rowsModified).flatMap((o) => Object.keys(o)).length;
  }

  get fieldsRenamed() {
    return this.data.editMode?.renamedFields || {};
  }

  get numFieldsRenamed() {
    return Object.keys(this.fieldsRenamed).length;
  }

  get fieldsAdded() {
    return this.data.editMode?.addedFieldIds || [];
  }

  get numFieldsAdded() {
    return this.fieldsAdded.length;
  }

  updateSource({ fieldIds, fieldNames, name }: FirestoreSource) {
    this.ref.set(
      {
        fieldIds,
        fieldNames,
        name,
      },
      { merge: true }
    );
  }

  // Edit mode actions
  activateEditMode() {
    if (!this.isEditModeActive) {
      this.ref.set(
        {
          editMode: {
            addedFieldIds: [],
            addedFieldNames: {},
            deletedRowIds: [],
            editedRows: {},
            deletedFieldIds: [],
            renamedFields: {},
          },
        },
        { merge: true }
      );
    }
  }

  exitEditMode() {
    // Run in transaction to prevent one user blasting away edit mode before another person is done
    firestoreDb.runTransaction(async (transaction) => {
      transaction.set(
        this.ref,
        {
          editMode: FieldValue.delete() as unknown as undefined,
        },
        { merge: true }
      );
    });
  }

  saveEditMode() {
    firestoreDb.runTransaction(async (transaction) => {
      // TODO: do we need to update delete and edit to run in transaction?
      if (this.data.editMode) {
        // Delete rows
        for (const deletedRowId of this.data.editMode.deletedRowIds ?? []) {
          this.rows[deletedRowId]?.delete();
        }

        // Edit rows
        for (const [rowId, newRowData] of Object.entries(this.rowsModified)) {
          this.rows[rowId].editRowData(newRowData);
        }

        for (const deleteFieldId of this.columnsDeleted) {
          this.deleteFieldId(deleteFieldId);
        }

        // Rename fields, add new fields, and exit edit mode
        transaction.set(
          this.ref,
          {
            editMode: FieldValue.delete() as unknown as undefined,
            fieldIds: FieldValue.arrayUnion(
              ...(this.data.editMode.addedFieldIds ?? [])
            ) as unknown as string[],
            fieldNames: {
              ...this.data.fieldNames,
              ...this.data.editMode.renamedFields,
              ...this.data.editMode.addedFieldNames,
            },
          },
          { merge: true }
        );
      }
    });
  }

  async saveModuleConnectionEdits({
    successMessage,
    moduleId,
    rowId,
  }: {
    successMessage: string;
    moduleId: string;
    rowId: string;
  }) {
    const { editedFieldValues, editedFieldNames, addedFieldIds } =
      this.rootStore.uiModuleSourceEditor.getRowEditor(moduleId, rowId);
    const { currentSession } = this.rootStore.sessionsStore;
    const originalData = {
      fieldNames: { ...this.data.fieldNames },
      rowData: this.rows[rowId].getFieldValues(),
    };

    await this.ref.set(
      {
        fieldIds: FieldValue.arrayUnion(...addedFieldIds) as unknown as string[],
        fieldNames: {
          ...this.data.fieldNames,
          ...editedFieldNames,
        },
      },
      { merge: true }
    );

    addedFieldIds.forEach((field) => {
      currentSession?.switchModuleField({ moduleId, field });
    });

    this.rows[rowId].editRowData(editedFieldValues);

    this.rootStore.uiSnackbarStore.setUndoDetails(() => {
      addedFieldIds.forEach((field) => {
        currentSession?.switchModuleField({ moduleId, field });
      });
      this.ref.set(
        {
          fieldIds: FieldValue.arrayRemove(...addedFieldIds) as unknown as string[],
          fieldNames: originalData.fieldNames,
        },
        { merge: true }
      );
      this.rows[rowId].editRowData(originalData.rowData);
    }, successMessage);
  }

  isColumnMarkedForDeletion = (fieldId: string) =>
    !!this.data.editMode?.deletedFieldIds?.includes(fieldId);

  isRowMarkedForDeletion(rowId: string) {
    return !!this.data.editMode?.deletedRowIds?.includes(rowId);
  }

  isRowEdited(rowId: string) {
    return !!Object.keys(this.data.editMode?.editedRows || {}).includes(rowId);
  }

  isRowModified(rowId: string) {
    return this.isRowMarkedForDeletion(rowId) || this.isRowEdited(rowId);
  }

  deleteRow(rowId: string) {
    this.ref.update({
      'editMode.deletedRowIds': FieldValue.arrayUnion(rowId),
    });
  }

  deleteColumn(fieldId: string) {
    this.ref.update({
      'editMode.deletedFieldIds': FieldValue.arrayUnion(fieldId),
    });
  }

  revertRowModifications(rowId: string) {
    this.ref.update({
      'editMode.deletedRowIds': FieldValue.arrayRemove(rowId),
      [`editMode.editedRows.${rowId}`]: FieldValue.delete(),
    });
  }

  getCellInitialValueForEditing(rowId: string, fieldId: string) {
    return this.getCellEditedValue(rowId, fieldId) ?? this.getCellCurrentValue(rowId, fieldId);
  }

  getCellEditedValue(rowId: string, fieldId: string) {
    return this.data.editMode?.editedRows?.[rowId]?.[fieldId] ?? null;
  }

  getCellCurrentValue(rowId: string, fieldId: string) {
    return this.rows[rowId].getValue(fieldId);
  }

  isCellEdited(rowId: string, fieldId: string) {
    return this.getCellEditedValue(rowId, fieldId) !== null;
  }

  commitCell(rowId: string, fieldId: string, newValue: string) {
    // Check if the commit is on a cell of the "New Column"
    let actualFieldId = fieldId;
    if (fieldId === ADD_NEW_COLUMN_KEY) {
      actualFieldId = this.queueNewColumnForAddition();
    }

    const currentValue = this.getCellCurrentValue(rowId, actualFieldId);

    this.ref.update({
      [`editMode.editedRows.${rowId}.${actualFieldId}`]:
        currentValue === newValue ? FieldValue.delete() : newValue,
    });
  }

  pasteCells = (data: string[][], startPosition: { rowIdx: number; colIdx: number }) => {
    data.forEach((pastedRow, pastedRowIdx) => {
      const targetRow = this.filteredRows[startPosition.rowIdx + pastedRowIdx];
      if (targetRow) {
        pastedRow.forEach((pastedCellValue, pastedCellColIdx) => {
          const targetColumn = this.visibleColumns[startPosition.colIdx + pastedCellColIdx];
          if (targetColumn) {
            this.commitCell(targetRow.id, targetColumn.fieldId, pastedCellValue);
          }
        });
      }
    });
  };

  private onlyQueueNewColumnForAddition() {
    const fieldId = ulid();
    this.ref.update({
      'editMode.addedFieldIds': FieldValue.arrayUnion(fieldId),
      [`editMode.addedFieldNames.${fieldId}`]: DEFAULT_NEW_COLUMN_NAME,
    });

    return fieldId;
  }

  queueNewColumnForAddition() {
    const fieldId = this.onlyQueueNewColumnForAddition();

    this.rootStore.uiSnackbarStore.setUndoDetails(async () => {
      await this.ref.update({
        'editMode.addedFieldIds': FieldValue.arrayRemove(fieldId),
        [`editMode.addedFieldNames.${fieldId}`]: FieldValue.delete(),
      });
    }, 'New column created');

    return fieldId;
  }

  getAddedColumns() {
    return (
      this.data.editMode?.addedFieldIds?.map((fieldId) => ({
        fieldId,
        fieldName: this.data.editMode!.addedFieldNames[fieldId],
      })) || []
    );
  }

  editColumnName(fieldId: string, newName: string) {
    if (this.data.fieldIds.includes(fieldId)) {
      // Existing column being edited
      const currentFieldName = this.data.fieldNames[fieldId];
      if (newName !== currentFieldName) {
        this.ref.update({
          [`editMode.renamedFields.${fieldId}`]: newName,
        });
      } else {
        this.ref.update({
          [`editMode.renamedFields.${fieldId}`]: FieldValue.delete(),
        });
      }
    } else if (this.data.editMode?.addedFieldIds.includes(fieldId)) {
      // Added column being edited
      this.ref.update({
        [`editMode.addedFieldNames.${fieldId}`]: newName,
      });
    }
  }

  getRenamedColumnName(fieldId: string) {
    return this.data.editMode?.renamedFields?.[fieldId] || null;
  }

  toggleSearchSettingCustomField(fieldId: string) {
    if (this.searchSetting.type === 'custom') {
      if (this.searchSetting.fieldIds.includes(fieldId)) {
        this.updateSearchSetting({
          type: 'custom',
          fieldIds: this.searchSetting.fieldIds.filter((f) => f !== fieldId),
        });
      } else {
        this.updateSearchSetting({
          type: 'custom',
          fieldIds: this.searchSetting.fieldIds.concat(fieldId),
        });
      }
    }
  }

  changeSearchSettingType(type: string) {
    if (type === 'all' || type === 'visible') {
      this.updateSearchSetting({
        type,
      });
    } else if (type === 'custom') {
      this.updateSearchSetting({
        type,
        fieldIds: [],
      });
    }
  }

  private updateSearchSetting(newSetting: SourceSearchSetting) {
    this.rootStore.userSettingsStore.modifyUserSettings({
      [`sourceSearch.${this.id}`]: newSetting,
    });
  }
}
