import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { RowData } from '@creative-kit/shared';
import { useStore } from 'logic';
import { RouteNames } from 'logic/routes';
import { newSourceFromCSV } from 'logic/data/source';
import { ImportSourceSelector } from 'ui/ImportSource/ImportSourceSelector';
import { SourceCSVUpload } from './SourceCSVUpload';
import { SourceListCreation } from './SourceListCreation';
import { SourceSpreadsheetCreation } from './SourceSpreadsheetCreation';
import { BottomBar } from './importStyles';

interface ImportExistingSourceProps {
  sourceId: string;
}

export const ImportExistingSource = observer(({ sourceId }: ImportExistingSourceProps) => {
  const {
    sourcesStore: { sources },
    sessionsStore: { currentSession },
    uiActiveSessionStore: { setSelectedSource, setAddRowsDialogOpen },
  } = useStore();
  const source = sources[sourceId];
  const [sourceImportStep, setSourceImportStep] = useState<RouteNames | null>(null);
  const [importInProgress, setImportInProgress] = useState(false);

  const handleContinue = useCallback(
    (importType: RouteNames) => {
      setSourceImportStep(importType);
    },
    [setSourceImportStep]
  );

  const handleCancel = useCallback(() => {
    setSourceImportStep(null);
  }, [setSourceImportStep]);

  const handleUploadCSV = async (file: File, sourceName: string) => {
    try {
      setImportInProgress(true);
      await currentSession!.upsertSourceToSession({
        ...(await newSourceFromCSV({
          file,
          sourceName: sourceName ?? source.data.name,
        })),
        sourceId,
      });
      setSelectedSource(sourceId);
    } finally {
      setImportInProgress(false);
    }
  };

  const handleUploadNewSource = useCallback(
    async (sourceData, rows: RowData[]) => {
      try {
        setImportInProgress(true);
        source.updateSource({
          ...sourceData,
          name: sourceData.name ?? source.data.name,
        });
        await source.addNewRows(rows, { addEmptyRows: true });
      } finally {
        setImportInProgress(false);
      }
    },
    [source]
  );

  if (sourceImportStep === RouteNames.IMPORT_CSV) {
    return (
      <SourceCSVUpload
        onCancel={handleCancel}
        onUpload={handleUploadCSV}
        uploadLoading={importInProgress}
      />
    );
  }

  if (sourceImportStep === RouteNames.IMPORT_LIST) {
    return (
      <SourceListCreation
        onCancel={handleCancel}
        onUpload={handleUploadNewSource}
        importLoading={importInProgress}
      />
    );
  }

  if (sourceImportStep === RouteNames.IMPORT_SPREADSHEET) {
    return (
      <SourceSpreadsheetCreation
        onCancel={handleCancel}
        onUpload={handleUploadNewSource}
        importLoading={importInProgress}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        // 90% to leave room for source header row
        height: '90%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: (t) => t.spacing(2) }}>
          {source.data.name} is empty
        </Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => {
            setAddRowsDialogOpen(true);
          }}
        >
          Add New Rows
        </Button>
      </Box>
      <BottomBar sx={{ flex: 0 }}>
        <ImportSourceSelector title="RESET LIBRARY" onContinue={handleContinue} />
      </BottomBar>
    </Box>
  );
});
