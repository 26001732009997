/* eslint-disable react/no-array-index-key */
import { sanitizeUrl } from '@braintree/sanitize-url';
import { Highlight } from './Highlight';

const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

interface TextWithUrlsProps {
  children?: string;
  highlight?: string[];
}

// Takes a string and hyperlinks any urls
export const TextWithUrls = ({ children, highlight }: TextWithUrlsProps) => {
  const hyperlinkedText = children?.split(/(\s+)/).map((part, idx) =>
    URL_REGEX.test(part.trim()) ? (
      <a key={idx} href={sanitizeUrl(part)} target="_blank" rel="noopener noreferrer">
        {part}
      </a>
    ) : highlight ? (
      <Highlight key={idx} textToHighlight={part} searchWords={highlight} />
    ) : (
      <span key={idx}>{part}</span>
    )
  );
  return <>{hyperlinkedText}</>;
};
