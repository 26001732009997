import type { RootStore } from 'logic';
import { computed, makeObservable, reaction } from 'mobx';
import { RouteNames } from './routes';

export class UiCardView {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      selectedCardId: computed,
      isCardFocused: computed,
    });
  }

  init() {
    reaction(
      () => this.selectedCardId,
      () => {
        if (this.selectedCardId) {
          this.rootStore.uiActiveSessionStore.toggleSelectedCard(this.selectedCardId);
        }
      },
      { fireImmediately: true }
    );
    reaction(
      // Ensure that you can never deselect the focused card
      () => this.rootStore.uiActiveSessionStore.selectedCardIds,
      (selectedIds) => {
        if (this.isCardFocused && !selectedIds.includes(this.selectedCardId)) {
          this.rootStore.uiActiveSessionStore.selectCardIds([this.selectedCardId]);
        }
      }
    );
  }

  get selectedCardId() {
    const { routerState } = this.rootStore.routerStore;

    return routerState.params.cardId;
  }

  get isCardFocused() {
    return !!this.selectedCardId;
  }

  exitCardFocus = () => {
    this.toggleCardFocus(this.selectedCardId);
  };

  toggleCardFocus = (cardId: string) => {
    if (cardId === this.selectedCardId) {
      this.rootStore.routerStore.goTo(RouteNames.SESSION, {
        params: { sessionId: this.rootStore.sessionsStore.currentSessionId },
      });
      this.rootStore.uiActiveSessionStore.deselectAllCards();
    } else {
      this.rootStore.uiActiveSessionStore.deselectAllCards();
      this.rootStore.uiActiveSessionStore.toggleSelectedCard(cardId);
      this.rootStore.routerStore.goTo(RouteNames.CARD, {
        params: {
          sessionId: this.rootStore.sessionsStore.currentSessionId,
          cardId,
        },
      });
    }
  };
}
