import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formulaIcon: {
    width: theme.spacing(3),
    height: theme.spacing(2),
    fill: 'red',
  },
}));

export function FormulaIcon({ color }: { color?: string }) {
  const styles = useStyles();
  return (
    <svg
      className={styles.formulaIcon}
      style={{ fill: color ?? '#fff' }}
      xmlns="http://www.w3.org/2000/svg"
      width="8.885"
      height="9.828"
      viewBox="0 0 8.885 9.828"
    >
      <defs />
      <path
        className="a"
        d="M8.369,7.514l.652.652a.614.614,0,0,1-.869.869L7.5,8.383l-.651.652a.616.616,0,0,1-.869,0h0a.615.615,0,0,1,0-.868l.652-.652L5.98,6.863a.614.614,0,0,1,.869-.869l.65.65.652-.652a.616.616,0,0,1,.869,0h0a.617.617,0,0,1,0,.869ZM6.415,0A1.988,1.988,0,0,1,8.351,1.555a.213.213,0,0,1-.223.288H7.343a.511.511,0,0,1-.434-.278s-.044-.336-.493-.336c-.48,0-.614.614-.614.614L5.555,3.071H6.722a.307.307,0,0,1,0,.614H5.432l-.86,4.3A2.5,2.5,0,0,1,2.116,9.828,1.915,1.915,0,0,1,.32,8.213.183.183,0,0,1,.5,7.985h.7a.537.537,0,0,1,.439.271.473.473,0,0,0,.481.343c.48,0,.614-.614.614-.614l.86-4.3H3.037a.307.307,0,0,1,0-.614h.676l.246-1.228A2.308,2.308,0,0,1,6.415,0Z"
        transform="translate(-0.314 0)"
      />
    </svg>
  );
}
