import { Paper } from '@mui/material';
import { KBarAnimator, KBarPortal, KBarPositioner, KBarProvider } from 'kbar';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { CustomKbarResults } from './CustomKbarResults';
import { CustomKbarSearch } from './CustomKbarSearch';

interface CustomKbarProps {
  children?: ReactNode;
}

export const CustomKbar = observer(({ children }: CustomKbarProps) => (
  <KBarProvider>
    <KBarPortal>
      <KBarPositioner style={{ zIndex: 9999 }}>
        <Paper elevation={8} sx={{ width: 650, mt: 3 }}>
          <KBarAnimator>
            <CustomKbarSearch />
            <CustomKbarResults />
          </KBarAnimator>
        </Paper>
      </KBarPositioner>
    </KBarPortal>
    {children}
  </KBarProvider>
));
