import { observable, action, makeObservable, runInAction } from 'mobx';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';

import { auth, firebase } from 'firebase-modules';
import { createRouterState, RouterState } from 'mobx-state-router';
import type { RootStore } from '.';
import { RouteNames } from './routes';
import { db } from './data';

interface AuthUser {
  uid: string;
  avatarUrl: string;
  displayName: string | null;
  email: string | null;
  canUseApp?: boolean;
}

export class AuthStore {
  private rootStore: RootStore;
  user?: AuthUser | null;
  userLoading: boolean = true;
  signInRedirect: RouterState = createRouterState(RouteNames.HOME);

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      user: observable,
      userLoading: observable,
      updateUser: action.bound,
      authStateChanged: action.bound,
      setSignInRedirect: action.bound,
    });

    this.rootStore = rootStore;
  }

  init() {
    auth.onAuthStateChanged(this.authStateChanged);
  }

  async updateUser() {
    const firebaseUser = auth.currentUser;
    if (!firebaseUser) {
      this.user = null;
      Sentry.setUser(null);
      ReactGA.set({
        userId: null,
        user_properties: null,
      });
    } else {
      const userId = firebaseUser.uid;
      this.user = {
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        displayName: firebaseUser.displayName || firebaseUser.email || null,
        avatarUrl: firebaseUser.photoURL || '',
      };

      Sentry.setUser({
        id: userId,
        email: firebaseUser.email || 'no@email.com',
        username: firebaseUser.displayName || 'No Name',
      });
      ReactGA.set({
        userId,
        user_properties: {
          email: firebaseUser.email || 'no@email.com',
          name: firebaseUser.displayName || 'No Name',
        },
      });

      const userDoc = await db.users(userId).get();
      runInAction(() => {
        this.user && (this.user.canUseApp = !!userDoc.data()?.canUseApp);
      });
    }
  }

  authStateChanged() {
    this.userLoading = false;
    this.updateUser();
  }

  loginWithGoogle = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    await auth.signInWithPopup(googleProvider);
    this.rootStore.routerStore.goToState(this.signInRedirect);
  };

  logout = async () => {
    await auth.signOut();
  };

  setSignInRedirect = (toRoute: RouterState) => {
    this.signInRedirect = toRoute;
  };
}
