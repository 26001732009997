/* eslint-disable import/no-duplicates */
import fb from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const productionDomains = ['app.creativequeue.io'];

if (productionDomains.includes(window.location.hostname)) {
  fb.initializeApp({
    apiKey: 'AIzaSyAIN-JAZdIXCfcC28VkwsbaqD-nD55PNl8',
    authDomain: 'shotlist-5b502.firebaseapp.com',
    databaseURL: 'https://shotlist-5b502.firebaseio.com',
    projectId: 'shotlist-5b502',
    storageBucket: 'shotlist-5b502.appspot.com',
    messagingSenderId: '1046855265223',
    appId: '1:1046855265223:web:3361ec02ce004bd8fd47e2',
    measurementId: 'G-7LVHHV7KEL',
  });
} else {
  fb.initializeApp({
    apiKey: 'AIzaSyCKjEfc5JDezLDFm3OWCVwwVadleBV-s8I',
    authDomain: 'shotlist-dev.firebaseapp.com',
    projectId: 'shotlist-dev',
    storageBucket: 'shotlist-dev.appspot.com',
    messagingSenderId: '296840654953',
    appId: '1:296840654953:web:77fe49e1538238466932f8',
    measurementId: 'G-1TZ6P7GNBH',
  });
}

export const firebase = fb;
export const auth = fb.auth();
export const { firestore } = fb;
export const db = fb.firestore();
export const fn = fb.functions();
export const { FieldValue } = fb.firestore;
export const BATCH_MAX = 500;

if (window.location.hostname === 'localhost' && import.meta.env.VITE_DISABLE_EMULATORS !== 'true') {
  db.useEmulator('localhost', 8001);
  fn.useEmulator('localhost', 5001);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).fb = firebase;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).auth = auth;
