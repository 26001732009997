import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';

interface SearchInputProps {
  value: string;
  onTextChange: (s: string) => unknown;
  onFocus?: () => void;
  placeholder?: string;
  sx?: TextFieldProps['sx'];
}

export const SearchInput = forwardRef(
  ({ sx, onTextChange, value, placeholder, onFocus }: SearchInputProps, ref) => (
    <TextField
      sx={sx}
      fullWidth
      type="search"
      onFocus={onFocus}
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      placeholder={placeholder}
      value={value ?? ''}
      onPaste={(e) => {
        const pastedLines = e.clipboardData.getData('Text').split('\n');
        // Support pasting in rows from spreadsheet when pasted content is multiline
        if (pastedLines.length > 1) {
          e.preventDefault();
          onTextChange(pastedLines.join(', '));
          // Support pasting in a list of filenames
        } else if (e.clipboardData.files.length > 0) {
          e.preventDefault();
          onTextChange([...e.clipboardData.files].map((item) => item.name).join(', '));
        }
      }}
      onKeyUp={(e) => {
        if (e.key == 'Escape' || e.key == 'Enter') {
          (document.activeElement as HTMLTextAreaElement).blur();
        }
      }}
      onChange={(e) => {
        onTextChange(e.target.value);
        if (e.target.value == '') e.target.blur();
      }}
      inputRef={ref}
    />
  )
);
