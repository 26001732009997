import React from 'react';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HelpOutlineIcon from '@mui/icons-material/Help';

const useStyles = makeStyles({
  root: {
    verticalAlign: 'middle',
    alignSelf: 'center',
    cursor: 'pointer',
  },
});

interface HelpTooltipProps {
  children: NonNullable<React.ReactNode>;
  className?: string;
}

export const HelpTooltip = ({ children, className }: HelpTooltipProps) => {
  const styles = useStyles();
  return (
    <Tooltip className={clsx(styles.root, className)} title={children}>
      <HelpOutlineIcon fontSize="inherit" />
    </Tooltip>
  );
};
