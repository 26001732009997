import { ButtonBase, Menu, MenuItem, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useState } from 'react';

export interface ModuleWithCount {
  id: string;
  name: string;
  multi: boolean;
  sourceId: string;
  color: string;
  count: number;
}

interface StyleProps {
  backgroundColor: string;
}

const useStyles = makeStyles((theme) => ({
  button: (p: StyleProps) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    lineHeight: '31px',
    padding: `0 ${theme.spacing(0.5)}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: p.backgroundColor,
    color: theme.palette.getContrastText(p.backgroundColor),
  }),
  moduleName: {
    margin: `0 ${theme.spacing(0.5)}`,
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
  },
  moduleMenuItem: {
    fontSize: 14,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    textTransform: 'uppercase',
  },
}));

interface ModuleSelectionDropdownProps {
  modules: ModuleWithCount[];
  onSelect: (m: ModuleWithCount) => void;
  selected?: string;
}

export const ModuleSelectionDropdown = observer((props: ModuleSelectionDropdownProps) => {
  const {
    uiActiveSessionStore: { setNewModuleDialogOpen },
  } = useStore();
  const theme = useTheme();
  const selectedModule = props.modules.find((m) => m.id === props.selected);
  const styles = useStyles({
    backgroundColor: selectedModule?.color || theme.palette.background.paper,
  });

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const isMenuOpen = !!menuAnchor;

  const toggleMenu = (e: MouseEvent<HTMLButtonElement>) => {
    if (menuAnchor) {
      setMenuAnchor(null);
    } else {
      setMenuAnchor(e.currentTarget);
    }
  };

  const closeMenu = () => setMenuAnchor(null);

  return (
    <>
      <ButtonBase className={styles.button} onClick={toggleMenu}>
        <span>▼</span>
        <span className={styles.moduleName}>
          {selectedModule ? selectedModule.name : 'no modules'}
        </span>
        {selectedModule && <span>({selectedModule.count})</span>}
      </ButtonBase>
      <Menu open={isMenuOpen} anchorEl={menuAnchor} onClose={closeMenu}>
        {props.modules.map((m) => (
          <MenuItem
            disabled={selectedModule?.id === m.id}
            onClick={() => {
              props.onSelect(m);
              closeMenu();
            }}
            key={m.id}
          >
            <span
              className={styles.moduleMenuItem}
              style={{
                backgroundColor: m.color,
                color: theme.palette.getContrastText(m.color),
              }}
            >
              {m.name} ({m.count})
            </span>
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            setNewModuleDialogOpen(true);
            closeMenu();
          }}
        >
          + New Bucket
        </MenuItem>
      </Menu>
    </>
  );
});
