import { firestoreConverter } from './converter';
import { FirestoreSession } from './session';
import { FirestoreCard } from './card';
import { FirestoreComment } from './comment';
import { FirestoreSource } from './source';
import { EnvFirestore, CollectionRef, DocumentRef } from './types';
import { FirestoreSourceRow } from './row';
import { FirestoreUser, FirestoreUserApproval, FirestoreUserSettings } from './userSettings';

export const firestoreCollections = <F>(fb: EnvFirestore) => ({
  possibleUsers: fb.collection('possibleUsers'),
  sessions: fb.collection('sessions').withConverter(firestoreConverter) as CollectionRef<
    F,
    FirestoreSession
  >,
  sessionCards: (sessionId: string) =>
    fb
      .collection(`sessions/${sessionId}/cardList`)
      .withConverter(firestoreConverter) as CollectionRef<F, FirestoreCard>,
  sessionComments: (sessionId: string) =>
    fb
      .collection(`sessions/${sessionId}/comments`)
      .withConverter(firestoreConverter) as CollectionRef<F, FirestoreComment>,
  sessionSources: (sessionId: string) =>
    fb
      .collection(`sessions/${sessionId}/sources`)
      .withConverter(firestoreConverter) as CollectionRef<F, FirestoreSource>,
  sessionSourceRows: (sessionId: string, sourceId: string) =>
    fb
      .collection(`sessions/${sessionId}/sources/${sourceId}/rows`)
      .withConverter(firestoreConverter) as CollectionRef<F, FirestoreSourceRow>,
  sessionUserSettings: (sessionId: string, userId: string) =>
    fb
      .doc(`sessions/${sessionId}/userSettings/${userId}`)
      .withConverter(firestoreConverter) as DocumentRef<F, FirestoreUserSettings>,
  users: (userId: string) =>
    fb.doc(`users/${userId}`).withConverter(firestoreConverter) as DocumentRef<F, FirestoreUser>,
  userApprovals: (userId: string) =>
    fb.doc(`userApprovals/${userId}`).withConverter(firestoreConverter) as DocumentRef<
      F,
      FirestoreUserApproval
    >,
});
