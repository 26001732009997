import { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import DataGrid, { Position, TextEditor } from '@creative-kit/react-data-grid';
import { parse } from 'papaparse';
import { Observer } from 'mobx-react-lite';

import type { Column } from '@creative-kit/react-data-grid';
import type { FirestoreSource, RowData } from '@creative-kit/shared';
import { AutoSizer } from 'react-virtualized';
import clsx from 'clsx';

interface SourceRowsProps {
  rows: RowData[];
  setRows: (newRows: RowData[]) => void;
  fieldIds: FirestoreSource['fieldIds'];
  fieldNames: FirestoreSource['fieldNames'];
  className?: string;
  sourceTableClassName?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    '& .rdg': {
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.text.primary}`,
      backgroundColor: theme.palette.background.default,
    },
    '& .rdg-header-row': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .rdg-row': {
      backgroundColor: theme.palette.background.default,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    '& .rdg-cell': {
      borderBottom: `1px solid ${theme.palette.text.primary}`,
      borderRight: `1px solid ${theme.palette.text.primary}`,
      '&:last-child': {
        borderRight: 'none',
      },
    },
  },

  sourceTable: {
    height: '100%',
    position: 'relative',
  },
}));

export const SourceRows = ({
  fieldIds,
  fieldNames,
  className,
  sourceTableClassName,
  rows,
  setRows,
}: SourceRowsProps) => {
  const styles = useStyles();

  const columns: readonly Column<Record<string, string>>[] = fieldIds.map((fieldId) => ({
    key: fieldId,
    name: fieldNames[fieldId],
    editable: true,
    resizable: true,
    editor: TextEditor,
  }));

  const [selectedCell, setSelectedCell] = useState<Position | null>(null);

  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      // if pasting in an input field, then we shouldn't paste across cells
      if (e.target instanceof HTMLElement && e.target.nodeName.toLowerCase() === 'input') {
        return;
      }

      let pastedText = e.clipboardData?.getData('text');
      const pastedFiles = e.clipboardData?.files;
      if (pastedFiles?.length && !pastedText) {
        pastedText = [...pastedFiles].map((f) => f.name).join('\n');
      }

      if (selectedCell && pastedText) {
        const { data } = parse<string[]>(pastedText, {
          header: false,
          skipEmptyLines: true,
        });

        const newRows = [...rows];
        for (let i = 0; i < data.length; i++) {
          const targetRowIdx = i + selectedCell.rowIdx;
          const targetRow = newRows[targetRowIdx] || {};
          const dataRow = data[i];
          for (let j = selectedCell.idx; j < fieldIds.length; j++) {
            targetRow[fieldIds[j]] = dataRow[j - selectedCell.idx];
          }
          newRows[targetRowIdx] = targetRow;
        }

        setRows([...newRows]);
      }
    };
    document.addEventListener('paste', handlePaste, true);

    return () => document.removeEventListener('paste', handlePaste, true);
  }, [selectedCell, rows, setRows, fieldIds]);

  return (
    <div className={clsx(styles.root, className)}>
      <div className={clsx(styles.sourceTable, sourceTableClassName)}>
        <AutoSizer>
          {({ width, height }) => (
            <Observer>
              {() => (
                <DataGrid
                  disableHeaderSelection
                  style={{ width, height }}
                  rows={rows.concat([{}])}
                  columns={columns}
                  onRowsChange={(newRows) => {
                    setRows(newRows);
                  }}
                  onSelectedCellChange={setSelectedCell}
                />
              )}
            </Observer>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};
