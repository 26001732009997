import { observer } from 'mobx-react-lite';
import makeStyles from '@mui/styles/makeStyles';
import { useStore } from 'logic';

import { useShortcuts } from 'logic/hooks';
import { useMemo } from 'react';
import { META_KEY } from 'logic/ui-keyboard-shortcuts';
import { QueueCard } from './QueueCard';
import { QueueList } from './QueueList';
import { cardSpaceHeight } from './queue';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    marginTop: theme.spacing(1),
    padding: '10px 5px 0',
    position: 'relative',
    minHeight: 0,
  },
  cardWrapper: {
    marginBottom: cardSpaceHeight,
  },
}));

export const BatchQueue = observer(() => {
  const {
    uiBatchCardsStore: { generatedCards, exitPreview, createCards },
  } = useStore();

  const shortcuts = useMemo(
    () => [
      {
        name: 'executeBatch',
        fn: () => createCards(),
        combination: ['shift', META_KEY, 'enter'],
      },
      {
        name: 'goBack',
        fn: () => exitPreview(),
        combination: ['shift', 'esc'],
      },
    ],
    [createCards, exitPreview]
  );
  useShortcuts(shortcuts);

  const styles = useStyles();

  return (
    <div className={styles.root}>
      <QueueList
        numItems={generatedCards.length}
        renderQueueItem={(index) => (
          <div className={styles.cardWrapper}>
            <QueueCard id={generatedCards[index].id} />
          </div>
        )}
      />
    </div>
  );
});
