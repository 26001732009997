import { TextField, TextFieldProps } from '@mui/material';
import { useState } from 'react';

interface NumericTextFieldProps {
  TextFieldProps: TextFieldProps;
  value: number;
  onChange: (newValue: number) => void;
  integer?: boolean;
}

export const NumericTextField = (props: NumericTextFieldProps) => {
  const [localValue, setLocalValue] = useState(props.value.toString());

  const onTextFieldBlur = () => {
    const currentValue = localValue.trim();
    const currentNumberValue = Number(currentValue);

    let finalValue: number;
    if (currentValue === '' || isNaN(currentNumberValue)) {
      finalValue = 0;
    } else if (props.integer) {
      finalValue = Math.floor(currentNumberValue);
    } else {
      finalValue = currentNumberValue;
    }

    props.onChange(finalValue);
    setLocalValue(finalValue.toString());
  };

  const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.trim();
    const cleanValue = props.integer ? newValue.replace(/\./g, '') : newValue;
    const newNumberValue = Number(cleanValue);
    setLocalValue(cleanValue);

    if (cleanValue !== '' && !cleanValue.endsWith('.')) {
      props.onChange(newNumberValue);
    }
  };

  return (
    <TextField
      {...props.TextFieldProps}
      value={localValue}
      onChange={onTextFieldChange}
      onBlur={onTextFieldBlur}
    />
  );
};
