import type { RootStore } from 'logic';
import { action, computed, makeObservable, observable, reaction } from 'mobx';

export const NEW_SOURCE_TAB = 'NEW_SOURCE_TAB';
export const COLLAPSED_ROW_ESTIMATED_SIZE_PX = 122;
export enum QueueTabName {
  InProgress = 'In Progress',
  Done = 'Done',
  Batch = 'Batch',
  Selected = 'Selected',
}

export enum CardView {
  collapsed = 'collapsed',
  expanded = 'expanded',
}

export class UiActiveSessionStore {
  private rootStore: RootStore;
  selectedQueueTab: QueueTabName = QueueTabName.InProgress;
  private selectedSourceTab = '';
  selectedTabActive: boolean = false;
  cardView: CardView = CardView.collapsed;
  selectedCardIds: string[] = [];
  expandedCardIds: string[] = [];
  batchDeleteLoading = false;
  duplicationOperationLoading = false;
  autofocusSourceRename = false;
  newModuleDialogOpen = false;
  addRowsDialogOpen = false;
  queueViewMenuOpen = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable<
      this,
      | 'cardView'
      | 'selectedCardIds'
      | 'expandedCardIds'
      | 'autofocusSourceRename'
      | 'selectedQueueTab'
      | 'selectedTabActive'
      | 'selectedSourceTab'
    >(this, {
      autofocusSourceRename: observable,
      cardView: observable,
      selectedCardIds: observable,
      expandedCardIds: observable,
      toggleExpandedCard: action.bound,
      selectedCards: computed,
      hasSelectedCards: computed,
      selectedQueueTab: observable,
      selectedTabActive: observable,
      batchDeleteLoading: observable,
      duplicationOperationLoading: observable,
      addRowsDialogOpen: observable,
      focusCardModule: action.bound,
      toggleSelectedCard: action.bound,
      deselectAllCards: action.bound,
      selectAllCardsBelow: action.bound,
      setAddRowsDialogOpen: action.bound,
      setBatchDeleteLoading: action.bound,
      selectCardIds: action.bound,
      deselectAllCardsOtherThan: action.bound,
      selectAllOrBelow: action.bound,
      currentQueueTab: computed,
      setCurrentQueueTab: action.bound,
      selectedSourceTab: observable,
      setSelectedSource: action.bound,
      newModuleDialogOpen: observable,
      setNewModuleDialogOpen: action.bound,
      setSourceRenameFocus: action.bound,
      currentSourceTab: computed,
      areSessionCardsLoading: computed,
      queueViewMenuOpen: observable,
      setQueueViewMenuOpen: action.bound,
      setCardView: action.bound,
      toggleCardView: action.bound,
      shouldShowQueueFilter: computed,
    });

    reaction(
      // Ensure that you can never deselect the focused card
      () => this.rootStore.sessionsStore.currentSessionId,
      () => this.reset()
    );

    reaction(
      () => this.hasSelectedCards,
      () => {
        if (!this.hasSelectedCards) {
          this.selectedTabActive = false;
        }
      }
    );
  }

  reset() {
    this.selectedQueueTab = QueueTabName.InProgress;
    this.selectedTabActive = false;
    this.selectedSourceTab = '';
    this.cardView = CardView.collapsed;
    this.selectedCardIds = [];
    this.batchDeleteLoading = false;
    this.autofocusSourceRename = false;
    this.newModuleDialogOpen = false;
    this.addRowsDialogOpen = false;
    this.queueViewMenuOpen = false;
  }

  get shouldShowQueueFilter() {
    return (
      !this.rootStore.uiCardView.isCardFocused &&
      (this.rootStore.uiCardSearchStore.hasSearch ||
        this.rootStore.uiModulePowerColumnStore.hasAnyPowerColumnFilters)
    );
  }

  get currentSession() {
    return this.rootStore.sessionsStore.currentSession!;
  }

  get currentSourceTab() {
    if (this.selectedSourceTab) {
      return this.selectedSourceTab;
    }

    const maybeFirstSource = this.currentSession?.data.sourceIds[0];
    if (maybeFirstSource) {
      return maybeFirstSource;
    }

    return '';
  }

  get areSessionCardsLoading() {
    // Need to both the cards store list and session card ids loaded to display session cards
    return (
      this.currentSession?.data.cardIds.length !==
      Object.keys(this.rootStore.cardsStore.cards).length
    );
  }

  setCurrentQueueTab(newTab: QueueTabName) {
    const newTabIsSelected = newTab === QueueTabName.Selected;
    // TODO: more scalable way of handling special tabs
    this.selectedTabActive = newTabIsSelected;
    if (!newTabIsSelected) {
      this.selectedQueueTab = newTab;
    }
  }

  // Use special logic to display selected tab because we want to track what tab was previously selected
  get showSelectedTab() {
    if (this.hasSelectedCards) {
      return this.selectedTabActive;
    }
    return false;
  }

  get currentQueueTab(): QueueTabName {
    return this.selectedQueueTab;
  }

  get areCardsExpanded() {
    return this.cardView === CardView.expanded;
  }

  get areCardsCollapsed() {
    return this.cardView === CardView.collapsed;
  }

  get selectedCards() {
    return this.selectedCardIds.map((id) => this.rootStore.cardsStore.cards[id]);
  }

  get expandedCards() {
    return this.expandedCardIds.map((id) => this.rootStore.cardsStore.cards[id]);
  }

  get hasSelectedCards() {
    return this.selectedCardIds.length > 0;
  }

  isCardSelected = (cardId: string) => this.selectedCardIds.includes(cardId);

  /**
   * When focusing a module,
   * - select card
   * - change source tab to module's source
   * - focus the source search input
   * - update source power column to show this module's connections
   */
  focusCardModule = (cardId: string, moduleId: string) => {
    this.deselectAllCards();
    this.toggleSelectedCard(cardId);
    this.selectModuleSource(moduleId);
  };

  selectModuleSource = (moduleId: string) => {
    const module = this.currentSession?.data.modules[moduleId];
    if (module) {
      this.setSelectedSource(module.sourceId);
      // Disable for now a-la: https://www.notion.so/creativequeue/BUG-Unable-to-input-text-into-Connections-during-editing-50545a3f7ea141089815b80ca00429a1
      // this.rootStore.uiSourceSearchStore.setSearchFocus(true);
      this.rootStore.uiModulePowerColumnStore
        .getSourceModulePowerColumn(module.sourceId)
        .setSelectedModule(module.id);
    }
  };

  toggleSelectedCard = (cardId: string) => {
    if (this.isCardSelected(cardId)) {
      this.selectedCardIds = this.selectedCardIds.filter((ids) => ids !== cardId);
    } else {
      this.selectedCardIds = [...this.selectedCardIds, cardId];
    }
  };

  // If no cards selected, select all. Otherwise select below last selected
  selectAllOrBelow = () => {
    if (this.hasSelectedCards) {
      this.selectAllCardsBelow(this.selectedCardIds[this.selectedCardIds.length - 1]);
    } else {
      const ids =
        this.currentQueueTab === QueueTabName.InProgress
          ? this.rootStore.sessionsStore.currentSession?.inProgressVisibleCardIds
          : this.rootStore.sessionsStore.currentSession?.doneVisibleCardIds;
      this.selectCardIds(ids ?? []);
    }
  };

  selectCardIds = (ids: string[]) => {
    this.selectedCardIds = Array.from(new Set([...this.selectedCardIds, ...ids]));
  };

  deselectAllCardsOtherThan = (cardId: string) => {
    this.selectedCardIds = Array.from(this.selectedCardIds).filter((id) => id == cardId);
  };

  selectAllCardsBelow = (id: string) => {
    const cardIds = this.currentSession.data.cardIds ?? [];
    const visibleIds = cardIds.filter(
      (cardId) => this.rootStore.cardsStore.cards[cardId]?.isVisible
    );
    const currentIdx = this.currentSession!.currentCardIdx;
    const idx = visibleIds.findIndex((cId) => cId === id);
    const rest = currentIdx <= idx ? visibleIds.slice(idx) : visibleIds.slice(idx, currentIdx);
    this.selectedCardIds = [...new Set([...this.selectedCardIds, ...rest])];
  };

  selectCardsBetween = (
    endId: string,
    startId: string = this.selectedCardIds[this.selectedCardIds.length - 1]
  ) => {
    const cardIds = this.currentSession.data.cardIds ?? [];
    const visibleIds = cardIds.filter(
      (cardId) => this.rootStore.cardsStore.cards[cardId]?.isVisible
    );
    const startIdx = visibleIds.findIndex((cId) => cId === startId);
    const endIdx = visibleIds.findIndex((cId) => cId === endId);
    /**
     * Reverse order of slice based on whether end is before or after start
     * If endIndex is greater than start, need to add one to capture all of the cards between the range
     */
    const rest =
      endIdx <= startIdx
        ? visibleIds.slice(endIdx, startIdx)
        : visibleIds.slice(startIdx, endIdx + 1);
    this.selectedCardIds = [...new Set([...this.selectedCardIds, ...rest])];
  };

  deselectAllCards = () => {
    this.selectedCardIds = [];
  };

  setSelectedSource(newSourceId: string) {
    this.selectedSourceTab = newSourceId;
  }

  setSourceRenameFocus(value: boolean) {
    this.autofocusSourceRename = value;
  }

  setNewModuleDialogOpen(isOpen: boolean) {
    this.newModuleDialogOpen = isOpen;
  }

  setAddRowsDialogOpen(isOpen: boolean) {
    this.addRowsDialogOpen = isOpen;
  }

  setBatchDeleteLoading(isLoading: boolean) {
    this.batchDeleteLoading = isLoading;
  }

  setQueueViewMenuOpen(isOpen: boolean) {
    this.queueViewMenuOpen = isOpen;
  }

  setCardView(view: CardView) {
    this.cardView = view;
  }

  toggleCardView() {
    if (this.areCardsCollapsed) {
      const cardIds = Object.keys(this.rootStore.cardsStore.cards);
      this.expandedCardIds = cardIds;
      this.cardView = CardView.expanded;
    } else {
      this.expandedCardIds = [];
      this.cardView = CardView.collapsed;
    }
  }

  toggleExpandedCard(cardId: string) {
    if (this.expandedCardIds.includes(cardId)) {
      this.expandedCardIds = this.expandedCardIds.filter((id) => id !== cardId);
    } else {
      this.expandedCardIds = [...this.expandedCardIds, cardId];
    }
  }
}
