import { action, computed, makeObservable, observable } from 'mobx';
import type { RootStore } from '.';
import { DragDropBase, DragStatus } from './dragdrop';

export class UiCardDragDropStore extends DragDropBase {
  private reorderingValid = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable<UiCardDragDropStore, 'reorderingValid'>(this, {
      reorderingValid: observable,
      cardDragStatus: computed,
      setReorderingValid: action.bound,
    });

    this.rootStore = rootStore;
  }

  get cardDragStatus(): DragStatus {
    return this.reorderingValid ? this.dragStatus : { targetId: this.dragStatus.targetId };
  }

  startReordering(cardId: string) {
    this.dragStatus.targetId = cardId;
  }

  updateReordering(cardId: string, position: 'before' | 'after') {
    const currentSession = this.rootStore.sessionsStore.currentSession!;
    this._updateReordering(currentSession.data.cardIds, cardId, position);
  }

  endReordering() {
    const reorderedCardId = this.dragStatus.targetId!;
    const targetCardId = this.dragStatus.overId!;
    const reorderPosition = this.dragStatus.position;
    const { reorderingValid } = this;
    this.dragStatus = {};
    this.reorderingValid = false;

    if (!targetCardId || !reorderingValid) {
      return;
    }

    this.rootStore.sessionCardActions.reorderCards(reorderedCardId, targetCardId, reorderPosition!);
  }

  setReorderingValid(isValid: boolean) {
    this.reorderingValid = isValid;
  }
}
