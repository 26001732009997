import { DragDropBase } from './dragdrop';

export class UiModuleDragDropStore extends DragDropBase {
  public originalModuleIds: string[] = [];

  get moduleDragStatus() {
    return this.dragStatus;
  }

  startReordering(moduleId: string) {
    this.moduleDragStatus.targetId = moduleId;
    this.originalModuleIds = [...this.rootStore.sessionsStore.currentSession!.data.moduleIds];
  }

  updateReordering(targetModuleId: string, position: 'before' | 'after') {
    this._updateReordering(this.originalModuleIds, targetModuleId, position);
  }

  get reorderedModules() {
    const { targetId: moduleId, overId, position } = this.moduleDragStatus;
    if (!overId || !moduleId) return this.originalModuleIds;

    const modulesToReorder = this.originalModuleIds.filter((id) => id !== moduleId);
    const targetModuleIndex = modulesToReorder.indexOf(overId);

    const targetAndDragModuleIds = position === 'after' ? [overId, moduleId] : [moduleId, overId];

    return [
      ...modulesToReorder.slice(0, targetModuleIndex),
      ...targetAndDragModuleIds,
      ...modulesToReorder.slice(targetModuleIndex + 1),
    ];
  }

  endReordering() {
    if (this.moduleDragStatus?.targetId) {
      this.rootStore.sessionsStore.currentSession?.setModuleIds(this.reorderedModules);
      super.endReordering();
    }
  }
}
