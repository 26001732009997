import { action, makeObservable, observable } from 'mobx';
import { List } from 'react-virtualized';

export class UiQueueScrollStore {
  listRef: List | null = null;

  constructor() {
    makeObservable(this, {
      listRef: observable,
      setList: action.bound,
      scrollToTop: action.bound,
      scrollToEnd: action.bound,
    });
  }

  setList(ref: List | null) {
    this.listRef = ref;
  }

  scrollToTop() {
    this.listRef?.scrollToRow(0);
  }

  scrollToEnd() {
    this.listRef?.scrollToRow(-1);
  }
}
