import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Box, CircularProgress, Typography } from '@mui/material';
import { DEFAULT_NEW_COLUMN_NAME } from 'logic/source';
import { ulid } from 'ulid';

import { useImportStyles } from 'ui/ImportSource/importStyles';
import { createMetaShortcut, META_KEY } from 'logic/ui-keyboard-shortcuts';
import { FirestoreSource, RowData } from '@creative-kit/shared';
import { useShortcuts } from 'logic/hooks';
import { useForm } from 'react-hook-form';
import { AutomaticModuleCreationCheckbox, SourceCreationForm, SourceNameInput } from './SourceForm';

interface SourceSpreadsheetCreationProps {
  importLoading?: boolean;
  onCancel: () => void;
  onUpload: (source: FirestoreSource, rows: RowData[], automaticModuleCreation: boolean) => void;
}

export const SourceSpreadsheetCreation = observer(
  ({ importLoading, onCancel, onUpload }: SourceSpreadsheetCreationProps) => {
    const importStyles = useImportStyles();

    const { handleSubmit, control } = useForm<SourceCreationForm>({
      defaultValues: { sourceName: '', automaticModuleCreation: true },
    });

    const handleCreate = useCallback(
      async ({ sourceName, automaticModuleCreation }: SourceCreationForm) => {
        // Create empty source with 3 columns and 5 rows
        const colA = ulid();
        const colB = ulid();
        const colC = ulid();
        await onUpload(
          {
            name: sourceName,
            fieldIds: [colA, colB, colC],
            fieldNames: {
              [colA]: `${DEFAULT_NEW_COLUMN_NAME} 1`,
              [colB]: `${DEFAULT_NEW_COLUMN_NAME} 2`,
              [colC]: `${DEFAULT_NEW_COLUMN_NAME} 3`,
            },
          },
          Array(5)
            .fill(0)
            .map((_) => ({})),
          automaticModuleCreation
        );
      },
      [onUpload]
    );

    const onSubmit = handleSubmit(handleCreate);

    const shortcuts = useMemo(
      () => [
        {
          name: 'continue',
          fn: () => onSubmit(),
          combination: [META_KEY, 'enter'],
        },
        {
          name: 'goBack',
          fn: () => onCancel(),
          combination: ['esc'],
        },
      ],
      [onSubmit, onCancel]
    );
    useShortcuts(shortcuts);

    return (
      <div className={importStyles.root}>
        <div className={importStyles.content}>
          <Typography variant="h4">Create New Spreadsheet</Typography>
          {importLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            <>
              <SourceNameInput control={control} label="Spreadsheet Name" onSubmit={onSubmit} />
              <div className={importStyles.stepDetails}>Click continue to format spreadsheet</div>
              <Box mt={8} sx={{ width: '100%' }}>
                <AutomaticModuleCreationCheckbox control={control} />
                <div className={importStyles.navigation}>
                  <Button onClick={() => onCancel()}>Cancel</Button>
                  <Button onClick={onSubmit}>Continue ({createMetaShortcut('Enter')})</Button>
                </div>
              </Box>
            </>
          )}
        </div>
      </div>
    );
  }
);
