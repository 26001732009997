import type { RootStore } from 'logic';
import { comparer, reaction } from 'mobx';
import { FieldValue, db as firestoreDb, BATCH_MAX, fn } from 'firebase-modules';
import { CardStatus, safeBatch } from '@creative-kit/shared';
import { Card } from './card';
import { RouteNames } from './routes';
import { QueueTabName } from './ui-active-session';

export const NEW_SOURCE_TAB = 'NEW_SOURCE_TAB';
export const COLLAPSED_ROW_ESTIMATED_SIZE_PX = 122;

export class SessionCardActionsStore {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  getCardById = (id: string): Card | undefined => this.rootStore.cardsStore.cards[id];

  get currentSession() {
    return this.rootStore.sessionsStore.currentSession!;
  }

  private completeCardOnly = (cardIds: string[]) => {
    safeBatch(firestoreDb, cardIds, (batch, cardId) => {
      const card = this.getCardById(cardId);
      if (card) {
        card.createCommentIfNeeded();
        batch.set(
          this.currentSession.cardListRef.doc(cardId),
          {
            doneAt: FieldValue.serverTimestamp() as unknown as Date,
            filename: card.filename,
          },
          {
            merge: true,
          }
        );
      }
    });

    // if not the current card, we need to move its place in the cardId list
    const cardIdsCopy = [...this.currentSession.data.cardIds].filter((id) => !cardIds.includes(id));
    const currentCardIdx = this.currentSession.cardList.findIndex(
      (c) => c.status === CardStatus.Current
    );
    if (currentCardIdx >= 0) {
      cardIdsCopy.splice(currentCardIdx, 0, ...cardIds);
      this.currentSession.sessionRef.set(
        {
          cardIds: cardIdsCopy,
        },
        {
          merge: true,
        }
      );
    }

    // If in card view, return user to session view after marking card as done
    if (this.rootStore.uiCardView.isCardFocused) {
      this.rootStore.routerStore.goTo(RouteNames.SESSION, {
        params: { sessionId: this.rootStore.sessionsStore.currentSessionId },
      });
    }

    return async () => {
      for (const cardId of cardIds) {
        this.currentSession.cardListRef.doc(cardId).update({
          doneAt: FieldValue.delete(),
          filename: FieldValue.delete(),
        });
      }
    };
  };

  private async batchRemoveCards(cardIds: string[]) {
    const deletedCardIds = [...cardIds];
    const deletedCards = cardIds.map((id) => this.getCardById(id));

    try {
      this.rootStore.uiActiveSessionStore.setBatchDeleteLoading(true);
      await fn.httpsCallable('batchDeleteCards')({
        sessionId: this.currentSession.id,
        cardIds: deletedCardIds,
      });
    } finally {
      this.rootStore.uiActiveSessionStore.setBatchDeleteLoading(false);
    }

    return async () => {
      await safeBatch(firestoreDb, deletedCards, (batch, card) => {
        if (card) {
          const cardRef = this.currentSession.cardListRef.doc(card.id);
          batch.set(cardRef, card.data);
        }
      });
      this.currentSession.sessionRef.set(
        {
          cardIds: [...this.currentSession.data.cardIds, ...deletedCardIds],
        },
        { merge: true }
      );
    };
  }

  completeCard = async (cardId: string) => {
    const card = await this.rootStore.cardsStore.cards[cardId];
    const undo = await this.completeCardOnly([cardId]);
    this.rootStore.uiSnackbarStore.setUndoDetails(undo, `Card #${card.cardNumber} marked as done`);

    this.rootStore.uiCardView.exitCardFocus();
  };

  private markCardInProgressOnly = async (cardIds: string[]) => {
    const originalCardIds = [...this.currentSession.data.cardIds];
    const originalDoneAtById: Record<string, Date | undefined> = {};
    safeBatch(firestoreDb, cardIds, (batch, cardId) => {
      const card = this.rootStore.cardsStore.cards[cardId];
      if (card.status === CardStatus.Completed) {
        originalDoneAtById[cardId] = card.data.doneAt;

        const cardRef = this.currentSession.cardListRef.doc(cardId);
        batch.set(
          cardRef,
          {
            doneAt: FieldValue.delete() as unknown as Date,
          },
          { merge: true }
        );
      }
    });
    const newCardIds = originalCardIds.filter((c) => !cardIds.includes(c)).concat(...cardIds);
    this.currentSession.sessionRef.set(
      {
        cardIds: newCardIds,
      },
      { merge: true }
    );

    return async () => {
      for (const cardId of cardIds) {
        this.currentSession.cardListRef.doc(cardId).set(
          {
            doneAt: originalDoneAtById[cardId],
          },
          { merge: true }
        );
      }
      this.currentSession.sessionRef.set(
        {
          cardIds: originalCardIds,
        },
        { merge: true }
      );
    };
  };

  markCardInProgress = async (cardId: string) => {
    const card = this.rootStore.cardsStore.cards[cardId];
    const undo = await this.markCardInProgressOnly([cardId]);

    if (undo !== undefined) {
      this.rootStore.uiSnackbarStore.setUndoDetails(
        undo,
        `Card #${card.cardNumber} marked as in progress`
      );
    }
  };

  toggleCardProgress = (cardId: string) => {
    const card = this.rootStore.cardsStore.cards[cardId];
    if (card) {
      if (card.status === CardStatus.Completed) {
        this.markCardInProgress(cardId);
      } else {
        this.completeCard(cardId);
      }
    }
  };

  promoteCardAndCompleteCurrent = (cardId: string) => {
    const {
      currentCardIdx,
      currentCardId,
      data: { cardIds },
    } = this.currentSession;
    this.completeCardOnly([currentCardId]);
    const promotedCardIdx = cardIds.indexOf(cardId);
    const newCurrentCardIdx = currentCardIdx + 1;
    cardIds.splice(newCurrentCardIdx, 0, cardIds.splice(promotedCardIdx, 1)[0]);
    this.currentSession.sessionRef.set(
      {
        cardIds,
      },
      { merge: true }
    );
  };

  promoteCardAndQueueCurrent = (cardId: string) => {
    const { currentCardIdx } = this.currentSession;
    this.moveCardToIndex(cardId, currentCardIdx);
  };

  moveCardToIndex = (cardId: string, indexToMove: number) => {
    const cardIds = [...this.currentSession.data.cardIds];
    const promotedCardIdx = cardIds.indexOf(cardId);
    cardIds.splice(indexToMove, 0, cardIds.splice(promotedCardIdx, 1)[0]);
    return this.currentSession.sessionRef.set(
      {
        cardIds,
      },
      { merge: true }
    );
  };

  promoteCardToUpNext = (cardId: string) => {
    this.moveCardToIndex(cardId, this.currentSession.upNextCardIdx);
  };

  promoteCardToOnDeck = (cardId: string) => {
    this.moveCardToIndex(cardId, this.currentSession.onDeckCardIdx);
  };

  moveCardToLast = (cardId: string) => {
    this.moveCardToIndex(cardId, this.currentSession.data.cardIds.length - 1);
  };

  removeCard = async (cardId: string) => {
    const card = this.rootStore.cardsStore.cards[cardId];
    const queueName = this.currentSession.inProgressCardIds.includes(cardId)
      ? 'In Progress'
      : 'Done';
    const undo = await this.batchRemoveCards([cardId]);
    this.rootStore.uiSnackbarStore.setUndoDetails(
      undo,
      `Card #${card.cardNumber} deleted from ${queueName}`
    );

    // If in card view, return user to session view after deleting card
    if (card.isFocused) {
      this.rootStore.routerStore.goTo(RouteNames.SESSION, {
        params: { sessionId: this.rootStore.sessionsStore.currentSessionId },
      });
    }
  };

  batchRemoveVisibleCards = async () => {
    const visibleCards = this.currentSession.getVisible(this.currentSession.inProgressCardIds);
    const deletedCards = [...visibleCards];
    visibleCards.forEach((c) => c.setModificationState('deleting'));
    const undo = await this.batchRemoveCards(deletedCards.map(({ id }) => id));
    visibleCards.forEach((c) => c.resetMotificationState());
    this.rootStore.uiSnackbarStore.setUndoDetails(
      undo,
      `${deletedCards.length} Cards Removed from Queue`
    );
  };

  removeSelectedCards = async () => {
    const { selectedCardIds, selectedCards, deselectAllCards } =
      this.rootStore.uiActiveSessionStore;
    const cardIds = [...selectedCardIds];
    selectedCards.forEach((c) => c.setModificationState('deleting'));
    const undo = await this.batchRemoveCards(cardIds);
    deselectAllCards();
    selectedCards.forEach((c) => c.resetMotificationState());
    this.rootStore.uiSnackbarStore.setUndoDetails(undo, `${cardIds.length} cards removed`);
  };

  duplicateCard = async (cardId: string, duplicateIdx?: number) => {
    const cardToCopy = this.rootStore.cardsStore.cards[cardId];
    const { undo, duplicatedCardIds } = await this.duplicateCardOnly([cardId], duplicateIdx);
    this.rootStore.uiSnackbarStore.setUndoDetails(
      undo,
      `Card #${cardToCopy.cardNumber} duplicated below original card`
    );

    // When focusing a card, redirect to newly duplicated card
    if (this.rootStore.uiCardView.isCardFocused && duplicatedCardIds.length > 0) {
      this.rootStore.uiCardView.toggleCardFocus(duplicatedCardIds[0]);
    }
  };

  duplicateSelectedCards = async () => {
    const { selectedCardIds, selectCardIds, setCurrentQueueTab, deselectAllCards } =
      this.rootStore.uiActiveSessionStore;
    const cardIds = [...selectedCardIds];
    const { undo, duplicatedCardIds } = await this.duplicateCardOnly(
      cardIds,
      this.currentSession.data.cardIds.length
    );
    this.rootStore.uiSnackbarStore.setUndoDetails(
      undo,
      `${cardIds.length} cards duplicated at end of queue (Selected)`
    );
    deselectAllCards();
    selectCardIds(duplicatedCardIds);
    if (duplicatedCardIds.length > 1) {
      setCurrentQueueTab(QueueTabName.Selected);
    }
  };

  reorderCards(reorderedCardId: string, targetCardId: string, reorderPosition: 'before' | 'after') {
    const beforeCardIds = [...this.currentSession.data.cardIds];
    const finalCardIds = [...this.currentSession.data.cardIds];
    const reorderedCardIdx = finalCardIds.indexOf(reorderedCardId);
    finalCardIds.splice(reorderedCardIdx, 1);
    const targetCardIdx = finalCardIds.indexOf(targetCardId);
    const reorderedCard = this.rootStore.cardsStore.cards[reorderedCardId];

    finalCardIds.splice(
      reorderPosition === 'before' ? targetCardIdx : targetCardIdx + 1,
      0,
      reorderedCardId
    );

    this.currentSession.sessionRef.set(
      {
        cardIds: finalCardIds,
      },
      { merge: true }
    );

    let queueChanged = false;
    const queueChangeUnsubscribe = reaction(
      () => this.currentSession.data.cardIds,
      (newCardIds) => {
        // Card id reordering has changed
        if (!comparer.shallow(newCardIds, finalCardIds)) {
          queueChanged = true;
          queueChangeUnsubscribe();
          revokeUndo();
        }
      },
      { equals: comparer.shallow }
    );
    const revokeUndo = this.rootStore.uiSnackbarStore.setUndoDetails(async () => {
      queueChangeUnsubscribe();
      if (queueChanged) {
        return;
      }
      await this.currentSession.sessionRef.set(
        {
          cardIds: beforeCardIds,
        },
        { merge: true }
      );
    }, `Card #${reorderedCard.cardNumber} reordered`);
  }

  private duplicateCardOnly = async (cardIds: string[], duplicateIdx?: number) => {
    const beforeCardIds = [...this.currentSession.data.cardIds];
    const newCardIds = [...this.currentSession.data.cardIds];
    const duplicatedCardIds: string[] = [];

    try {
      this.rootStore.uiActiveSessionStore.duplicationOperationLoading = true;

      for (const cardId of cardIds) {
        const cardRef = this.currentSession.cardListRef.doc();
        duplicatedCardIds.push(cardRef.id);
        const cardIdx = duplicateIdx ?? newCardIds.indexOf(cardId);
        const cardToCopy = this.rootStore.cardsStore.cards[cardId];
        const isSourceCardCompleted = cardToCopy.status === CardStatus.Completed;
        const data = { ...cardToCopy.data };
        if (isSourceCardCompleted) {
          delete data.doneAt;
          newCardIds.push(cardRef.id);
        } else {
          newCardIds.splice(cardIdx + 1, 0, cardRef.id);
        }
        // eslint-disable-next-line no-await-in-loop
        await cardRef.set(data);
      }

      await this.currentSession.sessionRef.set(
        {
          cardIds: newCardIds,
        },
        { merge: true }
      );

      return {
        duplicatedCardIds,
        undo: async () => {
          await this.currentSession.sessionRef.set(
            {
              cardIds: beforeCardIds,
            },
            { merge: true }
          );
          for (const cardId of duplicatedCardIds) {
            this.currentSession.cardListRef.doc(cardId).delete();
          }
        },
      };
    } finally {
      this.rootStore.uiActiveSessionStore.duplicationOperationLoading = false;
    }
  };

  completeOrMoveSelectedCards = async () => {
    const { selectedCardIds, currentQueueTab, deselectAllCards } =
      this.rootStore.uiActiveSessionStore;
    const cardIds = [...selectedCardIds];
    const undo = await // TODO: something went wrong with these again
    (currentQueueTab === QueueTabName.InProgress
      ? this.completeCardOnly(cardIds)
      : this.markCardInProgressOnly(cardIds));
    deselectAllCards();
    this.rootStore.uiSnackbarStore.setUndoDetails(undo, `${cardIds.length} cards updated`);
  };

  async batchAddCards(targetCards: Card[], afterCardId?: string) {
    if (!this.rootStore.authStore.user) {
      return;
    }

    const targetCardsCopy = [...targetCards];

    const cardIdsCopy = [...this.currentSession.data.cardIds];
    const newCardIds: string[] = [];
    while (targetCardsCopy.length > 0) {
      const batchCards = targetCardsCopy.splice(0, BATCH_MAX / 3); // 3 changes per card
      // eslint-disable-next-line no-await-in-loop
      await firestoreDb.runTransaction(async (transaction) => {
        for await (const targetCard of batchCards) {
          const cardRef = this.currentSession.cardListRef.doc();
          // eslint-disable-next-line no-await-in-loop
          newCardIds.push(cardRef.id);
          transaction.set(cardRef, {
            createdAt: FieldValue.serverTimestamp() as unknown as Date,
            createdBy: {
              userId: this.rootStore.authStore.user?.uid ?? '',
              email: this.rootStore.authStore.user?.email ?? '',
            },
            connections: targetCard.data.connections,
            ...(targetCard.data.suffix ? { suffix: targetCard.data.suffix } : {}),
          });
        }
      });
    }
    const insertCardId =
      afterCardId || this.currentSession.data.cardIds[this.currentSession.data.cardIds.length - 1];
    const insertIndex = cardIdsCopy.indexOf(insertCardId) + 1;
    cardIdsCopy.splice(insertIndex, 0, ...newCardIds);

    this.rootStore.uiActiveSessionStore.deselectAllCards();
    this.rootStore.uiActiveSessionStore.selectCardIds(newCardIds);

    await this.currentSession.sessionRef.set(
      {
        cardIds: cardIdsCopy,
      },
      { merge: true }
    );
    this.rootStore.uiActiveSessionStore.setCurrentQueueTab(QueueTabName.Selected);

    this.rootStore.uiSnackbarStore.setUndoDetails(async () => {
      this.rootStore.uiActiveSessionStore.deselectAllCards();
      await fn.httpsCallable('batchDeleteCards')({
        sessionId: this.currentSession.id,
        cardIds: newCardIds,
      });
    }, `${newCardIds.length} Cards Added to In Progress (Selected)`);
  }
}
