import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Source } from 'logic/source';
import { ButtonPopover } from 'ui/ButtonPopover';
import { observer } from 'mobx-react-lite';
import { useStore } from 'logic';

interface SourceSearchSettingManagerProps {
  source: Source;
}

export const SourceSearchSettingManager = observer(
  ({ source }: SourceSearchSettingManagerProps) => {
    const { searchSetting } = source;

    const {
      uiSourceSearchStore: { openSettingsMenu, closeSettingsMenu, isSettingsMenuOpen },
    } = useStore();

    const badgeContent =
      searchSetting.type === 'all' ? 'A' : searchSetting.type === 'visible' ? 'V' : 'F';

    const toggleSearchField = (fieldId: string) => {
      source.toggleSearchSettingCustomField(fieldId);
    };

    const setSearchSettingType = (type: string) => {
      source.changeSearchSettingType(type);
    };

    return (
      <ButtonPopover
        open={isSettingsMenuOpen(source.id)}
        requestOpen={() => openSettingsMenu(source.id)}
        requestClose={() => closeSettingsMenu(source.id)}
        icon={<ManageSearchIcon />}
        title="Search Settings"
        button={
          <Box sx={{ position: 'relative' }}>
            <IconButton size="small">
              <ManageSearchIcon />
            </IconButton>
            <Box
              sx={(theme) => ({
                position: 'absolute',
                top: -1,
                right: -1,
                fontSize: 10,
                fontWeight: 'bold',
                width: 14,
                height: 14,
                backgroundColor: theme.palette.success.main,
                color: theme.palette.getContrastText(theme.palette.success.main),
                borderRadius: 14,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              {badgeContent}
            </Box>
          </Box>
        }
      >
        <FormControl component="fieldset" margin="normal" sx={{ m: 0 }}>
          <RadioGroup
            value={searchSetting.type}
            onChange={(_, value) => setSearchSettingType(value)}
          >
            <FormControlLabel value="all" control={<Radio />} label="All fields" />
            <FormControlLabel value="visible" control={<Radio />} label="Visible fields" />
            <FormControlLabel value="custom" control={<Radio />} label="Specific fields" />
            {searchSetting.type === 'custom' && (
              <Box sx={{ pl: 2, mb: 2 }}>
                {source.fields.map((f) => (
                  <FormControl key={f.fieldId} fullWidth>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={searchSetting.fieldIds.includes(f.fieldId)}
                          onChange={() => toggleSearchField(f.fieldId)}
                        />
                      }
                      label={f.fieldName}
                    />
                  </FormControl>
                ))}
              </Box>
            )}
          </RadioGroup>
        </FormControl>
      </ButtonPopover>
    );
  }
);
