import * as React from 'react';
import { VisualState, useKBar } from 'kbar';
import { Box, TextField } from '@mui/material';

export const CustomKbarSearch = () => {
  const { query, search, actions, currentRootActionId, options } = useKBar((state) => ({
    search: state.searchQuery,
    currentRootActionId: state.currentRootActionId,
    actions: state.actions,
    activeIndex: state.activeIndex,
    showing: state.visualState === VisualState.showing,
  }));

  const ownRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    query.setSearch('');
    ownRef.current!.focus();
    return () => query.setSearch('');
  }, [currentRootActionId, query]);

  const placeholder = React.useMemo((): string => {
    const defaultText = 'Type a command or search…';
    return currentRootActionId ? actions[currentRootActionId].name : defaultText;
  }, [actions, currentRootActionId]);

  return (
    <Box sx={{ m: 1 }}>
      <TextField
        InputProps={{
          sx: {
            fontSize: 24,
          },
        }}
        key={currentRootActionId}
        size="small"
        fullWidth
        ref={ownRef}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        autoComplete="off"
        spellCheck="false"
        value={search}
        placeholder={placeholder}
        onChange={(event) => {
          query.setSearch(event.target.value);
          options?.callbacks?.onQueryChange?.(event.target.value);
        }}
        onKeyDown={(event) => {
          if (currentRootActionId && !search && event.key === 'Backspace') {
            const { parent } = actions[currentRootActionId];
            query.setCurrentRootAction(parent);
          }
        }}
      />
    </Box>
  );
};
