import type { FirestoreCard } from '../firestore';
import type { IRootStore } from './root';

export enum CardStatus {
  Upcoming = 'Upcoming',
  OnDeck = 'On Deck',
  Next = 'Next',
  Current = 'Current',
  Completed = 'Completed',
  Preview = 'Preview',
}

export class BaseCard {
  protected rootStore: IRootStore;
  id: string;
  sessionId: string;
  data: FirestoreCard;
  private _previewIndex?: number;

  constructor(
    id: string,
    sessionId: string,
    data: FirestoreCard,
    rootStore: IRootStore,
    previewIndex?: number
  ) {
    this.id = id;
    this.sessionId = sessionId;
    this.data = data;
    this.rootStore = rootStore;
    this._previewIndex = previewIndex;
  }

  protected get session() {
    return this.rootStore.sessionsStore.sessions[this.sessionId];
  }

  get suffix() {
    return this.data.suffix;
  }

  get isTiedToSession() {
    return this._previewIndex === undefined;
  }

  get cardIdx() {
    return this._previewIndex ?? this.session.data.cardIds.indexOf(this.id);
  }

  get cardNumber() {
    return this.cardIdx + 1;
  }

  get isEditable() {
    return this.isTiedToSession;
  }

  get labelInfo() {
    return this.session.computeMainLabel(this);
  }

  get mainLabel() {
    return this.labelInfo.mainLabel;
  }

  get hasDeletedConnections() {
    return this.session.modules.some((module) =>
      this.getModuleConnections(module.id).some((row) => row.isDeleted)
    );
  }

  getConnectedRows(moduleId: string) {
    let connectedRows = this.data.connections[moduleId] || [];
    if (typeof connectedRows === 'string') {
      connectedRows = [connectedRows];
    }

    return connectedRows;
  }

  getModuleConnections(moduleId: string) {
    const metadataRows = this.getConnectedRows(moduleId);

    return this.session.getRowsForModule(moduleId, metadataRows);
  }

  get filenameInfo() {
    return this.session.computeFilename(
      this,
      this.session.data.filenameFormula,
      this.session.data.filenameSettings
    );
  }

  get unsuffixedFilename() {
    // Ignore this.data.filename, which is used to lock in suffix for completed cards
    return this.filenameInfo.filename;
  }

  get filename() {
    const filename = this.unsuffixedFilename;

    /**
     * Need to separately add suffix here to prevent infinite loops when
     * automatically versioning filenames
     */
    return [filename]
      .concat(
        this.suffix
          ? this.session.safeFilenamePart(this.suffix, this.session.data.filenameSettings)
          : []
      )
      .join(this.session.data.filenameSettings.fieldSeparator);
  }

  get status() {
    const { cardIdx, isTiedToSession, session } = this;
    return !isTiedToSession
      ? CardStatus.Preview
      : cardIdx < session.currentCardIdx
        ? CardStatus.Completed
        : cardIdx === session.currentCardIdx + 1
          ? CardStatus.Next
          : cardIdx === session.currentCardIdx + 2
            ? CardStatus.OnDeck
            : cardIdx === session.currentCardIdx
              ? CardStatus.Current
              : CardStatus.Upcoming;
  }

  get isUpNext() {
    return this.cardIdx === this.session.currentCardIdx + 1;
  }

  get queuePosition() {
    return this.cardNumber - this.session.currentCardIdx;
  }

  get hasDuplicates() {
    return Object.values(this.rootStore.cardsStore.cards).some(
      (card) => card.id !== this.id && card.filename === this.filename
    );
  }
}
