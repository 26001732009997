import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useStore } from 'logic';
import { serializeField } from '@creative-kit/shared';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { UploadSourceInput } from 'ui/UploadSourceInput';
import { AvailableField } from '../Formula/AvailableField';
import { Formula } from '../Formula';

const useStyles = makeStyles({
  availableFields: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export const CardTitleManager = observer(() => {
  const {
    sessionsStore: { currentSession },
    uiCardTitleStore,
  } = useStore();

  const {
    init: initCardTitleUi,
    availableAppFields,
    availableFieldsByModule,
    saveFormula,
    isDialogOpen,
    closeDialog,
    cancelDragField,
  } = uiCardTitleStore;

  useEffect(() => {
    initCardTitleUi();
  }, [initCardTitleUi, currentSession?.id]);

  const hasFields = availableFieldsByModule.length > 0;
  const styles = useStyles();

  return (
    <Dialog fullWidth maxWidth="sm" open={isDialogOpen} onClose={closeDialog}>
      <DialogTitle>Title Formula</DialogTitle>
      <DialogContent>
        <div>
          <Formula formulaStore={uiCardTitleStore} />
        </div>
        <h3>Super Fields</h3>
        <div className={styles.availableFields}>
          {availableAppFields.map((field) => (
            <AvailableField
              key={serializeField(field)}
              field={field}
              cancelDragField={cancelDragField}
            />
          ))}
        </div>
        <hr />
        {hasFields ? (
          availableFieldsByModule.map(({ module, fields }) => (
            <div key={module.id}>
              <h3>{module.name}</h3>
              <div className={styles.availableFields}>
                {fields?.map((field) => (
                  <AvailableField
                    key={serializeField(field)}
                    field={field}
                    module={module}
                    cancelDragField={cancelDragField}
                  />
                ))}
              </div>
            </div>
          ))
        ) : (
          <Box textAlign="center">
            <UploadSourceInput
              buttonProps={{ variant: 'outlined' }}
              buttonContent="Upload A Source To Configure Title"
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          onClick={() => {
            saveFormula();
            closeDialog();
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});
