import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TextField } from '@mui/material';
import { useStore } from 'logic';
import { RouteNames } from 'logic/routes';
import { ImportSourceSelector } from 'ui/ImportSource/ImportSourceSelector';
import { Controller, useForm } from 'react-hook-form';

export const ImportView = observer(() => {
  const {
    routerStore,
    sessionsStore: { createNewSession },
  } = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const handleContinue = useCallback(
    (importType: RouteNames, sessionName: string) => {
      routerStore.goTo(importType, {
        queryParams: { name: sessionName },
      });
    },
    [routerStore]
  );

  const { handleSubmit, watch, control } = useForm<{ sessionName: string }>({
    defaultValues: { sessionName: '' },
  });

  const currentSessionName = watch('sessionName');
  const handleSetupLater = useCallback(async () => {
    try {
      setIsLoading(true);
      await createNewSession({ name: currentSessionName });
    } finally {
      setIsLoading(false);
    }
  }, [createNewSession, currentSessionName]);

  return (
    <ImportSourceSelector
      title="NEW PROJECT"
      isLoading={isLoading}
      onContinue={(importType) => {
        // make sure session name is filled out before submitting
        handleSubmit(({ sessionName }) => handleContinue(importType, sessionName))();
      }}
      onSetupLater={handleSetupLater}
    >
      <Controller
        control={control}
        name="sessionName"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
          <TextField
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            inputRef={ref}
            error={!!error}
            helperText={error?.message}
            autoFocus
            fullWidth
            type="text"
            label="Project Name"
          />
        )}
      />
    </ImportSourceSelector>
  );
});
