import { action, makeObservable, observable } from 'mobx';
import type { RootStore } from '.';

export enum ItemTypes {
  SourceEntry = 'SourceEntry',
  CardListEntry = 'CardListEntry',
  AvailableFormulaField = 'AvailableFormulaField',
  Source = 'Source',
  FormulaField = 'FormulaField',
  ModuleListEntry = 'ModuleListEntry',
}

export interface SourceDragObject {
  type: ItemTypes.SourceEntry;
  sourceId: string;
  rowId: string;
}

export interface CardListDragObject {
  type: ItemTypes.CardListEntry;
  id: string;
}

export interface ModuleDragObject {
  type: ItemTypes.ModuleListEntry;
  id: string;
}

export interface DragStatus {
  targetId?: string;
  overId?: string;
  position?: 'before' | 'after';
}

export abstract class DragDropBase {
  protected rootStore: RootStore;
  protected dragStatus: DragStatus = {};

  constructor(rootStore: RootStore) {
    makeObservable<DragDropBase, 'dragStatus'>(this, {
      dragStatus: observable,
      startReordering: action.bound,
      updateReordering: action.bound,
      endReordering: action.bound,
      cancelReordering: action.bound,
    });

    this.rootStore = rootStore;
  }

  startReordering(targetId: string) {
    this.dragStatus.targetId = targetId;
  }

  endReordering() {
    if (this.dragStatus?.targetId) {
      this.dragStatus = {};
    }
  }

  cancelReordering() {
    this.dragStatus = {};
  }

  protected _updateReordering(
    listIds: string[],
    targetModuleId: string,
    position: 'before' | 'after'
  ) {
    const targetIdx = listIds.indexOf(this.dragStatus.targetId!);
    const overIdx = listIds.indexOf(targetModuleId);
    if (targetIdx === overIdx) {
      this.dragStatus.overId = undefined;
      this.dragStatus.position = undefined;
    } else {
      this.dragStatus.overId = targetModuleId;
      this.dragStatus.position = position;
    }
  }

  abstract updateReordering(targetModuleId: string, position: 'before' | 'after'): void;
}
