import makeStyles from '@mui/styles/makeStyles';

interface TooltipListProps {
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  tooltipList: {
    paddingLeft: theme.spacing(1),
    marginLeft: theme.spacing(1),
    '& li + li': {
      paddingTop: theme.spacing(1),
    },
  },
}));

// Wraps bullet point copy in a tooltip with appropriate spacing modifications for readability
export const TooltipList = ({ children }: TooltipListProps) => {
  const styles = useStyles();
  return <ul className={styles.tooltipList}>{children}</ul>;
};
