import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { Box, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SessionModule } from '@creative-kit/shared';
import { TooltipList } from 'ui/TooltipList';
import { FormulaIcon } from 'ui/FormulaIcon';
import { Circle, DoubleArrow } from '@mui/icons-material';

interface ModuleHeaderTooltipProps {
  module: SessionModule;
}
const useStyles = makeStyles(() => ({
  doubleArrowIcon: {
    fontSize: '17px',
    borderRadius: '20px',
    transform: 'rotate(180deg)',
    border: '2px solid white',
    zIndex: 10,
  },
}));

export const ModuleHeaderTooltip = observer(({ module }: ModuleHeaderTooltipProps) => {
  const {
    sessionsStore: { currentSession },
    sourcesStore: { sources },
    uiActiveSessionStore: { currentSourceTab },
    uiModulePowerColumnStore: { getSourceModulePowerColumn },
  } = useStore();

  const { getDependenciesForModule } = currentSession!;
  const { cardTitleDeps, filenameDeps } = getDependenciesForModule(module.id);
  const dependencyList = [];

  const styles = useStyles();
  const powerColumn = getSourceModulePowerColumn(module.sourceId);
  const source = sources[module.sourceId];
  const isModuleSourceSelected = currentSourceTab === source?.id;
  const isModulePowerColumnSelected = powerColumn?.selectedModule?.id === module.id;

  if (filenameDeps.length > 0) {
    dependencyList.push('Filename Formula');
  }
  if (cardTitleDeps.length > 0) {
    dependencyList.push('Card Title Formula');
  }

  if (isModuleSourceSelected && isModulePowerColumnSelected) {
    return (
      <Box pr={1}>
        <DoubleArrow
          sx={{
            backgroundColor: module.color,
          }}
          className={styles.doubleArrowIcon}
        />
      </Box>
    );
  } else if (dependencyList.length > 0) {
    return (
      <Tooltip
        title={
          <>
            <Typography variant="h6">This Asset is used in your</Typography>
            <TooltipList>
              {dependencyList.map((dep) => (
                <li key={dep}>{dep}</li>
              ))}
            </TooltipList>
          </>
        }
      >
        <Box pr={1}>
          <FormulaIcon color={module.color} />
        </Box>
      </Tooltip>
    );
  } else {
    return (
      <Box pr={1}>
        <Circle style={{ fontSize: '0.9rem', color: module.color }} />
      </Box>
    );
  }
  // If no icon, add placeholder spacing to align titles for all modules
  return <Box pr={4} />;
});
