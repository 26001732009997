import { FirestoreComment } from '@creative-kit/shared';
import { action, makeObservable, observable } from 'mobx';

export class Comment {
  id: string;
  data: FirestoreComment;

  constructor(id: string, data: FirestoreComment) {
    makeObservable(this, {
      data: observable.struct,
      updateData: action,
    });

    this.id = id;
    this.data = data;
  }

  updateData(newData: FirestoreComment) {
    this.data = newData;
  }
}
