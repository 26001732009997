import { makeStyles } from '@mui/styles';
import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { EditorProps } from '@creative-kit/react-data-grid';
import { Row } from 'logic/row';

type CellEditorProps = EditorProps<Row>;

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});

export const CellEditor = observer<CellEditorProps>(
  forwardRef((props: CellEditorProps, ref) => {
    const { sourcesStore } = useStore();
    const source = sourcesStore.sources[props.row.sourceId];
    const rowId = props.row.id;
    const fieldId = props.column.key;
    const initialValue = source.getCellInitialValueForEditing(rowId, fieldId);

    const isDeleted = source.isRowMarkedForDeletion(rowId);
    const { onClose } = props;
    useEffect(() => {
      if (isDeleted) {
        onClose(false);
      }
    }, [isDeleted, onClose]);

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.select();
      }
    }, []);

    const saveCell = () => {
      source.commitCell(rowId, fieldId, inputRef.current!.value);
      onClose(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' || e.key === 'Tab') {
        e.stopPropagation();
        saveCell();
      } else if (e.key === 'Escape') {
        e.stopPropagation();
        onClose(false);
      }
    };

    /*
  The react-data-grid "editor" renderer expects a component that exposes a ref with the API
  below. Since we're managing the input node and value ourselves, we return no-ops from this API
  */
    useImperativeHandle(ref, () => ({
      getInputNode: () => null,
      getValue: () => ({}),
    }));

    const styles = useStyles();

    return (
      <input
        ref={inputRef}
        onBlur={saveCell}
        onKeyDown={handleKeyDown}
        className={styles.root}
        defaultValue={initialValue}
      />
    );
  })
);
