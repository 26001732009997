import { Box, Paper, Popover, SxProps, Theme, Typography } from '@mui/material';
import { cloneElement, ReactElement, ReactNode, useRef, useState } from 'react';

interface ButtonPopoverProps {
  button: ReactElement;
  icon: ReactNode;
  title: string;
  children: ReactNode | ((close: () => void) => ReactNode);
  menuSx?: SxProps<Theme>;
  contentSx?: SxProps<Theme>;
  onOpen?: () => void;
  open?: boolean;
  requestOpen?: () => void;
  requestClose?: () => void;
}

export const ButtonPopover = ({
  button,
  icon,
  title,
  menuSx,
  contentSx,
  children,
  onOpen,
  open,
  requestOpen,
  requestClose,
}: ButtonPopoverProps) => {
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const isControlled = open !== undefined;

  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);

  const menuButton = cloneElement(button, {
    ref: anchorEl,
    onClick() {
      if (isControlled) {
        requestOpen?.();
      } else {
        setUncontrolledOpen(true);
        onOpen?.();
      }
    },
  });

  const handleClose = () => {
    if (isControlled) {
      requestClose?.();
    } else {
      setUncontrolledOpen(false);
    }
  };

  return (
    <>
      {menuButton}
      <Popover
        open={isControlled ? !!open : uncontrolledOpen}
        onClose={handleClose}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper
          sx={[
            {
              display: 'flex',
              flexDirection: 'column',
              width: 350,
            },
            ...(Array.isArray(menuSx) ? menuSx : menuSx ? [menuSx] : []),
          ]}
        >
          <Typography
            variant="body1"
            sx={(theme) => ({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: theme.spacing(1),
              textTransform: 'uppercase',
              backgroundColor: theme.palette.background.default,
            })}
          >
            {icon}&nbsp;{title}
          </Typography>
          <Box
            sx={[
              (theme) => ({
                p: theme.spacing(2),
              }),
              ...(Array.isArray(contentSx) ? contentSx : contentSx ? [contentSx] : []),
            ]}
          >
            {typeof children === 'function' ? children(handleClose) : children}
          </Box>
        </Paper>
      </Popover>
    </>
  );
};
