import { Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import { SessionModule } from '@creative-kit/shared';
import { useStore } from 'logic';
import clsx from 'clsx';
import { Card } from 'logic/card';
import { useCallback, useState } from 'react';
import { ImageUploadButton } from 'ui/ImageUploadButton';
import { DoubleArrowIcon } from 'ui/DoubleArrowIcon';

interface GhostModuleRowProps {
  card: Card;
  module: SessionModule;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
    border: `2px solid ${theme.palette.grey[500]}`,
    borderRadius: 5,
    cursor: 'pointer',
    opacity: 0.5,
    '&:last-child': {
      marginBottom: 0,
    },
    '&:hover': {
      opacity: 1,
    },
  },
  overlay: {
    position: 'absolute',
    background: 'grey',
    width: '100%',
    height: '100%',
    fontSize: theme.typography.h5.fontSize,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pairs: {
    flex: 1,
    padding: theme.spacing(0, 1),
    margin: 0,
  },
  pair: {
    padding: theme.spacing(2, 0),
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
  field: {
    width: '20%',
    // Max character length is approximately 13
    maxWidth: 125,
    overflowWrap: 'anywhere',
    hyphens: 'auto',
    textTransform: 'capitalize',
    fontSize: '0.6rem',
  },
  value: {
    flex: 1,
    marginLeft: theme.spacing(4),
  },
}));

export const GhostModuleRow = observer(({ card, module }: GhostModuleRowProps) => {
  const {
    sessionsStore: { currentSession },
    uiActiveSessionStore: { focusCardModule },
    sourcesStore: { sources },
  } = useStore();
  const [isOver, setIsOver] = useState(false);
  const mouseEnter = useCallback(() => setIsOver(true), []);
  const mouseLeave = useCallback(() => setIsOver(false), []);

  const source = sources[module.sourceId];

  const styles = useStyles();

  if (source?.type === 'image') {
    return (
      <ImageUploadButton
        sourceId={source.id}
        multiple={module.multi}
        onUploadSuccess={(rowIds) => {
          currentSession?.addConnection({
            rowIds,
            moduleId: module.id,
            card,
          });
        }}
      >
        <Box
          display="flex"
          sx={{
            flex: 1,
            justifyContent: 'center',
            opacity: 0.5,
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <Button
            sx={{
              width: '100%',
              opacity: 0.2,
              backgroundColor: 'text.secondary',
              '&:hover': {
                backgroundColor: 'text.secondary',
              },
            }}
            variant="contained"
          >
            <FileUploadRoundedIcon style={{ alignSelf: 'center' }} />
          </Button>
        </Box>
      </ImageUploadButton>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={clsx(styles.root)}
      tabIndex={0}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      role="button"
    >
      <Box className={styles.overlay} sx={{ opacity: isOver ? 0.6 : 0.2 }}>
        <Button
          variant="contained"
          startIcon={<DoubleArrowIcon />}
          onClick={() => {
            focusCardModule(card.id, module.id);
          }}
        >
          Connect
        </Button>
      </Box>
      <ul className={styles.pairs}>
        {module.fields.map((fieldId) => (
          <li className={styles.pair} key={fieldId}>
            <span className={styles.field}>{source?.data?.fieldNames[fieldId]}</span>
            <span className={styles.value} />
          </li>
        ))}
      </ul>
    </div>
  );
});
