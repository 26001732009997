import type { RootStore } from 'logic';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  customTxtField,
  Field,
  fieldEquals,
  isAppTextField,
  ModuleField,
  SessionModule,
} from '@creative-kit/shared';

interface FormulaDragStatus {
  fieldIdx?: number;
  position?: 'before' | 'after';
}

export abstract class FormulaBase {
  protected rootStore: RootStore;
  formula: Field[] = [];
  dragStatus: FormulaDragStatus = {};
  isDialogOpen: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable<FormulaBase>(this, {
      formula: observable,
      dragStatus: observable,
      originalFormula: computed,
      setTextFieldValue: action.bound,
      dragField: action.bound,
      cancelDragField: action.bound,
      dropField: action.bound,
      removeField: action.bound,
      reorderField: action.bound,
      init: action.bound,
      availableFieldsByModule: computed,
      isDialogOpen: observable,
      openDialog: action.bound,
      closeDialog: action.bound,
    });
  }

  openDialog() {
    this.isDialogOpen = true;
  }

  closeDialog() {
    this.isDialogOpen = false;
  }

  abstract get originalFormula(): Field[];

  abstract saveFormula: () => void;

  init() {
    this.formula = [...this.originalFormula];

    // Fill in empty text fields as necessary
    for (let idx = 0; idx < this.formula.length; idx++) {
      const field = this.formula[idx];
      if (idx % 2 === 0 && !isAppTextField(field)) {
        this.formula.splice(idx, 0, customTxtField());
      } else if (idx === this.formula.length - 1 && !isAppTextField(field)) {
        this.formula.splice(idx + 1, 0, customTxtField());
      }
    }

    // By default, we want there to be one empty text field in the formula
    if (this.formula.length === 0) {
      this.formula.push(customTxtField());
    }
  }

  get availableFieldsByModule(): {
    module: SessionModule;
    fields: ModuleField[];
  }[] {
    const { modules } = this.rootStore.sessionsStore.currentSession!;
    const { sources } = this.rootStore.sourcesStore;

    return modules
      .filter((m) => !m.multi)
      .map((module) => ({
        module,
        fields: sources[module.sourceId]?.data.fieldIds
          .filter(
            (f1) =>
              !this.formula.find(
                (f2) => f2.type === 'module' && f2.moduleId === module.id && f2.field === f1
              )
          )
          .map((field) => ({
            type: 'module',
            moduleId: module.id,
            field,
          })),
      }));
  }

  get cleanFormula(): Field[] {
    return this.formula.filter((f) => !(isAppTextField(f) && f.text.trim().length === 0));
  }

  get isEmpty() {
    return this.cleanFormula.length === 0;
  }

  setTextFieldValue(fieldIdx: number, newValue: string) {
    const field = this.formula[fieldIdx];
    if (isAppTextField(field)) {
      field.text = newValue;
    }
  }

  dragField(fieldIdx: number, position: 'before' | 'after') {
    // Filler field
    if (fieldIdx === -1) {
      this.dragStatus.fieldIdx = this.formula.length - 1;
      this.dragStatus.position = 'after';
    } else {
      this.dragStatus.fieldIdx = fieldIdx;
      this.dragStatus.position = position;
    }
  }

  cancelDragField() {
    this.dragStatus = {};
  }

  dropField(field: Field) {
    if (this.dragStatus.fieldIdx !== undefined) {
      const { fieldIdx, position } = this.dragStatus;
      const targetFormulaField = this.formula[fieldIdx];

      // When dropping a field, we need to make sure there's an available custom text field between each other type of field
      const isTargetText = isAppTextField(targetFormulaField);
      if ((isTargetText && position === 'before') || (!isTargetText && position === 'after')) {
        this.formula.splice(fieldIdx + (position === 'after' ? 1 : 0), 0, customTxtField(), field);
      } else {
        this.formula.splice(fieldIdx + (position === 'after' ? 1 : 0), 0, field, customTxtField());
      }
      this.dragStatus = {};
    }
  }

  removeField(fieldIndex: number) {
    // When removing a field from the formula, we merge its two adjacent text fields into one.
    const fieldBefore = this.formula[fieldIndex - 1];
    const fieldAfter = this.formula[fieldIndex + 1];
    if (isAppTextField(fieldBefore)) {
      const afterText = isAppTextField(fieldAfter) ? fieldAfter.text : '';
      fieldBefore.text += afterText;
      this.formula.splice(fieldIndex, 2);

      if (fieldIndex < this.dragStatus.fieldIdx!) {
        this.dragStatus.fieldIdx! -= 2;
      }
    }
  }

  getFieldIndex(field: Field) {
    const fieldIndex = this.formula.findIndex(fieldEquals(field));
    return fieldIndex;
  }

  addField(field: Field) {
    this.formula.push(field);
  }

  reorderField(field: Field) {
    const fieldIndex = this.getFieldIndex(field);
    this.removeField(fieldIndex);
    this.dropField(field);
  }
}
