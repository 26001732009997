import { useCallback, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { KeyboardShortcut, getShortcutStringWithKeys } from './ui-keyboard-shortcuts';

type Updater<T> = (newValue: T) => void;

export const useInputCallback = <
  T extends HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement,
>(
  cb: Updater<string>
) =>
  useCallback(
    (e: React.ChangeEvent<T>) => {
      const newValue = e.target.value;
      cb(newValue);
    },
    [cb]
  );

export const useNumberCallback = <T extends HTMLInputElement>(cb: Updater<number>) =>
  useCallback(
    (e: React.ChangeEvent<T>) => {
      const newValue = e.target.value;
      cb(+newValue);
    },
    [cb]
  );

export const useCheckboxCallback = <T extends HTMLInputElement>(cb: Updater<boolean>) =>
  useCallback(
    (e: React.ChangeEvent<T>) => {
      const newValue = e.target.checked;
      cb(newValue);
    },
    [cb]
  );

// Hook to define local shortcuts for a component that don't fit in the global space
export function useShortcuts(shortcuts: KeyboardShortcut[]) {
  useEffect(() => {
    shortcuts.forEach((shortcut) => {
      Mousetrap.bind(getShortcutStringWithKeys(shortcut), shortcut.fn);
    });

    return () => {
      shortcuts.forEach((shortcut) => {
        Mousetrap.unbind(getShortcutStringWithKeys(shortcut), shortcut.fn.name);
      });
    };
  }, [shortcuts]);
}
