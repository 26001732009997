import { ItemTypes, SourceDragObject } from 'logic/dragdrop';
import { ConnectDropTarget, DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

type Item = SourceDragObject | { files: File[] };
export interface SourceDropResult {
  item: Item | null;
  isDraggingSource: boolean;
  isHovering: boolean;
}

const acceptedTypes: (string | symbol)[] = [ItemTypes.SourceEntry, NativeTypes.FILE];

export const useSourceDrop = (
  onDrop?: (item: Item) => void
): [SourceDropResult, ConnectDropTarget] => {
  const [result, drop] = useDrop<SourceDragObject, void, SourceDropResult>({
    accept: acceptedTypes,
    collect: (monitor: DropTargetMonitor<SourceDragObject>) => {
      const item = monitor.getItem();
      const itemType = monitor.getItemType();
      return {
        item,
        isDraggingSource: !!item && !!itemType && acceptedTypes.includes(itemType),
        isHovering: monitor.isOver(),
      };
    },
    drop: onDrop,
  });

  return [result, drop];
};
