import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import DeleteIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { BaseRow, SessionModule } from '@creative-kit/shared';
import { CopiableField } from 'ui/CopiableField';
import { useStore } from 'logic';
import clsx from 'clsx';
import { Highlight } from 'ui/Highlight';
import { WarningIcon } from 'ui/WarningIcon';
import { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { useShortcuts } from 'logic/hooks';
import { matchMetaShortcutToEvent, META_KEY } from 'logic/ui-keyboard-shortcuts';
import { TextWithUrls } from 'ui/TextWithUrls';

interface ModuleRowFieldsProps {
  isEditable: boolean;
  row?: BaseRow;
  index: number;
  onRemove: () => void;
  module: SessionModule;
}

const useStyles = makeStyles((theme) => ({
  root: {
    // Need background to prevent selected modules from washing out this content
    background: theme.palette.mode === 'dark' ? 'rgb(49, 49, 49)' : '#fff',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
    border: `2px solid ${theme.palette.grey[800]}`,
    borderRadius: 5,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  header: {
    backgroundColor: 'rgb(58, 58, 58)',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    paddingLeft: 0,
    justifyContent: 'space-between',
    fontSize: '0.8em',
  },
  selectedRoot: {
    '&::after': {
      content: '""',
      position: 'absolute',
      right: -5,
      top: 0,
      bottom: 0,
      width: 5,
      borderRadius: 5,
      backgroundColor: '#ffee00',
    },
  },
  errorRoot: {
    '&::after': {
      content: '""',
      position: 'absolute',
      right: -5,
      top: 0,
      bottom: 0,
      width: 5,
      borderRadius: 5,
      backgroundColor: theme.palette.error.main,
    },
  },
  pairs: {
    flex: 1,
    padding: theme.spacing(0, 1),
    margin: 0,
  },
  pair: {
    padding: theme.spacing(1, 0),
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
  field: {
    width: '20%',
    // Max character length is approximately 13
    maxWidth: 125,
    overflowWrap: 'anywhere',
    hyphens: 'auto',
    textTransform: 'capitalize',
    fontSize: '0.6rem',
    fontWeight: 800,
  },
  value: {
    flex: 1,
    fontSize: '1rem',
    marginLeft: theme.spacing(4),
  },
  textValue: {
    fontWeight: 400,
    lineHeight: '1.5em',
    fontSize: '1.2rem',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
  },
  copiableValue: {},
  deletedValue: {
    textDecoration: 'line-through',
  },
}));

export const ModuleRowFields = observer(
  ({ isEditable, row, index, onRemove, module }: ModuleRowFieldsProps) => {
    const {
      uiCardSearchStore: { hasSearch, searchTerms },
      uiModulePowerColumnStore: { isRowSelected, getSourceModulePowerColumn },
      uiModuleSourceEditor,
      sourcesStore: { sources },
    } = useStore();

    const source = sources[module.sourceId];
    const rowId = row?.id ?? '';
    const isGhostRow = !rowId;
    const powerColumn = getSourceModulePowerColumn(module.sourceId);
    const connectionCount = powerColumn.getModuleLinkCount(module.id, rowId);
    const isSelected = isRowSelected(module.id, rowId);
    const {
      editedFieldId,
      editedFieldNames,
      editedFieldValues,
      addedFieldIds,
      hasEdits,
      addNewColumn,
      updateColumnName,
      updateColumnValue,
      resetEditMode,
      startEditing,
    } = uiModuleSourceEditor.getRowEditor(module.id, rowId);
    const styles = useStyles();
    const [showExitDialog, setShowExitDialog] = useState(false);
    const closeExitDialog = () => setShowExitDialog(false);

    const connectionNumber = index + 1;
    const isEditing = !!editedFieldId;

    const handleSubmit = useCallback(() => {
      // Prevents callback from firing when you're not editing
      if (isEditing) {
        source.saveModuleConnectionEdits({
          successMessage: `${module.name} Source Connection #${connectionNumber} Updated`,
          rowId,
          moduleId: module.id,
        });
        resetEditMode();
      }
    }, [isEditing, connectionNumber, resetEditMode, module.id, module.name, rowId, source]);

    const handleConfirmedCancel = useCallback(() => {
      if (row?.isEmpty) {
        source.deleteRow(rowId);
        source.saveEditMode();
        onRemove();
      }
      resetEditMode();
    }, [rowId, row?.isEmpty, onRemove, source, resetEditMode]);

    const handleCancel = useCallback(() => {
      if (hasEdits) {
        setShowExitDialog(true);
      } else {
        handleConfirmedCancel();
      }
    }, [hasEdits, handleConfirmedCancel]);

    const handleKeyDownSubmit = (event: KeyboardEvent) => {
      if (matchMetaShortcutToEvent(event, 'Enter') && isEditing) {
        handleSubmit();
      }
    };

    const shortcuts = useMemo(
      () => [
        {
          name: 'continue',
          fn: handleSubmit,
          combination: [META_KEY, 'enter'],
        },
        {
          name: 'cancel',
          fn: handleCancel,
          combination: ['esc'],
        },
      ],
      [handleCancel, handleSubmit]
    );
    useShortcuts(shortcuts);

    const connectionIndicator = connectionCount > 1 && (
      <Button
        size="small"
        variant="text"
        style={{ opacity: 0.5 }}
        onClick={() => powerColumn.toggleRow(rowId)}
      >
        Used on {connectionCount} Assets
      </Button>
    );
    return (
      <div
        className={clsx(styles.root, {
          [styles.selectedRoot]: isSelected,
          [styles.errorRoot]: row?.isDeleted,
        })}
      >
        <Dialog open={showExitDialog} onClose={closeExitDialog}>
          <DialogTitle>Discard Your Changes?</DialogTitle>
          <DialogContent>Discarding edits means your changes won't be saved!</DialogContent>
          <DialogActions>
            <Button onClick={closeExitDialog}>Return to Edit</Button>
            <Button
              onClick={() => {
                handleConfirmedCancel();
                closeExitDialog();
              }}
              color="primary"
            >
              Discard Changes
            </Button>
          </DialogActions>
        </Dialog>
        <div className={styles.header}>
          {isEditing ? (
            <>
              <div>
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  startIcon={<CloseIcon />}
                  sx={{ marginLeft: (t) => t.spacing(1) }}
                  onClick={handleCancel}
                >
                  Discard
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  startIcon={<CheckIcon />}
                  sx={{ marginLeft: (t) => t.spacing(1) }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
              {connectionIndicator}
            </>
          ) : (
            <>
              <Box display="flex" alignItems="center">
                {isEditable && !isGhostRow && (
                  <Tooltip title="Remove Connection">
                    <IconButton size="small" onClick={onRemove}>
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              {connectionIndicator}
              <span style={{ fontWeight: 600 }}>{connectionNumber}</span>
            </>
          )}
        </div>
        <ul className={styles.pairs}>
          {module.fields.map((fieldId) =>
            isEditing ? (
              <li className={styles.pair} key={fieldId}>
                <TextField
                  defaultValue={source?.data.fieldNames[fieldId]}
                  value={editedFieldNames[fieldId]}
                  onChange={(e) => updateColumnName(fieldId, e.target.value)}
                  onKeyDown={handleKeyDownSubmit}
                />
                <TextField
                  className={clsx(styles.value, styles.textValue)}
                  autoFocus={editedFieldId === fieldId}
                  defaultValue={row?.getValue(fieldId) ?? ''}
                  value={editedFieldValues[fieldId]}
                  // set cursor to end of text to allow user to start adding text easily
                  onFocus={(e) =>
                    e.target.setSelectionRange(
                      e.currentTarget.value.length,
                      e.currentTarget.value.length
                    )
                  }
                  onChange={(e) => updateColumnValue(fieldId, e.target.value)}
                  onKeyDown={handleKeyDownSubmit}
                  multiline
                />
              </li>
            ) : (
              <li className={styles.pair} key={fieldId}>
                <span className={styles.field}>{source?.data.fieldNames[fieldId]}</span>
                <span className={styles.value}>
                  <CopiableField
                    textClassname={clsx(styles.textValue, {
                      [styles.deletedValue]: row?.isDeleted,
                    })}
                    text={row?.getValue(fieldId) ?? ''}
                    textElement={
                      hasSearch ? (
                        <Highlight
                          searchWords={searchTerms}
                          textToHighlight={row?.getValue(fieldId) ?? ''}
                        />
                      ) : (
                        <TextWithUrls>{row?.getValue(fieldId)}</TextWithUrls>
                      )
                    }
                  />
                </span>
                {isEditable && (
                  <IconButton size="small" onClick={() => startEditing(fieldId)}>
                    <EditIcon
                      fontSize="small"
                      sx={{
                        opacity: 0.25,
                        '&:hover': {
                          opacity: 1.0,
                        },
                      }}
                    />
                  </IconButton>
                )}
              </li>
            )
          )}
          {addedFieldIds.map((fieldId) => (
            <li className={styles.pair} key={fieldId}>
              <TextField
                autoFocus
                value={editedFieldNames[fieldId]}
                onChange={(e) => updateColumnName(fieldId, e.target.value)}
                onKeyDown={handleKeyDownSubmit}
              />
              <TextField
                className={clsx(styles.value, styles.textValue)}
                value={editedFieldValues[fieldId]}
                onChange={(e) => updateColumnValue(fieldId, e.target.value)}
                onKeyDown={handleKeyDownSubmit}
                multiline
              />
            </li>
          ))}
        </ul>
        {isEditing && <Button onClick={addNewColumn}>Add New Field</Button>}
        {row?.isDeleted && (
          <Tooltip
            title="This connection has been deleted from the original source – Delete the connection or find a replacement!"
            sx={{
              marginX: 0.5,
            }}
          >
            <WarningIcon />
          </Tooltip>
        )}
      </div>
    );
  }
);
