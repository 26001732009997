import { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import type { Source } from 'logic/source';
import { observer } from 'mobx-react-lite';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface EditControlsProps {
  source: Source;
}

export const EditControls = observer(({ source }: EditControlsProps) => {
  const [showExitDialog, setShowExitDialog] = useState(false);
  const openExitDialog = () => setShowExitDialog(true);
  const closeExitDialog = () => setShowExitDialog(false);

  useEffect(() => {
    if (!source.isEditModeActive) {
      closeExitDialog();
    }
  }, [source.isEditModeActive]);

  if (source.isEditModeActive) {
    return (
      <>
        <Button
          variant="contained"
          size="small"
          color="error"
          startIcon={<CloseIcon />}
          onClick={() => {
            if (source.hasEdits) {
              openExitDialog();
            } else {
              source.exitEditMode();
            }
          }}
        >
          Discard
        </Button>
        <Button
          onClick={() => source.saveEditMode()}
          variant="contained"
          size="small"
          color="success"
          startIcon={<CheckIcon />}
        >
          Save
        </Button>

        <Dialog open={showExitDialog} onClose={closeExitDialog}>
          <DialogTitle>Discard Your Changes?</DialogTitle>
          <DialogContent>Discarding edits means your changes won't be saved!</DialogContent>
          <DialogActions>
            <Button onClick={closeExitDialog}>Return to Edit</Button>
            <Button onClick={() => source.exitEditMode()} color="primary">
              Discard Changes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <Button
      variant="contained"
      size="small"
      startIcon={<EditIcon />}
      onClick={() => source.activateEditMode()}
    >
      Edit
    </Button>
  );
});
