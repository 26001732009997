// Ranking string
const rules = new Intl.PluralRules('en', { type: 'ordinal' });
const suffixes = {
  zero: 'th',
  one: 'st',
  two: 'nd',
  few: 'rd',
  many: 'th',
  other: 'th',
};

export const rankString = (position: number) => `${position}${suffixes[rules.select(position)]}`;
