import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Box, CircularProgress, Typography } from '@mui/material';
import { FirestoreSource, RowData } from '@creative-kit/shared';
import { createMetaShortcut } from 'logic/ui-keyboard-shortcuts';
import { useImportStyles } from 'ui/ImportSource/importStyles';
import { newImageSource } from 'logic/data/source';
import { useForm } from 'react-hook-form';
import { AutomaticModuleCreationCheckbox, SourceCreationForm, SourceNameInput } from './SourceForm';

interface SourceImageCreationProps {
  importLoading?: boolean;
  onCancel: () => void;
  onUpload: (source: FirestoreSource, rows: RowData[], automaticModuleCreation: boolean) => void;
}

export const SourceImageCreation = observer(
  ({ importLoading, onCancel, onUpload }: SourceImageCreationProps) => {
    const importStyles = useImportStyles();

    const { handleSubmit, control } = useForm<SourceCreationForm>({
      defaultValues: { sourceName: '', automaticModuleCreation: true },
    });

    const handleCreate = useCallback(
      async ({ sourceName, automaticModuleCreation }: SourceCreationForm) => {
        const { source } = newImageSource({ sourceName });
        await onUpload(source, [], automaticModuleCreation);
      },
      [onUpload]
    );

    return (
      <div className={importStyles.root}>
        <div className={importStyles.content}>
          <Typography variant="h4">Create Image Library</Typography>
          {importLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            <>
              <SourceNameInput
                label="Image Library Name"
                control={control}
                onSubmit={handleSubmit(handleCreate)}
              />
              <div className={importStyles.stepDetails}>Continue to Upload Images</div>
              <Box mt={8} sx={{ width: '100%' }}>
                <AutomaticModuleCreationCheckbox control={control} />
                <div className={importStyles.navigation}>
                  <Button onClick={() => onCancel()}>Cancel</Button>
                  <Button onClick={handleSubmit(handleCreate)}>
                    Continue ({createMetaShortcut('Enter')})
                  </Button>
                </div>
              </Box>
            </>
          )}
        </div>
      </div>
    );
  }
);
