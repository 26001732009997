import React, { useCallback, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Menu, MenuItem } from '@mui/material';

import { useStore } from 'logic';
import type { DateFormat } from '@creative-kit/shared';

export const CurrentDateFormatMenu = () => {
  const {
    uiFilenameStore: { availableDateFormats, setCurrDateFormat },
  } = useStore();
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const toggleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(menuAnchor ? null : e.currentTarget);
  };
  const closeMenu = () => setMenuAnchor(null);

  const handleNewDateFormat = useCallback(
    (format: DateFormat) => () => {
      closeMenu();
      setCurrDateFormat(format);
    },
    [setCurrDateFormat]
  );

  return (
    <>
      <IconButton size="small" onClick={toggleMenu}>
        <ExpandMoreIcon />
      </IconButton>
      <Menu open={!!menuAnchor} anchorEl={menuAnchor} onClose={closeMenu}>
        {availableDateFormats.map((format) => (
          <MenuItem key={format} onClick={handleNewDateFormat(format)}>
            {format}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
