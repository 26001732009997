import { observer } from 'mobx-react-lite';
import { FormatterProps } from '@creative-kit/react-data-grid';
import { Row } from 'logic/row';
import { ButtonBase, CircularProgress } from '@mui/material';
import { useStore } from 'logic';

const IMAGE_HEIGHT = 150;

export const ImageCellFormatter = observer((props: FormatterProps<Row>) => {
  const { row } = props;
  const url = row.getCellImageUrl(IMAGE_HEIGHT * 2);

  const {
    uiImageModal: { setImage },
    uiImageSourceUpload: { getProgress },
  } = useStore();

  const progress = getProgress(row.sourceId, row.id);

  if (url) {
    return (
      <ButtonBase onClick={() => setImage(row.image)}>
        <img style={{ borderRadius: '5px', height: IMAGE_HEIGHT }} src={url} alt="" />
      </ButtonBase>
    );
  }

  if (progress) {
    if (progress.error) {
      return <span>{progress.error}</span>;
    }

    return <CircularProgress variant="determinate" value={progress.progress * 100} />;
  }

  return null;
});
