import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { HeaderRendererProps } from '@creative-kit/react-data-grid';
import { Row } from 'logic/row';
import { ModuleSelectionDropdown } from 'ui/ModuleSelectionDropdown';
import { Box } from '@mui/material';

interface ModulePowerColumnHeaderRendererProps extends HeaderRendererProps<Row> {
  sourceId: string;
}

export const ModulePowerColumnHeaderRenderer = observer(
  (props: ModulePowerColumnHeaderRendererProps) => {
    const {
      uiModulePowerColumnStore: { getSourceModulePowerColumn },
    } = useStore();
    const powerColumn = getSourceModulePowerColumn(props.sourceId);
    const currentModule = powerColumn?.selectedModule;

    return (
      <Box style={{ position: 'absolute', inset: 3 }}>
        <ModuleSelectionDropdown
          selected={currentModule?.id}
          modules={powerColumn.modulesWithCount}
          onSelect={(m) => {
            powerColumn.setSelectedModule(m.id);
          }}
        />
      </Box>
    );
  }
);
