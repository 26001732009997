import { action, makeObservable, observable } from 'mobx';

export class UiThemeStore {
  darkMode = false;

  constructor() {
    makeObservable(this, {
      darkMode: observable,
      toggleDarkMode: action.bound,
    });
  }

  init() {
    const savedDarkMode = window.localStorage.getItem('darkMode');
    if (savedDarkMode) {
      this.darkMode = savedDarkMode === 'true';
    } else if (window.matchMedia) {
      this.darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    } else {
      this.darkMode = false;
    }
  }

  toggleDarkMode() {
    this.darkMode = !this.darkMode;
    window.localStorage.setItem('darkMode', `${this.darkMode}`);
  }
}
