import { useRef, useEffect, useState } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStore } from 'logic';
import { newSourceFromCSV } from 'logic/data/source';

interface UploadSourceInputProps {
  buttonProps?: ButtonProps;
  buttonContent: React.ReactNode;
}

export const UploadSourceInput = observer(
  ({ buttonContent, buttonProps }: UploadSourceInputProps) => {
    const {
      sessionsStore: { currentSession },
    } = useStore();
    const { upsertSourceToSession } = currentSession!;
    const fileInput = useRef<HTMLInputElement>(null);
    const [addSourceInProgress, setAddSourceInProgress] = useState(false);

    // When current session changes, make sure to reset loading state
    useEffect(() => {
      setAddSourceInProgress(false);
    }, [currentSession?.id]);

    const handleAddSource = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files![0];
      setAddSourceInProgress(true);
      // TODO: what happens if add source gets cancelled?
      try {
        await upsertSourceToSession(await newSourceFromCSV({ file }));
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      } finally {
        setAddSourceInProgress(false);
      }
    };

    return (
      <>
        <input
          accept=".csv"
          style={{ display: 'none' }}
          id="add-source-input"
          type="file"
          onChange={handleAddSource}
          ref={fileInput}
        />

        <Button {...buttonProps}>
          <label htmlFor="add-source-input">
            {addSourceInProgress ? <CircularProgress color="secondary" size={25} /> : buttonContent}
          </label>
        </Button>
      </>
    );
  }
);
