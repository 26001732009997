import { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'logic';
import { RouteNames } from 'logic/routes';
import { ImportSourceSelector } from 'ui/ImportSource/ImportSourceSelector';
import { ulid } from 'ulid';
import { DEFAULT_NEW_COLUMN_NAME } from 'logic/source';
import { newSourceFromCSV } from 'logic/data/source';
import { FirestoreSource, RowData } from '@creative-kit/shared';
import { SourceCSVUpload } from './SourceCSVUpload';
import { SourceListCreation } from './SourceListCreation';
import { SourceSpreadsheetCreation } from './SourceSpreadsheetCreation';
import { SourceImageCreation } from './SourceImageCreation';

export const ImportSource = observer(() => {
  const {
    sourcesStore: { sources },
    sessionsStore: { currentSession },
    uiActiveSessionStore: { setSelectedSource, setSourceRenameFocus },
  } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [sourceImportStep, setSourceImportStep] = useState<RouteNames | null>(null);

  const handleContinue = useCallback(
    (importType: RouteNames) => {
      setSourceImportStep(importType);
    },
    [setSourceImportStep]
  );

  const handleCancel = useCallback(() => {
    setSourceImportStep(null);
  }, [setSourceImportStep]);

  const handleUploadCSV = async (
    file: File,
    sourceName: string,
    automaticModuleCreation: boolean
  ) => {
    try {
      setIsLoading(true);
      const sourceId = await currentSession!.upsertSourceToSession({
        ...(await newSourceFromCSV({
          file,
          sourceName,
        })),
        automaticModuleCreation,
      });
      setSelectedSource(sourceId);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadNewSource = useCallback(
    async (sourceData: FirestoreSource, rows: RowData[], automaticModuleCreation: boolean) => {
      try {
        setIsLoading(true);
        const sourceId = await currentSession!.upsertSourceToSession({
          source: sourceData,
          rows,
          automaticModuleCreation,
        });
        setSelectedSource(sourceId);

        const source = sources[sourceId];
        if (source) {
          setSelectedSource(sourceId);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [currentSession, sources, setSelectedSource]
  );

  const handleSetupLater = useCallback(async () => {
    try {
      setIsLoading(true);
      // Create empty source with 3 columns and 5 rows
      const colA = ulid();
      const sourceId = await currentSession!.upsertSourceToSession({
        source: {
          name: 'Untitled Source',
          fieldIds: [colA],
          fieldNames: {
            [colA]: `${DEFAULT_NEW_COLUMN_NAME} 1`,
          },
        },
        rows: [],
      });

      const source = sources[sourceId];
      if (source) {
        setSelectedSource(sourceId);
        setSourceRenameFocus(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, [currentSession, setSelectedSource, setSourceRenameFocus, sources]);

  if (sourceImportStep === RouteNames.IMPORT_CSV) {
    return (
      <SourceCSVUpload
        onCancel={handleCancel}
        onUpload={handleUploadCSV}
        uploadLoading={isLoading}
      />
    );
  }

  if (sourceImportStep === RouteNames.IMPORT_LIST) {
    return (
      <SourceListCreation
        onCancel={handleCancel}
        onUpload={handleUploadNewSource}
        importLoading={isLoading}
      />
    );
  }

  if (sourceImportStep === RouteNames.IMPORT_SPREADSHEET) {
    return (
      <SourceSpreadsheetCreation
        onCancel={handleCancel}
        onUpload={handleUploadNewSource}
        importLoading={isLoading}
      />
    );
  }

  if (sourceImportStep === RouteNames.IMPORT_IMAGES) {
    return (
      <SourceImageCreation
        onCancel={handleCancel}
        onUpload={handleUploadNewSource}
        importLoading={isLoading}
      />
    );
  }

  return (
    <ImportSourceSelector
      title="ADD NEW SOURCE"
      onContinue={handleContinue}
      onSetupLater={handleSetupLater}
      isLoading={isLoading}
    />
  );
});
