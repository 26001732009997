import { observer } from 'mobx-react-lite';
import { useStore } from 'logic';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { ImportExistingSource } from 'ui/ImportSource/ImportExistingSource';
import { withPlural } from '@creative-kit/shared';
import { AddRowsModal } from 'ui/AddRows/AddRowsModal';

interface SourceEmptyStateProps {
  sourceId: string;
}

export const SourceEmptyState = observer(({ sourceId }: SourceEmptyStateProps) => {
  const {
    uiSourceSearchStore: { searchesById, openSettingsMenu },
    uiActiveSessionStore: { setAddRowsDialogOpen },
    sourcesStore: { sources },
  } = useStore();

  const source = sources[sourceId];
  const isSearching = !!searchesById[sourceId];
  const isImageSource = source?.type === 'image';

  if (isSearching) {
    const badgeContent =
      source.searchSetting.type === 'all'
        ? 'A'
        : source.searchSetting.type === 'visible'
          ? 'V'
          : 'F';

    const settingsButton = (
      <Button
        size="small"
        variant="contained"
        onClick={() => openSettingsMenu(sourceId)}
        startIcon={
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <ManageSearchIcon />
            <Box
              sx={(theme) => ({
                position: 'absolute',
                top: -1,
                right: -1,
                fontSize: 10,
                fontWeight: 'bold',
                width: 14,
                height: 14,
                backgroundColor: theme.palette.success.main,
                color: theme.palette.getContrastText(theme.palette.success.main),
                borderRadius: 14,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              })}
            >
              {badgeContent}
            </Box>
          </Box>
        }
      >
        Search Settings
      </Button>
    );

    const resultsWithAllFields =
      source.searchSetting.type !== 'all' && source.hasFilteredRowsWithAllFields ? (
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5">
            {withPlural(source.filteredRowsWithAllFields.length, 'result')} in
          </Typography>
          &nbsp;
          <Button
            size="small"
            variant="contained"
            onClick={() => source.changeSearchSettingType('all')}
          >
            All Fields
          </Button>
        </Box>
      ) : null;

    return (
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: (t) => t.spacing(2) }}>
          No Results
        </Typography>
        {isImageSource ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ mb: 2 }}>Drop images here to upload to source</Box>
            {settingsButton}
            {resultsWithAllFields}
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex' }}>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => {
                  setAddRowsDialogOpen(true);
                }}
              >
                Add New Rows
              </Button>
              {settingsButton}
            </Box>
            {resultsWithAllFields}
          </Box>
        )}
      </Box>
    );
  }

  if (isImageSource) {
    return (
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Drop images here to upload to Library or{' '}
        <Box ml={1}>
          <AddRowsModal sourceId={sourceId} />
        </Box>
      </Box>
    );
  }

  return <ImportExistingSource sourceId={source.id} />;
});
