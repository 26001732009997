import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { moduleColors } from '@creative-kit/shared';
import { ButtonBase, Paper, IconButton, FormControl, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import { FormulaIcon } from '../FormulaIcon';
import { ModuleFieldsListItem } from './ModuleFieldItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  moduleName: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  moduleColorBar: {
    display: 'flex',
    flex: '1 1 0',
    borderRadius: '5px',
  },
  color: {
    marginRight: theme.spacing(1),
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: '50%',
  },
  nameLabel: {
    marginRight: 'auto',
    fontWeight: 500,
  },
  toggleButton: {
    flexGrow: 1,
  },
  formulaIcon: {
    width: theme.spacing(3),
    height: theme.spacing(2),
  },
  toggleIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform'),
  },
  toggleUp: {
    transform: 'rotate(180deg)',
  },
  settingsButton: {
    padding: 0,
    marginLeft: theme.spacing(1),
  },
}));

export const ModuleFieldsList = observer(() => {
  const {
    sessionsStore: { currentSession },
    uiFilenameStore: { openDialog: openFilenameDialog },
  } = useStore();
  const styles = useStyles();

  const { modules, isFilenameVisible, switchFilenameVisibility } = currentSession!;

  const [expandedModule, setExpandedModule] = useState<null | string>(null);

  const toggleExpanded = (moduleId: string) => {
    setExpandedModule((em) => (moduleId === em ? null : moduleId));
  };

  const modulesList = modules.map((module) => (
    <ModuleFieldsListItem
      key={module.id}
      module={module}
      expanded={expandedModule === module.id}
      toggleExpanded={toggleExpanded}
    />
  ));

  return (
    <div className={styles.root}>
      {modulesList.length > 0 && (
        <>
          <Paper elevation={0}>
            <div className={styles.moduleName}>
              {/* Empty DIV to match Module List */}
              <div style={{ height: 13, width: 13 }} />
              <FormControl>
                <Switch
                  checked={isFilenameVisible}
                  onChange={(e) => switchFilenameVisibility(e.target.checked)}
                  size="small"
                />
              </FormControl>
              <div className={styles.moduleColorBar} style={{ background: moduleColors[0] }}>
                <ButtonBase className={styles.toggleButton}>
                  <FormulaIcon />
                  <span className={styles.nameLabel}>Filename</span>
                </ButtonBase>
                <div className={styles.settingsButton}>
                  <IconButton size="small" onClick={() => openFilenameDialog()}>
                    <MoreIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>
            </div>
          </Paper>
          {modulesList}
        </>
      )}
    </div>
  );
});
