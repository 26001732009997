import type { IRootStore } from '.';
import type { FirestoreSourceRow } from '../firestore';

export class BaseRow {
  id: string;
  sourceId: string;
  sessionId: string;
  protected data: FirestoreSourceRow;
  protected rootStore: IRootStore;

  constructor(
    id: string,
    sourceId: string,
    sessionId: string,
    data: FirestoreSourceRow,
    rootStore: IRootStore
  ) {
    this.id = id;
    this.sourceId = sourceId;
    this.sessionId = sessionId;
    this.data = data;
    this.rootStore = rootStore;
  }

  getFieldValues() {
    return this.data.fieldValues;
  }

  getValue(fieldId: string): string | undefined {
    return this.getFieldValues()[fieldId];
  }

  get isDeleted() {
    return !!this.data.isDeleted;
  }

  get isEmpty() {
    const hasData = Object.values(this.data.fieldValues).find((val) => !!val);
    return !hasData;
  }

  get connectedCards() {
    const modules = this.rootStore.sessionsStore.sessions[this.sessionId].getModulesForSource(
      this.sourceId
    );
    return Object.values(this.rootStore.cardsStore.cards).filter((card) =>
      modules.flatMap((module) => card.getConnectedRows(module.id)).includes(this.id)
    );
  }

  get image() {
    return this.data.image;
  }
}
