import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Select, MenuItem, FormControl, Typography, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BottomBar, useImportStyles } from 'ui/ImportSource/importStyles';
import {
  createMetaShortcut,
  matchMetaShortcutToEvent,
  META_KEY,
} from 'logic/ui-keyboard-shortcuts';
import { useShortcuts } from 'logic/hooks';
import { RouteNames } from 'logic/routes';

interface ImportSourceProps {
  title: string;
  children?: React.ReactNode;
  onContinue: (importType: RouteNames) => void;
  onSetupLater?: () => void;
  isLoading?: boolean;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  importForm: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1.25,
    textAlign: 'center',
    cursor: 'pointer',
  },
});

export const ImportSourceSelector = observer(
  ({ children, title, onSetupLater, onContinue, isLoading }: ImportSourceProps) => {
    const styles = useStyles();
    const importStyles = useImportStyles();
    const [importType, setImportType] = useState<RouteNames>(RouteNames.IMPORT_CSV);

    const handleContinue = useCallback(() => onContinue(importType), [onContinue, importType]);

    const shortcuts = useMemo(
      () => [
        {
          name: 'continue',
          fn: () => handleContinue(),
          combination: [META_KEY, 'enter'],
        },
        {
          name: 'setuplater',
          fn: () => onSetupLater?.(),
          combination: [META_KEY, 'shift', 'enter'],
        },
      ],
      [onSetupLater, handleContinue]
    );
    useShortcuts(shortcuts);

    return (
      <div className={styles.root}>
        <div className={importStyles.root}>
          <form
            className={styles.importForm}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="menu"
            tabIndex={0}
            onKeyDown={(event) => {
              if (matchMetaShortcutToEvent(event, 'Enter')) {
                handleContinue();
              }
            }}
          >
            <Typography variant="h5">{title}</Typography>
            {children}
            <FormControl sx={{ marginTop: (t) => t.spacing(4) }} fullWidth>
              <Select
                labelId="session-select-label"
                id="session-select"
                onChange={(e) => {
                  setImportType(e.target.value as RouteNames);
                }}
                value={importType}
              >
                <MenuItem value={RouteNames.IMPORT_CSV}>SPREADSHEET UPLOAD (CSV)</MenuItem>
                <MenuItem value={RouteNames.IMPORT_LIST}>TAG LIST</MenuItem>
                <MenuItem value={RouteNames.IMPORT_SPREADSHEET}>BLANK SPREADSHEET</MenuItem>
                <MenuItem value={RouteNames.IMPORT_IMAGES}>IMAGE GALLERY</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              sx={{ marginTop: (t) => t.spacing(4) }}
              onClick={() => handleContinue()}
            >
              Continue ({createMetaShortcut('Enter')})
            </Button>
          </form>
        </div>
        {onSetupLater && (
          <BottomBar>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button variant="outlined" onClick={() => onSetupLater()}>
                Quick Start ({createMetaShortcut('Shift Enter')})
              </Button>
            )}
          </BottomBar>
        )}
      </div>
    );
  }
);
