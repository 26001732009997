import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { useStore } from 'logic';
import { RouteNames } from 'logic/routes';
import { useShortcuts } from 'logic/hooks';
import { newSourceFromCSV } from 'logic/data/source';
import { SourceCSVUpload } from 'ui/ImportSource/SourceCSVUpload';

export const ImportCSVView = observer(() => {
  const {
    routerStore,
    sessionsStore: { sessions, importSession, importInProgress },
  } = useStore();

  const handleUpload = async (file: File, sourceName: string, automaticModuleCreation: boolean) => {
    importSession(async (sessionId) => {
      if (sessionId !== undefined && sessions[sessionId]) {
        const newSession = sessions[sessionId];
        await newSession.upsertSourceToSession({
          ...(await newSourceFromCSV({
            file,
            sourceName,
          })),
          automaticModuleCreation,
        });
        routerStore.goTo(RouteNames.SESSION, { params: { sessionId } });
      } else {
        throw new Error('Error creating new session');
      }
    });
  };

  const shortcuts = useMemo(
    () => [
      {
        name: 'goBack',
        fn: () => routerStore.goTo(RouteNames.HOME),
        combination: ['esc'],
      },
    ],
    [routerStore]
  );
  useShortcuts(shortcuts);

  return (
    <SourceCSVUpload
      uploadLoading={importInProgress}
      onUpload={handleUpload}
      onCancel={() => routerStore.goTo(RouteNames.HOME)}
    />
  );
});
