import { styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const BottomBar = styled('div')(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.palette.divider,
  padding: theme.spacing(2),
  justifyContent: 'center',
  textAlign: 'center',
}));

export const useImportStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.hint,
    lineHeight: 1.25,
    textAlign: 'center',
    cursor: 'pointer',
  },
  stepDetails: {
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: 16,
  },
  navigation: {
    width: '100%',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  pane: {
    flex: 1,
    minWidth: 0,
    margin: `0 ${theme.spacing(1)}`,
    position: 'relative',
    '&:first-child::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: 2,
      right: -theme.spacing(1),
      backgroundColor: theme.palette.text.hint,
    },
  },
  examples: {
    position: 'sticky',
    top: 0,
  },
  fieldType: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1,
    fontSize: '1.2em',
    paddingBottom: theme.spacing(1),
    fontWeight: 400,
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
  },
  fieldContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(3),
    '& > *': {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));
