import clsx from 'clsx';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import { Controller, Control, useWatch } from 'react-hook-form';
import { moduleColors } from '@creative-kit/shared';
import { LearnMoreTooltip } from 'ui/ModuleIndicators/ConnectionTooltip';
import { useStore } from 'logic';
import 'simplebar/dist/simplebar.min.css';
import SimpleBar from 'simplebar-react';
import { ModuleFieldsRadio } from '../QueueViewMenu/ModuleFieldsRadio';

const useStyles = makeStyles((theme) => ({
  colorPicker: {
    marginTop: theme.spacing(1),
  },
  colorChip: {
    width: 24,
    height: 24,
    borderRadius: '50%',
  },
  colorChipSelected: {
    background: theme.palette.action.selected,
  },
  visibleFieldWrapper: {
    marginTop: theme.spacing(1),
    maxHeight: 200,
  },
}));

export interface ModuleFormState {
  name: string;
  sourceId: string;
  type: 'single' | 'multi';
  color: string;
  moduleFields: string[];
}

interface NewModuleDialogProps {
  control: Control<ModuleFormState>;
}

export const ModuleEditorForm = observer(({ control }: NewModuleDialogProps) => {
  const sourceId = useWatch<ModuleFormState>({ control, name: 'sourceId' });
  const {
    sessionsStore: { currentSession },
    sourcesStore: { sources },
  } = useStore();

  const {
    data: { sourceIds },
  } = currentSession!;

  const styles = useStyles();

  const sessionSources = sourceIds.map((sid) => sources[sid]);

  return (
    <>
      <FormControl fullWidth margin="normal">
        <Controller
          control={control}
          name="name"
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
            <TextField
              onBlur={onBlur}
              onChange={onChange}
              value={value ?? ''}
              inputRef={ref}
              label="Bucket name"
              error={!!error}
              helperText={error?.message}
              autoFocus
              required
            />
          )}
        />
      </FormControl>

      <Controller
        control={control}
        name="sourceId"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <FormControl fullWidth margin="normal" error={!!error}>
            <FormLabel id="source-selector-label" required>
              Library
            </FormLabel>
            <RadioGroup value={value ?? null} aria-label="source-selector" name="source-selector">
              {sessionSources.map((source) => (
                <FormControlLabel
                  key={source.id}
                  value={source.id}
                  control={<Radio onChange={onChange} onBlur={onBlur} />}
                  label={source.data.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name="type"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <FormControl fullWidth margin="normal" component="fieldset" error={!!error}>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="row"
              justifyContent="space-between"
            >
              <FormLabel component="legend" required>
                Select Bucket Preference
              </FormLabel>{' '}
              <LearnMoreTooltip />
            </Box>
            <RadioGroup value={value ?? null} aria-label="module-type" name="module-type">
              <FormControlLabel
                value="single"
                control={<Radio onChange={onChange} onBlur={onBlur} />}
                label="Single Connection"
              />
              <FormControlLabel
                value="multi"
                control={<Radio onChange={onChange} onBlur={onBlur} />}
                label="Multi Connection"
              />
            </RadioGroup>
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name="color"
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl fullWidth margin="normal" component="fieldset" error={!!error}>
            <FormLabel component="legend" id="color-selector-label" required>
              Color
            </FormLabel>
            <ButtonGroup className={styles.colorPicker}>
              {moduleColors.map((color) => (
                <Button
                  key={color}
                  value={color}
                  className={clsx({
                    [styles.colorChipSelected]: color === value,
                  })}
                  onClick={() => {
                    onChange(color);
                  }}
                  size="small"
                >
                  <div style={{ backgroundColor: color }} className={styles.colorChip} />
                </Button>
              ))}
            </ButtonGroup>
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name="moduleFields"
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          if (!sourceId || sourceId.length === 0) {
            return <div />;
          }

          return (
            <FormControl fullWidth margin="normal" component="fieldset" error={!!error}>
              <FormLabel>Manage Visible Fields</FormLabel>
              <SimpleBar forceVisible="y" className={styles.visibleFieldWrapper} autoHide={false}>
                <ModuleFieldsRadio
                  sourceId={sourceId as string}
                  selectedFields={value ?? []}
                  onSwitchModuleField={(selectedField) => {
                    const list: string[] = value ?? [];
                    if (list.includes(selectedField)) {
                      onChange(list.filter((f) => f !== selectedField));
                    } else {
                      onChange([...list, selectedField]);
                    }
                  }}
                />
              </SimpleBar>
            </FormControl>
          );
        }}
      />
    </>
  );
});
