import { alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    zIndex: 2,
  },
  root: {
    flex: 1,
    position: 'relative',
    overflow: 'visible',
    background: alpha(theme.palette.mode === 'dark' ? 'rgb(49, 49, 49)' : '#fff', 1),
    boxShadow: 'none',
    // need to add margins to get hover state to show up
    margin: '5px 5px 0 5px',
  },
  isDeleting: {
    opacity: 0.3,
  },
  isSelected: {
    // Removed in: https://www.notion.so/creativequeue/6270a33055394f94ba81655b333ac74b?v=662fee7d69f748deb0f826e0dd5e7fe5&p=8b359637f4774ca38b039f24464ca14d&pm=s
    // boxShadow: `-5px 0 0 0px ${theme.palette.primary.light}`,
  },
  rootHoveringSource: {
    boxShadow: `-5px 0px 0px 0px ${theme.palette.primary.light}`,
  },
  reorderIndicator: {
    position: 'absolute',
    left: '0',
    width: '100%',
    height: 2,
    backgroundColor: '#06c',
    boxShadow: '0 0 5px #06c',
    opacity: 0,
    transition: theme.transitions.create('opacity'),
  },
  topReorderIndicator: {
    top: -6,
    opacity: 0,
  },
  bottomReorderIndicator: {
    bottom: -4,
    opacity: 0,
  },
  visibleIndicator: {
    opacity: 1,
  },
  expandedInfo: {
    justifyContent: 'center',
    margin: '0px',
    flexWrap: 'wrap',
    display: 'flex',
    padding: theme.spacing(1),
    backgroundImage: 'linear-gradient(180deg, #0a2f604f, #202020)',
  },

  disabledOnDrag: {
    opacity: 0.33,
  },
  dragHandle: {
    cursor: 'grab',
  },
  doneAt: {
    padding: `0 ${theme.spacing(1)}`,
    fontStyle: 'italic',
    color: theme.palette.text.hint,
  },
}));
