import { useState, useRef } from 'react';
import clsx from 'clsx';
import { Box, Card, CardContent, Collapse, ThemeProvider } from '@mui/material';
import { useDrop, useDrag } from 'react-dnd';
import { observer } from 'mobx-react-lite';

import { useStore } from 'logic';
import { ItemTypes, CardListDragObject } from 'logic/dragdrop';

import { useSourceDrop } from 'ui/useSourceDrop';
import { CardStatus } from '@creative-kit/shared';
import { createPortal } from 'react-dom';
import { CARD_TITLE_PORTAL_ID } from 'logic/ui-sticky-headers';
import { format } from 'date-fns';
import { getTheme } from 'theme';
import { QueueCardTitle } from './QueueCardTitle';
import { QueueCardFilename } from './QueueCardFilename';
import { QueueCardModules } from './QueueCardModules';
import { useStyles } from './cardStyles';

interface QueueCardProps {
  id: string;
}

export const QueueCard = observer(({ id: cardId }: QueueCardProps) => {
  const {
    cardsStore: { cards },
    uiCardDragDrop: { startReordering, updateReordering, endReordering, cardDragStatus },
    uiBatchCardsStore: { generatedCardsById },
    sessionsStore: { currentSession },
    uiActiveSessionStore: { expandedCardIds },
    uiStickyHeaders: { scrollPosition },
  } = useStore();

  const card = cards[cardId] || generatedCardsById[cardId];

  const isBeingDragged = cardDragStatus.targetId === cardId;
  const isReordering = !isBeingDragged && !!cardDragStatus.targetId;
  const isBefore = cardDragStatus.position === 'before' && cardDragStatus.overId === cardId;
  const isAfter = cardDragStatus.position === 'after' && cardDragStatus.overId === cardId;

  const isExpanded = !isReordering && expandedCardIds.includes(cardId);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const dragHandleRef = useRef<HTMLDivElement | null>(null);

  // Accept dropping of other cards for re-orderings
  const [, dropCard] = useDrop<CardListDragObject, void, {}>({
    accept: ItemTypes.CardListEntry,
    hover: (item, monitor) => {
      if (!cardRef.current || item.id === cardId) {
        return;
      }

      const hoverRect = cardRef.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset()!;
      const midPoint = (hoverRect.bottom - hoverRect.top) / 2;
      const verticalOffset = clientOffset.y - hoverRect.top;
      const status = verticalOffset < midPoint ? 'before' : 'after';
      updateReordering(cardId, status);
    },
    drop: () => endReordering(),
  });

  // Check if source is being dragged
  const [{ isDraggingSource: isDragging, isHovering }, dropSource] = useSourceDrop();
  const isDraggingSource = card.isEditable && isDragging;
  const isHoveringSource = card.isEditable && isHovering;

  // Re-order cards
  const [, drag, preview] = useDrag({
    type: ItemTypes.CardListEntry,
    item: () => {
      startReordering(cardId);
      return { id: cardId, label: card.mainLabel };
    },
    end: () => endReordering(),
  });

  const styles = useStyles();

  let canReorder = false;
  if (card.isEditable) {
    dropSource(cardRef);

    // Only allow re-ordering if the queue isn't filtered
    if (
      currentSession &&
      !currentSession.isFilteringInProgress &&
      card.status !== CardStatus.Completed
    ) {
      canReorder = true;
      dropCard(cardRef);
      drag(dragHandleRef);
    } else {
      dropCard(null);
      drag(null);
    }
  } else {
    dropSource(null);
    dropCard(null);
    drag(null);
  }

  const disableOnDrag = isDraggingSource && isExpanded;
  const showCardContent = isExpanded;
  const [renderCardContent, setRenderCardContent] = useState(showCardContent);

  if (!card.isVisible) {
    return null;
  }

  let positionedParent = cardRef.current;
  while (positionedParent && positionedParent.style.position !== 'absolute') {
    positionedParent = positionedParent.parentElement as HTMLDivElement;
  }
  const offsetTop = positionedParent?.offsetTop;
  const offsetHeight = cardRef.current?.offsetHeight;

  let shouldBeSticky = false;
  if (
    (!cardDragStatus.targetId || cardDragStatus.targetId === cardId) &&
    scrollPosition !== undefined &&
    offsetTop !== undefined &&
    offsetHeight !== undefined
  ) {
    const scrolledOffsetTop = scrollPosition - offsetTop;
    const scrolledOffsetBottom = offsetHeight - scrolledOffsetTop;
    const isCrossingBoundary = scrolledOffsetTop >= 0 && scrolledOffsetBottom > 0;
    if (isCrossingBoundary) {
      shouldBeSticky = true;
    }
  }

  const stickyContainer = document.getElementById(CARD_TITLE_PORTAL_ID);
  const title = (
    <Collapse in={!isBeingDragged}>
      <div
        ref={dragHandleRef}
        className={clsx({
          [styles.disabledOnDrag]: disableOnDrag,
          [styles.dragHandle]: canReorder,
        })}
      >
        <ThemeProvider theme={getTheme(true)}>
          <QueueCardTitle card={card} />
        </ThemeProvider>
      </div>
    </Collapse>
  );
  let titleEl = title;
  if (shouldBeSticky && stickyContainer) {
    titleEl = (
      <>
        {createPortal(title, stickyContainer)}
        <Box sx={{ height: dragHandleRef.current?.offsetHeight }} />
      </>
    );
  }

  return (
    <Collapse in={!isBeingDragged}>
      <div className={styles.wrapper}>
        <Card
          elevation={5}
          className={clsx(styles.root, {
            [styles.isDeleting]: card.isDeleting,
            [styles.isSelected]: card.isSelected,
            [styles.rootHoveringSource]: isHoveringSource,
          })}
        >
          {card.isEditable && (
            <div
              className={clsx(styles.reorderIndicator, styles.topReorderIndicator, {
                [styles.visibleIndicator]: isBefore,
              })}
            />
          )}
          <span ref={preview} />
          <div ref={cardRef}>
            {titleEl}
            {card.data.doneAt && (
              <p className={styles.doneAt}>
                Done: {format(card.data.doneAt, 'yyyy-MM-dd HH:mm:ssXXXX')}
              </p>
            )}
            <Collapse
              onEnter={() => setRenderCardContent(true)}
              onExit={() => setTimeout(() => setRenderCardContent(false), 250)}
              in={showCardContent}
              timeout="auto"
            >
              {renderCardContent && (
                <CardContent className={styles.expandedInfo}>
                  <div className={clsx({ [styles.disabledOnDrag]: disableOnDrag })}>
                    <QueueCardFilename card={card} />
                  </div>
                  <QueueCardModules card={card} />
                </CardContent>
              )}
            </Collapse>
            <div
              className={clsx(styles.reorderIndicator, styles.bottomReorderIndicator, {
                [styles.visibleIndicator]: isAfter,
              })}
            />
          </div>
        </Card>
      </div>
    </Collapse>
  );
});
