import { observer } from 'mobx-react-lite';
import makeStyles from '@mui/styles/makeStyles';
import { useStore } from 'logic';

import clsx from 'clsx';
import { QueueCard } from './QueueCard';
import { QueueList } from './QueueList';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: '10px 5px 0',
    position: 'relative',
    minHeight: 0,
  },
  expandedCardWrapper: {
    marginBottom: theme.spacing(1),
  },
}));

export const SelectedQueue = observer(() => {
  const {
    uiActiveSessionStore: { selectedCards, areCardsExpanded },
  } = useStore();

  const styles = useStyles();

  return (
    <div className={styles.root}>
      <QueueList
        numItems={selectedCards.length}
        renderQueueItem={(index) =>
          selectedCards[index] ? (
            <div
              className={clsx({
                [styles.expandedCardWrapper]: areCardsExpanded,
              })}
            >
              <QueueCard id={selectedCards[index].id} />
            </div>
          ) : (
            <>{null}</>
          )
        }
      />
    </div>
  );
});
