import type { RootStore } from 'logic';
import { format } from 'date-fns';
import { BaseRow, CardStatus, rankString } from '@creative-kit/shared';
import type { Source } from 'logic/source';
import { unparse } from 'papaparse';

enum FileExtension {
  csv = 'CSV',
  json = 'JSON',
}

export class UiExportSourceStore {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  private getFileName(sourceName: string, fileExtension: FileExtension, exportType: string) {
    const sessionName = this.rootStore.sessionsStore.currentSession?.data.name ?? '';
    return `${format(new Date(), 'yyyy-MM-dd HH')}∶${format(
      new Date(),
      'mm'
    )}_${sessionName}_${sourceName}_${exportType}.${
      fileExtension === FileExtension.csv ? 'csv' : 'json'
    }`;
  }

  private generateDownload(textContent: string, filename: string) {
    // eslint-disable-line
    const csvData = new Blob([textContent], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvData);

    const link = document.createElement('a');
    link.setAttribute('href', csvUrl);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    // trigger the download
    link.click();
  }

  private getSource(sourceId: string) {
    const { sources } = this.rootStore.sourcesStore;

    const currentSource = sources[sourceId];
    return currentSource;
  }

  exportFullSource = (sourceId: string) => {
    const source = this.getSource(sourceId);
    this.exportCSV(
      source.orderedRows,
      source,
      this.getFileName(source.data.name, FileExtension.csv, 'ALL ITEMS')
    );
  };

  exportUsedRows = (sourceId: string) => {
    const source = this.getSource(sourceId);
    const rows = Object.values(source.rows).filter((row) => row.connectedCards.length > 0);
    this.exportCSV(
      rows,
      source,
      this.getFileName(source.data.name, FileExtension.csv, 'USED ROWS')
    );
  };

  exportFilteredSource = (sourceId: string) => {
    const source = this.getSource(sourceId);
    this.exportCSV(
      source.filteredRows,
      source,
      this.getFileName(source.data.name, FileExtension.csv, 'SOURCE SEARCH')
    );
  };

  exportSourceHeader = (sourceId: string) => {
    const source = this.getSource(sourceId);
    this.exportCSV([], source, this.getFileName(source.data.name, FileExtension.csv, 'TEMPLATE'));
  };

  exportCSV = (sourceRows: BaseRow[], source: Source, filename: string) => {
    const mpc = this.rootStore.uiModulePowerColumnStore.getSourceModulePowerColumn(source.id);
    let modulesHeader: string[] = [];
    let modulesRowCards: string[][] = [];
    if (mpc) {
      modulesHeader = mpc.modulesWithCount.flatMap((mc) => [
        `${mc.name}: CARD IDENTIFIERS`,
        `${mc.name}: CARD NUMBERS`,
        `${mc.name}: CARD TITLES`,
        `${mc.name}: CARD FILENAMES`,
        `${mc.name}: CARD STATUSES`,
      ]);
      modulesRowCards = sourceRows.map((row) =>
        mpc.modulesWithCount.flatMap((mc) => [
          mpc
            .getModuleLinkedCards(mc.id, row.id)
            .map((card) => card.id)
            .join(','),
          mpc
            .getModuleLinkedCards(mc.id, row.id)
            .map((card) => card.cardNumber)
            .join(','),
          mpc
            .getModuleLinkedCards(mc.id, row.id)
            .map((card) => card.mainLabel)
            .join(','),
          mpc
            .getModuleLinkedCards(mc.id, row.id)
            .map((card) => card.filename)
            .join(','),
          mpc
            .getModuleLinkedCards(mc.id, row.id)
            .map((card) =>
              card.status === CardStatus.Preview
                ? 'PREVIEW'
                : card.status === CardStatus.Completed
                  ? 'DONE'
                  : card.status === CardStatus.Current
                    ? 'CURRENT'
                    : card.isUpNext
                      ? 'UP NEXT'
                      : rankString(card.queuePosition)
            )
            .join(','),
        ])
      );
    }

    const rowHeader = source.visibleColumns;
    const header: string[] = [...rowHeader.map(({ fieldName }) => fieldName), ...modulesHeader];
    // preparing the data rows
    const data = sourceRows
      // this order should match the data in the header
      .map((row, idx) => [
        ...rowHeader.map((column) => row.getValue(column.fieldId)),
        ...modulesRowCards[idx],
      ]);

    // adding the header first then each datarow
    const rows = [header, ...data];

    this.generateDownload(unparse(rows), filename);
  };
}
