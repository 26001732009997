import clsx from 'clsx';
import { Card, CardContent } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useStore } from 'logic';
import { format } from 'date-fns';
import { QueueCardTitle } from './QueueCardTitle';
import { QueueCardFilename } from './QueueCardFilename';
import { QueueCardModules } from './QueueCardModules';
import { useStyles } from './cardStyles';

interface QueueCardProps {
  id: string;
}

export const FocusedCard = observer(({ id: cardId }: QueueCardProps) => {
  const {
    cardsStore: { cards },
    uiBatchCardsStore: { generatedCardsById },
  } = useStore();

  const card = cards[cardId] || generatedCardsById[cardId];

  const styles = useStyles();

  return (
    <div className={styles.wrapper} style={{ overflow: 'scroll' }}>
      <Card
        elevation={5}
        className={clsx(styles.root, {
          [styles.isDeleting]: card.isDeleting,
          [styles.isSelected]: card.isSelected,
        })}
      >
        <div>
          <QueueCardTitle card={card} />
          {card.data.doneAt && (
            <p className={styles.doneAt}>
              Done: {format(card.data.doneAt, 'yyyy-MM-dd HH:mm:ssXXXX')}
            </p>
          )}

          <CardContent className={styles.expandedInfo}>
            <div style={{ width: '100%' }}>
              <QueueCardFilename card={card} />
            </div>
            <QueueCardModules card={card} />
          </CardContent>
        </div>
      </Card>
    </div>
  );
});
