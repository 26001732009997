import { action, makeObservable, observable } from 'mobx';

export const CARD_TITLE_PORTAL_ID = '__CARD_TITLE_PORTAL';
export const MODULE_HEADER_PORTAL_ID = '__MODULE_HEADER_PORTAL';

export class UiStickyHeadersStore {
  scrollPosition?: number;

  constructor() {
    makeObservable(this, {
      scrollPosition: observable,
      setScrollPosition: action.bound,
    });
  }

  setScrollPosition(newPosition: number) {
    this.scrollPosition = newPosition;
  }
}
