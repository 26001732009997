import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { ButtonProps, SxProps, Tooltip, Theme, IconButton } from '@mui/material';
import { SessionModule } from '@creative-kit/shared';
import AddIcon from '@mui/icons-material/Add';
import ReplaceIcon from '@mui/icons-material/Cached';
import { Card } from 'logic/card';
import { ImageUploadButton } from 'ui/ImageUploadButton';

interface AddModuleRowButtonProps extends ButtonProps {
  module: SessionModule;
  card: Card;
  buttonSx?: SxProps<Theme>;
}

export const AddModuleRowButton = observer(
  ({ module, card, buttonSx }: AddModuleRowButtonProps) => {
    const {
      sessionsStore: { currentSession },
      sourcesStore: { sources },
      uiPermissions: { cannotEditCurrentSession },
      uiModuleSourceEditor,
    } = useStore();
    const source = sources[module.sourceId];
    const editedRows = uiModuleSourceEditor.getEditedRowsForModule(module.id);
    const connectedRows = card.getConnectedRows(module.id);
    const isEditingRowsInCard = !!connectedRows.find((rowId) => editedRows.includes(rowId));

    const handleAddSourceRow = async () => {
      currentSession?.addEmptyConnectionToCard(card, module.id);
    };

    const handleAddImageRow = (rowIds: string[]) => {
      currentSession?.addConnection({
        rowIds,
        moduleId: module.id,
        card,
      });
    };

    const isImageModule = source?.type === 'image';

    if (cannotEditCurrentSession) {
      return null;
    }

    const singleModuleAlreadyConnected = !module.multi && connectedRows.length > 0;
    const isDisabled = isEditingRowsInCard && singleModuleAlreadyConnected;

    let tooltipTitle = 'Create New Row And Assign To This Bucket';
    if (isImageModule) {
      tooltipTitle = 'Upload Images';
    } else if (singleModuleAlreadyConnected) {
      if (isDisabled) {
        tooltipTitle =
          'Save / Exit Current Changes to Create a New Row and REPLACE the Current Connection in this Module';
      } else {
        tooltipTitle = 'Create a New Row and REPLACE the Current Connection in this Module';
      }
    }

    const content = (
      <Tooltip title={tooltipTitle}>
        <span>
          <IconButton
            disabled={isDisabled}
            size="small"
            sx={{ ...buttonSx, marginTop: 0 }}
            onClick={!isImageModule ? handleAddSourceRow : () => {}}
          >
            {singleModuleAlreadyConnected ? (
              <ReplaceIcon fontSize="small" />
            ) : (
              <AddIcon fontSize="small" />
            )}
          </IconButton>
        </span>
      </Tooltip>
    );

    if (isImageModule) {
      return (
        <ImageUploadButton
          sourceId={module.sourceId}
          multiple={module.multi}
          onUploadSuccess={handleAddImageRow}
        >
          {content}
        </ImageUploadButton>
      );
    }

    return content;
  }
);
