import { action, computed, makeObservable, observable } from 'mobx';
import type { RootStore } from '.';
import { Card } from './card';
import { Source } from './source';
import { UiSourceModulePowerColumn } from './ui-source-module-power-column';

export class UiModulePowerColumnStore {
  private rootStore: RootStore;
  sourceModulePowerColumns: Record<string, UiSourceModulePowerColumn> = {};
  showDetailedCounts: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable<this, 'sourceModulePowerColumns'>(this, {
      sourceModulePowerColumns: observable,
      rowSelectionsByModuleId: computed,
      showDetailedCounts: observable,
      initializeColumns: action,
      hasAnyPowerColumnFilters: computed,
    });
  }

  get hasAnyPowerColumnFilters() {
    return Object.keys(this.rowSelectionsByModuleId).length > 0;
  }

  initializeColumns(sources: Source[]) {
    sources.forEach((source) => {
      if (!this.sourceModulePowerColumns[source.id]) {
        this.sourceModulePowerColumns[source.id] = new UiSourceModulePowerColumn(
          this.rootStore,
          source.id,
          this.rootStore.sessionsStore.currentSession!
        );
      }
    });
  }

  getSourceModulePowerColumn = (sourceId: string) =>
    this.sourceModulePowerColumns[sourceId] || null;

  get rowSelectionsByModuleId() {
    const rowSelectionsByModuleId: Record<string, Set<string>> = {};

    Object.values(this.sourceModulePowerColumns).forEach((pc) => {
      Object.entries(pc.selectedRowsByModule).forEach(([moduleId, rows]) => {
        if (rows.size > 0) {
          rowSelectionsByModuleId[moduleId] = rows;
        }
      });
    });

    return rowSelectionsByModuleId;
  }

  clearAllModuleFilters = () => {
    for (const spc of Object.values(this.sourceModulePowerColumns)) {
      spc.clearAllFilters();
    }
  };

  clearModuleFilters = (moduleId: string) => {
    const sourceId = this.rootStore.sessionsStore.currentSession?.data.modules[moduleId].sourceId;
    if (sourceId) {
      this.sourceModulePowerColumns[sourceId].clearFilters(moduleId);
    }
  };

  isRowSelected = (moduleId: string, rowId: string) =>
    !!this.rowSelectionsByModuleId[moduleId]?.has(rowId);

  get hasRowSelection() {
    return Object.values(this.rowSelectionsByModuleId).some((sel) => sel.size > 0);
  }

  isCardVisibleBasedOnRowSelections = (card: Card) =>
    !this.hasRowSelection ||
    Object.entries(card.data.connections).some(([moduleId, rowOrRows]) => {
      const cardRows = Array.isArray(rowOrRows) ? rowOrRows : rowOrRows == null ? [] : [rowOrRows];
      const selectedRows = Array.from(this.rowSelectionsByModuleId[moduleId] || new Set<string>());

      return selectedRows.some((selectedRow) => cardRows.includes(selectedRow));
    });
}
