/**
 * Trims all leading/trailing special characters from a string
 */
export const trimSpecialCharacters = (originalString: string) => {
  const trim = (str: string) => str.replace(/^[^a-zA-Z0-9]|[^a-zA-Z0-9]$/g, '');
  let trimmedString = originalString;
  while (trim(trimmedString) !== trimmedString) {
    trimmedString = trim(trimmedString);
  }
  return trimmedString;
};
