import type { RootStore } from 'logic';
import { action, computed, makeObservable, observable } from 'mobx';
import { SessionModule } from '@creative-kit/shared';
import { Card } from './card';
import { QueueTabName } from './ui-active-session';

interface BatchBlank {
  mode: 'blank';
  numCards: number;
}

interface BatchSource {
  mode: 'source';
  moduleId: string;
  // generateFrom: string;
  numVariations: number;
  suffixes: string[];
}

type BatchSettings = BatchBlank | BatchSource;

export class UiBatchCardsStore {
  private rootStore: RootStore;

  batchBlankSettings?: BatchBlank;
  batchSourceSettings?: BatchSource;
  batchSettings?: BatchSettings;
  preview = false;
  afterCardId?: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      batchBlankSettings: observable,
      batchSourceSettings: observable,
      batchSettings: observable.ref,
      preview: observable,
      afterCardId: observable,
      activateBatchMode: action.bound,
      deactivateBatchMode: action.bound,
      previewBatchMode: action.bound,
      exitPreview: action.bound,
      switchBatchMode: action.bound,
      setNumCards: action.bound,
      setModuleId: action.bound,
      setNumVariations: action.bound,
      setSuffix: action.bound,
      showDialog: computed,
      generatedCards: computed,
      generatedCardsById: computed,
    });
  }

  get moduleOptions() {
    return (this.rootStore.sessionsStore.currentSession?.data.sourceIds || []).flatMap(
      (sourceId) =>
        this.rootStore.uiModulePowerColumnStore.getSourceModulePowerColumn(sourceId)
          ?.modulesWithCount ?? []
    );
  }

  get generatedCards() {
    const { currentSession } = this.rootStore.sessionsStore;
    if (!(currentSession && this.batchSettings)) return [];

    if (this.batchSettings.mode === 'blank') {
      return Array.from(Array(this.batchSettings.numCards)).map((_, idx) => {
        const card = new Card(
          `${idx}`,
          currentSession.id,
          {
            connections: {},
            createdAt: new Date(),
            createdBy: {
              userId: '',
              email: null,
            },
          },
          this.rootStore,
          currentSession.data.cardIds.length + idx
        );
        return card;
      });
    }

    const targetModule = currentSession.data.modules[this.batchSettings.moduleId] as
      | SessionModule
      | undefined;
    if (!targetModule) return [];

    const targetSource = this.rootStore.sourcesStore.sources[targetModule.sourceId];
    if (!targetSource) {
      return [];
    }

    const targetRows = targetSource.filteredRows;

    const cards: Card[] = [];
    let cardIdx = currentSession.data.cardIds.length;
    for (const row of targetRows) {
      for (let i = 0; i < this.batchSettings.numVariations; i++) {
        const card = new Card(
          `${row.id}_${i}`,
          currentSession.id,
          {
            connections: {
              [targetModule.id]: targetModule.multi ? [row.id] : row.id,
            },
            suffix: this.batchSettings.suffixes[i],
            createdAt: new Date(),
            createdBy: {
              userId: '',
              email: null,
            },
          },
          this.rootStore,
          cardIdx
        );
        cardIdx += 1;
        cards.push(card);
      }
    }

    return cards;
  }

  get generatedCardsById() {
    return this.generatedCards.reduce(
      (byId, current) => ({
        ...byId,
        [current.id]: current,
      }),
      {} as Record<string, Card>
    );
  }

  get visibleSuffixes() {
    return this.batchSettings?.mode === 'source'
      ? this.batchSettings.suffixes.slice(0, this.batchSettings.numVariations)
      : [];
  }

  activateBatchMode(afterCardId?: string) {
    this.batchBlankSettings = {
      mode: 'blank',
      numCards: 1,
    };

    this.batchSettings = this.batchBlankSettings;
    this.afterCardId = afterCardId;
  }

  deactivateBatchMode() {
    this.batchSettings = undefined;
    this.preview = false;
    this.afterCardId = undefined;
  }

  previewBatchMode() {
    this.preview = true;

    // Activate the batch tab
    this.rootStore.uiActiveSessionStore.setCurrentQueueTab(QueueTabName.Batch);
  }

  exitPreview() {
    this.preview = false;
  }

  get showDialog() {
    return !!this.batchSettings && !this.preview;
  }

  get showAddCardsButton() {
    return (
      this.rootStore.uiPermissions.canEditCurrentSession &&
      this.rootStore.uiActiveSessionStore.currentQueueTab !== QueueTabName.Done &&
      !this.rootStore.uiCardView.isCardFocused
    );
  }

  get batchModeActive() {
    return !!this.batchSettings;
  }

  switchBatchMode(newMode: string) {
    if (newMode === 'blank') {
      this.batchSettings = this.batchBlankSettings;
    } else {
      if (!this.batchSourceSettings) {
        // initialize batch from source settings with arbitrary source/module
        const { currentSession } = this.rootStore.sessionsStore;
        if (!currentSession) return;

        const arbitraryModule = currentSession.modules[0];
        if (!arbitraryModule) return;

        this.batchSourceSettings = {
          mode: 'source',
          moduleId: arbitraryModule.id,
          numVariations: 1,
          suffixes: [''],
        };
      }

      this.batchSettings = this.batchSourceSettings;
    }
  }

  setNumCards(newNumCards: number) {
    if (this.batchSettings?.mode === 'blank') {
      this.batchSettings.numCards = newNumCards;
    }
  }

  setModuleId(newModuleId: string) {
    if (this.batchSettings?.mode === 'source') {
      this.batchSettings.moduleId = newModuleId;
    }
  }

  get numRowsInSelectedSource() {
    const { currentSession } = this.rootStore.sessionsStore;
    if (!(currentSession && this.batchSettings?.mode === 'source')) return 0;
    const targetModule = currentSession.data.modules[this.batchSettings.moduleId];
    const targetSource = this.rootStore.sourcesStore.sources[targetModule.sourceId];
    return targetSource.orderedRows.length;
  }

  get numRowsVisibleInSelectedSource() {
    const { currentSession } = this.rootStore.sessionsStore;
    if (!(currentSession && this.batchSettings?.mode === 'source')) return 0;
    const targetModule = currentSession.data.modules[this.batchSettings.moduleId];
    const targetSource = this.rootStore.sourcesStore.sources[targetModule.sourceId];
    return targetSource.filteredRows.length;
  }

  setNumVariations(newNumVariations: number) {
    if (this.batchSettings?.mode === 'source') {
      this.batchSettings.numVariations = newNumVariations;

      while (this.batchSettings.suffixes.length < newNumVariations) {
        this.batchSettings.suffixes.push('');
      }
    }
  }

  setSuffix(idx: number, newSuffix: string) {
    if (this.batchSettings?.mode === 'source') {
      this.batchSettings.suffixes[idx] = newSuffix;
    }
  }

  createCards = () => {
    this.rootStore.sessionCardActions.batchAddCards(this.generatedCards, this.afterCardId);
    this.deactivateBatchMode();
  };
}
