import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { SessionModule } from '@creative-kit/shared';
import { Row } from 'logic/row';
import { ButtonBase, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/RemoveCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useStore } from 'logic';

interface ModuleRowImageProps {
  isEditable: boolean;
  row: Row;
  module: SessionModule;
  onRemove: () => void;
}

const IMAGE_HEIGHT = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: IMAGE_HEIGHT,
    margin: theme.spacing(0.75),
    '&:hover': {
      '& $delete': {
        opacity: 1,
        pointerEvents: 'initial',
      },
    },
  },
  imageButton: {
    height: IMAGE_HEIGHT,
  },
  hasSearch: {
    borderColor: '#ffee00',
    borderStyle: 'solid',
    borderWidth: 3,
  },
  image: {
    height: '100%',
    borderRadius: '5px',
  },
  delete: {
    position: 'absolute',
    top: 4,
    left: 0,
    opacity: 0,
    pointerEvents: 'none',
    transition: theme.transitions.create('opacity'),
  },
}));

export const ModuleRowImage = observer(({ row, onRemove, isEditable }: ModuleRowImageProps) => {
  const styles = useStyles();
  const {
    uiCardSearchStore: { hasSearch, searchTerms },
    uiImageModal: { setImage },
    uiImageSourceUpload: { getProgress },
  } = useStore();
  const url = row.getCellImageUrl(IMAGE_HEIGHT * 2);
  const progress = getProgress(row.sourceId, row.id);
  const doesImageMetadataMatchSearch =
    hasSearch &&
    !!Object.values(row.getFieldValues()).find((value) =>
      searchTerms.includes(value.toLowerCase())
    );

  return (
    <div className={styles.root}>
      {url && row.image && (
        <ButtonBase
          className={clsx(styles.imageButton, {
            [styles.hasSearch]: doesImageMetadataMatchSearch,
          })}
          onClick={() => setImage(row.image)}
        >
          <img className={styles.image} src={url} alt="" />
        </ButtonBase>
      )}
      {!row.image &&
        progress &&
        (progress.error ? (
          <ErrorIcon color="error" />
        ) : (
          <CircularProgress variant="determinate" value={progress.progress * 100} />
        ))}
      <IconButton
        sx={{ color: '#808080' }}
        className={styles.delete}
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          if (isEditable) {
            onRemove();
          }
        }}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
});
