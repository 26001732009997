import { useState, useCallback } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  List,
  Drawer,
  Typography,
  Box,
  Button,
  Divider,
  Collapse,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { observer } from 'mobx-react-lite';

import { useStore } from 'logic';
import { RouteNames } from 'logic/routes';
import { AuthTool } from './AuthTool';
import { SessionName } from './SessionName';
import { SessionItem } from './SessionItem';
import { DuplicateSession } from './DuplicateSession';
import { ShareSession } from './ShareSession';

interface StyleProps {
  isArchiveExpanded: boolean;
  isTrashExpanded: boolean;
}
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    opacity: 0.44,
    transition: theme.transitions.create('opacity'),
    '&:hover': {
      opacity: 1,
    },
  },
  drawer: {
    width: 500,
  },
  expand: {
    marginLeft: 'auto',
    transform: (p: StyleProps) => (p.isArchiveExpanded ? 'rotate(180deg)' : 'rotate(0deg)'),
    transition: theme.transitions.create('transform'),
  },
  expandTrash: {
    marginLeft: 'auto',
    transform: (p: StyleProps) => (p.isTrashExpanded ? 'rotate(180deg)' : 'rotate(0deg)'),
    transition: theme.transitions.create('transform'),
  },
  importStep: {
    color: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 24,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export const ApplicationBar = observer(() => {
  const [duplicateSessionId, setDuplicateSessionId] = useState<string>();
  const [isArchiveExpanded, setIsArchiveExpanded] = useState(false);
  const handleExpandClick = () => setIsArchiveExpanded(!isArchiveExpanded);

  const [isTrashExpanded, setIsTrashExpanded] = useState(false);
  const handleExpandTrashClick = () => setIsTrashExpanded(!isTrashExpanded);

  const styles = useStyles({ isArchiveExpanded, isTrashExpanded });

  const {
    sessionsStore: {
      activeSessions,
      archivedSessions,
      deletedSessions,
      currentSession,
      archiveSession,
      deleteSession,
    },
    authStore: { user },
    uiPermissions: { isCurrentSessionOwner },
    routerStore,
  } = useStore();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = useCallback(() => setDrawerOpen((d) => !d), []);
  const duplicateSession = (sessionId: string) => {
    setDuplicateSessionId(sessionId);
    setDrawerOpen(false);
  };

  const handleCreate = async () => {
    setDrawerOpen(false);
    await routerStore.goTo(RouteNames.HOME);
  };

  const loadSession = useCallback(
    (targetSessionId: string) => () => {
      toggleDrawer();
      routerStore.goTo(RouteNames.SESSION, {
        params: { sessionId: targetSessionId },
      });
    },
    [routerStore, toggleDrawer]
  );

  if (!user) {
    return null;
  }

  return (
    <>
      <AppBar className={styles.root} position="static" enableColorOnDark color="default">
        <Toolbar>
          <IconButton onClick={toggleDrawer} edge="start" color="inherit" size="large">
            <MenuIcon />
          </IconButton>
          <Link
            underline="none"
            sx={{
              color: (t) => t.palette.text.primary,
            }}
            href="#"
            onClick={() => routerStore.goTo(RouteNames.HOME)}
          >
            <Typography variant="h6">Creative Queue</Typography>
          </Link>
          <Box flex="1">{currentSession && <SessionName />}</Box>
          <ShareSession />
          {isCurrentSessionOwner === false && currentSession?.id !== undefined && (
            <Button
              variant="contained"
              onClick={() => duplicateSession(currentSession.id)}
              sx={{ margin: (t) => t.spacing(0, 2) }}
            >
              Duplicate Project
            </Button>
          )}
          <AuthTool />
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <div>
          <Box mt={1} mb={2} display="flex" flexDirection="column" alignItems="center">
            <Box mb={1}>
              <Typography variant="h5">Creative Queue</Typography>
            </Box>
            <Button size="small" variant="outlined" onClick={() => handleCreate()}>
              NEW PROJECT
            </Button>
          </Box>
          <Box m={1}>
            <Typography variant="h6" color="textSecondary">
              Active Projects
            </Typography>
          </Box>
          <Divider />
          {activeSessions.length === 0 ? (
            <Box m={1}>
              <Typography variant="subtitle2" color="textSecondary">
                No active Project
              </Typography>
            </Box>
          ) : (
            <List className={styles.drawer} component="nav">
              {activeSessions.map((session) => (
                <SessionItem
                  key={session.id}
                  session={session}
                  onClick={loadSession(session.id)}
                  archiveSession={archiveSession}
                  deleteSession={deleteSession}
                  duplicateSession={duplicateSession}
                />
              ))}
            </List>
          )}
          <Box m={1} display="flex" alignItems="center">
            <Typography variant="h6" color="textSecondary">
              Archived Projects
            </Typography>
            <IconButton
              className={styles.expand}
              onClick={handleExpandClick}
              aria-expanded={isArchiveExpanded}
              aria-label="show more"
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
          <Collapse in={isArchiveExpanded} timeout="auto">
            <div>
              <Divider />
              <List className={styles.drawer} component="nav">
                {archivedSessions.map((session) => (
                  <SessionItem
                    key={session.id}
                    session={session}
                    onClick={loadSession(session.id)}
                    archiveSession={archiveSession}
                    deleteSession={deleteSession}
                    duplicateSession={duplicateSession}
                  />
                ))}
              </List>
            </div>
          </Collapse>
          <Box m={1} display="flex" alignItems="center">
            <Typography variant="h6" color="textSecondary">
              Trashed Projects
            </Typography>
            <IconButton
              className={styles.expandTrash}
              onClick={handleExpandTrashClick}
              aria-expanded={isTrashExpanded}
              aria-label="show more"
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
          <Collapse in={isTrashExpanded}>
            <Divider />
            <List className={styles.drawer} component="nav">
              {deletedSessions.map((session) => (
                <SessionItem
                  key={session.id}
                  session={session}
                  onClick={loadSession(session.id)}
                  archiveSession={archiveSession}
                  deleteSession={deleteSession}
                  duplicateSession={duplicateSession}
                />
              ))}
            </List>
          </Collapse>
        </div>
      </Drawer>
      {duplicateSessionId && (
        <DuplicateSession
          open={duplicateSessionId !== undefined}
          onClose={() => setDuplicateSessionId(undefined)}
          sessionId={duplicateSessionId}
        />
      )}
    </>
  );
});
