import { withPlural } from '@creative-kit/shared';
import type { RootStore } from 'logic';
import { action, computed, makeObservable, observable } from 'mobx';

export enum BatchCopyMode {
  CARD_TITLE = 'cardtitle',
  FILENAME = 'filename',
  URL = 'url',
}

const BatchCopyName = {
  [BatchCopyMode.CARD_TITLE]: 'Card Titles',
  [BatchCopyMode.FILENAME]: 'Filenames',
  [BatchCopyMode.URL]: 'URL',
};

type CardsToUse = 'selected' | 'done' | 'inProgress';

export class UiBatchCopyStore {
  private rootStore: RootStore;
  copyMode: BatchCopyMode | null = null;
  cardsToUse: {
    done: boolean;
    inProgress: boolean;
    selected: boolean;
  } = {
    inProgress: false,
    selected: false,
    done: false,
  };
  lineSeparator: 'comma' | 'line' = 'line';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      lineSeparator: observable,
      cardsToUse: observable,
      copyMode: observable,
      textToCopy: computed,
      isDialogOpen: computed,
      selectedCards: computed,
      setCardsToUse: action,
      setCopyMode: action,
      setLineSeparator: action,
    });
  }

  get isDialogOpen() {
    return !!this.copyMode;
  }

  get selectedCards() {
    const { doneVisibleCardIds, inProgressCardIds } = this.rootStore.sessionsStore.currentSession!;
    const { selectedCardIds } = this.rootStore.uiActiveSessionStore;
    let cardIds: string[] = [];
    if (this.cardsToUse.done) {
      cardIds = [...cardIds, ...doneVisibleCardIds];
    }
    if (this.cardsToUse.inProgress) {
      cardIds = [...cardIds, ...inProgressCardIds];
    }
    if (this.cardsToUse.selected) {
      cardIds = [...cardIds, ...selectedCardIds];
    }
    return Array.from(new Set(cardIds));
  }

  get namesToUse(): string[] {
    const cards = this.rootStore.sessionsStore.currentSession?.getVisible(this.selectedCards) ?? [];
    switch (this.copyMode) {
      case BatchCopyMode.CARD_TITLE:
        return cards
          .filter((c) => c.labelInfo.errors.length === 0 && c.labelInfo.mainLabel.length > 0)
          .map((card) => card.mainLabel);
      case BatchCopyMode.FILENAME:
        return cards
          .filter((c) => !c.filenameNeedsAttention && c.filenameInfo.errors.length === 0)
          .map((card) => card.filename);
      case BatchCopyMode.URL:
        return cards.map((c) => c.permalink);
      default:
        return [];
    }
  }

  get textToCopy() {
    const names = this.namesToUse;
    return names.join(this.lineSeparator === 'comma' ? ', ' : '\n');
  }

  setLineSeparator = (separator: 'line' | 'comma') => {
    this.lineSeparator = separator;
  };

  setCardsToUse = (type: CardsToUse, value: boolean) => {
    this.cardsToUse[type] = value;
  };

  closeDialog = () => {
    this.copyMode = null;
  };

  setCopyMode = async (mode: BatchCopyMode) => {
    this.copyMode = mode;
  };

  openDialogForCardTitle = (cardsToUse: CardsToUse = 'selected') => {
    this.setCopyMode(BatchCopyMode.CARD_TITLE);
    this.setCardsToUse(cardsToUse, true);
  };

  openDialogForUrl = (cardsToUse: CardsToUse = 'selected') => {
    this.setCopyMode(BatchCopyMode.URL);
    this.setCardsToUse(cardsToUse, true);
  };

  openDialogForFilename = (cardsToUse: CardsToUse = 'selected') => {
    this.setCopyMode(BatchCopyMode.FILENAME);
    this.setCardsToUse(cardsToUse, true);
  };

  batchCopy = async () => {
    navigator.clipboard.writeText(this.textToCopy);
    this.rootStore.uiSnackbarStore.setSnackbarDetails({
      description: `${withPlural(
        this.namesToUse.length,
        BatchCopyName[this.copyMode!]
      )} copied to the clipboard`,
    });
  };
}
