import {
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Switch,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { NewModuleDialog } from './NewModuleDialog';

const useStyles = makeStyles((theme) => ({
  queuePreferenceToggle: {
    marginLeft: theme.spacing(1),
  },
}));

export const ModuleManager = observer(() => {
  const styles = useStyles();
  const {
    sessionsStore: { currentSession },
    uiFilenameStore: { openDialog: openFilenameDialog },
    uiCardTitleStore: { openDialog: openCardTitleDialog },
    uiActiveSessionStore: { newModuleDialogOpen, setNewModuleDialogOpen },
  } = useStore();

  const {
    data: { automaticModuleCreation },
    automaticCardTitleSuffix,
    automaticFilenameVersioning,
    toggleAutomaticFilenameVersioning,
    toggleAutomaticCardTitleSuffix,
    toggleAutomaticModuleCreation,
  } = currentSession!;

  const [moduleManagerAnchor, setModuleManagerAnchor] = useState<HTMLButtonElement | null>(null);

  return (
    <div>
      <IconButton onClick={(e) => setModuleManagerAnchor(e.currentTarget)} size="large">
        <SettingsIcon />
      </IconButton>
      <Menu
        open={!!moduleManagerAnchor}
        anchorEl={moduleManagerAnchor}
        onClose={() => setModuleManagerAnchor(null)}
        variant="menu"
      >
        <div>
          <ListSubheader>Manage Assets</ListSubheader>
          <MenuItem
            onClick={() => {
              setNewModuleDialogOpen(true);
              setModuleManagerAnchor(null);
            }}
          >
            New Bucket
          </MenuItem>
          <MenuItem
            onClick={() => {
              openCardTitleDialog();
              setModuleManagerAnchor(null);
            }}
          >
            Title Settings
          </MenuItem>
          <MenuItem
            onClick={() => {
              openFilenameDialog();
              setModuleManagerAnchor(null);
            }}
          >
            Filename Settings
          </MenuItem>
          <Divider />
          <ListSubheader>Queue Preferences</ListSubheader>
          <MenuItem disableGutters>
            <FormControl fullWidth>
              <FormControlLabel
                className={styles.queuePreferenceToggle}
                labelPlacement="end"
                control={
                  <Switch
                    size="small"
                    checked={automaticModuleCreation}
                    onChange={toggleAutomaticModuleCreation}
                  />
                }
                label="Create Buckets For New Sources"
              />
            </FormControl>
          </MenuItem>
          <MenuItem disableGutters>
            <FormControl fullWidth>
              <FormControlLabel
                className={styles.queuePreferenceToggle}
                labelPlacement="end"
                control={
                  <Switch
                    size="small"
                    checked={automaticCardTitleSuffix}
                    onChange={toggleAutomaticCardTitleSuffix}
                  />
                }
                label="Auto Add Filename Suffix to Asset Titles"
              />
            </FormControl>
          </MenuItem>
          <MenuItem disableGutters>
            <FormControl fullWidth>
              <FormControlLabel
                className={styles.queuePreferenceToggle}
                labelPlacement="end"
                control={
                  <Switch
                    size="small"
                    checked={automaticFilenameVersioning}
                    onChange={toggleAutomaticFilenameVersioning}
                  />
                }
                label="Auto Avoid Filename Duplicates"
              />
            </FormControl>
          </MenuItem>
        </div>
      </Menu>
      <NewModuleDialog open={newModuleDialogOpen} onClose={() => setNewModuleDialogOpen(false)} />
    </div>
  );
});
