import { BaseRow, FirestoreSourceRow, RowData } from '@creative-kit/shared';
import { action, makeObservable, observable } from 'mobx';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { Resize } from '@cloudinary/url-gen/actions/resize';
import { db } from './data';
import type { RootStore } from '.';
import { UiModuleRowEditor } from './ui-module-row-editor';

export class Row extends BaseRow {
  private ref;
  moduleRowEditor: UiModuleRowEditor;

  constructor(
    id: string,
    sourceId: string,
    sessionId: string,
    data: FirestoreSourceRow,
    rootStore: RootStore
  ) {
    super(id, sourceId, sessionId, data, rootStore);

    makeObservable<this, 'data'>(this, {
      data: observable.struct,
      updateData: action,
    });

    this.ref = db.sessionSourceRows(this.sessionId, this.sourceId).doc(this.id);
    this.moduleRowEditor = new UiModuleRowEditor();
  }

  updateData(newData: FirestoreSourceRow) {
    this.data = newData;
  }

  // Soft-delete if row is connected to cards
  delete() {
    if (this.connectedCards.length > 0) {
      this.ref.set(
        {
          isDeleted: true,
        },
        { merge: true }
      );
    } else {
      this.ref.delete();
    }
  }

  editRowData(newData: RowData) {
    this.ref.set(
      {
        fieldValues: {
          ...this.data.fieldValues,
          ...newData,
        },
      },
      { merge: true }
    );
  }

  setImage(image: FirestoreSourceRow['image']) {
    if (image) {
      const [filename, extension] = image.originalFilename.split('.');
      this.ref.set(
        {
          image,
          fieldValues: {
            notes: '',
            filename,
            extension,
          },
        },
        { merge: true }
      );
    }
  }

  getCellImageUrl(height: number) {
    if (this.data.image) {
      const { storage } = this.data.image;
      if (storage.type === 'cloudinary') {
        const image = new CloudinaryImage(
          storage.publicId,
          { cloudName: storage.cloudName },
          { secure: true }
        ).resize(Resize.scale().height(height));

        return image.toURL();
      }
    }

    return null;
  }
}
