import { action, makeObservable, observable } from 'mobx';
import { Card } from './card';

export class UiCardsStore {
  private cardExpandedStatus: Record<string, boolean> = {};

  constructor() {
    makeObservable<this, 'cardExpandedStatus'>(this, {
      cardExpandedStatus: observable,
      setCardExpanded: action.bound,
      initializeCards: action,
    });
  }

  initializeCards(cards: Card[]) {
    cards.forEach((card) => {
      if (this.cardExpandedStatus[card.id] === undefined) {
        this.setCardExpanded(card.id, true);
      }
    });
  }

  setCardExpanded(cardId: string, isExpanded: boolean) {
    this.cardExpandedStatus[cardId] = isExpanded;
  }

  isCardExpanded = (cardId: string) => !!this.cardExpandedStatus[cardId];
}
