import React, { useState, useCallback, useEffect } from 'react';
import {
  Avatar,
  Menu,
  MenuItem,
  Box,
  ButtonBase,
  FormControl,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useStore } from 'logic';
import googleLoginImage from 'images/google-signin.png';

export const AuthTool = observer(() => {
  const {
    authStore: { loginWithGoogle, user, logout },
    uiThemeStore: { darkMode, toggleDarkMode },
  } = useStore();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => setMenuAnchor(null), []);

  const doLogin = useCallback(() => {
    closeMenu();
    loginWithGoogle();
  }, [closeMenu, loginWithGoogle]);

  useEffect(() => {
    if (!user && menuAnchor) {
      setMenuAnchor(null);
    }
  }, [user, menuAnchor, setMenuAnchor]);

  const renderGoogleSignIn = () => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" onClick={doLogin}>
      <img alt="Login with Google" src={googleLoginImage} width={150} />
    </a>
  );

  if (!user) {
    return renderGoogleSignIn();
  }

  return (
    <>
      <ButtonBase onClick={openMenu}>
        <Avatar src={user.avatarUrl || ' '} alt={user.displayName || undefined} />
      </ButtonBase>
      <Menu
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={closeMenu}
      >
        <Box mx={1} my={2}>
          <FormControl component="div" margin="none" fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={darkMode}
                  onChange={toggleDarkMode}
                  name="darkMode"
                  color="secondary"
                />
              }
              label="Dark Mode"
            />
          </FormControl>
        </Box>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
});
