import { useStore } from 'logic';
import { useDropzone } from 'react-dropzone';

export function useSourceFilePicker(sourceId: string) {
  const { uiImageSourceUpload } = useStore();
  const { open: openSourceFilePicker, getInputProps } = useDropzone({
    onDrop(files: File[]) {
      for (const file of files) {
        uiImageSourceUpload.uploadImageToSource(sourceId, file);
      }
    },
  });
  return { openSourceFilePicker, getInputProps };
}
