import { Card } from 'logic/card';
import { observer } from 'mobx-react-lite';
import { QueueCardModule } from './QueueCardModule';

interface QueueCardModulesProps {
  card: Card;
}

export const QueueCardModules = observer(({ card }: QueueCardModulesProps) => (
  <>
    {card.visibleModules.map((m) => (
      <QueueCardModule key={m.id} moduleId={m.id} card={card} />
    ))}
  </>
));
