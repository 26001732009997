import { Card, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useStore } from 'logic';
import { CARD_TITLE_PORTAL_ID } from 'logic/ui-sticky-headers';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useRef } from 'react';

interface ModuleWrapperProps {
  // Expected children is CardContent component
  children?: React.ReactNode;
  header: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  isSelected?: boolean;
}

/**
 * Shell for creating the layout of a module or module like card inside the Queue Card
 */
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  header: {
    padding: `${theme.spacing(0.5)} 0 0 ${theme.spacing(0.5)}`,
    fontWeight: 600,
    fontSize: theme.typography.body1.fontSize,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
  },
}));

export const ModuleWrapper = observer(
  ({ isSelected = false, className, children, header, style, headerStyle }: ModuleWrapperProps) => {
    const styles = useStyles();
    const theme = useTheme();
    const cardRef = useRef<HTMLDivElement | null>(null);
    const headerRef = useRef<HTMLDivElement | null>(null);

    const {
      uiStickyHeaders: { scrollPosition },
    } = useStore();

    let positionedParent = cardRef.current;
    while (positionedParent && positionedParent.style.position !== 'absolute') {
      positionedParent = positionedParent.parentElement as HTMLDivElement;
    }

    const stickyHeaderContainer = document.getElementById(CARD_TITLE_PORTAL_ID);

    const offsetTop =
      positionedParent && cardRef.current && stickyHeaderContainer
        ? positionedParent.offsetTop +
          cardRef.current.offsetTop -
          stickyHeaderContainer.offsetHeight
        : undefined;
    const offsetHeight = cardRef.current?.offsetHeight;

    // @ts-ignore
    let shouldBeSticky = false;
    if (scrollPosition !== undefined && offsetTop !== undefined && offsetHeight !== undefined) {
      const scrolledOffsetTop = scrollPosition - offsetTop;
      const scrolledOffsetBottom = offsetHeight - scrolledOffsetTop;
      const isCrossingBoundary = scrolledOffsetTop > 0 && scrolledOffsetBottom > 0;
      if (isCrossingBoundary) {
        shouldBeSticky = true;
      }
    }

    const title = (
      <div
        ref={headerRef}
        className={styles.header}
        style={{
          ...headerStyle,
          backgroundColor: 'transparent',
          color: theme.palette.text.primary,
        }}
      >
        {header}
      </div>
    );
    let titleEl = title;
    // Removed sticky bar as a part of https://www.notion.so/creativequeue/6270a33055394f94ba81655b333ac74b?v=662fee7d69f748deb0f826e0dd5e7fe5&p=f34c764188ed4e23b2cfbe61b4bc54c4&pm=s
    //
    // const stickyContainer = document.getElementById(MODULE_HEADER_PORTAL_ID);
    // if (shouldBeSticky && stickyContainer && headerRef.current) {
    //   titleEl = (
    //     <>
    //       {createPortal(
    //         <Slide in direction="down" appear>
    //           {title}
    //         </Slide>,
    //         stickyContainer
    //       )}
    //       <Box sx={{ height: headerRef.current.offsetHeight }} />
    //     </>
    //   );
    // }

    return (
      <Card
        sx={{
          '&:hover': {
            backgroundColor: () => (isSelected ? undefined : 'rgb(55,55,55, 0.75)'),
          },
        }}
        ref={cardRef}
        className={clsx(styles.root, className)}
        style={style}
        elevation={theme.palette.mode === 'light' ? 4 : 0}
      >
        {titleEl}
        {children}
      </Card>
    );
  }
);
