import { AppField, Field, fieldEquals, FILENAME_SUFFIX_FIELD } from '@creative-kit/shared';
import { FormulaBase } from './formula';

export class UiCardTitleStore extends FormulaBase {
  get originalFormula(): Field[] {
    return this.rootStore.sessionsStore.currentSession!.data.cardTitleFormula;
  }

  get availableAppFields(): AppField[] {
    return [FILENAME_SUFFIX_FIELD].filter((f1) => !this.formula.find(fieldEquals(f1)));
  }

  saveFormula = () => {
    this.rootStore.sessionsStore.currentSession!.saveCardTitleFormula(this.formula);
  };
}
