import { useRef } from 'react';
import clsx from 'clsx';
import { useDrop, useDragLayer } from 'react-dnd';
import { observer } from 'mobx-react-lite';
import { alpha, Box, Button, CircularProgress, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { useStore } from 'logic';
import { ItemTypes } from 'logic/dragdrop';
import { ModuleDropTargets } from 'ui/ModuleDropTargets';

import { QueueTabName } from 'logic/ui-active-session';
import { withPlural } from '@creative-kit/shared';
import { CARD_TITLE_PORTAL_ID, MODULE_HEADER_PORTAL_ID } from 'logic/ui-sticky-headers';
import { QueueCard } from './QueueCard';
import { QueueList } from './QueueList';
import { useSourceDrop } from './useSourceDrop';
import { cardSpaceHeight } from './queue';
import { QueueSearchSettingIcon } from './QueueSearchSettingManager';

interface StyleProps {
  isDraggingSource: boolean;
  listIsEmpty: boolean;
  isSearching: boolean;
}

const HOVER_THRESHOLD = 10;
const CREATE_OVERLAY_HEIGHT = 125;
const TOP_OVERLAY_HEIGHT = 50;
const DRAG_SCROLL_OFFSET = 0;
const DRAG_SCROLL_HEIGHT = 150;
const DRAG_SCROLL_SPEED_PX_PER_SECOND = 1000;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 0,
    boxShadow: (p: StyleProps) =>
      p.isSearching ? `0 0 5px 2px ${theme.palette.info.main}` : 'none',
  },
  list: {
    position: 'relative',
    zIndex: 2,
    margin: '10px 5px 0',
    overflow: 'hidden',
    transition: theme.transitions.create('margin'),
    flex: 1,
  },
  listLoading: {
    alignSelf: 'center',
    marginTop: theme.spacing(1),
    zIndex: theme.zIndex.modal,
    // background: theme.palette.grey['300'],
  },

  dragDropMessage: (p: StyleProps) => ({
    position: 'absolute',
    width: '100%',
    display: 'flex',
    opacity: p.isDraggingSource ? 1 : 0,
    transition: theme.transitions.create('opacity'),
    textAlign: 'center',
    backgroundColor: alpha(theme.palette.background.default, 0.9),
    color: theme.palette.getContrastText(theme.palette.background.default),
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    zIndex: theme.zIndex.drawer,
  }),
  topDragDropMessage: {
    // Offset by height to overlay top message ontop of action bar
    top: -50,
    // Now that top message is overlaying action bar, prevent it from blocking clicks on hamburger menu
    pointerEvents: 'none',
    height: TOP_OVERLAY_HEIGHT,
  },
  middleDragDropMessage: {
    height: cardSpaceHeight,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomDragDropMessage: (p: StyleProps) => ({
    bottom: 0,
    flexGrow: 1,
    position: 'relative',
    opacity: p.listIsEmpty ? 1 : undefined,
    minHeight: p.isDraggingSource ? CREATE_OVERLAY_HEIGHT : 0,
  }),

  cardDropLayer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  noResults: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '@global': {
    '.ReactVirtualized__Grid__innerScrollContainer': {
      marginBottom: CREATE_OVERLAY_HEIGHT,
    },
  },
}));

const useDragLayerStyles = makeStyles((theme) => ({
  cardListItemPreview: {
    position: 'fixed',
    zIndex: 1000,
    top: 0,
    left: 0,
    pointerEvents: 'none',
    backgroundColor: theme.palette.background.default,
    padding: 4,
    border: `1px solid ${theme.palette.text.primary}`,
    fontWeight: 600,
    fontFamily: 'inherit',
    fontSize: 24,
  },
}));

const getQueueCardDragPreviewStyle = (currentOffset: { x: number; y: number }) => {
  const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px)`;
  return { transform, WebkitTransform: transform };
};

const QueueCardDragLayer = observer(({ containerBounds }: { containerBounds?: DOMRect }) => {
  const styles = useDragLayerStyles();
  const {
    uiCardDragDrop: { cardDragStatus, setReorderingValid },
  } = useStore();

  const { itemType, item, isDragging, currentOffset } = useDragLayer((monitor) => {
    if (
      containerBounds &&
      monitor.getItemType() === ItemTypes.CardListEntry &&
      cardDragStatus.targetId &&
      monitor.isDragging()
    ) {
      const offset = monitor.getClientOffset();
      if (offset) {
        setReorderingValid(
          offset.x >= containerBounds.x - HOVER_THRESHOLD &&
            offset.x <= containerBounds.x + containerBounds.width + HOVER_THRESHOLD
        );
      }
    }

    return {
      itemType: monitor.getItemType(),
      item: monitor.getItem(),
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getClientOffset(),
    };
  });

  if (!isDragging || itemType !== ItemTypes.CardListEntry || currentOffset === null) {
    return null;
  }

  return (
    <span
      className={styles.cardListItemPreview}
      style={getQueueCardDragPreviewStyle(currentOffset)}
    >
      {item.label}
    </span>
  );
});

export const InProgressQueue = observer(() => {
  const {
    sessionsStore: { currentSession },
    uiCardDragDrop: { endReordering, cardDragStatus },
    uiActiveSessionStore: { setCurrentQueueTab, areSessionCardsLoading },
    uiBatchCardsStore: { activateBatchMode },
    uiCardSearchStore: { openSettingsMenu },
  } = useStore();

  const {
    inProgressCardIds,
    inProgressVisibleCount,
    doneVisibleCount,
    inProgressVisibleCardIds,
    isFilteringInProgress,
  } = currentSession!;

  const containerRef = useRef<HTMLDivElement>(null);

  // Check if dragging source item
  const [{ isDraggingSource }] = useSourceDrop();

  const [, dropCard] = useDrop({
    accept: ItemTypes.CardListEntry,
    drop: () => endReordering(),
  });

  const dragScroll = useRef(0);
  const dragScrollRequested = useRef(false);
  const [, cardDragMonitor] = useDrop({
    accept: [ItemTypes.CardListEntry],
    hover: (_, monitor) => {
      const list = listEl.current?.querySelector('.ReactVirtualized__List');
      if (!list) return;
      const { top, bottom } = list.getBoundingClientRect();
      const offset = monitor.getClientOffset()!.y;
      let toTop = DRAG_SCROLL_HEIGHT - (offset - top);
      let toBottom = DRAG_SCROLL_HEIGHT - (bottom - offset);

      let scroll = true;
      if (monitor.getItemType() === ItemTypes.SourceEntry) {
        toTop += DRAG_SCROLL_OFFSET;
        toBottom += DRAG_SCROLL_OFFSET;

        scroll =
          toTop > 0
            ? toTop < DRAG_SCROLL_HEIGHT
            : toBottom > 0
            ? toBottom < DRAG_SCROLL_HEIGHT
            : false;
      }

      const now = window.performance.now();
      const timeDiffInSeconds = (now - dragScroll.current) / 1000;

      const scrollRate = scroll
        ? toTop > 0
          ? -toTop / DRAG_SCROLL_HEIGHT
          : toBottom > 0
          ? toBottom / DRAG_SCROLL_HEIGHT
          : 0
        : 0;

      if (scrollRate !== 0 && !dragScrollRequested.current) {
        dragScroll.current = now;
        dragScrollRequested.current = true;
        requestAnimationFrame(() => {
          dragScrollRequested.current = false;
          list.scrollTop += scrollRate * DRAG_SCROLL_SPEED_PX_PER_SECOND * timeDiffInSeconds;
        });
      }
    },
  });

  const bounds = containerRef.current?.getBoundingClientRect();
  const listEl = useRef<HTMLDivElement>(null);
  cardDragMonitor(containerRef);
  const listIsEmpty = inProgressCardIds.length === 0;
  const styles = useStyles({
    isDraggingSource,
    listIsEmpty,
    isSearching: isFilteringInProgress,
  });

  return (
    <div ref={containerRef} className={styles.root}>
      <div
        ref={dropCard}
        className={styles.cardDropLayer}
        style={{
          display: cardDragStatus.targetId ? 'block' : 'none',
        }}
      />
      <QueueCardDragLayer containerBounds={bounds} />
      {isFilteringInProgress === false && listIsEmpty === false && (
        <div className={clsx(styles.dragDropMessage, styles.topDragDropMessage)}>
          <ModuleDropTargets prompt="Create new card as current" position="start" />
        </div>
      )}
      {areSessionCardsLoading && (
        <div className={styles.listLoading}>
          <CircularProgress size={100} color="secondary" />
        </div>
      )}
      {inProgressVisibleCardIds.length > 0 && (
        <div ref={listEl} className={styles.list}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 5,
              right: 0,
              zIndex: 100,
              overflow: 'hidden',
            }}
          >
            <div style={{ position: 'relative', zIndex: 10 }} id={CARD_TITLE_PORTAL_ID} />
            <div id={MODULE_HEADER_PORTAL_ID} />
          </Box>
          <QueueList
            numItems={inProgressVisibleCount}
            renderQueueItem={(index) => (
              <>
                {cardDragStatus.targetId && (
                  <Box
                    sx={{
                      height: (theme) =>
                        cardDragStatus.targetId !== inProgressVisibleCardIds[index]
                          ? theme.spacing(index === 0 ? 1 : 0.5)
                          : 0,
                    }}
                  />
                )}
                <QueueCard id={inProgressVisibleCardIds[index]} />
                {cardDragStatus.targetId && (
                  <Box
                    sx={{
                      height: (theme) =>
                        cardDragStatus.targetId !== inProgressVisibleCardIds[index]
                          ? theme.spacing(0.5)
                          : 0,
                    }}
                  />
                )}
                {/* COMMENTED THIS OUT FOR NOW AS A PART OF [CQ-625] WHEN PAIRING WITH ADRIAN */}
                {/* {areCardsExpanded && !cardDragStatus.targetId && (
                  <div className={styles.middleDragDropMessage}>
                    {
                      // Don't show additional create card targets when filtering/searching
                      !isFilteringInProgress &&
                        (isDraggingSource ? (
                          <ModuleDropTargets
                            prompt="Create new card"
                            afterCardId={inProgressCardIds[index]}
                          />
                        ) : (
                          <IconButton onClick={() => activateBatchMode(inProgressCardIds[index])}>
                            <AddCircleIcon />
                          </IconButton>
                        ))
                    }
                  </div>
                )} */}
              </>
            )}
          />
        </div>
      )}
      {isFilteringInProgress && inProgressVisibleCount === 0 && (
        <div className={styles.noResults}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5" sx={{ mb: 2 }}>
              No Assets
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Button
                onClick={() => activateBatchMode()}
                variant="contained"
                size="small"
                sx={{ mr: 1 }}
              >
                + Add
              </Button>
              <Button
                onClick={() => openSettingsMenu()}
                variant="contained"
                size="small"
                startIcon={<QueueSearchSettingIcon />}
              >
                Search Settings
              </Button>
            </Box>
            {doneVisibleCount > 0 && (
              <Typography variant="h5">
                {withPlural(doneVisibleCount, 'result')}
                {' in '}
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setCurrentQueueTab(QueueTabName.Done)}
                >
                  Done
                </Button>
              </Typography>
            )}
          </Box>
        </div>
      )}
      {!isFilteringInProgress && inProgressVisibleCount === 0 && !isDraggingSource && (
        <Box
          sx={{
            flex: '1 0 100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle1">Add Assets from the Library or</Typography>
          <Button
            onClick={() => activateBatchMode()}
            variant="contained"
            size="small"
            sx={{ ml: 1 }}
          >
            + Add
          </Button>
        </Box>
      )}
      {!isFilteringInProgress && listIsEmpty && (
        <div className={clsx(styles.dragDropMessage, styles.bottomDragDropMessage)}>
          <ModuleDropTargets prompt="Drag a row here to start working!" position="end" />
        </div>
      )}
    </div>
  );
});
