import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { useStore } from 'logic';
import { ulid } from 'ulid';
import { ModuleEditorForm, ModuleFormState } from './ModuleEditorForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  newModuleDialog: {
    width: 500,
  },
  dialogTitle: {
    paddingBottom: 0,
  },
}));

interface NewModuleDialogProps {
  open: boolean;
  onClose: () => void;
}

export const NewModuleDialog = observer(({ open, onClose }: NewModuleDialogProps) => {
  const {
    sessionsStore: { currentSession },
  } = useStore();

  const { modules, upsertModule } = currentSession!;

  const styles = useStyles();

  const { control, reset, handleSubmit, setError } = useForm<ModuleFormState>();

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleAddModule = ({ name, sourceId, type, color, moduleFields }: ModuleFormState) => {
    const isDuplicateModule = modules.find((m) => m.name.toLowerCase() === name.toLowerCase());
    if (isDuplicateModule) {
      setError('name', {
        type: 'manual',
        message: 'Choose another Bucket name. This name already exists in this Project.',
      });
      return;
    }
    if (name && sourceId && type && color) {
      const id = ulid();
      upsertModule({
        moduleId: id,
        name,
        sourceId,
        multi: type === 'multi',
        table: true,
        color,
        fields: moduleFields,
      });
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
      <div className={styles.newModuleDialog}>
        <DialogTitle className={styles.dialogTitle}>Add a New Bucket</DialogTitle>
        <DialogContent>
          <ModuleEditorForm control={control} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit(handleAddModule)} color="primary">
            Add
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
});
