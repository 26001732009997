import React from 'react';
import { SessionModule } from '@creative-kit/shared'; // Adjust import paths as necessary
import { Card } from 'logic/card';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { UiSourceModulePowerColumn } from 'logic/ui-source-module-power-column';
import { useStore } from 'logic';

const baseIconStyles = {
  fontSize: '17px',
  top: -10,
  right: -10,
  borderRadius: '20px',
  color: 'black',
};
const useStyles = makeStyles((_theme) => ({
  revealSourceIcon: {
    ...baseIconStyles,
    position: 'absolute',
    padding: '2px',
    backgroundColor: 'white',
    zIndex: 20,
  },
  filterListBadge: {
    ...baseIconStyles,
    position: 'absolute',
    padding: '2px',
    backgroundColor: 'white',
  },
  doubleArrowIcon: {
    ...baseIconStyles,
    position: 'absolute',
    transform: 'rotate(180deg)',
    border: '2px solid white',
    color: 'white',
    zIndex: 10,
  },
}));
interface ChipFilterLabelProps {
  isHoveringChip?: boolean;
  isRelatedToCurrentSource: boolean | undefined;
  isCardSelected: boolean;
  currentPowerColumn?: UiSourceModulePowerColumn; // Adjust type as necessary
  sourceModulePowerColumns: Record<string, UiSourceModulePowerColumn>; // Adjust type as necessary
  associatedModule?: SessionModule;
  card: Card;
  moduleColor?: string;
  label: React.ReactNode;
}

export const ChipFilterLabel: React.FC<ChipFilterLabelProps> = ({
  isHoveringChip = false,
  isRelatedToCurrentSource,
  isCardSelected,
  currentPowerColumn,
  sourceModulePowerColumns,
  associatedModule,
  card,
  moduleColor,
  label,
}) => {
  const styles = useStyles();

  const {
    uiActiveSessionStore: { selectModuleSource },
  } = useStore();

  const handleModuleFocus = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (associatedModule) selectModuleSource(associatedModule.id);
  };

  return (
    <div>
      {isHoveringChip && (
        <SearchIcon
          onClick={handleModuleFocus}
          sx={{
            backgroundColor: (t) => moduleColor ?? t.palette.text.primary,
            zIndex: 10,
          }}
          className={styles.revealSourceIcon}
        />
      )}
      {isRelatedToCurrentSource && isCardSelected && (
        <DoubleArrowIcon
          sx={{
            backgroundColor: (t) => moduleColor ?? t.palette.text.primary,
          }}
          className={styles.doubleArrowIcon}
        />
      )}
      {currentPowerColumn &&
        Object.entries(sourceModulePowerColumns).some(
          ([_sourceId, powerColumnStore]) =>
            associatedModule && powerColumnStore.isFilterActiveForCard(card, associatedModule)
        ) && (
          <FilterListIcon
            sx={{
              backgroundColor: (t) => t.palette.background.default,
            }}
            className={styles.filterListBadge}
          />
        )}
      {label}
    </div>
  );
};
