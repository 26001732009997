import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Menu, MenuItem, Tab, TabProps, TextField, Tooltip, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useStore } from 'logic';
import { Source } from 'logic/source';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from 'logic/dragdrop';
import { ConfirmPopover } from './ConfirmPopover';

const useStyles = makeStyles((theme) => ({
  input: {
    height: '1rem',
  },
  sourceCount: {
    color: theme.palette.text.hint,
  },
}));

interface SourceTabProps extends TabProps {
  sourceId: string;
}

export const SourceTab = observer(({ sourceId, ...props }: SourceTabProps) => {
  const {
    sessionsStore: { currentSession },
    sourcesStore: { sources },
    uiSourceTabDragDrop: { startReordering, updateReordering, endReordering },
    uiActiveSessionStore: { autofocusSourceRename, setSourceRenameFocus },
  } = useStore();

  const styles = useStyles();
  const rootRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [newSourceName, setNewSourceName] = useState('');
  const handleContextMenuClose = () => setAnchorEl(null);
  const source = sources[sourceId] as Source | undefined;
  const sourceRowCount = source?.sourceCount;
  const sourceName = source?.data.name;

  const { updateSource, deleteSource, getDependenciesForSource } = currentSession!;

  const [, drop] = useDrop<{ id: string }, void, { isOver: boolean }>({
    accept: ItemTypes.Source,
    drop: () => {
      endReordering();
    },
    hover: (item, monitor) => {
      if (!rootRef.current || item.id === sourceId) {
        return;
      }
      const hoverRect = rootRef.current.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset()!;
      const midPoint = (hoverRect.bottom - hoverRect.top) / 2;
      const verticalOffset = clientOffset.y - hoverRect.top;
      const status = verticalOffset < midPoint ? 'before' : 'after';
      updateReordering(sourceId, status);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [, drag] = useDrag(() => ({
    type: ItemTypes.Source,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: () => {
      startReordering(sourceId);
      return {
        id: sourceId,
      };
    },
    end: () => endReordering(),
  }));

  drop(drag(rootRef));

  useEffect(() => {
    /**
     * The autofocus input was being flaky
     * Whenever the edit mode is engaged:
     * - set default name to the source name
     * - call focus on the input
     */
    if (isEditing) {
      setNewSourceName(sourceName ?? '');
      inputRef.current?.focus();
    }
  }, [isEditing, sourceName]);

  // When creating a new blank source, we want to prompt user to rename it
  useEffect(() => {
    if (autofocusSourceRename) {
      setIsEditing(true);
      // Reset value after focusing so next time its called the effect will be re-run
      setSourceRenameFocus(false);
    }
  }, [autofocusSourceRename, setSourceRenameFocus]);

  const handleFinishRename = (newName: string) => {
    setIsEditing(false);
    if (newName && newName !== sourceName) {
      // Let state update render before sending out API request to give feedback to user
      setTimeout(() => updateSource({ sourceId, name: newName }), 0);
    }
  };

  const handleDelete = async () => {
    handleContextMenuClose();
    await deleteSource({ sourceId });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleFinishRename(newSourceName);
    } else if (e.key === 'Escape') {
      setIsEditing(false);
    }
  };

  const deleteConfirmationMessage = useMemo(() => {
    const { sourceCardTitleDeps, sourceFilenameDeps } = getDependenciesForSource(sourceId);
    return sourceCardTitleDeps.length > 0 || sourceFilenameDeps.length > 0
      ? 'Deleting this source will delete modules which are included in your card title or filename formulas. This will cause all of your cards to be modified. This action cannot be undone'
      : 'Deleting this source will delete all modules linked to this source. This action cannot be undone';
  }, [sourceId, getDependenciesForSource]);

  return (
    <Tab
      {...props}
      sx={{
        flexDirection: 'row',
        transform: 'translate3d(0, 0, 0)',
      }}
      ref={rootRef}
      onContextMenu={(e) => {
        e.preventDefault();
        setAnchorEl(e.currentTarget);
      }}
      label={
        <>
          <TextField
            inputRef={inputRef}
            onBlur={() => {
              // Prevent onBlur on render from cancelling autofocus
              if (hasFocus) {
                handleFinishRename(newSourceName);
              }
            }}
            onFocus={() => {
              setHasFocus(true);
            }}
            onKeyDown={handleKeyDown}
            onChange={(e) => setNewSourceName(e.target.value)}
            inputProps={{ className: styles.input }}
            sx={{
              display: isEditing ? 'inline-block' : 'none',
              whiteSpace: 'nowrap',
            }}
          />
          {isEditing === false && (
            <Tooltip title="Double Click to rename this Library">
              <Box
                onDoubleClick={() => setIsEditing(true)}
                sx={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                }}
              >
                {sourceName}
                <span className={styles.sourceCount}> ({sourceRowCount})</span>
              </Box>
            </Tooltip>
          )}
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleContextMenuClose}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                handleContextMenuClose();
                setIsEditing(true);
                inputRef.current?.focus();
              }}
            >
              Rename
            </MenuItem>
            <ConfirmPopover
              message={deleteConfirmationMessage}
              onConfirm={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
            >
              <MenuItem>Delete</MenuItem>
            </ConfirmPopover>
          </Menu>
        </>
      }
    />
  );
});
