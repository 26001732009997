import { observer } from 'mobx-react-lite';
import { Box, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useStore } from 'logic';

import { QueueTabName } from 'logic/ui-active-session';
import { withPlural } from '@creative-kit/shared';
import { CARD_TITLE_PORTAL_ID, MODULE_HEADER_PORTAL_ID } from 'logic/ui-sticky-headers';
import { QueueCard } from './QueueCard';
import { WarningIcon } from './WarningIcon';
import { QueueList } from './QueueList';
import { cardSpaceHeight } from './queue';
import { QueueSearchSettingIcon } from './QueueSearchSettingManager';

interface StyleProps {
  isSearching: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 5px 0',
    position: 'relative',
    minHeight: 0,
    overflow: 'hidden scroll',
    boxShadow: (p: StyleProps) =>
      p.isSearching ? `0 0 5px 2px ${theme.palette.info.main}` : 'none',
  },
  noResults: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filenameActions: {
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  batchButtons: {
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  cardWrapper: {
    marginBottom: cardSpaceHeight,
  },
  list: {
    position: 'relative',
    zIndex: 2,
    margin: '10px 5px 0',
    overflow: 'hidden',
    transition: theme.transitions.create('margin'),
    flex: 1,
  },
}));

export const DoneQueue = observer(() => {
  const {
    sessionsStore: { currentSession },
    uiActiveSessionStore: { setCurrentQueueTab },
    uiBatchCardsStore: { activateBatchMode },
    uiCardSearchStore: { openSettingsMenu },
  } = useStore();
  const styles = useStyles({
    isSearching: !!currentSession?.isFilteringInProgress,
  });

  if (!currentSession) {
    return null;
  }

  const {
    doneVisibleCardIds,
    doneVisibleCount,
    inProgressVisibleCount,
    numFilenamesNeedingAttention,
    isFilteringInProgress,
  } = currentSession;

  return (
    <div className={styles.root}>
      {numFilenamesNeedingAttention > 0 && (
        <div className={styles.filenameActions}>
          <p>
            <WarningIcon /> <span>Some filenames have changed and may need updating!</span>
          </p>
          <p>{numFilenamesNeedingAttention} filenames need your attention</p>
          <p className={styles.batchButtons}>
            <Button variant="contained" size="small" onClick={currentSession.acceptAllFilenames}>
              Update All
            </Button>
            <Button variant="contained" size="small" onClick={currentSession.ignoreAllFilenames}>
              Ignore
            </Button>
          </p>
        </div>
      )}
      <div className={styles.list}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 5,
            right: 0,
            zIndex: 100,
            overflow: 'hidden',
          }}
        >
          <div style={{ position: 'relative', zIndex: 10 }} id={CARD_TITLE_PORTAL_ID} />
          <div id={MODULE_HEADER_PORTAL_ID} />
        </Box>
        <QueueList
          numItems={doneVisibleCardIds.length}
          renderQueueItem={(index) => (
            <div className={styles.cardWrapper}>
              <QueueCard id={doneVisibleCardIds[index]} />
            </div>
          )}
        />
      </div>
      {isFilteringInProgress && doneVisibleCount === 0 && (
        <div className={styles.noResults}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5" sx={{ mb: 2 }}>
              No Results
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Button
                onClick={() => activateBatchMode()}
                variant="contained"
                size="small"
                sx={{ mr: 1 }}
              >
                + Add
              </Button>
              <Button
                onClick={() => openSettingsMenu()}
                variant="contained"
                size="small"
                startIcon={<QueueSearchSettingIcon />}
              >
                Search Settings
              </Button>
            </Box>
            {inProgressVisibleCount > 0 && (
              <Typography variant="h5">
                {withPlural(inProgressVisibleCount, 'result')}
                {' in '}
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setCurrentQueueTab(QueueTabName.InProgress)}
                >
                  In Progress
                </Button>
              </Typography>
            )}
          </Box>
        </div>
      )}
    </div>
  );
});
