import type { RootStore } from '.';
import { UiModuleRowEditor } from './ui-module-row-editor';

export class UiModuleSourceEditorStore {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  public getEditedRowsForModule(moduleId: string) {
    const targetSourceId = this.rootStore.sessionsStore.currentSession!.modules.find(
      (m) => m.id === moduleId
    )?.sourceId;
    if (targetSourceId) {
      const targetSource = this.rootStore.sourcesStore.sources[targetSourceId];
      if (targetSource) {
        return targetSource.orderedRows.filter((r) => r.moduleRowEditor.isEditing).map((r) => r.id);
      }
    }

    return [];
  }

  public getRowEditor(moduleId: string, rowId: string) {
    const targetSourceId = this.rootStore.sessionsStore.currentSession!.modules.find(
      (m) => m.id === moduleId
    )?.sourceId;
    if (targetSourceId) {
      const targetSource = this.rootStore.sourcesStore.sources[targetSourceId];
      const editor = targetSource?.rows[rowId]?.moduleRowEditor;
      if (editor) {
        return editor;
      }
    }

    return new UiModuleRowEditor();
  }
}
