import React from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useStore } from 'logic';
import { RouteNames } from 'logic/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    ...theme.typography.h4,
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.hint,
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      margin: `0 ${theme.spacing(2)}`,
    },
  },
}));

export const TourView = () => {
  const styles = useStyles();
  const { routerStore } = useStore();
  return (
    <div className={styles.root}>
      <div className={styles.title}>TOUR COMING SOON!</div>
      <div className={styles.actions}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => routerStore.goTo(RouteNames.SESSION)}
        >
          New Session
        </Button>
      </div>
    </div>
  );
};
