import { Folder } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material';
import { useStore } from 'logic';
import { DownloadFolderNaming } from 'logic/ui-download-folders';
import { matchMetaShortcutToEvent } from 'logic/ui-keyboard-shortcuts';
import { observer } from 'mobx-react-lite';
import { KeyboardEvent } from 'react';
import { HelpTooltip } from './HelpTooltip';

export const QueueFolderDownloadModal = observer(() => {
  const {
    uiDownloadFolders: {
      createFolders,
      isDialogOpen,
      closeDialog,
      folderNaming,
      selectFolder,
      selectDownloadFolderName,
      selectedFolderName,
      canUseDownloadFolders,
      cardsToUse,
      setCardsToUse,
      selectedCards,
      foldersToCreate,
    },
    sessionsStore: { currentSession },
  } = useStore();

  const invalidFolderNames = selectedCards.length - foldersToCreate.length;
  const { inProgressVisibleCount, doneVisibleCount } = currentSession!;

  const handleDownload = async () => {
    if (selectedFolderName) {
      await createFolders();
      closeDialog();
    } else {
      alert('You must select a folder to proceed');
    }
  };

  const handleKeyDownSubmit = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeDialog();
    }
    if (matchMetaShortcutToEvent(event, 'Enter')) {
      handleDownload();
    }
  };

  return (
    <Dialog
      disablePortal
      disableEnforceFocus
      onKeyDown={handleKeyDownSubmit}
      open={isDialogOpen}
      onClose={closeDialog}
      sx={{ position: 'absolute' }}
      BackdropProps={{
        sx: {
          position: 'absolute',
        },
      }}
    >
      <DialogTitle>Create Folders from Assets</DialogTitle>
      <DialogContent>
        <FormControl component="div" margin="normal" fullWidth>
          <FormLabel component="legend">Create Folders Using...</FormLabel>
          <FormControlLabel
            control={
              <Switch
                checked={cardsToUse.inProgress}
                onChange={(_, checked) => setCardsToUse('inProgress', checked)}
                name="UseInProgress"
                color="primary"
              />
            }
            label={`In Progress (${inProgressVisibleCount})`}
          />
          <FormControlLabel
            control={
              <Switch
                checked={cardsToUse.done}
                onChange={(_, checked) => setCardsToUse('done', checked)}
                name="UseDone"
                color="primary"
              />
            }
            label={`Done (${doneVisibleCount})`}
          />
        </FormControl>
        <FormControl margin="normal" component="fieldset" fullWidth>
          <FormLabel component="legend">Name Folders Using</FormLabel>
          <RadioGroup
            row
            aria-label="fields"
            name="fields"
            value={folderNaming}
            onChange={(_, value) => selectDownloadFolderName(value as DownloadFolderNaming)}
          >
            <FormControlLabel
              value={DownloadFolderNaming.CARD_TITLE}
              label="Asset Titles"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value={DownloadFolderNaming.FILENAME}
              label="Asset Filenames"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </FormControl>
        <FormControl margin="normal" component="fieldset" fullWidth>
          <FormLabel>Folder Destination</FormLabel>
          <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Button onClick={selectFolder} disabled={!canUseDownloadFolders}>
              Select Folder
            </Button>
            {!canUseDownloadFolders && (
              <HelpTooltip>
                <span>
                  Your browser does not support this feature. Please use Chrome to download folders!
                </span>
              </HelpTooltip>
            )}
            {selectedFolderName && (
              <>
                <Folder />
                {selectedFolderName}
              </>
            )}
          </div>
        </FormControl>
        <FormControl fullWidth>
          <Box pt={1}>
            {invalidFolderNames > 0 && (
              <Typography variant="subtitle2" textAlign="center">
                Note: {invalidFolderNames} Folders Will NOT Be Created For Assets With Incomplete{' '}
                {folderNaming === DownloadFolderNaming.CARD_TITLE ? 'Card Titles' : 'Filenames'}
              </Typography>
            )}
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button onClick={handleDownload} disabled={!selectedFolderName} color="primary">
          {foldersToCreate.length > 0 ? `Create ${foldersToCreate.length} Folders` : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
});
