import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';

import { ItemTypes } from 'logic/dragdrop';
import { Field, serializeField } from '@creative-kit/shared';

import { FormulaBase } from 'logic/formula';
import { FormulaField } from './FormulaField';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.text.hint}`,
    borderRadius: 16,
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
    position: 'relative',
    minHeight: theme.spacing(4),
  },
  placeholder: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.8em',
    color: theme.palette.text.hint,
    padding: `0 ${theme.spacing(1)}`,
    textAlign: 'center',
    pointerEvents: 'none',
  },
}));

interface FormulaProps {
  formulaStore: FormulaBase;
}

export const Formula = observer(({ formulaStore }: FormulaProps) => {
  const {
    formula,
    dropField,
    dragField,
    isEmpty,
    setTextFieldValue,
    removeField,
    cancelDragField,
    reorderField,
    dragStatus,
  } = formulaStore;

  const rootRef = useRef<HTMLDivElement>(null);
  const [, dropAvailableField] = useDrop<{ field: Field }, void, {}>({
    accept: ItemTypes.AvailableFormulaField,
    drop: (item, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        dropField(item.field);
      }
    },
  });
  const [, dropFormulaField] = useDrop<{ field: Field }, void, {}>({
    accept: ItemTypes.FormulaField,
    drop: (item, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        reorderField(item.field);
      }
    },
  });

  const styles = useStyles();

  dropAvailableField(dropFormulaField(rootRef));

  const fieldProps = {
    setTextFieldValue,
    reorderField,
    removeField,
    dragStatus,
    cancelDragField,
    dragField,
    dropField,
  };

  return (
    <div className={styles.root} ref={rootRef}>
      {formula.map((f, idx) => (
        <FormulaField {...fieldProps} key={serializeField(f)} field={f} index={idx} />
      ))}
      <FormulaField {...fieldProps} field={null} index={-1} />
      {isEmpty && (
        <div className={styles.placeholder}>
          Compose By Typing In The Bubbles + Dragging from Below!
        </div>
      )}
    </div>
  );
});
