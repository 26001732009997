import { Batch, EnvFirestore } from './types';

/**
 * Firestore batch has a limit of 500 items
 * To avoid hitting limit on large operations
 * We create batches of batches to leverage the functionality without blowing up app
 */
export async function safeBatch<T, F>(
  fb: EnvFirestore,
  rawData: T[],
  // Provide a func to interact with each individual item in your array in Firebase
  batchOperation: (batch: Batch<F>, data: T) => void
) {
  const dataToUse = [...rawData];
  // Add rows to source in batches
  const batches = [];
  while (dataToUse.length > 0) {
    const slice = dataToUse.splice(0, 100);
    const batch = fb.batch();
    batches.push(batch);
    for (const row of slice) {
      // Needed to cast because Firebase is confused about two types that are referencing the same underlying type
      batchOperation(batch as unknown as Batch<F>, row);
    }
  }
  await Promise.all<unknown>(batches.map((b) => b.commit()));
}
