import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import type ft from 'firebase';

import { FirestoreSource } from '@creative-kit/shared';
import type { RootStore } from '.';
import { db } from './data';
import { Source } from './source';

export class SourcesStore {
  private stopListeningToSources: () => void = () => {};
  private rootStore: RootStore;
  private currentSessionId?: string;
  sources: Record<string, Source> = {};

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      sources: observable,
      resetSources: action,
      areSourcesLoaded: computed,
    });

    this.rootStore = rootStore;
  }

  init() {
    reaction(() => [this.rootStore.sessionsStore.currentSession], this.watchSources, {
      fireImmediately: true,
    });
  }

  watchSources = () => {
    const { currentSession } = this.rootStore.sessionsStore;
    if (!currentSession || currentSession.id !== this.currentSessionId) {
      this.resetSources();
    }

    if (!currentSession) {
      return;
    }

    this.currentSessionId = currentSession.id;

    this.stopListeningToSources = db
      .sessionSources(this.currentSessionId)
      .onSnapshot(this.updateSources);
  };

  resetSources() {
    this.stopListeningToSources();
    Object.values(this.sources).forEach((source) => source.stopListening());

    for (const key of Object.keys(this.sources)) {
      delete this.sources[key];
    }

    this.stopListeningToSources = () => {};
  }

  updateSources = (snapshot: ft.firestore.QuerySnapshot<FirestoreSource>) => {
    runInAction(() => {
      snapshot.docChanges().forEach((change) => {
        if (this.currentSessionId) {
          const changeType = change.type;
          const changeDoc = change.doc;

          if (changeType === 'added') {
            this.sources[changeDoc.id] = new Source(
              changeDoc.id,
              this.currentSessionId,
              changeDoc.data(),
              this.rootStore
            );
            this.sources[changeDoc.id].startListening();
          } else if (changeType === 'modified') {
            this.sources[changeDoc.id].updateData(changeDoc.data());
          } else {
            this.sources[changeDoc.id].stopListening();
            delete this.sources[changeDoc.id];
          }
        }
      });

      this.rootStore.uiModulePowerColumnStore.initializeColumns(Object.values(this.sources));
    });
  };

  get areSourcesLoaded() {
    return Object.keys(this.sources).length > 0;
  }
}
