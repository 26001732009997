import { CardStatus } from '@creative-kit/shared';
import { Chip, ChipProps, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export const useCardStatusStyle = () => {
  const theme = useTheme();
  const cardStatusColors: Record<CardStatus, object> = {
    [CardStatus.Preview]: theme.statusColors.pri,
    [CardStatus.Current]: theme.statusColors.currentCard,
    [CardStatus.Next]: theme.statusColors.nextCard,
    [CardStatus.OnDeck]: theme.statusColors.onDeckCard,
    [CardStatus.Upcoming]: theme.statusColors.upcomingCard,
    [CardStatus.Completed]: theme.statusColors.doneCard,
  };
  return (status: CardStatus) => cardStatusColors[status];
};

interface StatusDotProps extends ChipProps {
  status: CardStatus;
  label?: ReactNode;
}

export const StatusDot = ({ status, label, sx, ...props }: StatusDotProps) => {
  const getStatusColor = useCardStatusStyle();
  return (
    <Chip
      size="small"
      sx={{
        textTransform: 'uppercase',
        height: 20,
        minWidth: 20,
        fontSize: 16,
        ...getStatusColor(status),
        ...sx,
      }}
      label={label}
      {...props}
    />
  );
};
