import { action, makeObservable, observable, runInAction } from 'mobx';
import type { RootStore } from 'logic';

interface SnackbarDetails {
  fn?: () => void;
  actionText?: string;
  description: string;
  timestamp: number;
}

export const MESSAGE_TIME_LIMIT_MS = 15 * 1000;

export class UiSnackbarStore {
  private rootStore: RootStore;
  private snackbarDetails?: SnackbarDetails;
  public remainingTime?: number;
  public isActionInProgress?: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable<UiSnackbarStore, 'snackbarDetails' | 'checkRemainingTime'>(this, {
      snackbarDetails: observable.ref,
      remainingTime: observable,
      setSnackbarDetails: action.bound,
      checkRemainingTime: action.bound,
      reset: action.bound,
    });
  }

  init() {
    /*
    Note about cleaning up: these event listeners should be setup at most once throughout
    the lifecycle of the app, namely when the Root Store calls the init() method of this
    store. Since this store survives throughout the life of the app, it's fine that there's
    no cleanup for the moment. If the CQ app eventually becomes a modular part of a bigger
    system, stores will have cleanup() methods in addition to their init() methods, where
    events and reactions can be removed as necessary.
    */
    document.addEventListener(
      'keydown',
      action((e) => {
        // Using manual listener instead of mousetrap to ensure that undo works after interacting with input
        if (e.metaKey && e.key === 'z') {
          this.runSnackbarAction();
        }
      })
    );
  }

  reset() {
    this.snackbarDetails = undefined;
    this.remainingTime = undefined;
  }

  private checkRemainingTime() {
    const { snackbarDetails } = this;
    if (!snackbarDetails) {
      return;
    }

    const remainingTime = Math.max(
      MESSAGE_TIME_LIMIT_MS - (Date.now() - snackbarDetails.timestamp),
      0
    );
    if (remainingTime === 0) {
      this.reset();
    } else {
      this.remainingTime = remainingTime;
      requestAnimationFrame(this.checkRemainingTime);
    }
  }

  setUndoDetails(undoFn: () => unknown, description: string) {
    const undoShortcut =
      this.rootStore.uiKeyboardShortcutsStore.getShortcutCombinationByName('undo');
    const actionText = `Undo (${undoShortcut})`;

    return this.setSnackbarDetails({
      fn: undoFn,
      actionText,
      description,
    });
  }

  setSnackbarDetails({ fn, actionText, description }: Omit<SnackbarDetails, 'timestamp'>) {
    this.snackbarDetails = {
      fn,
      actionText,
      description,
      timestamp: Date.now(),
    };

    this.checkRemainingTime();

    return () => {
      if (this.snackbarDetails?.fn === fn) {
        this.reset();
      }
    };
  }

  get hasAction() {
    return !!this.snackbarDetails?.actionText && !!this.snackbarDetails.fn;
  }

  get actionText() {
    return this.snackbarDetails?.actionText;
  }

  get description() {
    return this.snackbarDetails?.description;
  }

  runSnackbarAction = async () => {
    if (this.snackbarDetails?.fn) {
      const { fn } = this.snackbarDetails;
      this.reset();
      try {
        runInAction(() => {
          this.isActionInProgress = true;
        });
        await fn();
      } finally {
        runInAction(() => {
          this.isActionInProgress = false;
        });
      }
    }
  };
}
