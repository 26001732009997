import { observer } from 'mobx-react-lite';
import LinearProgress from '@mui/material/LinearProgress';

import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Box } from '@mui/material';

export enum BatchOperationType {
  Delete,
  Duplicate,
}

const batchOperationTypeMap = {
  [BatchOperationType.Delete]: 'deleting',
  [BatchOperationType.Duplicate]: 'duplicating',
};

const useStyles = makeStyles({
  modalScrim: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'black',
    opacity: 0.5,
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 15,
  },
  container: {
    position: 'absolute',
    background: 'none', // If you want a transparent background, this is correct.
    zIndex: 10,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type BatchOperationInProgressModalProps = { batchOperationType: BatchOperationType };
export const BatchOperationInProgressModal = observer(
  // @ts-ignore
  (props: BatchOperationInProgressModalProps) => {
    console.log(props);
    const styles = useStyles();
    return (
      <div className={styles.container}>
        <div className={styles.modalScrim} />
        <div className={styles.messageContainer}>
          <p style={{ margin: 0 }}>Wait a sec,</p>
          <h1>We're {batchOperationTypeMap[props.batchOperationType]} your Assets</h1>
          <Box style={{ marginBottom: '10px' }} sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        </div>
      </div>
    );
  }
);
