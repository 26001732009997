import { action, makeObservable, observable } from 'mobx';
import Dexie from 'dexie';
import 'dexie-observable';
import type { RootStore } from 'logic';

const LOCAL_DB_VERSION = 1;
const SESSION_SOURCE_FIELDS_KEY = 'sessionSourceFields';
interface SessionSourceFieldsEntry {
  sourceId: string;
  sessionId: string;
  fields: string[];
  showPowerColumn?: boolean;
}

export class UiSourceFieldsStore {
  fieldsById: Record<string, Record<string, { showPowerColumn?: boolean; fields: string[] }>> = {};
  localDb: Dexie;
  sessionSourceFields: Dexie.Table<SessionSourceFieldsEntry, [string, string]>;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable<UiSourceFieldsStore, 'updateLocalFieldCache'>(this, {
      fieldsById: observable,
      updateLocalFieldCache: action,
    });

    const localDb = new Dexie('UiSourceFieldsStore');
    localDb.version(LOCAL_DB_VERSION).stores({
      [SESSION_SOURCE_FIELDS_KEY]: '&[sessionId+sourceId]',
    });
    this.localDb = localDb;
    this.sessionSourceFields = localDb.table(SESSION_SOURCE_FIELDS_KEY);
    this.rootStore = rootStore;
  }

  private updateLocalFieldCache(entry: SessionSourceFieldsEntry) {
    if (!this.fieldsById[entry.sessionId]) {
      this.fieldsById[entry.sessionId] = {};
    }
    this.fieldsById[entry.sessionId][entry.sourceId] = {
      fields: entry.fields,
      showPowerColumn: entry.showPowerColumn,
    };
  }

  async init() {
    this.localDb.on('changes', (changes) => {
      changes.forEach((change) => {
        if ('obj' in change) {
          this.updateLocalFieldCache(change.obj);
        }
      });
    });

    // load initial data
    const data = await this.sessionSourceFields.toArray();
    data.forEach((entry) => {
      this.updateLocalFieldCache(entry);
    });
  }

  togglePowerColumn = (sourceId: string) => {
    const currentSessionId = this.rootStore.sessionsStore.currentSession?.id;
    const source = this.rootStore.sourcesStore.sources[sourceId];
    if (currentSessionId && source) {
      const showPowerColumn = this.fieldsById[currentSessionId]?.[sourceId]?.showPowerColumn;
      const currentSourceFields = this.fieldsById[currentSessionId]?.[sourceId]?.fields;
      const fields = (currentSourceFields || source.data.fieldIds).slice(0);
      this.sessionSourceFields.put({
        sourceId,
        sessionId: currentSessionId,
        fields,
        showPowerColumn: showPowerColumn === undefined ? false : !showPowerColumn,
      });
    }
  };

  toggleField = (sourceId: string, field: string) => {
    const currentSessionId = this.rootStore.sessionsStore.currentSession?.id;
    const source = this.rootStore.sourcesStore.sources[sourceId];
    if (currentSessionId && source) {
      const currentSourceFields = this.fieldsById[currentSessionId]?.[sourceId]?.fields;
      const fields = currentSourceFields || source.data.fieldIds;
      const newFields = fields.includes(field)
        ? fields.filter((f) => f !== field)
        : [...fields, field];
      this.sessionSourceFields.put({
        sourceId,
        sessionId: currentSessionId,
        fields: newFields,
      });
    }
  };
}
