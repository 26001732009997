import { useRegisterActions } from 'kbar';
import { useStore } from 'logic';
import { useConfirm } from 'material-ui-confirm';
import { expandKbarActions, KbarSection } from 'utils/kbar';
import { QueueTabName } from './ui-active-session';
import { DownloadFolderNaming } from './ui-download-folders';

export function useActiveSessionKbar({
  openSourceFilePicker,
}: {
  openSourceFilePicker: () => unknown;
}) {
  const rootStore = useStore();

  const confirm = useConfirm();

  const {
    hasSelectedCards,
    selectedCards,
    currentQueueTab,
    currentSourceTab: sourceTabId,
  } = rootStore.uiActiveSessionStore;

  const { selectedCardId, isCardFocused } = rootStore.uiCardView;

  const {
    moveCardToIndex,
    removeSelectedCards,
    duplicateSelectedCards,
    completeOrMoveSelectedCards,
    batchRemoveVisibleCards,
    removeCard,
    duplicateCard,
    completeCard,
    markCardInProgress,
  } = rootStore.sessionCardActions;

  const {
    currentCardIdx,
    upNextCardIdx,
    onDeckCardIdx,
    inProgressVisibleCardIds,
    doneVisibleCardIds,
  } = rootStore.sessionsStore.currentSession ?? {};
  const currentSource = rootStore.sourcesStore.sources[sourceTabId];
  const sourceName = currentSource?.data?.name;
  const isInProgress = currentQueueTab === QueueTabName.InProgress;

  const nestedActions: KbarSection[] = [
    {
      section: 'Focused Asset',
      isVisible: isCardFocused,
      actions: [
        {
          id: 'selected-mark-done',
          name: isInProgress ? 'Mark as Done' : 'Return to In Progress',
          perform() {
            if (isInProgress) {
              completeCard(selectedCardId);
            } else {
              markCardInProgress(selectedCardId);
            }
          },
        },
        {
          id: 'selected-duplicate',
          name: 'Duplicate',
          async perform() {
            duplicateCard(selectedCardId);
          },
        },
        {
          id: 'selected-delete',
          name: 'Delete Asset',
          keywords: 'clear cards delete selected cards visible remove',
          async perform() {
            removeCard(selectedCardId);
          },
        },
        ...(isInProgress
          ? [
              {
                id: 'selected-first',
                name: 'Move to First',
                async perform() {
                  await moveCardToIndex(selectedCardId, currentCardIdx!);
                },
              },
              {
                id: 'selected-up-next',
                name: 'Move to Up Next',
                async perform() {
                  await moveCardToIndex(selectedCardId, upNextCardIdx!);
                },
              },
              {
                id: 'selected-on-deck',
                name: 'Move to On Deck',
                async perform() {
                  await moveCardToIndex(selectedCardId, onDeckCardIdx!);
                },
              },
            ]
          : []),
      ],
    },
    {
      section: 'Selected Assets',
      isVisible: hasSelectedCards && !isCardFocused,
      actions: [
        {
          id: 'selected-mark-done',
          name: isInProgress ? 'Mark as Done' : 'Return to In Progress',
          perform() {
            completeOrMoveSelectedCards();
          },
        },
        {
          id: 'selected-duplicate',
          name: 'Duplicate',
          async perform() {
            duplicateSelectedCards();
          },
        },
        {
          id: 'selected-delete',
          name: 'Delete Asset',
          keywords: 'clear cards delete selected cards visible remove',
          async perform() {
            removeSelectedCards();
          },
        },
        ...(isInProgress
          ? [
              {
                id: 'selected-first',
                name: 'Move to First',
                async perform() {
                  // TODO: move in the order they are in the queue
                  for (const [index, card] of selectedCards.entries()) {
                    // eslint-disable-next-line no-await-in-loop
                    await moveCardToIndex(card.id, currentCardIdx! + index);
                  }
                },
              },
              {
                id: 'selected-up-next',
                name: 'Move to Up Next',
                async perform() {
                  for (const [index, card] of selectedCards.entries()) {
                    // eslint-disable-next-line no-await-in-loop
                    await moveCardToIndex(card.id, upNextCardIdx! + index);
                  }
                },
              },
              {
                id: 'selected-on-deck',
                name: 'Move to On Deck',
                async perform() {
                  for (const [index, card] of selectedCards.entries()) {
                    // eslint-disable-next-line no-await-in-loop
                    await moveCardToIndex(card.id, onDeckCardIdx! + index);
                  }
                },
              },
            ]
          : []),
        {
          id: 'selected-download-csv',
          name: 'Download Asset CSVs',
          perform() {
            rootStore.uiExportQueueStore.exportSelectedCardListCsv();
          },
        },
        {
          id: 'selected-batch-copy-title',
          name: 'Batch Copy Asset Titles to Clipboard',
          perform() {
            rootStore.uiBatchCopyStore.openDialogForCardTitle('selected');
          },
        },
        {
          id: 'selected-batch-copy-urls',
          name: 'Batch Copy Asset URLs to Clipboard',
          perform() {
            rootStore.uiBatchCopyStore.openDialogForUrl('selected');
          },
        },
        {
          id: 'selected-batch-copy-filenames',
          name: 'Batch Copy Asset Filenames to Clipboard',
          perform() {
            rootStore.uiBatchCopyStore.openDialogForFilename('selected');
          },
        },

        {
          id: 'select-all',
          name: `Select All Assets Below Asset #${
            selectedCards?.[selectedCards.length - 1]?.cardNumber
          }`,
          perform() {
            rootStore.uiActiveSessionStore.selectAllCardsBelow(
              selectedCards?.[selectedCards.length - 1].id
            );
          },
        },
        {
          id: 'select-all-visible',
          name: 'Select Visible Assets',
          perform() {
            const ids = isInProgress ? inProgressVisibleCardIds : doneVisibleCardIds;
            rootStore.uiActiveSessionStore.selectCardIds(ids!);
          },
        },
        {
          id: 'deselect-all',
          name: 'Deselect All Assets',
          perform() {
            rootStore.uiActiveSessionStore.deselectAllCards();
          },
        },
      ],
    },
    {
      section: 'Add Assets',
      actions: [
        {
          id: 'add-blank-tasks',
          name: 'Add Blank Assets',
          perform() {
            setTimeout(() => rootStore.uiBatchCardsStore.activateBatchMode(), 0);
          },
        },
        ...rootStore.uiBatchCardsStore.moduleOptions
          .filter((m) => m.sourceId === sourceTabId)
          .map((moduleOpt) => ({
            id: `add-module-cards-${moduleOpt.id}`,
            name: `Add Assets Using ${moduleOpt.name}`,
            perform() {
              rootStore.uiBatchCardsStore.activateBatchMode();
              rootStore.uiBatchCardsStore.switchBatchMode('source');
              rootStore.uiBatchCardsStore.setModuleId(moduleOpt.id);
            },
          })),
      ],
    },
    {
      section: 'Library Actions',
      actions: [
        currentSource?.type === 'text'
          ? {
              id: 'add-rows',
              name: `Add Data to ${sourceName}`,
              perform() {
                rootStore.uiActiveSessionStore.setAddRowsDialogOpen(true);
              },
            }
          : {
              id: 'add-rows',
              name: `Add Images to ${sourceName}`,
              perform() {
                openSourceFilePicker();
              },
            },
      ],
    },
    {
      section: 'Manage Cards',
      actions: [
        {
          id: 'add-module',
          name: 'Add Module',
          perform() {
            rootStore.uiActiveSessionStore.setNewModuleDialogOpen(true);
          },
        },
        {
          id: 'modify-card-title',
          name: 'Modify Card Title',
          perform() {
            rootStore.uiCardTitleStore.openDialog();
          },
        },
        {
          id: 'modify-filename',
          name: 'Modify Filename Formula',
          perform() {
            rootStore.uiFilenameStore.openDialog();
          },
        },
        {
          id: 'modify-module-fields',
          name: 'Modify Module Fields',
          perform() {
            rootStore.uiActiveSessionStore.setQueueViewMenuOpen(true);
          },
        },
      ],
    },
    {
      section: 'Batch Asset Actions',
      // only show when you can't remove selected cards to avoid confusion
      isVisible: !hasSelectedCards,
      actions: [
        {
          id: 'delete-visible-cards',
          keywords: 'remove cards clear queue',
          name: `Delete visible tasks (${rootStore.sessionsStore.currentSession?.inProgressVisibleCount})`,
          async perform() {
            try {
              await confirm({
                title: 'Delete Selected',
                content: `Do you want to delete ${rootStore.sessionsStore.currentSession?.inProgressVisibleCount} In Progress Assets?`,
                allowClose: false,
              });
              batchRemoveVisibleCards();
            } catch (err) {
              //
            }
          },
        },
      ],
    },
    {
      section: 'Queue CSV Export',
      keywords: 'export library download',
      actions: [
        {
          id: 'export-all-cards',
          name: 'Export All Asset',
          perform() {
            rootStore.uiExportQueueStore.exportFullCardListCsv();
          },
        },
        {
          id: 'export-search-cards',
          name: 'Export Current Search Results',
          perform() {
            rootStore.uiExportQueueStore.exportVisibleCardListCsv();
          },
        },
      ],
    },
    {
      section: `${sourceName} CSV Export`,
      keywords: 'export library download',
      actions: [
        {
          id: 'export-current-results',
          name: 'Current Results',
          perform() {
            rootStore.uiExportSourceStore.exportFilteredSource(sourceTabId);
          },
        },
        {
          id: 'export-used-rows',
          name: 'Rows Used On Assets',
          perform() {
            rootStore.uiExportSourceStore.exportUsedRows(sourceTabId);
          },
        },
        {
          id: 'export-all-rows',
          name: 'All Items in Source',
          perform() {
            rootStore.uiExportSourceStore.exportFullSource(sourceTabId);
          },
        },
        {
          id: 'export-source-template',
          name: 'Source Template (Columns Only)',
          perform() {
            rootStore.uiExportSourceStore.exportUsedRows(sourceTabId);
          },
        },
      ],
    },
    {
      section: 'Create folders From Cards',
      actions: [
        {
          id: 'create-folders-cards',
          name: 'Create Folders From Cards',
          perform() {
            rootStore.uiDownloadFolders.selectDownloadFolderName(DownloadFolderNaming.FILENAME);
          },
        },
        {
          id: 'create-folders-filenames',
          name: 'Create Folders From Card Filenames',
          perform() {
            rootStore.uiDownloadFolders.selectDownloadFolderName(DownloadFolderNaming.FILENAME);
          },
        },
        {
          id: 'create-folders',
          name: 'Create Folders From Card Title',
          perform() {
            rootStore.uiDownloadFolders.selectDownloadFolderName(DownloadFolderNaming.CARD_TITLE);
          },
        },
      ],
    },
    {
      section: 'Batch Copy Card Info To Clipboard',
      actions: [
        {
          id: 'copy-filenames',
          name: 'Batch Copy Filenames to Clipboard',
          perform() {
            rootStore.uiBatchCopyStore.openDialogForFilename();
          },
        },
        {
          id: 'copy-urls',
          name: 'Batch Copy Card Urls to Clipboard',
          perform() {
            rootStore.uiBatchCopyStore.openDialogForUrl();
          },
        },
        {
          id: 'copy-titles',
          name: 'Batch Copy Card Titles to Clipboard',
          perform() {
            rootStore.uiBatchCopyStore.openDialogForCardTitle();
          },
        },
      ],
    },
    {
      section: 'Queue Preferences',
      actions: [
        {
          id: 'toggle-automatic-module-creation',
          name: 'Toggle: Automatically Create Modules for Newly Added Sources',
          perform() {
            rootStore.sessionsStore.currentSession?.toggleAutomaticModuleCreation();
          },
        },
        {
          id: 'toggle-automatic-add-filename-suffix',
          name: 'Toggle: Automatically Add Filename Suffix to Card Title',
          perform() {
            rootStore.sessionsStore.currentSession?.toggleAutomaticModuleCreation();
          },
        },
        {
          id: 'toggle-automatic-avoid-filename-duplicates',
          name: 'Toggle: Automatically Avoid Filename Duplicates',
          perform() {
            rootStore.sessionsStore.currentSession?.toggleAutomaticModuleCreation();
          },
        },
      ],
    },
    {
      section: 'Asset Size',
      keywords: 'queue view',
      actions: [
        {
          id: 'toggle-Asset-size',
          keywords: 'uncollapse',
          name: rootStore.uiActiveSessionStore.areCardsCollapsed
            ? 'Expanded Assets'
            : 'Collapsed Assets',
          perform() {
            rootStore.uiActiveSessionStore.toggleCardView();
          },
        },
      ],
    },
    {
      section: 'Go To...',
      actions: [
        {
          id: 'top',
          name: 'Top',
          keywords: 'scroll top',
          perform() {
            rootStore.uiQueueScroll.scrollToTop();
          },
        },
        {
          id: 'bottom',
          name: 'Bottom',
          keywords: 'scroll end',
          perform() {
            rootStore.uiQueueScroll.scrollToEnd();
          },
        },
        {
          id: 'in-progress',
          name: 'In Progress',
          perform() {
            rootStore.uiActiveSessionStore.setCurrentQueueTab(QueueTabName.InProgress);
          },
        },
        {
          id: 'done',
          name: 'Done',
          perform() {
            rootStore.uiActiveSessionStore.setCurrentQueueTab(QueueTabName.Done);
          },
        },
        ...Object.values(rootStore.sourcesStore.sources).map((source) => ({
          id: `go-to-${source.id}`,
          name: `${source.data.name}`,
          perform() {
            rootStore.uiActiveSessionStore.setSelectedSource(source.id);
            // Give the state a chance to update before setting focus
            setTimeout(() => rootStore.uiSourceSearchStore.setSearchFocus(true), 0);
          },
        })),
      ],
    },
  ];
  const actions = expandKbarActions(nestedActions);
  useRegisterActions(actions, [JSON.stringify(actions)]);
}
