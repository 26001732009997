import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';

import { observer } from 'mobx-react-lite';
import { useStore } from 'logic';
import type { RowData } from '@creative-kit/shared';
import { NEW_SOURCE_TAB } from 'logic/ui-active-session';
import { useDropzone } from 'react-dropzone';
import { SourceRows } from './SourceRows';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    opacity: 0.7,
    transition: theme.transitions.create('opacity'),
    '&:hover': {
      opacity: 1,
    },
  },
  buttonTitle: {
    fontSize: 16,
  },
  title: {
    flexGrow: 1,
  },
  sourceName: {
    color: '#81C784',
    fontStyle: 'italic',
  },
  sourceRows: {
    marginTop: theme.spacing(5),
  },
}));

interface AddRowsModalProps {
  sourceId: string;
}

const getEmptyRows = () =>
  Array(100)
    .fill(0)
    .map((_) => ({}));

export const AddRowsModal = observer(({ sourceId }: AddRowsModalProps) => {
  const styles = useStyles();
  const [finalRows, setFinalRows] = React.useState<RowData[]>(getEmptyRows());

  const {
    uiActiveSessionStore: { addRowsDialogOpen, setAddRowsDialogOpen },
    sourcesStore: { sources },
    uiImageSourceUpload,
  } = useStore();

  const source = sources[sourceId];
  const sourceData = source?.data;
  const sourceName = source?.data.name;

  const handleClose = () => {
    setAddRowsDialogOpen(false);
  };

  const handleSaveChanges = () => {
    source.addNewRows(finalRows);
    setAddRowsDialogOpen(false);
    setFinalRows(getEmptyRows());
  };

  const handleClickOpen = () => {
    setAddRowsDialogOpen(true);
  };

  const { open, getInputProps } = useDropzone({
    onDrop(files: File[]) {
      for (const file of files) {
        uiImageSourceUpload.uploadImageToSource(sourceId, file);
      }
    },
  });

  if (source?.type === 'image') {
    return (
      <>
        <input {...getInputProps()} />
        <Button startIcon={<UploadIcon />} variant="contained" size="small" onClick={open}>
          Add Images
        </Button>
      </>
    );
  }

  const showAddButton = sourceId && sourceId !== NEW_SOURCE_TAB && source?.orderedRows.length > 0;

  return (
    <div>
      {showAddButton && (
        <Button startIcon={<AddIcon />} variant="contained" size="small" onClick={handleClickOpen}>
          Add
        </Button>
      )}

      <Dialog fullScreen disableEnforceFocus open={addRowsDialogOpen} onClose={handleClose}>
        <div className={styles.root}>
          <AppBar position="static" color="transparent">
            <Toolbar>
              <Typography variant="h6" className={styles.title}>
                Add new data to <span className={styles.sourceName}>{sourceName} </span>
              </Typography>

              <IconButton
                edge="end"
                color="inherit"
                aria-label="close"
                onClick={handleClose}
                size="large"
              >
                <ClearIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>

        <DialogContent>
          <SourceRows
            className={styles.sourceRows}
            rows={finalRows}
            setRows={setFinalRows}
            fieldIds={sourceData?.fieldIds}
            fieldNames={sourceData?.fieldNames}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSaveChanges} className={styles.buttonTitle} variant="outlined">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
