import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import 'simplebar/dist/simplebar.min.css';
import SimpleBar from 'simplebar-react';

import { useStore } from 'logic';
import { serializeField } from '@creative-kit/shared';
import { useCheckboxCallback, useInputCallback, useNumberCallback } from 'logic/hooks';
import { FIELD_SEPARATORS, SPACE_SEPARATORS, CARD_ORDER_DIGITS } from 'logic/ui-filename';

import { UploadSourceInput } from 'ui/UploadSourceInput';
import { AvailableField } from '../Formula/AvailableField';
import { Formula } from '../Formula';

const useStyles = makeStyles((theme) => ({
  availableFields: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  separatorOptions: {
    marginLeft: theme.spacing(4),
  },
  dialog: {
    overflowY: 'hidden',
  },
  simpleBar: {
    maxHeight: '87%',
  },
}));

export const FilenameManager = observer(() => {
  const {
    sessionsStore: { currentSession },
    uiFilenameStore,
  } = useStore();

  const { cardList } = currentSession!;

  const {
    init: initFilenameUi,
    availableFieldsByModule,
    availableAppFields,
    previewCardIdx,
    saveFormula,
    cleanFormula,
    filenameSettings,
    cancelDragField,
    updateSetting,
    // setFieldSeparator,
    // setSpaceSeparator,
    // setRemoveSpaces,
    // setSpecialCharacterSeparator,
    // setCardNumberWidth,
    // setFilenameCaps,
    // setRemoveSpecialFilenameCharacters,
    isDialogOpen,
    // setTrimSpaces,
    // setTrimSpecialCharacters,
    closeDialog,
  } = uiFilenameStore;

  useEffect(() => {
    initFilenameUi();
  }, [initFilenameUi, currentSession?.id]);
  const {
    specialCharacterReplacement,
    disableSpecialCharacterCleanup,
    disableFilenameCleanup,
    fieldSeparator,
    filenameCaps,
    cardNumberWidth,
    spaceSeparator,
    trimSpaces,
    trimSpecialCharacters,
  } = filenameSettings;
  const styles = useStyles();

  const handleCapsChange = useCheckboxCallback((v) => updateSetting('filenameCaps', v));
  const handleDisableFilenameCleanupChange = useCheckboxCallback((v) =>
    updateSetting('disableFilenameCleanup', !v)
  );
  const handleDisableSpecialCharacterChange = useCheckboxCallback((v) =>
    updateSetting('disableSpecialCharacterCleanup', !v)
  );
  const handleTrimSpaces = useCheckboxCallback((v) => updateSetting('trimSpaces', v));
  const handleTrimSpecialCharacters = useCheckboxCallback((v) =>
    updateSetting('trimSpecialCharacters', v)
  );
  const onSpecialCharacterSeparatorChange = useInputCallback((v) =>
    updateSetting('specialCharacterReplacement', v)
  );
  const onFieldSeparatorChange = useInputCallback((v) => updateSetting('fieldSeparator', v));
  const onSpaceSeparatorChange = useInputCallback((v) => updateSetting('spaceSeparator', v));
  const onWidthChange = useNumberCallback((v) => updateSetting('cardNumberWidth', v));
  const hasFields = availableFieldsByModule.length > 0;

  const cardForPreview = useMemo(() => {
    // If we have opened the filename manager from a card, use that specific card for preview
    if (previewCardIdx && cardList[previewCardIdx]) {
      return currentSession!.computeFilename(
        cardList[previewCardIdx],
        cleanFormula,
        filenameSettings
      ).filename;
    }
    // Otherwise, find the first card with a "valid" filename
    for (const card of cardList) {
      const { errors, filename } = currentSession!.computeFilename(
        card,
        cleanFormula,
        filenameSettings
      );
      if (errors.length === 0) {
        return filename;
      }
    }
  }, [cardList, currentSession, cleanFormula, filenameSettings, previewCardIdx]);

  return (
    <Dialog
      classes={{ paper: styles.dialog }}
      style={{ overflowY: 'hidden' }}
      fullScreen
      fullWidth
      open={isDialogOpen}
      onClose={closeDialog}
    >
      <DialogTitle>Filename Formula</DialogTitle>
      <SimpleBar forceVisible="y" autoHide={false} className={styles.simpleBar}>
        <DialogContent sx={{ overflowX: 'hidden' }}>
          <TextField
            value={cardForPreview ?? ''}
            disabled={cardForPreview === undefined}
            label="Filename Preview"
            fullWidth
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            sx={{
              marginBottom: (t) => t.spacing(2),
            }}
          />
          <div>
            <Formula formulaStore={uiFilenameStore} />
          </div>
          <hr />
          {hasFields ? (
            <>
              <h3>Super Fields</h3>
              <div className={styles.availableFields}>
                {availableAppFields.map((field) => (
                  <AvailableField
                    key={serializeField(field)}
                    field={field}
                    cancelDragField={cancelDragField}
                  />
                ))}
              </div>
              <hr />
              {availableFieldsByModule.map(({ module, fields }) => (
                <div key={module.id}>
                  <h3>{module.name}</h3>
                  <div className={styles.availableFields}>
                    {fields?.map((field) => (
                      <AvailableField
                        key={serializeField(field)}
                        field={field}
                        module={module}
                        cancelDragField={cancelDragField}
                      />
                    ))}
                  </div>
                </div>
              ))}
              <hr />
              <h3>Settings</h3>
              <div>
                <FormControl component="div" margin="normal" fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={filenameCaps}
                        onChange={handleCapsChange}
                        name="filenameCaps"
                        color="primary"
                      />
                    }
                    label="ALL CAPS for Filename"
                  />
                </FormControl>
                <FormControl margin="normal" component="fieldset" fullWidth>
                  <FormLabel component="legend">Separate Fields with:</FormLabel>
                  <RadioGroup
                    row
                    aria-label="fields"
                    name="fields"
                    value={fieldSeparator}
                    onChange={onFieldSeparatorChange}
                  >
                    {FIELD_SEPARATORS.map(({ name, value }) => (
                      <FormControlLabel
                        key={name}
                        value={value}
                        label={name}
                        control={<Radio color="primary" />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                <FormControl margin="normal" component="fieldset" fullWidth>
                  <FormLabel component="legend">Queue Order Digits:</FormLabel>
                  <RadioGroup
                    row
                    aria-label="digits"
                    name="digits"
                    value={cardNumberWidth}
                    onChange={onWidthChange}
                  >
                    {CARD_ORDER_DIGITS.map(({ name, value }) => (
                      <FormControlLabel
                        key={name}
                        value={value}
                        label={name}
                        control={<Radio color="primary" />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                <FormControlLabel
                  control={
                    <Switch
                      checked={trimSpecialCharacters}
                      onChange={handleTrimSpecialCharacters}
                      name="trimSpecialCharacters"
                      color="primary"
                    />
                  }
                  label="Trim special characters at start and end of Field"
                />
                <FormControl component="div" margin="normal" fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!disableSpecialCharacterCleanup}
                        onChange={handleDisableSpecialCharacterChange}
                        name="filenameCleanup"
                        color="primary"
                      />
                    }
                    label="Remove special characters"
                  />
                </FormControl>
                <Collapse in={!disableSpecialCharacterCleanup}>
                  <FormControl
                    margin="normal"
                    className={styles.separatorOptions}
                    component="fieldset"
                    fullWidth
                  >
                    <FormLabel component="legend">Replace Special Characters with:</FormLabel>
                    <RadioGroup
                      row
                      aria-label="replacement"
                      name="replacement"
                      value={specialCharacterReplacement}
                      onChange={onSpecialCharacterSeparatorChange}
                    >
                      {SPACE_SEPARATORS.map(({ name, value }) => (
                        <FormControlLabel
                          key={name}
                          value={value}
                          label={name}
                          control={<Radio />}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Collapse>
                <FormControlLabel
                  control={
                    <Switch
                      checked={trimSpaces}
                      onChange={handleTrimSpaces}
                      name="trimSpaces"
                      color="primary"
                    />
                  }
                  label="Trim spaces at start and end of Field"
                />
                <FormControl component="div" margin="normal" fullWidth>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!disableFilenameCleanup}
                        onChange={handleDisableFilenameCleanupChange}
                        name="filenameCleanup"
                        color="primary"
                      />
                    }
                    label="Remove spaces"
                  />
                </FormControl>
                <Collapse in={!disableFilenameCleanup}>
                  <FormControl
                    margin="normal"
                    className={styles.separatorOptions}
                    component="fieldset"
                    fullWidth
                  >
                    <FormLabel component="legend">Replace Spaces with:</FormLabel>
                    <RadioGroup
                      row
                      aria-label="replacement"
                      name="replacement"
                      value={spaceSeparator}
                      onChange={onSpaceSeparatorChange}
                    >
                      {SPACE_SEPARATORS.map(({ name, value }) => (
                        <FormControlLabel
                          key={name}
                          value={value}
                          label={name}
                          control={<Radio />}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Collapse>
              </div>
            </>
          ) : (
            <Box textAlign="center">
              <UploadSourceInput
                buttonProps={{ variant: 'outlined' }}
                buttonContent="Upload A Source To Configure Filename"
              />
            </Box>
          )}
        </DialogContent>
      </SimpleBar>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          onClick={() => {
            saveFormula();
            closeDialog();
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});
