export const plural = (num: number, one: string, many?: string) => {
  if (num === 1) {
    return one;
  }

  return many || `${one}s`;
};

export const withPlural = (num: number, one: string, many?: string) =>
  `${num} ${plural(num, one, many)}`;
