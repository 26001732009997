import { ulid } from 'ulid';
import { FIELD_DATE_FORMATS } from '../datetime';

export interface PosNoField {
  type: 'app';
  field: 'POS_NO';
}

export interface SessionNameField {
  type: 'app';
  field: 'SESSION_NAME';
}

export interface FilenameSuffixField {
  type: 'app';
  field: 'FILENAME_SUFFIX';
}

export type DateFormat = (typeof FIELD_DATE_FORMATS)[number];
export interface DateField {
  type: 'app';
  field: 'CURR_DATE';
  format: DateFormat;
}

export interface TextField {
  id: string;
  type: 'app';
  field: 'CUSTOM_TXT';
  text: string;
}

export const POS_NO_FIELD: PosNoField = {
  type: 'app',
  field: 'POS_NO',
};

export const SESSION_NAME_FIELD: SessionNameField = {
  type: 'app',
  field: 'SESSION_NAME',
};

export const CURR_DATE_FIELD: DateField = {
  type: 'app',
  field: 'CURR_DATE',
  format: 'yyyy-MM-dd',
};

export const FILENAME_SUFFIX_FIELD: FilenameSuffixField = {
  type: 'app',
  field: 'FILENAME_SUFFIX',
};

export const customTxtField = (): TextField => ({
  id: ulid(),
  type: 'app',
  field: 'CUSTOM_TXT',
  text: '',
});

export type AppField = PosNoField | DateField | TextField | SessionNameField | FilenameSuffixField;

export interface ModuleField {
  type: 'module';
  moduleId: string;
  field: string;
}

export type Field = AppField | ModuleField;

export const serializeField = (f: Field) => {
  if (f.type === 'module') {
    return `module:${f.moduleId}:${f.field}`;
  }

  if (f.field === 'CUSTOM_TXT') {
    return `app:CUSTOM_TXT:${f.id}`;
  }

  if (
    f.field === 'FILENAME_SUFFIX' ||
    f.field === 'CURR_DATE' ||
    f.field === 'POS_NO' ||
    f.field === 'SESSION_NAME'
  ) {
    return `app:${f.field}`;
  }
};

export const isAppTextField = (f?: Field): f is TextField =>
  !!f && f.type === 'app' && f.field === 'CUSTOM_TXT';

export const isAppDateField = (f?: Field): f is DateField =>
  !!f && f.type === 'app' && f.field === 'CURR_DATE';

export const fieldEquals = (f1: Field) => (f2: Field) => serializeField(f1) === serializeField(f2);

export const getModuleFieldLabel = (f: ModuleField) => f.field;

export const getAppFieldLabel = (f: AppField) => {
  if (f.field === 'FILENAME_SUFFIX') {
    return 'Filename Suffix';
  }
  if (f.field === 'POS_NO') {
    return 'Queue Order';
  }
  if (f.field === 'SESSION_NAME') {
    return 'Session Name';
  }
  if (isAppTextField(f)) {
    return f.text;
  }
  if (isAppDateField(f)) {
    let label = 'Current Date';
    if (f.format) {
      label = `${label} (${f.format})`;
    }
    return label;
  }
};

export const getFieldLabel = (f: Field) => {
  if (f.type === 'app') {
    return getAppFieldLabel(f);
  }

  return f.field;
};
