import React from 'react';
import { createRouterState } from 'mobx-state-router';

import { routes, UiRouterStore } from './routes';
import { SessionsStore } from './sessions';
import { AuthStore } from './auth';
import { CardsStore } from './cards';
import { CommentsStore } from './comments';
import { SourcesStore } from './sources';

import { UiThemeStore } from './ui-theme';
import { UiCommentsStore } from './ui-comments';
import { UiCardTitleStore } from './ui-card-title';
import { UiFilenameStore } from './ui-filename';
import { UiSessionNameStore } from './ui-session-name';
import { UiExportQueueStore } from './ui-export-queue';
import { UiExportSourceStore } from './ui-export-source';
import { UiSourceSearchStore } from './ui-source-search';
import { UiSourceFieldsStore } from './ui-source-fields';
import { UiCardSearchStore } from './ui-card-search';
import { UiSnackbarStore } from './ui-snackbar';
import { UiKeyboardShortcutsStore } from './ui-keyboard-shortcuts';
import { UiBatchCardsStore } from './ui-batch-cards';
import { UiCardsStore } from './ui-cards';
import { UiActiveSessionStore } from './ui-active-session';
import { UiModulePowerColumnStore } from './ui-module-power-column';
import { UiCardDragDropStore } from './ui-card-dragdrop';
import { UiModuleDragDropStore } from './ui-module-dragdrop';
import { UiImageSourceUploads } from './ui-image-source-upload';
import { UiImageModal } from './ui-image-modal';
import { UiSourceResizeStore } from './ui-source-resize';
import { UiPermissionStore } from './ui-permissions';
import { UserSettingsStore } from './user-settings';
import { UiModuleSourceEditorStore } from './ui-module-source-editor';
import { UiStickyHeadersStore } from './ui-sticky-headers';
import { UiDownloadFoldersStore } from './ui-download-folders';
import { UiBatchCopyStore } from './ui-batch-copy';
import { UiSourceTabDragDropStore } from './ui-source-tab-dragdrop';
import { UiQueueScrollStore } from './ui-list-scroll';
import { SessionCardActionsStore } from './session-card-actions';
import { UiCardView } from './ui-card-view';
import { UiQueueSelectorStore } from './ui-queue-selector-store';
import { UiModuleConnectionDisplayStore } from './ui-module-connection-display';

const notFound = createRouterState('notFound');

export class RootStore {
  routerStore: UiRouterStore;
  sessionsStore: SessionsStore;
  cardsStore: CardsStore;
  commentsStore: CommentsStore;
  sourcesStore: SourcesStore;
  authStore: AuthStore;
  userSettingsStore: UserSettingsStore;
  uiThemeStore: UiThemeStore;
  uiCardDragDrop: UiCardDragDropStore;
  uiSourceTabDragDrop: UiSourceTabDragDropStore;
  uiCommentsStore: UiCommentsStore;
  uiCardTitleStore: UiCardTitleStore;
  uiFilenameStore: UiFilenameStore;
  uiSessionNameStore: UiSessionNameStore;
  uiExportQueueStore: UiExportQueueStore;
  uiExportSourceStore: UiExportSourceStore;
  uiSourceSearchStore: UiSourceSearchStore;
  uiSourceFieldsStore: UiSourceFieldsStore;
  uiSourceResize: UiSourceResizeStore;
  uiCardSearchStore: UiCardSearchStore;
  uiSnackbarStore: UiSnackbarStore;
  uiKeyboardShortcutsStore: UiKeyboardShortcutsStore;
  uiBatchCardsStore: UiBatchCardsStore;
  uiCardsStore: UiCardsStore;
  uiActiveSessionStore: UiActiveSessionStore;
  uiModulePowerColumnStore: UiModulePowerColumnStore;
  uiModuleDragDrop: UiModuleDragDropStore;
  uiImageSourceUpload: UiImageSourceUploads;
  uiImageModal: UiImageModal;
  uiPermissions: UiPermissionStore;
  uiModuleSourceEditor: UiModuleSourceEditorStore;
  uiStickyHeaders: UiStickyHeadersStore;
  uiDownloadFolders: UiDownloadFoldersStore;
  uiBatchCopyStore: UiBatchCopyStore;
  uiQueueScroll: UiQueueScrollStore;
  uiCardView: UiCardView;
  sessionCardActions: SessionCardActionsStore;
  uiQueueSelector: UiQueueSelectorStore;
  uiModuleConnectionDisplayStore: UiModuleConnectionDisplayStore;

  constructor() {
    this.routerStore = new UiRouterStore(this, routes, notFound);
    this.sessionsStore = new SessionsStore(this);
    this.cardsStore = new CardsStore(this);
    this.commentsStore = new CommentsStore(this);
    this.sourcesStore = new SourcesStore(this);
    this.authStore = new AuthStore(this);
    this.uiThemeStore = new UiThemeStore();
    this.uiCardDragDrop = new UiCardDragDropStore(this);
    this.uiCommentsStore = new UiCommentsStore();
    this.uiCardTitleStore = new UiCardTitleStore(this);
    this.uiFilenameStore = new UiFilenameStore(this);
    this.uiSessionNameStore = new UiSessionNameStore(this);
    this.uiExportQueueStore = new UiExportQueueStore(this);
    this.uiExportSourceStore = new UiExportSourceStore(this);
    this.uiSourceSearchStore = new UiSourceSearchStore();
    this.uiSourceFieldsStore = new UiSourceFieldsStore(this);
    this.uiSourceResize = new UiSourceResizeStore();
    this.uiCardSearchStore = new UiCardSearchStore(this);
    this.uiSnackbarStore = new UiSnackbarStore(this);
    this.uiKeyboardShortcutsStore = new UiKeyboardShortcutsStore(this);
    this.uiBatchCardsStore = new UiBatchCardsStore(this);
    this.uiCardsStore = new UiCardsStore();
    this.uiActiveSessionStore = new UiActiveSessionStore(this);
    this.uiModulePowerColumnStore = new UiModulePowerColumnStore(this);
    this.uiModuleDragDrop = new UiModuleDragDropStore(this);
    this.uiImageSourceUpload = new UiImageSourceUploads(this);
    this.uiImageModal = new UiImageModal();
    this.uiPermissions = new UiPermissionStore(this);
    this.userSettingsStore = new UserSettingsStore(this);
    this.uiModuleSourceEditor = new UiModuleSourceEditorStore(this);
    this.uiStickyHeaders = new UiStickyHeadersStore();
    this.uiDownloadFolders = new UiDownloadFoldersStore(this);
    this.uiBatchCopyStore = new UiBatchCopyStore(this);
    this.uiSourceTabDragDrop = new UiSourceTabDragDropStore(this);
    this.sessionCardActions = new SessionCardActionsStore(this);
    this.uiQueueScroll = new UiQueueScrollStore();
    this.uiCardView = new UiCardView(this);
    this.uiQueueSelector = new UiQueueSelectorStore(this);
    this.uiModuleConnectionDisplayStore = new UiModuleConnectionDisplayStore(this);
  }

  init() {
    this.authStore.init();
    this.sessionsStore.init();
    this.cardsStore.init();
    this.commentsStore.init();
    this.sourcesStore.init();
    this.uiThemeStore.init();
    this.uiSourceFieldsStore.init();
    this.uiKeyboardShortcutsStore.init();
    this.uiSnackbarStore.init();
    this.userSettingsStore.init();
    this.uiCardView.init();
  }
}

export const storeContext = React.createContext<RootStore>({} as RootStore);
export const useStore = () => React.useContext(storeContext);
