import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useStore } from 'logic';
import { BatchCopyMode } from 'logic/ui-batch-copy';
import { matchMetaShortcutToEvent } from 'logic/ui-keyboard-shortcuts';
import { observer } from 'mobx-react-lite';
import { KeyboardEvent } from 'react';

export const BatchCopyToClipboardModal = observer(() => {
  const {
    uiBatchCopyStore: {
      isDialogOpen,
      closeDialog,
      setCopyMode,
      copyMode,
      cardsToUse,
      setCardsToUse,
      lineSeparator,
      textToCopy,
      setLineSeparator,
      batchCopy,
      selectedCards,
      namesToUse,
    },
    uiActiveSessionStore: { selectedCardIds },
    sessionsStore: { currentSession },
  } = useStore();

  const { inProgressVisibleCount, doneVisibleCount } = currentSession!;

  const invalidNames = selectedCards.length - namesToUse.length;

  const handleCopy = async () => {
    batchCopy();
    closeDialog();
  };

  const handleKeyDownSubmit = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeDialog();
    }
    if (matchMetaShortcutToEvent(event, 'Enter')) {
      handleCopy();
    }
  };

  return (
    <Dialog
      disablePortal
      disableEnforceFocus
      onKeyDown={handleKeyDownSubmit}
      open={isDialogOpen}
      onClose={closeDialog}
      sx={{ position: 'absolute' }}
      BackdropProps={{
        sx: {
          position: 'absolute',
        },
      }}
    >
      <DialogTitle>Batch Copy from Assets</DialogTitle>
      <DialogContent>
        <FormControl margin="normal" component="fieldset" fullWidth>
          <FormLabel component="legend">Copy Info</FormLabel>
          <RadioGroup
            row
            aria-label="fields"
            name="fields"
            value={copyMode}
            onChange={(_, value) => setCopyMode(value as BatchCopyMode)}
          >
            <FormControlLabel
              value={BatchCopyMode.CARD_TITLE}
              label="Asset Titles"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value={BatchCopyMode.FILENAME}
              label="Asset Filenames"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value={BatchCopyMode.URL}
              label="Asset Urls"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </FormControl>
        <FormControl component="div" margin="normal" fullWidth>
          <FormLabel component="legend">Batch Copy Info From</FormLabel>
          <FormControlLabel
            control={
              <Switch
                checked={cardsToUse.selected}
                onChange={(_, checked) => setCardsToUse('selected', checked)}
                name="UseSelected"
                color="primary"
              />
            }
            label={`Selected (${selectedCardIds.length})`}
          />
          <FormControlLabel
            control={
              <Switch
                checked={cardsToUse.inProgress}
                onChange={(_, checked) => setCardsToUse('inProgress', checked)}
                name="UseInProgress"
                color="primary"
              />
            }
            label={`In Progress (${inProgressVisibleCount})`}
          />
          <FormControlLabel
            control={
              <Switch
                checked={cardsToUse.done}
                onChange={(_, checked) => setCardsToUse('done', checked)}
                name="UseDone"
                color="primary"
              />
            }
            label={`Done (${doneVisibleCount})`}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel>Preview</FormLabel>
          <TextareaAutosize value={textToCopy} readOnly style={{ borderRadius: 5 }} maxRows={4} />
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <RadioGroup
            row
            aria-label="fields"
            name="fields"
            value={lineSeparator}
            onChange={(_, value) => setLineSeparator(value as any)}
          >
            <FormControlLabel
              value="line"
              label="Line Separated"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value="comma"
              label="Comma Separated"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </FormControl>
        <FormControl fullWidth>
          <Box pt={1}>
            {invalidNames > 0 && (
              <Typography variant="subtitle2" textAlign="center">
                Note: {invalidNames} Assets with Incomplete{' '}
                {copyMode === BatchCopyMode.CARD_TITLE ? 'Card Titles' : 'Filenames'} will not be
                copied
              </Typography>
            )}
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button onClick={handleCopy} color="primary">
          Copy To Clipboard
        </Button>
      </DialogActions>
    </Dialog>
  );
});
