import { FormControl, FormControlLabel, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';

interface ModuleFieldsRadioProps {
  selectedFields: string[];
  sourceId: string;
  onSwitchModuleField: (field: string) => void;
}

const useStyles = makeStyles((theme) => ({
  fieldSwitches: {
    padding: theme.spacing(0, 1, 1),
  },
  fieldSwitch: {
    padding: theme.spacing(0.5, 1),
  },
  fieldSwitchInput: {
    marginRight: theme.spacing(0.5),
  },
}));

export const ModuleFieldsRadio = observer(
  ({ selectedFields, sourceId, onSwitchModuleField }: ModuleFieldsRadioProps) => {
    const styles = useStyles();

    const {
      sourcesStore: { sources },
    } = useStore();

    const source = sources[sourceId];

    if (!source) {
      return null;
    }

    const { fieldIds, fieldNames } = source.data;

    return (
      <div className={styles.fieldSwitches}>
        {fieldIds &&
          fieldIds.map((fieldId) => (
            <div className={styles.fieldSwitch} key={fieldId}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      className={styles.fieldSwitchInput}
                      checked={selectedFields.includes(fieldId)}
                      onChange={() => onSwitchModuleField(fieldId)}
                    />
                  }
                  label={fieldNames[fieldId]}
                />
              </FormControl>
            </div>
          ))}
      </div>
    );
  }
);
