import { format } from 'date-fns';
import { clean } from 'diacritic';

import { Field, FirestoreSession, FilenameSettings, SessionPermissions } from '../firestore';
import type { IRootStore } from './root';
import { BaseCard } from './card';
import { trimSpecialCharacters as trimSpecialCharactersFn } from '../trimSpecialCharacters';

export class BaseSession {
  protected rootStore: IRootStore;
  id: string;
  data: FirestoreSession;

  constructor(id: string, data: FirestoreSession, rootStore: IRootStore) {
    this.data = data;
    this.id = id;
    this.rootStore = rootStore;
  }

  get modules() {
    return this.data.moduleIds.map((moduleId) => this.data.modules[moduleId]);
  }

  get cardList() {
    return this.data.cardIds.map((id) => this.rootStore.cardsStore.cards[id]).filter((s) => !!s);
  }

  get doneCardIds() {
    return this.data.cardIds
      .slice(0, this.currentCardIdx)
      .filter((id) => !!this.rootStore.cardsStore.cards[id])
      .reverse();
  }

  get inProgressCardIds() {
    return this.data.cardIds
      .slice(this.currentCardIdx)
      .filter((id) => !!this.rootStore.cardsStore.cards[id]);
  }

  get currentCardIdx() {
    const firstNotDone = this.cardList.findIndex((s) => !s.data.doneAt);
    return firstNotDone === -1 ? this.cardList.length : firstNotDone;
  }

  get canViewSession() {
    return this.data.publicAccessLevel === SessionPermissions.VIEW;
  }

  get canEditSession() {
    return this.data.publicAccessLevel === SessionPermissions.EDIT;
  }

  protected getModuleFieldValueForCard(field: Field, card: BaseCard) {
    const cardConnections = card.data.connections;
    const module = field.type === 'module' && this.data.modules[field.moduleId];
    if (!module) {
      return null;
    }

    const source = this.rootStore.sourcesStore.sources[module.sourceId];
    if (!source) {
      return null;
    }

    const rowId = cardConnections[module.id];
    if (typeof rowId !== 'string') {
      return null;
    }
    const row = source.rows[rowId];
    if (!row) {
      return null;
    }

    const part = row.getValue(field.field);
    return part || null;
  }

  computeFormulaField(field: Field, card: BaseCard, cardNumberWidth: number): string | null {
    let computedField: string | null = null;
    if (field.type === 'app') {
      if (field.field === 'SESSION_NAME') {
        computedField = this.data.name;
      } else if (field.field === 'POS_NO') {
        computedField = card.cardNumber.toString().padStart(cardNumberWidth, '0');
      } else if (field.field === 'CUSTOM_TXT') {
        computedField = field.text;
      } else if (field.field === 'CURR_DATE') {
        computedField = format(new Date(), field.format);
      } else if (field.field === 'FILENAME_SUFFIX') {
        computedField = card.suffix ?? null;
      }
    } else {
      computedField = this.getModuleFieldValueForCard(field, card);
    }
    return computedField;
  }

  computeMainLabel(card: BaseCard) {
    const errors: string[] = [];
    const mainLabel = this.data.cardTitleFormula
      .reduce((parts: string[], field) => {
        const computedField = this.computeFormulaField(field, card, 0);

        if (computedField === null && field.type === 'module') {
          errors.push(this.data.modules[field.moduleId]?.name);
        }

        const part = computedField ?? 'No Connection';
        if (part.length > 0) {
          parts.push(part);
        }
        return parts;
      }, [])
      .join(' – ');

    return {
      mainLabel,
      // remove any duplicates if the same module is used multiple times
      errors: Array.from(new Set(errors)),
    };
  }

  safeFilenamePart = (
    originalName: string,
    {
      disableFilenameCleanup,
      disableSpecialCharacterCleanup,
      spaceSeparator,
      specialCharacterReplacement,
      trimSpaces,
      trimSpecialCharacters,
    }: FilenameSettings
  ) => {
    let cleanedFilename = originalName;

    if (trimSpaces) {
      cleanedFilename = clean(cleanedFilename.trim());
    }

    if (trimSpecialCharacters) {
      cleanedFilename = trimSpecialCharactersFn(cleanedFilename);
    }

    if (!disableFilenameCleanup) {
      cleanedFilename = clean(cleanedFilename.trim()).replace(/\s+/g, spaceSeparator);

      if (spaceSeparator.length > 0) {
        cleanedFilename = cleanedFilename.replace(
          new RegExp(`${spaceSeparator}+`, 'g'),
          spaceSeparator
        );
      }
    }

    if (!disableSpecialCharacterCleanup) {
      cleanedFilename = cleanedFilename.replace(
        new RegExp(`[^a-z0-9${spaceSeparator}]+`, 'gi'),
        specialCharacterReplacement
      );
    }

    return cleanedFilename;
  };

  /**
   * This takes formula and settings as arguments instead of acccessing on instance so we can compute filename on the fly while editing the formula
   */
  computeFilename(card: BaseCard, filenameFormula: Field[], filenameSettings: FilenameSettings) {
    const errors: string[] = [];
    const filename = filenameFormula
      .reduce((parts: string[], field) => {
        const computedField = this.computeFormulaField(
          field,
          card,
          filenameSettings.cardNumberWidth
        );
        if (computedField === null && field.type === 'module') {
          errors.push(this.data.modules[field.moduleId]?.name);
        }

        const part =
          filenameSettings.filenameCaps && computedField
            ? computedField.toUpperCase()
            : computedField;

        parts.push(part ?? 'No Connection');
        return parts;
      }, [])
      .map((f) => this.safeFilenamePart(f, filenameSettings))
      .join(filenameSettings.fieldSeparator);

    return {
      filename,
      // remove any duplicates if the same module is used multiple times
      errors: Array.from(new Set(errors)),
    };
  }

  getRowsForModule(moduleId: string, metadataRows: string[]) {
    const sourceId = this.data.modules[moduleId]?.sourceId;
    const source = this.rootStore.sourcesStore.sources[sourceId];
    if (!source) {
      return [];
    }

    const rows = metadataRows.map((rowId) => source.rows[rowId]).filter((row) => !!row);

    if (source.type === 'image') {
      rows.reverse();
    }

    return rows;
  }

  getModulesForSource = (sourceId: string) => this.modules.filter((m) => m.sourceId === sourceId);

  // Determines whether a given module is used for a formula
  getDependenciesForModule = (moduleId: string) => {
    const filenameDeps = this.data.filenameFormula.filter(
      (f) => f.type === 'module' && f.moduleId === moduleId
    );
    const cardTitleDeps = this.data.cardTitleFormula.filter(
      (f) => f.type === 'module' && f.moduleId === moduleId
    );
    return {
      cardTitleDeps,
      filenameDeps,
    };
  };

  getImageModules = () =>
    this.modules.filter((m) => {
      const source = this.rootStore.sourcesStore.sources[m.sourceId];
      return source.type === 'image';
    });

  getDependenciesForSource = (sourceId: string) => {
    const sourceModules = this.getModulesForSource(sourceId);
    const sourceFilenameDeps = [];
    const sourceCardTitleDeps = [];

    for (const module of sourceModules) {
      const { filenameDeps, cardTitleDeps } = this.getDependenciesForModule(module.id);
      sourceFilenameDeps.push(filenameDeps);
      sourceCardTitleDeps.push(cardTitleDeps);
    }
    return { sourceFilenameDeps, sourceCardTitleDeps };
  };

  get hasInProgressCardsWithDeletedConnections() {
    return this.inProgressCardIds
      .map((cardId) => this.rootStore.cardsStore.cards[cardId])
      .some((card) => card.hasDeletedConnections);
  }

  get hasDoneCardsWithDeletedConnections() {
    return this.doneCardIds
      .map((cardId) => this.rootStore.cardsStore.cards[cardId])
      .some((card) => card.hasDeletedConnections);
  }
}
