import { Box, CircularProgress, Typography } from '@mui/material';
import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';

export const NoSessionView = observer(() => {
  const {
    sessionsStore: { currentSessionError, currentSessionOwner },
  } = useStore();
  let content = <CircularProgress color="secondary" size={100} />;

  if (currentSessionError === 403) {
    content = (
      <Box sx={{ flexDirection: 'row', textAlign: 'center' }}>
        <Typography variant="h4">You need permission to access this Project.</Typography>
        <Typography variant="subtitle1">
          The user who owns this Project has not yet shared it for collaboration
        </Typography>
        <Typography variant="subtitle1">
          Project Owner:{' '}
          {currentSessionOwner ? (
            `${currentSessionOwner.name} (${currentSessionOwner.email})`
          ) : (
            <CircularProgress size={16} />
          )}
        </Typography>
      </Box>
    );
  }

  if (currentSessionError === 404) {
    content = <Typography variant="h4">The Project you requested does not exist</Typography>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: (t) => t.spacing(2),
        justifyContent: 'center',
      }}
    >
      {content}
    </Box>
  );
});
