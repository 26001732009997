import React from 'react';
import { Menu } from '@mui/material';
import { IconMenuItem, NestedMenuItem } from 'mui-nested-menu';
import CheckIcon from '@mui/icons-material/Check';

export interface MenuItemData {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  children?: MenuItemData[];
  enabled?: boolean; // Add this property
}

interface NestedSettingsMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
  menuItems: MenuItemData[];
}

export const NestedSettingsMenu: React.FC<NestedSettingsMenuProps> = ({
  anchorEl,
  open,
  onClose,
  menuItems,
}) => {
  const renderMenuItems = (items: MenuItemData[], parentMenuOpen: boolean) => {
    return items.map((item, index) => {
      return item.children ? (
        <NestedMenuItem
          ContainerProps={{ style: { margin: '5px' } }}
          key={index}
          label={item.label}
          parentMenuOpen={parentMenuOpen}
          leftIcon={item.icon}
          onClick={item.onClick}
        >
          {renderMenuItems(item.children, parentMenuOpen)}
        </NestedMenuItem>
      ) : (
        <IconMenuItem
          MenuItemProps={{ style: { minWidth: '150px', margin: '5px' } }}
          key={index}
          label={item.label}
          leftIcon={item.icon}
          rightIcon={item.enabled ? <CheckIcon /> : undefined}
          onClick={item.onClick}
        />
      );
    });
  };

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
      {renderMenuItems(menuItems, open)}
    </Menu>
  );
};
