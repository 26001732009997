import { SessionModule } from '@creative-kit/shared';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useStore } from 'logic';
import { Card } from 'logic/card';
import { observer } from 'mobx-react-lite';
import { useSourceDrop } from 'ui/useSourceDrop';
import { ModuleDropTarget } from './ModuleDropTarget';

interface ModuleDropTargetsProps {
  card?: Card;
  prompt?: string;
  position?: 'start' | 'end';
  afterCardId?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    pointerEvents: 'none',
  },
  rootWithDragging: {
    opacity: 1,
    pointerEvents: 'initial',
  },
  prompt: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  hoveredPrompt: {
    display: 'none',
  },
  modules: {
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
  },
  hoveredModules: {
    opacity: 1,
  },
}));

export const ModuleDropTargets = observer(
  ({ card, prompt, position, afterCardId }: ModuleDropTargetsProps) => {
    const [{ item, isDraggingSource, isHovering }, drop] = useSourceDrop();

    const {
      sessionsStore: { currentSession },
    } = useStore();

    const { getModulesForSource, getIsModuleVisible, getImageModules } = currentSession!;
    let modules: SessionModule[] = [];
    if (item) {
      if ('sourceId' in item) {
        // Dragging a source row
        modules = getModulesForSource(item.sourceId);
      } else if ('files' in item) {
        // Dragging image file(s)
        modules = getImageModules();
      }
    }

    const styles = useStyles();
    const hasHovering = prompt ? isHovering : isDraggingSource;

    return (
      <div
        className={clsx(styles.root, {
          [styles.rootWithDragging]: isDraggingSource,
        })}
        ref={drop}
      >
        <div
          className={clsx(styles.prompt, {
            [styles.hoveredPrompt]: hasHovering,
          })}
        >
          {prompt}
        </div>
        <div
          className={clsx(styles.modules, {
            [styles.hoveredModules]: hasHovering,
          })}
        >
          {modules.map((module) =>
            getIsModuleVisible(module.id) ? (
              <ModuleDropTarget
                key={module.id}
                module={module}
                card={card}
                position={position}
                afterCardId={afterCardId}
              />
            ) : null
          )}
        </div>
      </div>
    );
  }
);
