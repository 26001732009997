import type { RootStore } from 'logic';
import { format } from 'date-fns';
import type { Card } from './card';

enum FileExtension {
  csv = 'CSV',
  json = 'JSON',
}

enum CardListHeader {
  QUEUE_ORDER = 'Queue Order',
  CARD_STATUS = 'Card Status',
  TIME_MARKED_STATUS = 'Time Card Marked With Status',
  DATE_MARKED_STATUS = 'Date Card Marked With Status',
  CARD_TITLE = 'Card Title',
  ROW_ID = 'Card ID',
  DATE_CREATED = 'Date Created',
  CREATING_EMAIL = 'Creating User Email',
  CREATING_ID = 'Creating User ID',
  FILENAME = 'Filename',
}

const getTimeMarkedWithStatus = (dateSring: Date) => format(dateSring, 'HH:mm');

const getDateMarkedWithStatus = (dateString: Date) => format(dateString, 'yyyy-MM-dd');

export class UiExportQueueStore {
  private rootStore: RootStore;
  private csvHeader: string[];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.csvHeader = Object.values(CardListHeader);
  }

  private getFileName(fileExtension: FileExtension, exportType: string) {
    const sessionName = this.rootStore.sessionsStore.currentSession?.data.name ?? '';
    return `${format(new Date(), 'yyyy-MM-dd HH')}∶${format(
      new Date(),
      'mm'
    )}_${sessionName}_${exportType}.${fileExtension === FileExtension.csv ? 'csv' : 'json'}`;
  }

  private generateDownload(textContent: string, filename: string) {
    // eslint-disable-line
    const csvData = new Blob([textContent], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvData);

    const link = document.createElement('a');
    link.setAttribute('href', csvUrl);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    // trigger the download
    link.click();
  }

  private getQueue() {
    // eslint-disable-line
    const cardIds = this.rootStore.sessionsStore.currentSession?.data.cardIds || [];

    return cardIds.map((id) => this.rootStore.cardsStore.cards[id]);
  }

  private getModules() {
    return this.rootStore.sessionsStore.currentSession?.modules;
  }

  exportFullCardListCsv = () => {
    const cardList = this.getQueue();
    this.exportCardListCsv(cardList, this.getFileName(FileExtension.csv, 'ALL CARDS'));
  };

  exportSelectedCardListCsv = () => {
    const cardList = this.rootStore.uiActiveSessionStore.selectedCards;
    this.exportCardListCsv(cardList, this.getFileName(FileExtension.csv, 'SELECTED CARDS'));
  };

  exportVisibleCardListCsv = () => {
    const cardList = this.getQueue();
    const visibleCards = cardList.filter((card) => card.isVisible);
    this.exportCardListCsv(visibleCards, this.getFileName(FileExtension.csv, 'CURRENT RESULTS'));
  };

  exportCardListCsv = (cardList: Card[], filename: string) => {
    const modules = this.getModules() ?? [];
    // preparing the data rows
    const data = cardList
      .sort((a, b) => a.cardNumber - b.cardNumber)
      // this order should match the data in the csvHeader variable
      .map((card) => [
        card.cardNumber, //  'Queue Order'
        card.status, // 'Card Status
        getTimeMarkedWithStatus(card.data.doneAt || card.data.createdAt), // Time Marked With Card Status
        getDateMarkedWithStatus(card.data.doneAt || card.data.createdAt), // Date Marked With Card Status
        card.mainLabel, // 'Card Title'
        card.id, // 'Card ID'
        card.data.createdAt // 'Date Created'
          ? getDateMarkedWithStatus(card.data.createdAt)
          : '',
        card.data.createdBy?.email, // 'Creating User Email'
        card.data.createdBy?.userId, // 'Creating user ID'
        card.data.filename || card.filename,

        // Dynamic module ids
        ...modules.map((mod) => {
          const rows = card.getModuleConnections(mod.id).map((row) => row.id) ?? [];
          return `"${rows.join(',')}"`;
        }),
      ]);

    // Dynamically add every module to header of csv
    const header = [...this.csvHeader, ...modules.map((mod) => `Module: ${mod.name}`)];
    // adding the header first then each datarow
    const rows = [header, ...data];

    this.generateDownload(rows.map((e) => e.join(',')).join('\n'), filename);
  };

  exportCardListJson = () => {
    // eslint-disable-line
    const cardList = this.getQueue();

    const data = cardList
      .sort((a, b) => a.cardNumber - b.cardNumber)
      .map((card) => ({
        [CardListHeader.QUEUE_ORDER]: card.cardNumber,
        [CardListHeader.CARD_STATUS]: card.status,
        [CardListHeader.TIME_MARKED_STATUS]: getTimeMarkedWithStatus(
          card.data.doneAt || card.data.createdAt
        ),
        [CardListHeader.DATE_MARKED_STATUS]: getDateMarkedWithStatus(
          card.data.doneAt || card.data.createdAt
        ),
        [CardListHeader.TIME_MARKED_STATUS]: card.mainLabel,
        [CardListHeader.ROW_ID]: card.id,

        ...(card.data.createdAt
          ? {
              [CardListHeader.DATE_CREATED]: getDateMarkedWithStatus(card.data.createdAt),
            }
          : {}),
        [CardListHeader.CREATING_EMAIL]: card.data.createdBy?.email,
        [CardListHeader.CREATING_ID]: card.data.createdBy?.userId,
        [CardListHeader.FILENAME]: card.data.filename || card.filename,
      }));

    this.generateDownload(
      `data:text/csv;charset=utf-8,${JSON.stringify(data)}`,
      this.getFileName(FileExtension.json, 'ALL CARDS')
    );
  };
}
