import { lighten, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useStore } from 'logic';
import { ADD_NEW_COLUMN_KEY } from 'logic/source';
import { observer } from 'mobx-react-lite';
import { FormatterProps } from '@creative-kit/react-data-grid';
import { Highlight } from 'ui/Highlight';
import { Row } from 'logic/row';
import { TextWithUrls } from 'ui/TextWithUrls';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  oldValue: {
    textDecoration: 'line-through',
    color: theme.palette.error.main,
  },
  deleted: {
    backgroundColor: lighten(theme.palette.error.light, 0.2),
  },
  tooltip: {
    position: 'absolute',
    inset: 0,
    cursor: 'pointer',
  },
}));

export const CellFormatter = observer((props: FormatterProps<Row>) => {
  const {
    uiSourceSearchStore: { getSearchTerms },
    sourcesStore: { sources },
  } = useStore();
  const styles = useStyles();

  if (props.column.key === ADD_NEW_COLUMN_KEY) {
    return (
      <Tooltip title="Enter data in this column to create it">
        <div className={styles.tooltip} />
      </Tooltip>
    );
  }

  const source = sources[props.row.sourceId];
  const terms = getSearchTerms(props.row.sourceId);

  const rowId = props.row.id;
  const fieldId = props.column.key;
  const value = props.row.getValue(fieldId);

  const isCellEdited = source.isCellEdited(rowId, fieldId);
  const editedValue = source.getCellEditedValue(rowId, fieldId);
  const isColDeleted = source.isColumnMarkedForDeletion(fieldId);
  const cellClasses = clsx({ [styles.deleted]: isColDeleted });
  let displayedValue = (
    <div className={cellClasses}>
      <TextWithUrls>{value}</TextWithUrls>
    </div>
  );
  if (isCellEdited) {
    displayedValue = (
      <>
        <span>{editedValue}</span>
        <span className={styles.oldValue}>{value}</span>
      </>
    );
  }

  return terms.length > 0 && source.fieldIdsWithResults.includes(fieldId) ? (
    <Highlight searchWords={terms} textToHighlight={value ?? ''} />
  ) : (
    displayedValue
  );
});
