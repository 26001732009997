import { action, makeObservable, observable } from 'mobx';

export class UiCommentsStore {
  composedCommentsByCardId: Record<string, string> = {};

  constructor() {
    makeObservable(this, {
      composedCommentsByCardId: observable,
      setComposedComment: action.bound,
    });
  }

  setComposedComment(card: string, newComment: string) {
    this.composedCommentsByCardId[card] = newComment;
  }
}
