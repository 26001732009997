import { action, computed, makeObservable, observable, reaction } from 'mobx';
import type ft from 'firebase';

import { FirestoreComment } from '@creative-kit/shared';
import type { RootStore } from '.';
import { db } from './data';
import { Comment } from './comment';

export class CommentsStore {
  private stopListeningToComments = () => {};
  private rootStore: RootStore;
  comments: Record<string, Comment> = {};

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      comments: observable,
      updateComments: action.bound,
      resetComments: action,
      commentsByCard: computed,
    });

    this.rootStore = rootStore;
  }

  init() {
    reaction(() => this.rootStore.sessionsStore.currentSession, this.watchComments, {
      fireImmediately: true,
    });
  }

  watchComments = () => {
    const { currentSession } = this.rootStore.sessionsStore;
    this.stopListeningToComments();
    this.resetComments();
    if (currentSession) {
      this.stopListeningToComments = db
        .sessionComments(currentSession.id)
        .orderBy('createdAt', 'asc')
        .onSnapshot(this.updateComments);
    }
  };

  resetComments() {
    for (const key of Object.keys(this.comments)) {
      delete this.comments[key];
    }
  }

  updateComments(snapshot: ft.firestore.QuerySnapshot<FirestoreComment>) {
    snapshot.docChanges().forEach((change) => {
      const changeType = change.type;
      const changeDoc = change.doc;
      if (changeType === 'added') {
        this.comments[changeDoc.id] = new Comment(changeDoc.id, changeDoc.data());
      } else if (change.type === 'modified') {
        this.comments[changeDoc.id].updateData(changeDoc.data());
      } else {
        delete this.comments[changeDoc.id];
      }
    });
  }

  get commentsByCard() {
    const commentsByCard: Record<string, Comment[]> = {};
    for (const comment of Object.values(this.comments)) {
      commentsByCard[comment.data.cardId] ||= [];
      commentsByCard[comment.data.cardId].push(comment);
    }
    for (const cardComments of Object.values(commentsByCard)) {
      cardComments.sort((a, b) => (a.data.createdAt < b.data.createdAt ? -1 : 1));
    }

    return commentsByCard;
  }
}
