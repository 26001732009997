import { PropsWithChildren } from 'react';
import useResizeObserver from 'use-resize-observer';

interface MeasurableContainerProps {
  onResize: () => void;
}

export const MeasurableContainer = ({
  onResize,
  children,
  ...otherProps
}: PropsWithChildren<MeasurableContainerProps>) => {
  const { ref } = useResizeObserver({ onResize });

  return (
    <div ref={ref} {...otherProps}>
      {children}
    </div>
  );
};
