import { IconButton } from '@mui/material';
import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { FormatterProps } from '@creative-kit/react-data-grid';
import DeleteIcon from '@mui/icons-material/RemoveCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Row } from 'logic/row';

export const SourceRowEditControls = observer((props: FormatterProps<Row>) => {
  const { sourcesStore } = useStore();
  const source = sourcesStore.sources[props.row.sourceId];
  const isModified = source.isRowModified(props.row.id);

  if (isModified) {
    return (
      <IconButton
        onClick={() => {
          source.revertRowModifications(props.row.id);
        }}
        size="small"
      >
        <RefreshIcon />
      </IconButton>
    );
  }

  return (
    <IconButton
      onClick={() => {
        source.deleteRow(props.row.id);
      }}
      size="small"
    >
      <DeleteIcon />
    </IconButton>
  );
});
