import React, { useCallback, useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import { Chip, Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import { useDrag, useDrop } from 'react-dnd';
import AutosizeInput from 'react-input-autosize';

import { useStore } from 'logic';
import { ItemTypes } from 'logic/dragdrop';
import { Field, isAppDateField, isAppTextField } from '@creative-kit/shared';

import { CurrentDateFormatMenu } from './CurrentDateFormatMenu';
import { useFieldLabel } from './useFieldLabel';

interface StyleProps {
  isCustomText: boolean;
  draggedOver?: 'before' | 'after';
  color: string;
}
const useStyles = makeStyles((theme) => ({
  root: (p: StyleProps) => ({
    padding: theme.spacing(0.5),
    position: 'relative',
    opacity: 0.999,
    '&::before': {
      content: '""',
      opacity: p.draggedOver === 'before' ? 1 : 0,
      position: 'absolute',
      top: theme.spacing(0.5),
      bottom: theme.spacing(0.5),
      left: 0,
      width: 1,
      backgroundColor: theme.palette.primary.main,
    },
    '&::after': {
      content: '""',
      opacity: p.draggedOver === 'after' ? 1 : 0,
      position: 'absolute',
      top: theme.spacing(0.5),
      bottom: theme.spacing(0.5),
      right: 0,
      width: 1,
      backgroundColor: theme.palette.primary.main,
    },
  }),
  filler: {
    flex: 1,
  },
  input: {
    border: 'none',
    outline: 'none',
    background: 'transparent',
    fontSize: 'inherit',
    color: theme.palette.text.primary,
  },
  chipRoot: (p: StyleProps) => ({
    cursor: p.isCustomText ? 'text' : 'pointer',
    backgroundColor: p.color,
    color: theme.palette.getContrastText(p.color),
    fontWeight: 500,
  }),
  chipIcon: (p: StyleProps) => ({
    color: theme.palette.getContrastText(p.color),
  }),
}));

type FormulaFieldProps =
  | {
      field: Field | null;
      index: number;
      readOnly: true;
      setTextFieldValue?: never;
      removeField?: never;
      dragField?: never;
      dropField?: never;
      cancelDragField?: never;
      reorderField?: never;
      dragStatus?: never;
    }
  | {
      field: Field | null;
      index: number;
      readOnly?: false;
      setTextFieldValue: (idx: number, val: string) => void;
      removeField: (idx: number) => void;
      dragField: (idx: number, position: 'before' | 'after') => void;
      dropField: (field: Field) => void;
      cancelDragField: () => void;
      reorderField: (field: Field) => void;
      dragStatus: {
        fieldIdx?: number;
        position?: 'before' | 'after';
      };
    };

export const FormulaField = observer(
  ({
    field,
    index,
    readOnly,
    setTextFieldValue,
    removeField,
    dragField,
    dropField,
    cancelDragField,
    reorderField,
    dragStatus,
  }: FormulaFieldProps) => {
    const {
      sessionsStore: { currentSession },
    } = useStore();
    const getFieldLabel = useFieldLabel();
    const {
      data: { modules },
    } = currentSession!;

    const rootRef = useRef<HTMLDivElement>(null);
    const [, drop] = useDrop<{ field: Field }, void, {}>({
      accept: ItemTypes.AvailableFormulaField,
      hover: (_item, monitor) => {
        if (!rootRef.current) {
          return;
        }

        const hoverRect = rootRef.current.getBoundingClientRect();
        const clientOffset = monitor.getClientOffset()!;
        const midPoint = (hoverRect.right - hoverRect.left) / 2;
        const horizontalOffset = clientOffset.x - hoverRect.left;
        const status = horizontalOffset < midPoint ? 'before' : 'after';
        dragField?.(index, status);
      },
      drop: (item) => {
        dropField?.(item.field);
      },
    });
    const [, dropComponent] = useDrop<{ field: Field }, void, {}>({
      accept: ItemTypes.FormulaField,
      hover: (_item, monitor) => {
        if (!rootRef.current) {
          return;
        }

        const hoverRect = rootRef.current.getBoundingClientRect();
        const clientOffset = monitor.getClientOffset()!;
        const midPoint = (hoverRect.right - hoverRect.left) / 2;
        const horizontalOffset = clientOffset.x - hoverRect.left;
        const status = horizontalOffset < midPoint ? 'before' : 'after';
        dragField?.(index, status);
      },
      drop: (item) => {
        reorderField?.(item.field);
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.FormulaField,
      item: {
        field,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (_item, monitor) => {
        if (!monitor.didDrop()) {
          cancelDragField?.();
        }
      },
    });

    const isCustomText = !!(field && isAppTextField(field));

    const dropped = dropComponent(drop(rootRef));
    if (!isCustomText && field !== null) {
      drag(dropped);
    }

    const draggedOver = dragStatus?.fieldIdx === index ? dragStatus?.position : undefined;

    const module = field?.type === 'module' ? modules[field.moduleId] : undefined;

    const styles = useStyles({
      isCustomText,
      draggedOver,
      color: module?.color || '#999',
    });

    const handleTxtValueChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextFieldValue?.(index, e.target.value);
      },
      [setTextFieldValue, index]
    );
    const deleteTxtValue = useCallback(() => {
      setTextFieldValue?.(index, '');
    }, [setTextFieldValue, index]);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const inputRefFn = useCallback((ref: HTMLInputElement | null) => {
      inputRef.current = ref;
    }, []);
    const focusInput = useCallback(() => {
      inputRef.current?.focus();
    }, []);
    const deleteField = useCallback(() => {
      removeField?.(index);
    }, [removeField, index]);

    useLayoutEffect(() => {
      if (inputRef.current && index === 0) {
        inputRef.current.focus();
      }
    }, [index]);

    if (field === null) {
      return <div ref={rootRef} className={clsx(styles.root, styles.filler)} />;
    }

    if (isAppTextField(field)) {
      const onDelete = field.text && !readOnly ? deleteTxtValue : undefined;
      return (
        <div ref={rootRef} className={styles.root}>
          <Chip
            size="small"
            classes={{
              root: styles.chipRoot,
              deleteIcon: styles.chipIcon,
            }}
            onClick={focusInput}
            onDelete={onDelete}
            label={
              <AutosizeInput
                inputRef={inputRefFn}
                inputClassName={styles.input}
                value={field.text}
                onChange={handleTxtValueChange}
                disabled={readOnly}
              />
            }
          />
        </div>
      );
    }

    const chipIcon = isAppDateField(field) ? <CurrentDateFormatMenu /> : undefined;
    const label = getFieldLabel(field);

    return (
      <Collapse in={!isDragging} orientation="horizontal">
        <div ref={rootRef} className={styles.root}>
          <Chip
            icon={chipIcon}
            classes={{
              root: styles.chipRoot,
              deleteIcon: styles.chipIcon,
            }}
            size="small"
            label={label}
            onDelete={!readOnly ? deleteField : undefined}
          />
        </div>
      </Collapse>
    );
  }
);
