import { QueueSearchSetting } from '@creative-kit/shared';
import type { RootStore } from 'logic';
import { action, computed, makeObservable, observable } from 'mobx';

export class UiCardSearchStore {
  currentSearch?: string;
  isSettingsMenuOpen = false;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      currentSearch: observable,
      setCurrentSearch: action.bound,
      searchTerms: computed,
      hasSearch: computed,
      isSettingsMenuOpen: observable,
      openSettingsMenu: action.bound,
      closeSettingsMenu: action.bound,
    });

    this.rootStore = rootStore;
  }

  // comma separated searches break out into multiple terms to search for
  get searchTerms() {
    const terms = this.currentSearch?.split(',');
    return terms?.map((t) => t.toLowerCase().trim()) ?? [];
  }

  get hasSearch() {
    return !!this.currentSearch?.trim();
  }

  setCurrentSearch(newSearch?: string) {
    this.currentSearch = newSearch;
  }

  openSettingsMenu() {
    this.isSettingsMenuOpen = true;
  }

  closeSettingsMenu() {
    this.isSettingsMenuOpen = false;
  }

  get setting(): QueueSearchSetting {
    return (
      this.rootStore.userSettingsStore.userSettings.queueSearch || {
        type: 'all',
        messages: false,
      }
    );
  }

  changeSearchSettingType = (type: string) => {
    if (type === 'all' || type === 'visible') {
      this.updateSearchSetting({
        type,
        messages: false,
      });
    }
  };

  changeSearchSettingMessages = (enabled: boolean) => {
    if (this.setting.type === 'all' || this.setting.type === 'visible') {
      this.updateSearchSetting({
        ...this.setting,
        messages: enabled,
      });
    }
  };

  changeSearchSettingModule = (moduleId: string) => {
    this.updateSearchSetting({
      type: 'module',
      moduleId,
      fieldIds: [],
    });
  };

  toggleSearchSettingModuleField = (fieldId: string) => {
    if (this.setting.type === 'module') {
      if (this.setting.fieldIds.includes(fieldId)) {
        this.updateSearchSetting({
          ...this.setting,
          fieldIds: this.setting.fieldIds.filter((f) => f !== fieldId),
        });
      } else {
        this.updateSearchSetting({
          ...this.setting,
          fieldIds: this.setting.fieldIds.concat(fieldId),
        });
      }
    }
  };

  private updateSearchSetting(newSetting: QueueSearchSetting) {
    this.rootStore.userSettingsStore.modifyUserSettings({
      queueSearch: newSetting,
    });
  }
}
