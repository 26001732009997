import { FieldValue, db as firestoreDb } from 'firebase-modules';
import { action, computed, makeObservable, observable } from 'mobx';

import { monotonicFactory, ulid } from 'ulid';
import {
  Field,
  FilenameSettings,
  FirestoreSession,
  moduleColors,
  BaseSession,
  safeBatch,
  FirestoreSource,
  RowData,
  SessionPermissions,
  FirestoreCard,
  waitFor,
  SessionModuleDisplaySettings,
} from '@creative-kit/shared';
import type firebase from 'firebase';
import type { RootStore } from '.';
import { db } from './data';
import { Card } from './card';

export class Session extends BaseSession {
  protected rootStore: RootStore;
  private ref;
  private commentsRef;
  cardListRef;

  constructor(id: string, data: FirestoreSession, rootStore: RootStore) {
    super(id, data, rootStore);

    makeObservable<this>(this, {
      data: observable.struct,
      setModuleIds: action,
      updateData: action,
      modules: computed,
      automaticFilenameVersioning: computed,
      automaticCardTitleSuffix: computed,
      visibleModules: computed,
      inProgressCardIds: computed,
      currentCardIdx: computed,
      currentCardId: computed,
      upNextCardIdx: computed,
      onDeckCardIdx: computed,
      doneCount: computed,
      doneCardIds: computed,
      doneTotalCount: computed,
      doneVisibleCount: computed,
      inProgressCount: computed,
      inProgressTotalCount: computed,
      inProgressVisibleCount: computed,
      cardList: computed,
      numFilenamesNeedingAttention: computed,
      isFilenameVisible: computed,
      getModuleDisplaySettings: action,
      setModuleDisplaySettings: action,
    });

    this.rootStore = rootStore;
    this.ref = db.sessions.doc(id);
    this.commentsRef = db.sessionComments(id);
    this.cardListRef = db.sessionCards(id);
  }

  get sessionRef() {
    return this.ref;
  }

  updateData(newData: FirestoreSession) {
    this.data = newData;
  }

  get modules() {
    return this.data.moduleIds.map((moduleId) => this.data.modules[moduleId]);
  }

  get hiddenModules() {
    return this.modules.filter((module) => !this.getIsModuleVisible(module.id));
  }

  get visibleModules() {
    return this.modules.filter((module) => this.getIsModuleVisible(module.id));
  }

  getModuleDisplaySettings(moduleId: string): SessionModuleDisplaySettings {
    return this.data.moduleDisplaySettings && this.data.moduleDisplaySettings[moduleId];
  }

  setModuleDisplaySettings(moduleId: string, settings: SessionModuleDisplaySettings) {
    this.ref.update({
      [`moduleDisplaySettings.${moduleId}`]: settings,
    });
  }

  public getVisible(list: string[]) {
    const {
      cardsStore: { cards },
    } = this.rootStore;

    return list.map((cardId) => cards[cardId]).filter((card) => card.isVisible);
  }

  private getVisibleCount(list: string[]) {
    return this.getVisible(list).length;
  }

  private getDisplayCount(list: string[]) {
    const numCards = list.length;

    if (!this.isFilteringInProgress) {
      return `${numCards}`;
    }

    const numVisibleCards = this.getVisibleCount(list);

    return `${numVisibleCards}/${numCards}`;
  }

  get currentCardId() {
    return this.data.cardIds[this.currentCardIdx];
  }

  get upNextCardIdx() {
    return this.currentCardIdx + 1;
  }

  get onDeckCardIdx() {
    return this.currentCardIdx + 2;
  }

  get inProgressVisibleCardIds() {
    return this.getVisible(this.inProgressCardIds).map((s) => s.id);
  }

  get inProgressVisibleCount() {
    return this.getVisibleCount(this.inProgressCardIds);
  }

  get isFilteringInProgress() {
    return (
      this.rootStore.uiCardSearchStore.hasSearch ||
      this.rootStore.uiModulePowerColumnStore.hasRowSelection
    );
  }

  get doneVisibleCardIds() {
    return this.getVisible(this.doneCardIds).map((s) => s.id);
  }

  get doneVisibleCount() {
    return this.getVisibleCount(this.doneCardIds);
  }

  get inProgressTotalCount() {
    return this.inProgressCardIds.length;
  }

  get doneTotalCount() {
    return this.doneCardIds.length;
  }

  get inProgressCount() {
    return this.getDisplayCount(this.inProgressCardIds);
  }

  get doneCount() {
    return this.getDisplayCount(this.doneCardIds);
  }

  get numFilenamesNeedingAttention() {
    return (this.cardList as Card[]).filter((s) => s.filenameNeedsAttention).length;
  }

  get isFilenameVisible() {
    const { visibility } = this.data;
    return visibility ? visibility?.hideFilename === false : true;
  }

  get includeFilenameInQueueSearch() {
    const { setting } = this.rootStore.uiCardSearchStore;
    return setting.type === 'all' || (setting.type === 'visible' && this.isFilenameVisible);
  }

  get includeTitleInQueueSearch() {
    const { setting } = this.rootStore.uiCardSearchStore;
    return setting.type === 'all' || setting.type === 'visible';
  }

  get includeMessagesInQueueSearch() {
    const { setting } = this.rootStore.uiCardSearchStore;
    return (setting.type === 'all' || setting.type === 'visible') && setting.messages;
  }

  getIsModuleVisible = (id: string) =>
    Boolean(this.data.visibility?.hiddenModuleIds?.includes(id)) === false;

  // Back-end operations
  acceptAllFilenames = () => {
    (this.cardList as Card[]).forEach((s) => s.acceptNewFilename());
  };

  ignoreAllFilenames = () => {
    (this.cardList as Card[]).forEach((s) => s.ignoreNewFilename());
  };

  setName = (newName: string) =>
    this.ref.set(
      {
        name: newName.trim(),
      },
      {
        merge: true,
      }
    );

  setModuleIds(moduleIds: string[]) {
    return this.ref.set(
      {
        moduleIds,
      },
      { merge: true }
    );
  }

  setSourceIds(sourceIds: string[]) {
    return this.ref.set(
      {
        sourceIds,
      },
      { merge: true }
    );
  }

  createComment(cardId: string, text: string) {
    const { user } = this.rootStore.authStore;
    if (!user) {
      return;
    }

    if (text) {
      this.commentsRef.add({
        cardId,
        text,
        userId: user.uid,
        avatarUrl: user.avatarUrl,
        displayName: user.displayName || 'anonymous',
        createdAt: FieldValue.serverTimestamp() as unknown as Date,
        updatedAt: FieldValue.serverTimestamp() as unknown as Date,
      });
    }
  }

  private addConnectionOnly = async ({
    rowIds,
    moduleId,
    card,
    position,
    afterCardId,
  }: {
    rowIds: string | string[];
    moduleId: string;
    card?: Card;
    position?: 'start' | 'end';
    afterCardId?: string;
  }) => {
    if (!this.rootStore.authStore.user) {
      return;
    }
    const module = this.data.modules[moduleId];
    const newConnectionValue = module.multi
      ? FieldValue.arrayUnion(...(Array.isArray(rowIds) ? rowIds : [rowIds]))
      : Array.isArray(rowIds)
      ? rowIds[0]
      : rowIds;
    if (card) {
      const existingConnections = card.data.connections[moduleId];
      await this.cardListRef.doc(card.id).update({
        [`connections.${moduleId}`]: newConnectionValue,
      });

      return async () => {
        await this.cardListRef.doc(card.id).update({
          [`connections.${moduleId}`]: module.multi
            ? FieldValue.arrayRemove(rowIds)
            : typeof existingConnections === 'string'
            ? existingConnections
            : FieldValue.delete(),
        });
      };
    }
    let cardRef: firebase.firestore.DocumentReference<FirestoreCard> | undefined;
    await firestoreDb.runTransaction(async (transaction) => {
      const newCard = await transaction.get(this.cardListRef.doc());
      cardRef = newCard.ref;
      transaction.set(cardRef, {
        createdAt: FieldValue.serverTimestamp() as unknown as Date,
        createdBy: {
          userId: this.rootStore.authStore.user?.uid ?? '',
          email: this.rootStore.authStore.user?.email ?? '',
        },
        connections: {
          [moduleId]: newConnectionValue as string,
        },
      });

      // Decide where to place the new card
      const cardIdsCopy = [...this.data.cardIds];

      if (position === 'end') {
        await transaction.set(
          this.ref,
          {
            cardIds: FieldValue.arrayUnion(cardRef.id) as unknown as string[],
          },
          { merge: true }
        );
      } else if (position === 'start') {
        cardIdsCopy.splice(this.currentCardIdx, 0, cardRef.id);
        await transaction.set(
          this.ref,
          {
            cardIds: cardIdsCopy,
          },
          { merge: true }
        );
      } else if (afterCardId) {
        const idx = this.data.cardIds.indexOf(afterCardId);
        cardIdsCopy.splice(idx + 1, 0, cardRef.id);
        await transaction.set(
          this.ref,
          {
            cardIds: cardIdsCopy,
          },
          { merge: true }
        );
      }
    });

    return async () => {
      if (cardRef) {
        const undoBatch = firestoreDb.batch();
        undoBatch.delete(cardRef);
        undoBatch.set(
          this.ref,
          {
            cardIds: FieldValue.arrayRemove(cardRef.id) as unknown as string[],
          },
          { merge: true }
        );

        await undoBatch.commit();
      }
    };
  };

  addConnection = async ({
    rowIds,
    moduleId,
    card,
    position,
    afterCardId,
  }: {
    rowIds: string | string[];
    moduleId: string;
    card?: Card;
    position?: 'start' | 'end';
    afterCardId?: string;
  }) => {
    const undo = await this.addConnectionOnly({
      rowIds,
      moduleId,
      card,
      position,
      afterCardId,
    });
    if (undo !== undefined) {
      const module = this.data.modules[moduleId];
      const undoCopy = card
        ? `Item from ${module.name} connected to card #${card.cardNumber}`
        : 'New Card Created';
      this.rootStore.uiSnackbarStore.setUndoDetails(undo, undoCopy);
    }
  };

  batchRemoveConnections = async ({
    selectedCards,
    moduleId,
    rowId,
  }: {
    selectedCards: Card[];
    rowId: string;
    moduleId: string;
  }) => {
    const undos: Array<() => Promise<void>> = [];

    await Promise.all(
      selectedCards.map(async (card) => {
        card.setModificationState('updating');
        const undo = await card.removeRowOnly(moduleId, rowId);
        card.resetMotificationState();
        if (undo) {
          undos.push(undo);
        }
      })
    );

    if (undos.length) {
      this.rootStore.uiSnackbarStore.setUndoDetails(
        () =>
          undos.forEach(async (undo) => {
            await undo();
          }),
        // Use undo length because some cards might not have the connection
        `${selectedCards.length} cards updated`
      );
    }
  };

  batchAddConnections = async ({
    selectedCards,
    ...rest
  }: {
    selectedCards: Card[];
    rowIds: string | string[];
    moduleId: string;
    position?: 'start' | 'end';
    afterCardId?: string;
  }) => {
    const undos: Array<() => Promise<void>> = [];
    selectedCards.forEach(async (card) => {
      card.setModificationState('updating');
      const undo = await this.addConnectionOnly({
        ...rest,
        card,
      });
      card.resetMotificationState();
      if (undo) {
        undos.push(undo);
      }
    });
    this.rootStore.uiSnackbarStore.setUndoDetails(
      () =>
        undos.forEach(async (undo) => {
          await undo();
        }),
      `${selectedCards.length} cards updated`
    );
  };

  addEmptyConnectionToCard = async (card: Card, moduleId: string, fieldId?: string) => {
    const module = this.data.modules[moduleId];
    const source = this.rootStore.sourcesStore.sources[module.sourceId];
    const id = source.addEmptyRow();
    this?.addConnection({
      rowIds: id,
      moduleId: module.id,
      card,
    });
    await waitFor(() => !!source.rows[id]);
    this.rootStore.uiModuleSourceEditor
      .getRowEditor(module.id, id)
      ?.startEditing(fieldId ?? module.fields[0]);
  };

  upsertSourceToSession = async ({
    source,
    rows,
    sourceId,
    automaticModuleCreation,
  }: {
    source: FirestoreSource;
    rows: RowData[];
    sourceId?: string;
    automaticModuleCreation?: boolean;
  }) => {
    // If sourceId, update source with new data. If no source, create new one.
    const sourceRef = sourceId
      ? db.sessionSources(this.id).doc(sourceId)
      : db.sessionSources(this.id).doc();

    await sourceRef.set({
      fieldIds: source.fieldIds,
      fieldNames: source.fieldNames,
      name: source.name,
    });

    if (source.type) {
      await sourceRef.set(
        {
          type: source.type,
        },
        { merge: true }
      );
    }

    const ulidFactory = monotonicFactory();
    const rowsCollection = db.sessionSourceRows(this.id, sourceRef.id);
    // Add rows to source in batches
    await safeBatch(firestoreDb, rows, (batch, row) => {
      const rowDoc = rowsCollection.doc(ulidFactory());
      batch.set(rowDoc, { fieldValues: row });
    });

    // Add source to session's sources
    await this.ref.set(
      {
        sourceIds: FieldValue.arrayUnion(sourceRef.id) as unknown as string[],
      },
      { merge: true }
    );

    if (automaticModuleCreation ?? this.data.automaticModuleCreation) {
      const moduleId = ulid();
      const randomColor = moduleColors[Math.floor(Math.random() * moduleColors.length)];
      this.upsertModule({
        moduleId,
        name: source.name,
        sourceId: sourceRef.id,
        fields: source.fieldIds,
        multi: source.type === 'image',
        table: true,
        color: randomColor,
      });
    }

    return sourceRef.id;
  };

  deleteSource = async ({ sourceId }: { sourceId: string }) => {
    const batch = firestoreDb.batch();

    const sourceRef = db.sessionSources(this.id).doc(sourceId);
    batch.delete(sourceRef);

    batch.set(
      this.ref,
      {
        sourceIds: FieldValue.arrayRemove(sourceId) as unknown as string[],
      },
      { merge: true }
    );

    // Delete all modules that use the deleted source
    for (const moduleId of Object.keys(this.data.modules)) {
      const module = this.data.modules[moduleId];
      if (module.sourceId === sourceId) {
        this.deleteModule({ moduleId: module.id });
      }
    }
    return batch.commit();
  };

  updateSource = ({ sourceId, name }: { sourceId: string; name: string }) => {
    const sourceRef = db.sessionSources(this.id).doc(sourceId);
    sourceRef.set(
      {
        name,
      },
      { merge: true }
    );
  };

  // Add or update module for current session
  upsertModule = ({
    moduleId,
    name,
    sourceId,
    multi,
    table,
    color,
    fields,
  }: {
    moduleId: string;
    name: string;
    sourceId: string;
    multi: boolean;
    table: boolean;
    color: string;
    fields?: string[];
  }) => {
    const source = this.rootStore.sourcesStore.sources[sourceId];
    const module = this.data.modules[moduleId];

    if (module && module.multi !== true && multi === true) {
      this.makeModuleConnectionsMulti({ moduleId });
    }

    if (source) {
      this.ref.update({
        [`modules.${moduleId}`]: {
          id: moduleId,
          name,
          sourceId,
          multi,
          table,
          color,
          fields: fields ?? source.data.fieldIds,
        },
        moduleIds: FieldValue.arrayUnion(moduleId),
      });
    }
  };

  deleteModule = ({ moduleId }: { moduleId: string }) => {
    // After deleting module remove, lingering data from cardList
    this.removeModuleConnectionsFromCards({ moduleId });

    // Filter out any items in formulas that might be dependent on module
    const updatedFilenameFormula = this.data.filenameFormula.filter((f) => {
      if (f.type !== 'module') {
        return true;
      }
      return f.type === 'module' && f.moduleId !== moduleId;
    });
    this.saveFilename(updatedFilenameFormula, this.data.filenameSettings);

    const updatedCardTitleFormula = this.data.cardTitleFormula.filter((f) => {
      if (f.type !== 'module') {
        return true;
      }
      return f.type === 'module' && f.moduleId !== moduleId;
    });
    this.saveCardTitleFormula(updatedCardTitleFormula);

    this.ref.update({
      [`modules.${moduleId}`]: FieldValue.delete(),
      moduleIds: FieldValue.arrayRemove(moduleId),
    });
  };

  // when changing module from single to multi, make the connections on cards into an array
  makeModuleConnectionsMulti = ({ moduleId }: { moduleId: string }) => {
    const batch = firestoreDb.batch();
    this.data.cardIds.forEach((cardId) => {
      const card = this.rootStore.cardsStore.cards[cardId];
      if (card) {
        const moduleConnections = card.getModuleConnections(moduleId);
        if (moduleConnections?.length > 0) {
          const cardRef = this.cardListRef.doc(cardId);
          batch.update(cardRef, {
            [`connections.${moduleId}`]: [moduleConnections[0].id],
          });
        }
      }
    });
    batch.commit();
  };

  // When changing Module source or type from Multi -> Single, we need to remove existing connections from all cards
  removeModuleConnectionsFromCards = ({ moduleId }: { moduleId: string }) => {
    const batch = firestoreDb.batch();
    this.data.cardIds.forEach((cardId) => {
      const cardRef = this.cardListRef.doc(cardId);
      batch.update(cardRef, {
        [`connections.${moduleId}`]: FieldValue.delete(),
      });
    });
    batch.commit();
  };

  switchModuleField = ({ moduleId, field }: { moduleId: string; field: string }) => {
    const module = this.data.modules[moduleId];
    if (module.fields.includes(field)) {
      this.ref.update({
        [`modules.${moduleId}.fields`]: FieldValue.arrayRemove(field),
      });
    } else {
      this.ref.update({
        [`modules.${moduleId}.fields`]: FieldValue.arrayUnion(field),
      });
    }
  };

  switchFilenameVisibility = (newValue: boolean) => {
    this.ref.update({
      'visibility.hideFilename': !newValue,
    });
  };

  toggleModuleVisibility = (id: string) => {
    if (this.getIsModuleVisible(id)) {
      this.ref.update({
        'visibility.hiddenModuleIds': FieldValue.arrayUnion(id),
      });
    } else {
      this.ref.update({
        'visibility.hiddenModuleIds': FieldValue.arrayRemove(id),
      });
    }
  };

  async updatePublicSessionAccessLevel(level: SessionPermissions) {
    const messages = {
      [SessionPermissions.VIEW]: 'Anyone with the link can now view your session',
      [SessionPermissions.EDIT]: 'Anyone with the link can edit now your session',
      [SessionPermissions.PRIVATE]: 'Your session is now private',
    };
    await this.ref.set(
      {
        publicAccessLevel: level,
      },
      { merge: true }
    );
    this.rootStore.uiSnackbarStore.setSnackbarDetails({
      description: messages[level],
    });
  }

  get automaticFilenameVersioning() {
    return this.data.automaticFilenameVersioning ?? true;
  }

  toggleAutomaticFilenameVersioning = () => {
    this.ref.set(
      {
        automaticFilenameVersioning: !this.data.automaticFilenameVersioning,
      },
      {
        merge: true,
      }
    );
  };

  get automaticCardTitleSuffix() {
    return this.data.automaticCardTitleSuffix ?? true;
  }

  toggleAutomaticCardTitleSuffix = () => {
    this.ref.set(
      {
        automaticCardTitleSuffix: !this.data.automaticCardTitleSuffix,
      },
      {
        merge: true,
      }
    );
  };

  toggleAutomaticModuleCreation = () => {
    this.ref.set(
      {
        automaticModuleCreation: !this.data.automaticModuleCreation,
      },
      {
        merge: true,
      }
    );
  };

  saveCardTitleFormula(newFormula: Field[]) {
    this.ref.set(
      {
        cardTitleFormula: newFormula,
      },
      {
        merge: true,
      }
    );
  }

  saveFilename(newFormula: Field[], settings: FilenameSettings) {
    this.ref.set(
      {
        filenameFormula: newFormula,
        filenameSettings: settings,
      },
      {
        merge: true,
      }
    );
  }
}
