import { observer } from 'mobx-react-lite';
import { Divider, IconButton, ListItemIcon, ListSubheader, Menu, MenuItem } from '@mui/material';
import { Card } from 'logic/card';
import { useStore } from 'logic';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import DoneIcon from '@mui/icons-material/DoneOutlineOutlined';
import DuplicateIcon from '@mui/icons-material/FileCopy';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import HistoryIcon from '@mui/icons-material/History';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenIcon from '@mui/icons-material/OpenInFull';
import { ButtonPopover } from 'ui/ButtonPopover';
import { CardStatus } from '@creative-kit/shared';
import { useRef, useState } from 'react';
import { useKBar } from 'kbar';
import { StatusDot } from './CardStatusDot';

interface QueueCardContextMenuProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  card: Card;
}

export const QueueCardContextMenu = observer(
  ({ card, open, setOpen }: QueueCardContextMenuProps) => {
    const {
      uiActiveSessionStore: { selectedCardIds, isCardSelected },
      uiCardTitleStore: { openDialog: openCardTitleDialog },
      uiFilenameStore: { openDialog: openFilenameDialog },
      uiSnackbarStore: { setSnackbarDetails },
      cardsStore: { cards },
      sessionCardActions: {
        removeCard,
        completeCard,
        promoteCardAndQueueCurrent,
        promoteCardToOnDeck,
        promoteCardToUpNext,
        moveCardToLast,
        moveCardToIndex,
        markCardInProgress,
        duplicateCard,
      },
      sessionsStore: { currentSession },
    } = useStore();

    const kbar = useKBar();
    const statusListEl = useRef<HTMLLIElement | null>(null);
    const [openStatusList, setOpenStatusList] = useState(false);

    const { inProgressCardIds } = currentSession!;

    const isCompleted = card.status === CardStatus.Completed;

    /**
     * If you have multiple cards selected and this is one of them
     * open the batch actions in kbar instead of letting user act on one card
     */
    if (isCardSelected(card.id) && selectedCardIds.length > 1) {
      return (
        <IconButton size="small" onClick={kbar.query.toggle}>
          <MoreIcon />
        </IconButton>
      );
    }

    return (
      <ButtonPopover
        title="Actions"
        open={open}
        requestOpen={() => setOpen(true)}
        requestClose={() => setOpen(false)}
        icon={() => null}
        button={
          <IconButton size="small">
            <MoreIcon />
          </IconButton>
        }
      >
        {(close) => (
          <>
            <ListSubheader>Actions</ListSubheader>
            {isCompleted ? (
              <MenuItem
                onClick={() => {
                  close();
                  markCardInProgress(card.id);
                }}
              >
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                Move to In Progress
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  close();
                  completeCard(card.id);
                }}
              >
                <ListItemIcon>
                  <DoneIcon />
                </ListItemIcon>
                Move to Done
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                close();
                duplicateCard(card.id);
              }}
            >
              <ListItemIcon>
                <DuplicateIcon />
              </ListItemIcon>
              Duplicate
            </MenuItem>
            <MenuItem
              onClick={() => {
                close();
                card.focusCard();
              }}
            >
              <ListItemIcon>
                <OpenIcon />
              </ListItemIcon>
              {card.isFocused ? 'Close' : 'Open'} Asset
            </MenuItem>
            <Divider />
            <ListSubheader>Copy To Clipboard</ListSubheader>
            <MenuItem
              onClick={() => {
                close();
                navigator.clipboard.writeText(card.permalink);
              }}
            >
              URL
            </MenuItem>
            <MenuItem
              disabled={card.filenameInfo.errors.length > 0}
              onClick={() => {
                close();
                navigator.clipboard.writeText(card.filename);
              }}
            >
              Filename
            </MenuItem>
            <MenuItem
              disabled={card.labelInfo.errors.length > 0}
              onClick={() => {
                close();
                navigator.clipboard.writeText(card.mainLabel);
              }}
            >
              Title
            </MenuItem>
            <Divider />
            <ListSubheader>Move To</ListSubheader>
            <MenuItem
              disabled={isCompleted || card.status === CardStatus.Current}
              onClick={() => {
                close();
                promoteCardAndQueueCurrent(card.id);
                setSnackbarDetails({ description: 'Asset Moved to First' });
              }}
            >
              <ListItemIcon>
                <StatusDot status={CardStatus.Current} />
              </ListItemIcon>
              First
            </MenuItem>
            <MenuItem
              disabled={isCompleted || card.status === CardStatus.Next}
              onClick={() => {
                close();
                promoteCardToUpNext(card.id);
                setSnackbarDetails({ description: 'Asset Moved to Up Next' });
              }}
            >
              <ListItemIcon>
                <StatusDot status={CardStatus.Next} />
              </ListItemIcon>
              Up Next
            </MenuItem>
            <MenuItem
              disabled={isCompleted || card.status === CardStatus.OnDeck}
              onClick={() => {
                close();
                promoteCardToOnDeck(card.id);
                setSnackbarDetails({ description: 'Asset Moved to On Deck' });
              }}
            >
              <ListItemIcon>
                <StatusDot status={CardStatus.OnDeck} />
              </ListItemIcon>
              On Deck
            </MenuItem>
            <MenuItem
              disabled={isCompleted}
              onClick={() => {
                close();
                moveCardToLast(card.id);
                setSnackbarDetails({ description: 'Asset Moved to Last' });
              }}
            >
              <ListItemIcon>
                <StatusDot status={CardStatus.Upcoming} />
              </ListItemIcon>
              Last
            </MenuItem>
            <MenuItem
              disabled={isCompleted}
              onClick={() => setOpenStatusList(true)}
              ref={statusListEl}
              sx={{ justifyContent: 'space-between' }}
            >
              Specific Position
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
            </MenuItem>
            <Menu
              open={openStatusList}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              anchorEl={statusListEl.current}
              onClose={() => setOpenStatusList(false)}
            >
              {inProgressCardIds?.map((id) => {
                const currentCard = cards[id];
                return (
                  <MenuItem
                    key={id}
                    onClick={() => {
                      close();
                      moveCardToIndex(card.id, currentCard.cardIdx);
                      setSnackbarDetails({
                        description: `Card Moved to #${currentCard.cardNumber}`,
                      });
                    }}
                  >
                    #{currentCard.cardNumber}
                  </MenuItem>
                );
              })}
            </Menu>
            <Divider />
            <ListSubheader>Asset Settings</ListSubheader>
            <MenuItem
              onClick={() => {
                close();
                openCardTitleDialog();
              }}
            >
              Title Settings
            </MenuItem>
            <MenuItem
              onClick={() => {
                close();
                openFilenameDialog(card);
              }}
            >
              Filename Settings
            </MenuItem>
            <Divider />
            {/* trash can icon */}
            <MenuItem
              onClick={() => {
                close();
                removeCard(card.id);
              }}
              sx={{ color: (t) => t.palette.error.main }}
            >
              <ListItemIcon>
                <DeleteIcon color="error" />
              </ListItemIcon>
              Delete
            </MenuItem>
          </>
        )}
      </ButtonPopover>
    );
  }
);
