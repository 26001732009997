import { action, makeObservable, observable, reaction } from 'mobx';
import type ft from 'firebase';

import { FirestoreCard } from '@creative-kit/shared';
import type { RootStore } from '.';
import { Card } from './card';
import { db } from './data';

export class CardsStore {
  private stopListeningToCards = () => {};
  private rootStore: RootStore;
  cards: Record<string, Card> = {};

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      cards: observable,
      updateCards: action.bound,
      resetCards: action,
    });

    this.rootStore = rootStore;
  }

  init() {
    reaction(() => this.rootStore.sessionsStore.currentSession, this.watchCards, {
      fireImmediately: true,
    });
  }

  watchCards = () => {
    const { currentSession } = this.rootStore.sessionsStore;
    this.stopListeningToCards();
    this.resetCards();
    if (currentSession) {
      this.stopListeningToCards = db
        .sessionCards(currentSession.id)
        .onSnapshot(this.updateCards.bind(this, currentSession.id));
    }
  };

  resetCards() {
    for (const key of Object.keys(this.cards)) {
      delete this.cards[key];
    }
  }

  updateCards(sessionId: string, snapshot: ft.firestore.QuerySnapshot<FirestoreCard>) {
    snapshot.docChanges().forEach((change) => {
      const changeType = change.type;
      const changeDoc = change.doc;
      if (changeType === 'added') {
        this.cards[changeDoc.id] = new Card(
          changeDoc.id,
          sessionId,
          changeDoc.data(),
          this.rootStore
        );
      } else if (change.type === 'modified') {
        this.cards[changeDoc.id].updateData(changeDoc.data());
      } else {
        delete this.cards[changeDoc.id];
      }
    });

    this.rootStore.uiCardsStore.initializeCards(Object.values(this.cards));
  }
}
