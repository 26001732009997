import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  NativeSelect,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { ButtonPopover } from 'ui/ButtonPopover';
import { observer } from 'mobx-react-lite';
import { useStore } from 'logic';
import { forwardRef, useState } from 'react';
import { FieldDescription } from 'logic/source';

interface QueueSearchSettingIconProps {
  button?: boolean;
  onClick?: () => void;
}

export const QueueSearchSettingIcon = observer(
  forwardRef(({ button, onClick }: QueueSearchSettingIconProps, ref) => {
    const {
      uiCardSearchStore: { setting },
    } = useStore();
    const badgeContent = setting.type === 'all' ? 'A' : setting.type === 'visible' ? 'V' : 'M';
    return (
      <Box onClick={onClick} ref={ref} sx={{ position: 'relative', display: 'flex' }}>
        {button ? (
          <IconButton size="small">
            <ManageSearchIcon />
          </IconButton>
        ) : (
          <ManageSearchIcon fontSize="small" />
        )}
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: -1,
            right: -1,
            fontSize: 10,
            fontWeight: 'bold',
            width: 14,
            height: 14,
            backgroundColor: theme.palette.success.main,
            color: theme.palette.getContrastText(theme.palette.success.main),
            borderRadius: 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          {badgeContent}
        </Box>
      </Box>
    );
  })
);

export const QueueSearchSettingManager = observer(() => {
  const {
    uiCardSearchStore: {
      setting,
      openSettingsMenu,
      closeSettingsMenu,
      isSettingsMenuOpen,
      changeSearchSettingType,
      changeSearchSettingModule,
      toggleSearchSettingModuleField,
      changeSearchSettingMessages,
    },
    sessionsStore: { currentSession },
    sourcesStore,
  } = useStore();

  const [isModuleType, setIsModuleType] = useState(setting.type === 'module');
  const currentType = isModuleType ? 'module' : setting.type;

  const setSearchSettingType = (type: string) => {
    if (type === 'all' || type === 'visible') {
      changeSearchSettingType(type);
      setIsModuleType(false);
    } else {
      setIsModuleType(true);
    }
  };

  if (!currentSession) {
    return null;
  }

  const { modules } = currentSession;

  let selectedModuleFields: FieldDescription[] | null = null;
  if (setting.type === 'module') {
    const selectedModule = modules.find((m) => m.id === setting.moduleId);
    if (selectedModule) {
      const source = sourcesStore.sources[selectedModule.sourceId];
      if (source) {
        selectedModuleFields = source.ownFields;
      }
    }
  }

  return (
    <ButtonPopover
      open={isSettingsMenuOpen}
      requestOpen={openSettingsMenu}
      requestClose={closeSettingsMenu}
      icon={<ManageSearchIcon />}
      title="Search Settings"
      button={<QueueSearchSettingIcon button />}
    >
      <FormControl component="fieldset" margin="normal" sx={{ m: 0 }}>
        <RadioGroup value={currentType} onChange={(_, value) => setSearchSettingType(value)}>
          <FormControlLabel value="all" control={<Radio />} label="All fields" />
          {currentType === 'all' && (
            <FormControl sx={{ ml: 3 }} fullWidth>
              <FormControlLabel
                control={
                  <Switch
                    checked={setting.type === 'all' && setting.messages}
                    onChange={(_, checked) => changeSearchSettingMessages(checked)}
                  />
                }
                label="Include messages"
              />
            </FormControl>
          )}
          <FormControlLabel value="visible" control={<Radio />} label="Visible fields" />
          {currentType === 'visible' && (
            <FormControl sx={{ ml: 3 }} fullWidth>
              <FormControlLabel
                control={
                  <Switch
                    checked={setting.type === 'visible' && setting.messages}
                    onChange={(_, checked) => changeSearchSettingMessages(checked)}
                  />
                }
                label="Include messages"
              />
            </FormControl>
          )}
          <FormControlLabel value="module" control={<Radio />} label="Specific Bucket" />
          {currentType === 'module' && (
            <FormControl sx={{ ml: 4 }}>
              <NativeSelect
                value={setting.type === 'module' ? setting.moduleId : ''}
                onChange={(e) => changeSearchSettingModule(e.target.value)}
              >
                <option value="" disabled>
                  Pick a Bucket
                </option>
                {modules.map((module) => (
                  <option key={module.id} value={module.id}>
                    {module.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          )}
          {selectedModuleFields?.length === 0 && (
            <Box sx={{ mt: 3, ml: 5 }}>No fields available</Box>
          )}
          {setting.type === 'module' &&
            selectedModuleFields &&
            selectedModuleFields.map((f) => (
              <FormControl sx={{ ml: 5 }} key={f.fieldId}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={setting.fieldIds.includes(f.fieldId)}
                      onClick={() => toggleSearchSettingModuleField(f.fieldId)}
                    />
                  }
                  label={f.fieldName}
                />
              </FormControl>
            ))}
        </RadioGroup>
      </FormControl>
    </ButtonPopover>
  );
});
