import { useState, useCallback, forwardRef } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { ButtonBase } from '@mui/material';

interface CopiableFieldProps {
  text: string;
  alwaysShowCopy?: boolean;
  oneLine?: boolean;
  textElement?: JSX.Element;
  fullWidth?: boolean;
  textClassname?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    minWidth: 0,
    textAlign: 'start',
    fontSize: '1em',
    fontWeight: 'inherit',
    fontFamily: 'inherit',
  },
  inner: {
    display: 'flex',
    alignItems: 'baseline',
  },
  oneLine: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  text: {
    flex: 1,
    minWidth: 0,
  },
  copyBox: {
    position: 'relative',
    color: '#06c',
    fontSize: '0.8em',
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
  copied: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    opacity: 0,
  },
  justCopied: {
    animationName: '$justCopied',
    animationDuration: '400ms',
    animationTimingFunction: 'ease-in',
    animationIterationCount: 1,
  },
  '@keyframes justCopied': {
    '0%': {
      top: 0,
      opacity: 1,
    },
    '80%': {
      top: -15,
      opacity: 0.75,
    },
    '100%': {
      top: -20,
      opacity: 0,
    },
  },
}));

export const CopiableField = forwardRef<HTMLButtonElement, CopiableFieldProps>(
  (
    {
      text,
      oneLine,
      textElement,
      alwaysShowCopy,
      fullWidth,
      textClassname,
      ...other
    }: CopiableFieldProps,
    ref
  ) => {
    const [isOver, setIsOver] = useState(false);
    const mouseEnter = useCallback(() => setIsOver(true), []);
    const mouseLeave = useCallback(() => setIsOver(false), []);

    const copyField = useCallback(() => {
      navigator.clipboard.writeText(text);
      setJustCopied(true);
    }, [text]);

    const [justCopied, setJustCopied] = useState(false);

    const removeJustCopied = useCallback(() => setJustCopied(false), []);

    const styles = useStyles();

    const textClasses = clsx(styles.text, textClassname, {
      [styles.oneLine]: oneLine,
    });

    const copiedClasses = clsx(styles.copied, {
      [styles.justCopied]: justCopied,
    });

    return (
      <ButtonBase
        ref={ref}
        {...other}
        type="button"
        disableTouchRipple
        onClick={copyField}
        className={styles.root}
        style={{
          width: fullWidth ? '100%' : 'initial',
        }}
      >
        <div
          className={styles.inner}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
          style={{
            width: fullWidth ? '100%' : 'initial',
          }}
        >
          <div className={textClasses}>{textElement || text}</div>
          <div
            className={styles.copyBox}
            style={{
              visibility: isOver || alwaysShowCopy ? 'visible' : 'hidden',
            }}
          >
            <span className={copiedClasses} onAnimationEnd={removeJustCopied}>
              Copied
            </span>
            <span>Copy</span>
          </div>
        </div>
      </ButtonBase>
    );
  }
);
