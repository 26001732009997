import { useEffect, useRef, useState } from 'react';
import { COLLAPSED_ROW_ESTIMATED_SIZE_PX } from 'logic/ui-active-session';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { useStore } from 'logic';
import { MeasurableContainer } from './MeasurableContainer';
interface QueueListProps {
  numItems: number;
  renderQueueItem: (index: number) => JSX.Element;
}

export const QueueList = ({ numItems, renderQueueItem }: QueueListProps) => {
  const cache = useRef(
    new CellMeasurerCache({
      defaultHeight: COLLAPSED_ROW_ESTIMATED_SIZE_PX,
      fixedWidth: true,
    })
  );

  const {
    uiStickyHeaders: { setScrollPosition },
    uiQueueScroll: { setList },
  } = useStore();

  const [listRef, setListRef] = useState<List | null>(null);

  // This is necessary to enable drag/drop reordering before first scrolling...
  useEffect(() => {
    const timer = setTimeout(() => {
      listRef?.scrollToPosition(1);
      setTimeout(() => {
        listRef?.scrollToPosition(0);
      }, 0);
    }, 150);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <AutoSizer>
        {({ width, height }) => (
          <List
            ref={(ref) => {
              setListRef(ref);
              setList(ref);
            }}
            onScroll={({ scrollTop }) => setScrollPosition(scrollTop)}
            estimatedRowSize={COLLAPSED_ROW_ESTIMATED_SIZE_PX}
            width={width}
            height={height}
            rowCount={numItems}
            deferredMeasurementCache={cache.current}
            rowHeight={cache.current.rowHeight}
            rowRenderer={({ index, key, parent, style }) => (
              <CellMeasurer
                cache={cache.current}
                columnIndex={0}
                key={key}
                rowIndex={index}
                parent={parent}
              >
                {({ measure }) => (
                  <div style={style}>
                    <MeasurableContainer onResize={measure}>
                      {renderQueueItem(index)}
                    </MeasurableContainer>
                  </div>
                )}
              </CellMeasurer>
            )}
          />
        )}
      </AutoSizer>
    </>
  );
};
