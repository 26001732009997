import makeStyles from '@mui/styles/makeStyles';
import MuiWarningIcon from '@mui/icons-material/Warning';
import { forwardRef } from 'react';

const useStyles = makeStyles({
  root: {
    verticalAlign: 'top',
  },
});

export const WarningIcon = forwardRef<SVGSVGElement>((props, ref) => {
  const styles = useStyles();

  return (
    <MuiWarningIcon {...props} ref={ref} color="error" fontSize="inherit" className={styles.root} />
  );
});
