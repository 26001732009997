import { useCallback, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import makeStyles from '@mui/styles/makeStyles';
import { ulid } from 'ulid';
import { Button, CircularProgress, Typography } from '@mui/material';
import { FirestoreSource, RowData } from '@creative-kit/shared';
import { DEFAULT_NEW_COLUMN_NAME } from 'logic/source';
import { createMetaShortcut, META_KEY } from 'logic/ui-keyboard-shortcuts';
import { useImportStyles } from 'ui/ImportSource/importStyles';
import { SourceRows } from 'ui/AddRows/SourceRows';
import { useShortcuts } from 'logic/hooks';
import { useForm } from 'react-hook-form';
import { AutomaticModuleCreationCheckbox, SourceCreationForm, SourceNameInput } from './SourceForm';

interface SourceListCreationProps {
  importLoading?: boolean;
  onCancel: () => void;
  onUpload: (source: FirestoreSource, rows: RowData[], automaticModuleCreation: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  addRowsRoot: {
    marginTop: theme.spacing(0),
  },
  addRowsTable: {
    minHeight: '50vh',
  },
}));

export const SourceListCreation = observer(
  ({ onCancel, onUpload, importLoading }: SourceListCreationProps) => {
    const styles = useStyles();
    const importStyles = useImportStyles();

    const [finalRows, setFinalRows] = useState<RowData[]>([]);
    const columnId = useRef(ulid());
    const sourceData = useRef({
      fieldIds: [columnId.current],
      fieldNames: { [columnId.current]: DEFAULT_NEW_COLUMN_NAME },
    });

    const { handleSubmit, control } = useForm<SourceCreationForm>({
      defaultValues: { sourceName: '', automaticModuleCreation: true },
    });

    const handleCreate = useCallback(
      async ({ sourceName, automaticModuleCreation }: SourceCreationForm) => {
        const filteredRows = finalRows.filter(
          (row) => Object.values(row).every((value) => value === '') === false
        );

        onUpload(
          {
            ...sourceData.current,
            fieldNames: { [columnId.current]: sourceName },
            name: sourceName,
          },
          filteredRows,
          automaticModuleCreation
        );
      },
      [onUpload, finalRows]
    );

    const onSubmit = handleSubmit(handleCreate);

    const shortcuts = useMemo(
      () => [
        {
          name: 'continue',
          fn: () => onSubmit(),
          combination: [META_KEY, 'enter'],
        },
        {
          name: 'goBack',
          fn: () => onCancel(),
          combination: ['esc'],
        },
      ],
      [onSubmit, onCancel]
    );
    useShortcuts(shortcuts);

    return (
      <div className={importStyles.root}>
        <div className={importStyles.content}>
          <Typography variant="h4">Create New List Of Tags</Typography>
          {importLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            <>
              <SourceNameInput label="Tag List Name" control={control} onSubmit={onSubmit} />
              <div className={importStyles.stepDetails}>
                Leave blank, compose directly, or paste from emails, documents, and your desktop!
              </div>
              <div style={{ width: '100%' }}>
                <SourceRows
                  className={styles.addRowsRoot}
                  sourceTableClassName={styles.addRowsTable}
                  fieldIds={sourceData.current.fieldIds}
                  fieldNames={sourceData.current.fieldNames}
                  setRows={setFinalRows}
                  rows={finalRows}
                />
              </div>
              <AutomaticModuleCreationCheckbox control={control} />
              <div className={importStyles.navigation}>
                <Button onClick={() => onCancel()}>Cancel</Button>
                <Button onClick={onSubmit}>Continue ({createMetaShortcut('Enter')})</Button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);
