import { colors, createTheme, Theme } from '@mui/material';
import { green, grey, indigo } from '@mui/material/colors';

declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    hint: string;
  }

  interface PaletteOptions {
    default: PaletteColorOptions;
  }
}
declare module '@mui/material/styles' {
  interface Theme {
    statusColors: {
      pri: StatusStyle;
      sec: StatusStyle;
      currentCard: StatusStyle;
      nextCard: StatusStyle;
      onDeckCard: StatusStyle;
      upcomingCard: StatusStyle;
      doneCard: StatusStyle;
    };
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    statusColors?: {
      pri?: StatusStyle;
      sec?: StatusStyle;
      currentCard?: StatusStyle;
      nextCard?: StatusStyle;
      onDeckCard?: StatusStyle;
      upcomingCard?: StatusStyle;
      doneCard?: StatusStyle;
    };
  }
}

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

interface StatusStyle {
  backgroundColor: string;
}

const dummyTheme = createTheme();
const getStatusStyle = (backgroundColor: string): StatusStyle => ({
  backgroundColor,
});

export const getTheme = (darkMode: boolean) =>
  createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      background: {
        paper: darkMode ? 'rgb(50, 50, 50)' : '#fff',
        default: darkMode ? 'rgb(40, 40, 40)' : '#fafafa',
      },
      primary: {
        main: '#5c6bc0',
      },
      secondary: {
        main: '#f50057',
      },
      text: {
        hint: darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
      },
      default: dummyTheme.palette.augmentColor({
        color: {
          main: '#d0d0d0',
        },
      }),
    },
    components: {
      MuiButton: {
        defaultProps: {
          // @ts-expect-error
          color: 'default',
        },
        variants: [
          {
            props: { variant: 'outlined' },
            style: {
              color: darkMode ? '#fff' : '#000',
            },
          },
        ],
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: colors.grey[700],
            fontSize: 14,
          },
        },
      },
      MuiTabs: {
        defaultProps: {
          indicatorColor: 'secondary',
          textColor: 'inherit',
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: 160,
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'unset',
          },
        },
      },
    },
    statusColors: {
      pri: getStatusStyle(green[300]),
      sec: getStatusStyle(indigo[300]),
      currentCard: getStatusStyle('#ded135'),
      nextCard: getStatusStyle('#ff7805'),
      onDeckCard: getStatusStyle('#39c6c6'),
      upcomingCard: getStatusStyle(grey[400]),
      doneCard: getStatusStyle(green[600]),
    },
  });
