import Mousetrap from 'mousetrap';
import { KeyboardEvent } from 'react';
import type { RootStore } from '.';
import { isMac } from './utils';

const IS_MAC = isMac();

interface KeyboardKey {
  key: string;
  symbol: string;
}

export interface KeyboardShortcut {
  name: string;
  combination: (KeyboardKey | string)[];
  fn: (e: Mousetrap.ExtendedKeyboardEvent) => void;
  description?: string;
}

export const CMD_KEY: KeyboardKey = { key: 'command', symbol: '⌘' };
export const CTRL_KEY: KeyboardKey = { key: 'ctrl', symbol: '^' };
export const META_KEY = IS_MAC ? CMD_KEY : CTRL_KEY;

export function getShortcutStringWithKeys(shortcut: KeyboardShortcut) {
  return shortcut.combination.map((s) => (typeof s === 'string' ? s : s.key)).join('+');
}
function getShortcutStringWithSymbols(shortcut: KeyboardShortcut) {
  return shortcut.combination.map((s) => (typeof s === 'string' ? s : s.symbol)).join('+');
}
export function createMetaShortcut(key: string) {
  return `${IS_MAC ? CMD_KEY.symbol : CTRL_KEY.symbol}${key}`;
}

export function matchMetaShortcutToEvent(event: KeyboardEvent, key: string) {
  return ((IS_MAC && event.metaKey) || (!IS_MAC && event.ctrlKey)) && event.key === key;
}

export class UiKeyboardShortcutsStore {
  private rootStore: RootStore;
  shortcuts: KeyboardShortcut[];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.shortcuts = [
      {
        name: 'newCards',
        combination: ['n'],
        fn: () => {
          if (this.rootStore.uiPermissions.canEditCurrentSession) {
            this.rootStore.uiBatchCardsStore.activateBatchMode();
          }
        },
      },
      {
        name: 'completeCurrent',
        combination: [META_KEY, 'shift', 'd'],
        fn: (e) => {
          e.preventDefault();
          const { completeCard } = this.rootStore.sessionCardActions;
          const { currentCardId } = this.rootStore.sessionsStore.currentSession!;
          if (this.rootStore.uiPermissions.canEditCurrentSession) {
            completeCard(currentCardId);
          }
        },
      },
      {
        name: 'copyCurrentFilename',
        combination: [META_KEY, 'shift', 'c'],
        fn: (e) => {
          // Prevents chrome from opening dev tools
          e.preventDefault();
          const { currentCardId } = this.rootStore.sessionsStore.currentSession!;
          const { filename, cardNumber } = this.rootStore.cardsStore.cards[currentCardId];
          navigator.clipboard.writeText(filename);
          this.rootStore.uiSnackbarStore.setSnackbarDetails({
            description: `Card ${cardNumber}'s Filename copied to Clipboard`,
          });
        },
      },
      {
        name: 'undo',
        combination: [META_KEY, 'z'],
        fn: () => this.rootStore.uiSnackbarStore.runSnackbarAction(),
      },
      {
        name: 'clearFilters',
        combination: [META_KEY, 'esc'],
        fn: () => {
          this.rootStore.uiCardSearchStore.setCurrentSearch(undefined);
          this.rootStore.uiModulePowerColumnStore.clearAllModuleFilters();
        },
      },
      {
        name: 'deselectAll',
        combination: ['escape'],
        fn: () => {
          this.rootStore.uiActiveSessionStore.deselectAllCards();
        },
      },
      {
        name: 'completeAllSelected',
        combination: [META_KEY, 'e'],
        fn: async () => {
          this.rootStore.sessionCardActions.completeOrMoveSelectedCards();
        },
      },
      {
        name: 'selectAll',
        combination: [META_KEY, 'a'],
        fn: (e) => {
          const { selectAllOrBelow } = this.rootStore.uiActiveSessionStore;
          e.preventDefault();
          selectAllOrBelow();
        },
      },
      {
        name: 'toggleView',
        combination: ['x'],
        fn: () => {
          this.rootStore.uiActiveSessionStore.toggleCardView();
        },
      },
      {
        name: 'scrollTop',
        combination: [META_KEY, 'up'],
        fn: () => {
          this.rootStore.uiQueueScroll.scrollToTop();
        },
      },
      {
        name: 'scrollEnd',
        combination: [META_KEY, 'down'],
        fn: () => {
          this.rootStore.uiQueueScroll.scrollToEnd();
        },
      },
    ];
  }

  getShortcutCombinationByName = (name: string) => {
    const shortcut = this.shortcuts.find((s) => s.name === name);
    if (!shortcut) {
      return 'N/A';
    }
    return getShortcutStringWithSymbols(shortcut);
  };

  init() {
    this.shortcuts.forEach((shortcut) => {
      Mousetrap.bind(getShortcutStringWithKeys(shortcut), shortcut.fn);
    });
  }
}
