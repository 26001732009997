import { ulid } from 'ulid';
import { FirestoreSource, RowData, SourceType } from '@creative-kit/shared';
import CsvParser from 'papaparse';

// Returns standardized format for image source
export const newImageSource = ({
  sourceName,
}: {
  sourceName?: string;
}): { source: FirestoreSource } => {
  const newSourceName = sourceName ?? 'New Image Source';

  // Hardcode columns because Image Source columns are fixed
  const fieldNames = {
    notes: 'Notes',
    filename: 'Filename',
    extension: 'Extension',
  };
  return {
    source: {
      fieldNames,
      fieldIds: Object.keys(fieldNames),
      name: newSourceName,
      type: 'image' as SourceType,
    },
  };
};

// Format file into standardized format for uploading sources
export const newSourceFromCSV = async ({
  file,
  sourceName,
}: {
  file: File;
  sourceName?: string;
}): Promise<{ source: FirestoreSource; rows: RowData[] }> => {
  if (file.type !== 'text/csv') {
    throw new Error('Only CSV files are valid.');
  }

  const contents = await file.text();
  const parsed = CsvParser.parse<{ [key: string]: string }>(contents, {
    header: true,
    skipEmptyLines: true,
  });

  const {
    meta: { fields },
    errors,
    data,
  } = parsed;

  if (errors.length > 0 || !fields) {
    throw new Error(errors.map((e) => e.message).join('\n'));
  }

  const filteredFields = fields
    .filter((f) => f.length > 0)
    .map((fieldName) => ({
      fieldId: ulid(), // Using these generated IDs keeps us from having invalid characters such as *, ~, etc. in firestore field names
      fieldName,
    }));

  const uniqueFields = new Set(filteredFields.map((f) => f.fieldName)).size;
  if (uniqueFields !== filteredFields.length) {
    throw new Error('All field names have to be unique in your dataset');
  }

  const fieldIds = filteredFields.map((f) => f.fieldId);
  const fieldNames = filteredFields.reduce(
    (mapping, { fieldId, fieldName }) => ({
      ...mapping,
      [fieldId]: fieldName,
    }),
    {} as Record<string, string>
  );

  const reverseFieldMapping = filteredFields.reduce(
    (mapping, { fieldId, fieldName }) => ({
      ...mapping,
      [fieldName]: fieldId,
    }),
    {} as Record<string, string>
  );

  // Remove extension from filename
  const filenameLastDot = file.name.lastIndexOf('.');
  const formattedSourceName = sourceName || file.name.substring(0, filenameLastDot);

  const source = {
    fieldIds,
    fieldNames,
    name: formattedSourceName,
  };

  const rows = data.map((row) =>
    Object.fromEntries(
      Object.entries(row).map(([fieldName, value]) => [reverseFieldMapping[fieldName], value])
    )
  );
  return {
    rows,
    source,
  };
};
