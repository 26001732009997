import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import DataGrid, { Column, Position } from '@creative-kit/react-data-grid';
import makeStyles from '@mui/styles/makeStyles';

import { useStore } from 'logic';
import clsx from 'clsx';
import {
  ADD_NEW_COLUMN_KEY,
  DEFAULT_NEW_COLUMN_NAME,
  DELETE_ROW_COLUMN_KEY,
  IMAGE_COLUMN_KEY,
  MODULE_POWER_COLUMN_KEY,
} from 'logic/source';
import { Row } from 'logic/row';
import { debounce, lighten } from '@mui/material';
import { parse } from 'papaparse';
import { ModulePowerColumnHeaderRenderer } from './ModulePowerColumnHeaderRenderer';
import { ModulePowerColumnFormatter } from './ModulePowerColumnFormatter';
import { SourceRowEditControls } from './SourceRowEditControls';
import { SourceRow } from './SourceRow';
import { CellFormatter } from './CellFormatter';
import { CellEditor } from './CellEditor';
import { SourceColumnEditHeaderRenderer } from './SourceColumnHeaderRenderer';
import { ImageCellFormatter } from './ImageCellFormatter';
import { SourceEmptyState } from './SourceEmptyState';

interface SourceGridProps {
  id: string;
  width: number;
}

const useStyles = makeStyles((theme) => ({
  deleteColumnCell: {
    '&&&': {
      border: 'none',
      background: theme.palette.background.default,
      boxShadow: 'none',
    },
  },
  markForDeletion: {
    '&&&&': {
      // Override the styles of RDG
      backgroundColor: lighten(theme.palette.error.light, 0.2),
    },
    '& .rdg-cell-selected': {
      boxShadow: 'none',
    },
  },
  powerColumnCell: {
    position: 'relative',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    '&&&': {
      borderBottom: 'none',
      boxShadow: 'none',
    },
  },
  editHeaderCell: {
    // remove padding so input can take up full width
    padding: 0,
  },
  regularHeaderCell: {
    borderTop: `1px solid #242424`,
  },
}));

declare module '@creative-kit/react-data-grid' {
  interface Column<TRow, TSummaryRow = unknown> {
    isSourceColumn?: boolean;
  }
}

export const SourceGrid = observer(({ id: sourceId, width }: SourceGridProps) => {
  const {
    sourcesStore: { sources },
    uiSourceResize: { setSourceRowSize, getSourceRowSize },
  } = useStore();

  const source = sources[sourceId];

  const styles = useStyles();
  const {
    isEditModeActive,
    type: sourceType,
    showPowerColumn,
    visibleColumns,
    getAddedColumns,
    pasteCells,
    isColumnMarkedForDeletion,
  } = source ?? {};
  const columnWidth = 200;
  // If all columns can fit in available width, automatically size them. Otherwise, default to column width
  const doColumnsOverflow = (visibleColumns?.length + 1) * columnWidth > width;
  const dataGridColumns: Column<Row>[] = useMemo(() => {
    // check if source is defined or not
    if (visibleColumns === undefined) {
      return [];
    }
    const sourceColumns = visibleColumns.concat(isEditModeActive ? getAddedColumns() : []);

    const powerColumn: Column<Row> = {
      name: MODULE_POWER_COLUMN_KEY,
      key: MODULE_POWER_COLUMN_KEY,
      minWidth: 150,
      width: 150,
      headerCellClass: styles.powerColumnCell,
      cellClass: styles.powerColumnCell,
      headerRenderer: (props) => <ModulePowerColumnHeaderRenderer sourceId={sourceId} {...props} />,
      formatter: (props) => <ModulePowerColumnFormatter sourceId={sourceId} {...props} />,
    };

    const deleteRowColumn: Column<Row> = {
      name: ' ',
      key: DELETE_ROW_COLUMN_KEY,
      minWidth: 50,
      width: 50,
      headerCellClass: styles.deleteColumnCell,
      cellClass: styles.deleteColumnCell,
      formatter: SourceRowEditControls,
    };

    const addNewFieldColumn: Column<Row> = {
      name: DEFAULT_NEW_COLUMN_NAME,
      key: ADD_NEW_COLUMN_KEY,
      width: 225,
      headerCellClass: styles.regularHeaderCell,
      headerRenderer: isEditModeActive
        ? (props) => <SourceColumnEditHeaderRenderer sourceId={sourceId} {...props} />
        : undefined,
      editor: isEditModeActive ? CellEditor : undefined,
    };

    const imageColumn: Column<Row> = {
      name: 'Image',
      key: IMAGE_COLUMN_KEY,
      headerCellClass: styles.regularHeaderCell,
      width: '40%',
      formatter: ImageCellFormatter,
      resizable: true,
    };

    return (isEditModeActive ? [deleteRowColumn] : [])
      .concat(showPowerColumn ? [powerColumn] : [])
      .concat(sourceType === 'image' ? [imageColumn] : [])
      .concat(
        sourceColumns.map<Column<Row>>((col) => {
          let colWidth: number | undefined = getSourceRowSize(sourceId, col.fieldId);
          if (!colWidth) {
            colWidth = doColumnsOverflow ? 200 : undefined;
          }
          return {
            isSourceColumn: true,
            field: col.fieldId,
            key: col.fieldId,
            name: col.fieldName,
            resizable: true,
            width: colWidth,
            headerCellClass: clsx(styles.regularHeaderCell, {
              [styles.editHeaderCell]: isEditModeActive,
            }),
            cellClass: clsx({
              [styles.markForDeletion]: isColumnMarkedForDeletion(col.fieldId),
            }),
            headerRenderer: isEditModeActive
              ? (props) => <SourceColumnEditHeaderRenderer sourceId={sourceId} {...props} />
              : undefined,
            editor: isEditModeActive ? CellEditor : undefined,
            formatter: CellFormatter,
          };
        })
      )
      .concat(isEditModeActive ? [addNewFieldColumn] : []);
  }, [
    isColumnMarkedForDeletion,
    visibleColumns,
    isEditModeActive,
    getAddedColumns,
    showPowerColumn,
    sourceType,
    sourceId,
    doColumnsOverflow,
    getSourceRowSize,
    styles.powerColumnCell,
    styles.deleteColumnCell,
    styles.regularHeaderCell,
    styles.editHeaderCell,
    styles.markForDeletion,
  ]);

  const firstSourceColumnIdx = dataGridColumns.findIndex((c) => c.isSourceColumn);

  const onResize = debounce(setSourceRowSize, 200);

  const [selectedCell, setSelectedCell] = useState<Position | null>(null);

  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      // if pasting in an input field, then we shouldn't paste across cells
      if (e.target instanceof HTMLElement) {
        const nodeName = e.target.nodeName.toLowerCase();
        if (nodeName === 'input' || nodeName === 'textarea') {
          return;
        }
      }

      // only paste across cells if editing this source
      if (!(selectedCell && isEditModeActive)) {
        return;
      }

      const { idx: colIdx, rowIdx } = selectedCell;

      if (colIdx < firstSourceColumnIdx || rowIdx < 0) {
        return;
      }

      let pastedText = e.clipboardData?.getData('text');
      const pastedFiles = e.clipboardData?.files;
      if (pastedFiles?.length && !pastedText) {
        pastedText = [...pastedFiles].map((f) => f.name).join('\n');
      }

      if (!pastedText) {
        return;
      }

      const { data } = parse<string[]>(pastedText, {
        header: false,
        skipEmptyLines: true,
      });

      pasteCells(data, {
        rowIdx: selectedCell.rowIdx,
        colIdx: selectedCell.idx - firstSourceColumnIdx,
      });
    };
    document.addEventListener('paste', handlePaste, true);

    return () => document.removeEventListener('paste', handlePaste, true);
  }, [selectedCell, isEditModeActive, firstSourceColumnIdx, pasteCells]);

  if (!source) return null;
  return (
    <div style={{ height: '100%' }}>
      <DataGrid
        onColumnResize={(index, colWidth) => {
          const column = dataGridColumns[index];
          onResize(sourceId, column.key, colWidth);
        }}
        noRowsFallback={<SourceEmptyState sourceId={source.id} />}
        style={{ height: '100%' }}
        columns={dataGridColumns}
        rows={source.filteredRows}
        rowRenderer={SourceRow}
        headerRowHeight={35}
        onSelectedCellChange={setSelectedCell}
        rowHeight={({ type }) => {
          if (type === 'ROW' && source.type === 'image') {
            return 170;
          }

          return 35;
        }}
      />
    </div>
  );
});
