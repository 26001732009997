import { useEffect, useRef } from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { AutoSizer } from 'react-virtualized';
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Switch,
  Box,
  alpha,
  Collapse,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useStore } from 'logic';
import clsx from 'clsx';
import { TransitionGroup } from 'react-transition-group';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { ButtonPopover } from 'ui/ButtonPopover';
import { AddRowsModal } from 'ui/AddRows/AddRowsModal';
import { SearchInput } from 'ui/SearchInput';
import { EditControls } from './EditControls';
import { ImageUploadProgress } from './ImageUploadProgress';
import { SourceGrid } from './SourceGrid';
import { SourceSearchSettingManager } from './SourceSearchSettingManager';

interface SourceViewProps {
  id: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: theme.spacing(1),
    '& .rdg': {
      color: theme.palette.text.primary,
      borderRight: `1px solid ${theme.palette.text.primary}`,
      borderBottom: 'none',
      borderTop: 'none',
      borderLeft: 'none',
      backgroundColor: theme.palette.background.default,
    },
    '& .rdg-header-row': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .rdg-row': {
      backgroundColor: theme.palette.background.default,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    '& .rdg-cell': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: `1px solid rgb(60, 60, 60)`,
      borderRight: `1px solid rgb(60, 60, 60)`,
      '&:last-child': {
        // borderRight: 'none',
      },
    },
  },
  rootWithoutPowerColumn: {
    '& .rdg': {
      borderLeft: `1px solid ${theme.palette.text.primary}`,
    },
  },
  sourceTable: {
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    position: 'relative',
    flex: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  powerColumnLabel: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.default,
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    lineHeight: 1,
  },
  progress: {
    maxHeight: 150,
    overflow: 'auto',
    marginBottom: theme.spacing(1),
  },
}));

export const SourceView = observer(({ id: sourceId }: SourceViewProps) => {
  const {
    sessionsStore: { currentSession },
    uiPermissions: { canEditCurrentSession },
    sourcesStore: { sources },
    uiSourceSearchStore: { searchesById, setSearchString, autofocusSearch, setSearchFocus },
    uiSourceFieldsStore: { fieldsById, toggleField, togglePowerColumn },
    uiImageSourceUpload,
  } = useStore();

  const source = sources[sourceId];

  const styles = useStyles();

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (autofocusSearch) {
      inputRef.current?.focus();
      // Reset value after focusing so next time it's called the effect will be re-run
      setSearchFocus(false);
    }
  }, [autofocusSearch, setSearchFocus]);

  const [{ isDraggingFile }, drop] = useDrop<{ files: File[] }, void, { isDraggingFile: boolean }>({
    accept: NativeTypes.FILE,
    collect: (monitor) => ({
      isDraggingFile: monitor.getItemType() === NativeTypes.FILE,
    }),
    drop: (item) => {
      item.files?.forEach((file) => uiImageSourceUpload.uploadImageToSource(sourceId, file));
    },
  });

  if (!source) return null;

  const activeFields =
    (currentSession && fieldsById[currentSession.id]?.[sourceId]?.fields) || source.data.fieldIds;

  const uploads = uiImageSourceUpload.sourceUploads[sourceId] || [];
  const uploadProgresses = uploads.map((u) => (
    <Collapse key={u.id}>
      <ImageUploadProgress progress={u} />
    </Collapse>
  ));

  return (
    <div
      className={clsx(styles.root, {
        [styles.rootWithoutPowerColumn]: !source.showPowerColumn,
      })}
      ref={source.type === 'image' ? drop : undefined}
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: 100,
          inset: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
          color: (theme) => theme.palette.text.primary,
          opacity: isDraggingFile ? 1 : 0,
          transition: (theme) => theme.transitions.create('opacity'),
          pointerEvents: isDraggingFile ? 'initial' : 'none',
        }}
      >
        <div>Drop images here to upload to library</div>
      </Box>
      <div className={styles.actions}>
        {canEditCurrentSession && <EditControls source={source} />}
        {canEditCurrentSession && <AddRowsModal sourceId={source.id} />}
        <ButtonPopover
          button={
            <Button variant="outlined" size="small" startIcon={<VisibilityIcon />}>
              View
            </Button>
          }
          icon={<VisibilityIcon />}
          title="View"
          menuSx={{ width: 'auto' }}
          contentSx={{ p: 0 }}
        >
          <MenuItem>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Switch
                    checked={source.showPowerColumn}
                    onClick={() => togglePowerColumn(sourceId)}
                  />
                }
                label={<span className={styles.powerColumnLabel}>Power Column</span>}
              />
            </FormControl>
          </MenuItem>
          {source.data.fieldIds.map((fieldId) => (
            <MenuItem key={fieldId}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Switch
                      checked={activeFields.includes(fieldId)}
                      onClick={() => toggleField(sourceId, fieldId)}
                    />
                  }
                  label={source.data.fieldNames[fieldId]}
                />
              </FormControl>
            </MenuItem>
          ))}
        </ButtonPopover>
        <SourceSearchSettingManager key={source.id} source={source} />
        <SearchInput
          sx={{ flex: 1 }}
          placeholder="Find Rows"
          value={searchesById[sourceId] ?? ''}
          onTextChange={(text) => setSearchString(sourceId, text)}
          ref={inputRef}
        />
      </div>
      <TransitionGroup
        appear
        className={clsx({
          [styles.progress]: uploadProgresses.length > 0,
        })}
      >
        {uploadProgresses}
      </TransitionGroup>
      <div className={styles.sourceTable}>
        <AutoSizer>
          {({ width, height }) => (
            <Observer>
              {() => (
                <div style={{ width, height }}>
                  <SourceGrid id={sourceId} width={width} />
                </div>
              )}
            </Observer>
          )}
        </AutoSizer>
      </div>
    </div>
  );
});
