import { DragDropBase } from './dragdrop';

export class UiSourceTabDragDropStore extends DragDropBase {
  public originalSourceOrder: string[] = [];

  get sourceTabDragStatus() {
    return this.dragStatus;
  }

  startReordering(sourceId: string) {
    this.sourceTabDragStatus.targetId = sourceId;
    this.originalSourceOrder = this.rootStore.sessionsStore.currentSession!.data.sourceIds;
  }

  updateReordering(targetModuleId: string, position: 'before' | 'after') {
    this._updateReordering(this.originalSourceOrder, targetModuleId, position);
  }

  get reorderedSources() {
    const { targetId: sourceId, overId, position } = this.sourceTabDragStatus;
    if (!overId || !sourceId) return this.originalSourceOrder;

    const sourcesToReorder = this.originalSourceOrder.filter((id) => id !== sourceId);
    const targetModuleIndex = sourcesToReorder.indexOf(overId);

    const targetAndDragSourceIds = position === 'after' ? [overId, sourceId] : [sourceId, overId];

    return [
      ...sourcesToReorder.slice(0, targetModuleIndex),
      ...targetAndDragSourceIds,
      ...sourcesToReorder.slice(targetModuleIndex + 1),
    ];
  }

  async endReordering() {
    if (this.sourceTabDragStatus?.targetId) {
      // Wait for set to complete to prevent UI jank between preview and updated
      await this.rootStore.sessionsStore.currentSession?.setSourceIds(this.reorderedSources);
      super.endReordering();
    }
  }
}
