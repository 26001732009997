import { action, makeObservable } from 'mobx';

const computeResizeKey = (sourceId: string, columnId: string) =>
  `source:${sourceId}col:${columnId}`;

/**
 * Used to track the resizing of source rows to preserve the source state next render
 */
export class UiSourceResizeStore {
  constructor() {
    makeObservable(this, {
      setSourceRowSize: action.bound,
      getSourceRowSize: action.bound,
    });
  }

  setSourceRowSize = (sourceId: string, columnId: string, width: number) => {
    window.localStorage.setItem(computeResizeKey(sourceId, columnId), width.toString());
  };

  getSourceRowSize = (sourceId: string, columnId: string) => {
    const widthString = window.localStorage.getItem(computeResizeKey(sourceId, columnId));
    if (widthString) {
      const width = parseInt(widthString, 10);
      if (!isNaN(width)) {
        return width;
      }
    }
    return undefined;
  };
}
