import { useRef, useCallback } from 'react';
import { Box, TextField } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { useInputCallback } from 'logic/hooks';

const NameField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: 'transparent',
      borderBottomWidth: 1,
      transitionDuration: 300,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#bbb',
      borderBottomWidth: 1,
    },
    '& .MuiInput-input': {
      fontSize: 24,
      fontWeight: 500,
    },
  },
})(TextField);

export const SessionName = observer(() => {
  const {
    uiPermissions: { cannotEditCurrentSession },
    uiSessionNameStore: { savedName, inputName, setInputName, saveName },
  } = useStore();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const isChanging = useRef(false);

  const onNameInputChange = useInputCallback(setInputName);

  const changeName = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (inputRef.current) {
        isChanging.current = true;
        inputRef.current.blur();
        await saveName();
        isChanging.current = false;
      }
    },
    [saveName]
  );

  const onNameInputBlur = useCallback(() => {
    if (!isChanging.current) {
      setInputName();
    }
  }, [setInputName]);

  const blurOnEscape = useCallback((e: React.KeyboardEvent) => {
    if (inputRef.current && e.keyCode === 27) {
      // Escape
      inputRef.current.blur();
    }
  }, []);

  const nameValue = inputName === undefined ? savedName : inputName;

  return (
    <Box mx={3} my={1}>
      <form onSubmit={changeName}>
        <NameField
          inputRef={inputRef}
          fullWidth
          value={nameValue}
          onChange={onNameInputChange}
          onKeyDown={blurOnEscape}
          onBlur={onNameInputBlur}
          InputProps={{
            readOnly: cannotEditCurrentSession,
          }}
        />
      </form>
    </Box>
  );
});
