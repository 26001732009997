import { observer } from 'mobx-react-lite';
import { Button, ListSubheader } from '@mui/material';
import ViewCompactRoundedIcon from '@mui/icons-material/ViewCompactRounded';

import { useStore } from 'logic';
import { ButtonPopover } from 'ui/ButtonPopover';
import { ModuleFieldsList } from './ModuleFieldsList';

export const QueueViewMenu = observer(() => {
  const {
    sessionsStore: { currentSession },
    uiActiveSessionStore: { queueViewMenuOpen, setQueueViewMenuOpen },
  } = useStore();

  if (!currentSession) {
    return null;
  }

  return (
    <ButtonPopover
      button={
        <Button variant="outlined" size="small" startIcon={<ViewCompactRoundedIcon />}>
          Layout
        </Button>
      }
      icon={<ViewCompactRoundedIcon />}
      title="View"
      open={queueViewMenuOpen}
      requestOpen={() => setQueueViewMenuOpen(true)}
      requestClose={() => setQueueViewMenuOpen(false)}
    >
      <ListSubheader>Buckets and Data</ListSubheader>
      <ModuleFieldsList />
    </ButtonPopover>
  );
});
