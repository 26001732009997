/* eslint-disable global-require */
import ReactDOM from 'react-dom';
import { HistoryAdapter, browserHistory, RouterContext } from 'mobx-state-router';
import type h from 'history';
import 'react-virtualized/styles.css';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { RootStore, storeContext } from 'logic';
import { App } from './App';

import 'index.css';

const rootStoreInstance = new RootStore();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).rootStore = rootStoreInstance;
rootStoreInstance.init();
const historyAdapter = new HistoryAdapter(
  rootStoreInstance.routerStore,
  browserHistory as h.History
);
historyAdapter.observeRouterStateChanges();

ReactGA.initialize('G-ZGWW2X6KLP');
ReactGA.send('pageview');

historyAdapter.history.listen(({ pathname }) => {
  ReactGA.send({
    hitType: 'pageview',
    page: pathname,
  });
});

Sentry.init({
  enabled: process.env.NODE_ENV !== 'development',
  dsn: 'https://c9d96b33aefc42f09f10c661f7a0b34d@o1219955.ingest.sentry.io/6362564',
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5,
  environment: process.env.NODE_ENV,
  release: __COMMIT_HASH__,
});

const renderApp = (AppComponent: typeof App) => {
  ReactDOM.render(
    <RouterContext.Provider value={rootStoreInstance.routerStore}>
      <storeContext.Provider value={rootStoreInstance}>
        <DndProvider backend={HTML5Backend}>
          <AppComponent />
        </DndProvider>
      </storeContext.Provider>
    </RouterContext.Provider>,
    document.getElementById('root')
  );
};

renderApp(App);
