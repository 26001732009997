import { Button, emphasize, IconButton, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import CloseIcon from '@mui/icons-material/Close';
import { MESSAGE_TIME_LIMIT_MS } from 'logic/ui-snackbar';

const useStyles = makeStyles((theme) => {
  const emphasis = theme.palette.mode === 'light' ? 0.8 : 0.98;
  const backgroundColor = emphasize(theme.palette.background.default, emphasis);
  const color = theme.palette.getContrastText(backgroundColor);

  return {
    content: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor,
      color,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
    },
    main: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
    action: {
      marginLeft: theme.spacing(3),
    },
    progress: {
      height: 6,
      position: 'relative',
      backgroundColor: theme.palette.primary.light,
    },
    progressRemaining: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.primary.main,
    },
  };
});

export const AppSnackbar = observer(() => {
  const {
    uiSnackbarStore: {
      description,
      remainingTime,
      hasAction,
      actionText,
      runSnackbarAction,
      reset,
    },
  } = useStore();

  const styles = useStyles();

  return (
    <Snackbar
      open={!!remainingTime}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
    >
      <div className={styles.content}>
        <div className={styles.main}>
          {hasAction && (
            <Button variant="text" color="secondary" onClick={runSnackbarAction}>
              {actionText}
            </Button>
          )}
          <span>{description}</span>
          <div className={styles.action}>
            <IconButton size="small" color="inherit" onClick={reset}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className={styles.progress}>
          <div
            className={styles.progressRemaining}
            style={{
              width: `${(100 * (remainingTime || 0)) / MESSAGE_TIME_LIMIT_MS}%`,
            }}
          />
        </div>
      </div>
    </Snackbar>
  );
});
