import { List, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import { KBarResults, useMatches } from 'kbar';

export const CustomKbarResults = () => {
  const { results } = useMatches();

  return (
    <List>
      <KBarResults
        items={results}
        onRender={({ item, active }) =>
          typeof item === 'string' ? (
            <ListSubheader>{item}</ListSubheader>
          ) : (
            <ListItemButton selected={active}>
              <ListItemText>{item.name}</ListItemText>
            </ListItemButton>
          )
        }
      />
    </List>
  );
};
