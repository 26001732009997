import { observable, action, computed, makeObservable, reaction, runInAction } from 'mobx';
import type ft from 'firebase';

import { FieldValue, fn } from 'firebase-modules';

import { FirestoreSession, SessionPermissions } from '@creative-kit/shared';
import { getSessionSharingInfo } from 'api/getSessionSharingInfo';
import { db } from './data';
import { Session } from './session';
import type { RootStore } from '.';
import { RouteNames } from './routes';

interface SessionOwnerData {
  name: string;
  email: string;
}

export class SessionsStore {
  private rootStore: RootStore;
  private stopListeningToSessions = () => {};
  private stopListeningToCurrentSession = () => {};
  importInProgress = false;
  currentSessionError?: 404 | 403;
  currentSessionOwner?: SessionOwnerData;
  orderedSessions: Session[] = [];
  sessions: Record<string, Session> = {};

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      sessions: observable,
      orderedSessions: observable,
      importInProgress: observable,
      currentSessionError: observable,
      liveSessions: computed,
      deletedSessions: computed,
      activeSessions: computed,
      archivedSessions: computed,
      currentSession: computed,
      currentSessionId: computed,
      updateSessions: action.bound,
      importSession: action.bound,
      resetSessions: action,
      watchCurrentSession: action,
      currentSessionOwner: observable,
      setCurrentSessionOwner: action,
    });

    this.rootStore = rootStore;
  }

  init() {
    reaction(() => this.rootStore.authStore.user, this.watchSessions, {
      fireImmediately: true,
    });
    reaction(() => this.currentSessionId, this.watchCurrentSession, {
      fireImmediately: true,
    });
  }

  setCurrentSessionOwner(owner?: SessionOwnerData) {
    this.currentSessionOwner = owner;
  }

  // Make sure current session id is in the store to support viewing other users sessions
  watchCurrentSession = async () => {
    this.currentSessionError = undefined;
    this.stopListeningToCurrentSession();

    const updateCurrentSession = (snapshot: ft.firestore.DocumentSnapshot<FirestoreSession>) => {
      runInAction(() => {
        const sessionData = snapshot.data();
        if (!sessionData) {
          return;
        }
        if (!this.sessions[snapshot.id]) {
          this.sessions[snapshot.id] = new Session(snapshot.id, sessionData, this.rootStore);
        } else {
          this.sessions[snapshot.id].updateData(sessionData);
        }
      });
    };

    if (this.currentSessionId) {
      const currentSessionRef = db.sessions.doc(this.currentSessionId);

      try {
        const session = await currentSessionRef.get();
        if (session.exists === false) {
          this.currentSessionError = 404;
        }
        updateCurrentSession(session);
        this.stopListeningToCurrentSession = currentSessionRef.onSnapshot(updateCurrentSession);
      } catch (e) {
        this.currentSessionError = 403;
        const ownerData = await getSessionSharingInfo({
          sessionId: this.currentSessionId,
        });
        this.setCurrentSessionOwner(ownerData.data);
      }
    }
  };

  watchSessions = () => {
    const {
      authStore: { user },
    } = this.rootStore;
    this.stopListeningToSessions();
    this.stopListeningToCurrentSession();
    this.resetSessions();

    if (user) {
      const userSessionsRef = db.sessions
        .where('userId', '==', user.uid)
        .orderBy('createdAt', 'desc');
      this.stopListeningToSessions = userSessionsRef.onSnapshot(this.updateSessions);
    }
  };

  get liveSessions() {
    return this.orderedSessions.filter((s) => !s.data.deletedAt);
  }

  get deletedSessions() {
    return this.orderedSessions.filter((s) => !!s.data.deletedAt);
  }

  get activeSessions() {
    return this.liveSessions.filter((s) => !s.data.isArchived);
  }

  get archivedSessions() {
    return this.liveSessions.filter((s) => s.data.isArchived);
  }

  get currentSessionId(): string {
    const { routerState } = this.rootStore.routerStore;
    if (routerState.params.sessionId) {
      return routerState.params.sessionId;
    }
    return '';
  }

  get currentSession(): Session | null {
    const session = this.sessions[this.currentSessionId];

    if (session) {
      return session;
    }
    return null;
  }

  archiveSession = (sessionId: string) => {
    const sessionRef = db.sessions.doc(sessionId);
    sessionRef.set(
      {
        isArchived: true,
      },
      {
        merge: true,
      }
    );
  };

  deleteSession = (sessionId: string) => {
    const sessionRef = db.sessions.doc(sessionId);
    sessionRef.set(
      {
        isArchived: false,
        deletedAt: FieldValue.serverTimestamp() as unknown as Date,
      },
      {
        merge: true,
      }
    );
  };

  updateSessions(snapshot: ft.firestore.QuerySnapshot<FirestoreSession>) {
    snapshot.docChanges().forEach((change) => {
      const changeType = change.type;
      const changeDoc = change.doc;
      if (changeType === 'added') {
        this.sessions[changeDoc.id] = new Session(changeDoc.id, changeDoc.data(), this.rootStore);
      } else if (change.type === 'modified') {
        this.sessions[changeDoc.id].updateData(changeDoc.data());
      } else {
        delete this.sessions[changeDoc.id];
      }
    });

    this.orderedSessions = snapshot.docs.map((doc) => this.sessions[doc.id]);
  }

  resetSessions() {
    this.orderedSessions.splice(0, this.orderedSessions.length);
    for (const key of Object.keys(this.sessions)) {
      delete this.sessions[key];
    }
  }

  // Back-end operations
  createNewSession = async ({ name }: { name?: string } = {}) => {
    if (!this.rootStore.authStore.user) {
      return;
    }
    const newSessionRef = db.sessions.doc();
    await newSessionRef.set({
      createdAt: FieldValue.serverTimestamp() as unknown as Date,
      modules: {},
      moduleIds: [],
      name: name || 'Untitled Session',
      cardIds: [],
      sourceIds: [],
      userId: this.rootStore.authStore.user.uid,
      createdBy: {
        userId: this.rootStore.authStore.user.uid,
        email: this.rootStore.authStore.user.email,
      },
      filenameSettings: {
        cardNumberWidth: 3,
        spaceSeparator: '-',
        fieldSeparator: '_',
        specialCharacterReplacement: '',
        disableFilenameCleanup: false,
        disableSpecialCharacterCleanup: true,
        filenameCaps: true,
        trimSpaces: false,
        trimSpecialCharacters: false,
      },
      visibility: {
        hiddenModuleIds: [],
        hideFilename: false,
      },
      cardTitleFormula: [],
      filenameFormula: [],
      automaticModuleCreation: true,
      automaticFilenameVersioning: true,
      automaticCardTitleSuffix: true,
      publicAccessLevel: SessionPermissions.PRIVATE,
      moduleDisplaySettings: {},
    });
    await this.rootStore.routerStore.goTo(RouteNames.SESSION, {
      params: { sessionId: newSessionRef.id },
    });
    return newSessionRef.id;
  };

  //
  /**
   * Handles common logic for importing session with source configured
   * Because we need to route to new session before operating on it, we have a persistent loading state across those two pages
   */
  importSession = async (onSessionCreation: (sessionId: string) => Promise<unknown>) => {
    let sessionId;
    try {
      this.importInProgress = true;
      const sessionName = this.rootStore.routerStore.routerState.queryParams.name;
      sessionId = await this.createNewSession({ name: sessionName });
      if (sessionId) {
        await onSessionCreation(sessionId);
      } else {
        throw new Error('No session id');
      }
    } catch (error) {
      // On error, we should try to cleanup any created state
      if (sessionId) {
        this.deleteSession(sessionId);
        await this.rootStore.routerStore.goTo(RouteNames.HOME);
        sessionId = undefined;
      }
    } finally {
      this.importInProgress = false;
    }
  };

  duplicateSession = async (
    sessionId: string,
    options: {
      name: string;
      sources: boolean;
      sourceRows: boolean;
      modules: boolean;
      filenameFormula: boolean;
      queueCards: boolean;
      resetCardStatus: boolean;
    }
  ) => {
    if (!this.rootStore.authStore.user) {
      return;
    }
    const response = await fn.httpsCallable('duplicateSession')({
      sessionId,
      ...options,
    });
    const { newSessionId } = response.data;
    this.rootStore.routerStore.goTo(RouteNames.SESSION, {
      params: { sessionId: newSessionId },
    });
  };
}
