import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { FirestoreSource, RowData } from '@creative-kit/shared';
import { useStore } from 'logic';
import { RouteNames } from 'logic/routes';
import { SourceListCreation } from 'ui/ImportSource/SourceListCreation';

export const ImportListView = observer(() => {
  const {
    routerStore,
    sessionsStore: { sessions, importSession, importInProgress },
  } = useStore();

  const handleSubmit = useCallback(
    async (source: FirestoreSource, rows: RowData[], automaticModuleCreation: boolean) => {
      importSession(async (sessionId) => {
        if (sessionId !== undefined && sessions[sessionId]) {
          const newSession = sessions[sessionId];
          await newSession.upsertSourceToSession({
            source,
            rows,
            automaticModuleCreation,
          });
        }
      });
    },
    [sessions, importSession]
  );

  return (
    <SourceListCreation
      importLoading={importInProgress}
      onCancel={() => routerStore.goTo(RouteNames.HOME)}
      onUpload={handleSubmit}
    />
  );
});
