import { CardStatus, SessionModule } from '@creative-kit/shared';
import type { RootStore } from 'logic';
import { action, computed, makeObservable, observable } from 'mobx';
import { ModuleWithCount } from 'ui/ModuleSelectionDropdown';
import { Card } from './card';
import { Session } from './session';

export class UiSourceModulePowerColumn {
  private rootStore: RootStore;
  private session: Session;
  sourceId: string;

  private pSelectedModule?: string;
  private rawSelectedRowsByModule: Record<string, Set<string>> = {};

  constructor(rootStore: RootStore, sourceId: string, session: Session) {
    this.rootStore = rootStore;
    this.sourceId = sourceId;
    this.session = session;

    makeObservable<this, 'pSelectedModule' | 'rawSelectedRowsByModule'>(this, {
      pSelectedModule: observable,
      modulesWithCount: computed,
      selectedModule: computed,
      setSelectedModule: action.bound,
      rawSelectedRowsByModule: observable,
      selectedRowsByModule: computed,
      toggleRow: action.bound,
      clearAllFilters: action.bound,
      clearFilters: action.bound,
    });
  }

  get selectedRowsByModule() {
    const selected: Record<string, Set<string>> = {};

    Object.entries(this.rawSelectedRowsByModule).forEach(([moduleId, rowIds]) => {
      const selectedRows = new Set<string>();
      rowIds.forEach((rowId) => {
        if (this.getModuleLinkCount(moduleId, rowId) > 0) {
          selectedRows.add(rowId);
        }
      });

      selected[moduleId] = selectedRows;
    });

    return selected;
  }

  get modulesWithCount(): ModuleWithCount[] {
    return this.session.getModulesForSource(this.sourceId).map((module) => ({
      id: module.id,
      multi: module.multi,
      name: module.name,
      sourceId: this.sourceId,
      color: module.color,
      count: Object.values(this.rootStore.cardsStore.cards).filter(
        (card) => card.getModuleConnections(module.id).length > 0
      ).length,
    }));
  }

  get selectedModule() {
    let moduleId = this.pSelectedModule;
    if (!moduleId) {
      moduleId = this.modulesWithCount[0]?.id;
    }

    return (moduleId && this.modulesWithCount.find((m) => m.id === moduleId)) || null;
  }

  setSelectedModule(moduleId: string) {
    this.pSelectedModule = moduleId;
  }

  getModuleLinkedCards(moduleId: string, rowId: string) {
    return Object.values(this.rootStore.cardsStore.cards).filter((card) =>
      card.getConnectedRows(moduleId).includes(rowId)
    );
  }

  getModuleLinkCount(moduleId: string, rowId: string) {
    return this.getModuleLinkedCards(moduleId, rowId).length;
  }

  getCurrentModuleLinkCount = (rowId: string) => {
    const module = this.selectedModule;

    const count = {
      inProgress: 0,
      done: 0,
      total: 0,
    };

    if (module) {
      (this.rootStore.sessionsStore.currentSession?.cardList || []).forEach((card) => {
        if (card.getConnectedRows(module.id).includes(rowId)) {
          count.total += 1;
          if (card.status === CardStatus.Completed) {
            count.done += 1;
          } else {
            count.inProgress += 1;
          }
        }
      });
    }

    return count;
  };

  toggleRow(rowId: string) {
    const moduleId = this.selectedModule?.id;
    if (!moduleId) return;

    if (!this.rawSelectedRowsByModule[moduleId]) {
      this.rawSelectedRowsByModule[moduleId] = new Set();
    }

    if (this.rawSelectedRowsByModule[moduleId].has(rowId)) {
      this.rawSelectedRowsByModule[moduleId].delete(rowId);
    } else {
      this.rawSelectedRowsByModule[moduleId].add(rowId);
    }
  }

  isFilterActiveForCard(card: Card, associatedModule: SessionModule) {
    if (!this.selectedModule) return false;

    const setOfFilteredRowIds = this.selectedRowsByModule[associatedModule.id];
    if (!setOfFilteredRowIds) return false;

    const cardConnectedRowIds = card.getConnectedRows(associatedModule.id);

    return cardConnectedRowIds.some((rowIdForCard) => setOfFilteredRowIds.has(rowIdForCard));
  }

  clearAllFilters() {
    for (const selected of Object.values(this.rawSelectedRowsByModule)) {
      selected.clear();
    }
  }

  clearFilters(moduleId: string) {
    this.rawSelectedRowsByModule[moduleId].clear();
  }
}
