import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import clsx from 'clsx';
import { SessionModule } from '@creative-kit/shared';
import {
  ButtonBase,
  Collapse,
  Paper,
  IconButton,
  FormControl,
  Switch,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import ReorderIcon from '@mui/icons-material/Reorder';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes, ModuleDragObject } from 'logic/dragdrop';
import { ModuleFieldsRadio } from './ModuleFieldsRadio';
import { EditModuleDialog } from '../ModuleManager/EditModuleDialog';

interface ModuleFieldsListItemProps {
  module: SessionModule;
  expanded: boolean;
  toggleExpanded: (id: string) => void;
}

const useStyles = makeStyles((theme) => ({
  moduleName: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  moduleColorBar: {
    display: 'flex',
    flex: '1 1 0',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  color: {
    marginRight: theme.spacing(1),
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: '50%',
  },
  nameLabel: {
    marginRight: 'auto',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
  },
  toggleButton: {
    overflow: 'hidden',
    flexGrow: 1,
    paddingRight: theme.spacing(1),
  },
  formulaIcon: {
    width: theme.spacing(3),
    height: theme.spacing(2),
  },
  toggleIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform'),
  },
  toggleUp: {
    transform: 'rotate(180deg)',
  },
  settingsButton: {
    padding: 0,
  },
  reorderIcon: {
    color: theme.palette.grey['700'],
    fontSize: '13px',
  },
  reorderIndicator: {
    position: 'absolute',
    left: '0',
    width: '100%',
    height: 2,
    backgroundColor: '#06c',
    boxShadow: '0 0 5px #06c',
    opacity: 0,
    transition: theme.transitions.create('opacity'),
  },
  topReorderIndicator: {
    top: -1,
    opacity: 0,
  },
  bottomReorderIndicator: {
    bottom: -2,
    opacity: 0,
  },
  visibleIndicator: {
    opacity: 1,
  },
}));

export const ModuleFieldsListItem = observer(
  ({ module, expanded, toggleExpanded }: ModuleFieldsListItemProps) => {
    const {
      sessionsStore: { currentSession },
      uiModuleDragDrop: { moduleDragStatus, startReordering, endReordering, updateReordering },
    } = useStore();
    const styles = useStyles();
    const theme = useTheme();
    const ref = useRef<HTMLDivElement | null>(null);
    const isBefore =
      moduleDragStatus.position === 'before' && moduleDragStatus.overId === module.id;
    const isAfter = moduleDragStatus.position === 'after' && moduleDragStatus.overId === module.id;

    const [moduleToEdit, setModuleToEdit] = useState<SessionModule | undefined>();

    const { switchModuleField, getIsModuleVisible, toggleModuleVisibility } = currentSession!;

    const [, drop] = useDrop<ModuleDragObject, void, { isOver: boolean }>({
      accept: ItemTypes.ModuleListEntry,
      drop: () => {
        endReordering();
      },
      hover: (item, monitor) => {
        if (!ref.current || item.id === module.id) {
          return;
        }
        const hoverRect = ref.current.getBoundingClientRect();
        const clientOffset = monitor.getClientOffset()!;
        const midPoint = (hoverRect.bottom - hoverRect.top) / 2;
        const verticalOffset = clientOffset.y - hoverRect.top;
        const status = verticalOffset < midPoint ? 'before' : 'after';
        updateReordering(module.id, status);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    });

    const [{ isDragging }, drag] = useDrag(() => ({
      type: ItemTypes.ModuleListEntry,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      item: () => {
        startReordering(module.id);
        return {
          id: module.id,
        };
      },
      end: () => endReordering(),
    }));

    drop(drag(ref));

    return (
      <Collapse in={!isDragging}>
        {moduleToEdit && (
          <EditModuleDialog
            open={Boolean(moduleToEdit)}
            onClose={() => setModuleToEdit(undefined)}
            module={moduleToEdit}
          />
        )}
        <Paper
          elevation={0}
          key={module.id}
          ref={ref}
          sx={{
            position: 'relative',
            // Adding transform prevents dragging from showing whole list
            transform: 'translate3d(0, 0, 0)',
          }}
        >
          <div
            className={clsx(styles.reorderIndicator, styles.topReorderIndicator, {
              [styles.visibleIndicator]: isBefore,
            })}
          />
          <div className={styles.moduleName}>
            <ReorderIcon className={styles.reorderIcon} />
            <FormControl>
              <Switch
                checked={getIsModuleVisible(module.id)}
                onChange={() => toggleModuleVisibility(module.id)}
                size="small"
              />
            </FormControl>
            <div
              className={styles.moduleColorBar}
              style={{ background: module.color, width: '100%' }}
            >
              <ButtonBase className={styles.toggleButton} onClick={() => toggleExpanded(module.id)}>
                <ExpandMoreIcon
                  className={clsx(styles.toggleIcon, {
                    [styles.toggleUp]: expanded,
                  })}
                />
                <span
                  className={styles.nameLabel}
                  style={{ color: theme.palette.getContrastText(module.color) }}
                >
                  {module.name}
                </span>
              </ButtonBase>
              <div className={styles.settingsButton}>
                <IconButton
                  size="small"
                  onClick={() => {
                    setModuleToEdit(module);
                  }}
                >
                  <MoreIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          </div>
          <Collapse in={expanded}>
            <ModuleFieldsRadio
              selectedFields={module.fields}
              sourceId={module.sourceId}
              onSwitchModuleField={(f) => switchModuleField({ moduleId: module.id, field: f })}
            />
          </Collapse>
          <div
            className={clsx(styles.reorderIndicator, styles.bottomReorderIndicator, {
              [styles.visibleIndicator]: isAfter,
            })}
          />
        </Paper>
      </Collapse>
    );
  }
);
