import { computed, makeObservable } from 'mobx';
import type { RootStore } from '.';

export class UiPermissionStore {
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isCurrentSessionOwner: computed,
      canViewCurrentSession: computed,
      canEditCurrentSession: computed,
      cannotEditCurrentSession: computed,
    });

    this.rootStore = rootStore;
  }

  get isCurrentSessionOwner() {
    return (
      this.rootStore.sessionsStore.currentSession?.data.userId ===
      this.rootStore.authStore.user?.uid
    );
  }

  get canViewCurrentSession() {
    return (
      this.isCurrentSessionOwner ||
      this.rootStore.sessionsStore.currentSession?.canViewSession ||
      this.rootStore.sessionsStore.currentSession?.canEditSession
    );
  }

  get canEditCurrentSession() {
    return (
      this.isCurrentSessionOwner || this.rootStore.sessionsStore.currentSession?.canEditSession
    );
  }

  get cannotEditCurrentSession() {
    return !this.canEditCurrentSession;
  }
}
