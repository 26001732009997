import { action, computed, makeObservable, observable } from 'mobx';

import { FieldValue } from 'firebase-modules';
import {
  BaseCard,
  FILENAME_SUFFIX_FIELD,
  FirestoreCard,
  SessionModule,
} from '@creative-kit/shared';
import type { RootStore } from '.';
import { db } from './data';
import type { Session } from './session';
import { RouteNames } from './routes';

type ModificationState = 'deleting' | 'updating' | false;

export class Card extends BaseCard {
  protected rootStore: RootStore;
  private ref;
  modificationState: ModificationState = false;

  constructor(
    id: string,
    sessionId: string,
    data: FirestoreCard,
    rootStore: RootStore,
    previewIndex?: number
  ) {
    super(id, sessionId, data, rootStore, previewIndex);

    makeObservable(this, {
      data: observable.struct,
      modificationState: observable,
      updateData: action,
      cardIdx: computed,
      cardNumber: computed,
      isEditable: computed,
      isVisible: computed,
      isSelected: computed,
      isDeleting: computed,
      isUpdating: computed,
      filenameInfo: computed,
      filenameNeedsAttention: computed,
      status: computed,
      suffix: computed,
      permalink: computed,
      isFocused: computed,
      setSuffix: action,
      setModificationState: action,
      resetMotificationState: action,
    });

    this.rootStore = rootStore;
    this.ref = db.sessionCards(this.session.id).doc(this.id);
  }

  updateData(newData: FirestoreCard) {
    this.data = newData;
  }

  toggleSelection() {
    const {
      uiActiveSessionStore: { toggleSelectedCard },
    } = this.rootStore;
    return toggleSelectedCard(this.id);
  }

  get isDeleting() {
    return this.modificationState === 'deleting';
  }

  get isUpdating() {
    return this.modificationState === 'updating';
  }

  setModificationState(state: ModificationState) {
    this.modificationState = state;
  }

  resetMotificationState() {
    this.setModificationState(false);
  }

  get isSelected() {
    const {
      uiActiveSessionStore: { isCardSelected },
    } = this.rootStore;
    return isCardSelected(this.id);
  }

  /*
  Check whether a card is visible based on the card sea rch
  If a search term is entered, only show cards where the search
  term appears in one of the displayed fields of one of the modules
  */
  get isVisible() {
    const {
      uiCardSearchStore: { searchTerms, currentSearch },
      sessionsStore: { currentSession },
      uiModulePowerColumnStore: { isCardVisibleBasedOnRowSelections },
      uiPermissions: { canViewCurrentSession },
    } = this.rootStore;

    if (!currentSession || !canViewCurrentSession) {
      return false;
    }

    const { isFilteringInProgress, visibleModules } = currentSession;

    if (!isFilteringInProgress) {
      return true;
    }

    // Search for a specific card by #
    const numberSearchMatches = currentSearch?.match(/^#([0-9]+)$/);
    if (numberSearchMatches) {
      const targetCardNumber = numberSearchMatches[1];
      return this.cardNumber.toString() === targetCardNumber;
    }

    const modulesToCheck = visibleModules;
    const doesSearchMatchModules = searchTerms.some((term) =>
      modulesToCheck.some((module) => this.doesSearchMatchModule(module, term))
    );

    const doesCardMatchSearch =
      searchTerms.length > 0
        ? doesSearchMatchModules ||
          this.doesSearchMatchTitle ||
          this.doesSearchMatchFilename ||
          this.doesSearchMatchMessages
        : true;
    return isCardVisibleBasedOnRowSelections(this) && doesCardMatchSearch;
  }

  get doesSearchMatchTitle() {
    const {
      uiCardSearchStore: { searchTerms },
      sessionsStore: { currentSession },
      uiPermissions: { canViewCurrentSession },
    } = this.rootStore;

    if (!currentSession || !canViewCurrentSession) {
      return false;
    }

    const { isFilteringInProgress, includeTitleInQueueSearch } = currentSession;

    if (!isFilteringInProgress || !includeTitleInQueueSearch) {
      return false;
    }

    return searchTerms.some((term) => this.mainLabel.toLowerCase().includes(term));
  }

  get doesSearchMatchFilename() {
    const {
      uiCardSearchStore: { searchTerms },
      sessionsStore: { currentSession },
      uiPermissions: { canViewCurrentSession },
    } = this.rootStore;

    if (!currentSession || !canViewCurrentSession) {
      return false;
    }

    const { isFilteringInProgress, includeFilenameInQueueSearch } = currentSession;

    if (!isFilteringInProgress || !includeFilenameInQueueSearch) {
      return false;
    }

    return searchTerms.some((term) => {
      const doesSearchMatchFilename = this.filename.toLowerCase().includes(term);
      const doesSearchPartialMatchFilename =
        this.filename.length > 0 ? term.includes(this.filename.toLowerCase()) : false;
      return doesSearchMatchFilename || doesSearchPartialMatchFilename;
    });
  }

  get doesSearchMatchMessages() {
    const {
      uiCardSearchStore: { searchTerms },
      sessionsStore: { currentSession },
      uiPermissions: { canViewCurrentSession },
      commentsStore: { commentsByCard },
    } = this.rootStore;

    if (!currentSession || !canViewCurrentSession) {
      return false;
    }

    const { isFilteringInProgress, includeMessagesInQueueSearch } = currentSession;

    if (!isFilteringInProgress || !includeMessagesInQueueSearch) {
      return false;
    }

    const comments = commentsByCard[this.id];
    if (!comments) {
      return false;
    }

    return searchTerms.some((term) =>
      comments.some((comment) => comment.data.text.toLowerCase().includes(term))
    );
  }

  /**
   * Check if a card's filename needs attention, that is, if the card is marked
   * done, and its filename would change based on new formula or settings
   */
  get filenameNeedsAttention() {
    const { filename, filenameUpdateIgnoredAt } = this.data;
    const { filenameParametersUpdatedAt } = this.session.data;

    if (!(filename && filenameParametersUpdatedAt)) {
      return false;
    }

    const { errors } = this.filenameInfo;
    const computedFilename = this.filename;
    return (
      errors.length === 0 &&
      computedFilename !== filename &&
      (!filenameUpdateIgnoredAt || filenameUpdateIgnoredAt < filenameParametersUpdatedAt)
    );
  }

  get visibleModules() {
    const session = this.session as Session;
    const sessionVisibleModules = [...session.visibleModules];

    if (session.isFilteringInProgress) {
      // if (showHiddenModules) {
      // // make hidden modules visible for a card if they match the current search term
      //   sessionHiddenModules.forEach((module) => {
      //     if (!sessionVisibleModules.find(m => m.id === module.id) && this.doesSearchMatchModule(module, currentSearch)) {
      //       sessionVisibleModules.push(module);
      //     }
      //   });
      // }

      Object.entries(this.rootStore.uiModulePowerColumnStore.rowSelectionsByModuleId).forEach(
        ([moduleId, selectedRowsForModule]) => {
          if (this.getConnectedRows(moduleId).some((rId) => selectedRowsForModule.has(rId))) {
            if (!sessionVisibleModules.find((m) => m.id === moduleId)) {
              sessionVisibleModules.push(session.data.modules[moduleId]);
            }
          }
        }
      );
    }

    return sessionVisibleModules;
  }

  doesSearchMatchModule = (module: SessionModule, currentSearch: string | undefined) => {
    const {
      sourcesStore: { sources },
      uiCardSearchStore: { setting },
    } = this.rootStore;

    if (setting.type === 'module' && module.id !== setting.moduleId) {
      return false;
    }

    const moduleSource = sources[module.sourceId];

    const activeFields =
      setting.type === 'all'
        ? moduleSource.ownFields.map((f) => f.fieldId)
        : setting.type === 'visible'
          ? module.fields
          : setting.fieldIds;
    const moduleConnections = this.getModuleConnections(module.id);
    const cleanSearchTerm = (currentSearch || '').toLowerCase().trim();
    return moduleConnections.some((row) =>
      Object.entries(row.getFieldValues()).some(
        ([key, value]) =>
          activeFields.includes(key) &&
          typeof value === 'string' &&
          value.length > 0 &&
          value.toLowerCase().includes(cleanSearchTerm)
      )
    );
  };

  // Back-end operations
  acceptNewFilename = () => {
    if (this.filenameNeedsAttention) {
      this.ref.set(
        {
          filename: this.filename,
        },
        { merge: true }
      );
    }
  };

  ignoreNewFilename = () => {
    if (this.filenameNeedsAttention) {
      this.ref.set(
        {
          filenameUpdateIgnoredAt: FieldValue.serverTimestamp() as unknown as Date,
        },
        { merge: true }
      );
    }
  };

  get suffix(): string {
    if (this.data.suffix) {
      return this.data.suffix;
    }
    if (this.rootStore.sessionsStore.currentSession?.automaticFilenameVersioning) {
      const sessionCards = (this.session.cardList as Card[]) ?? [];
      const duplicateFilenameCards = sessionCards
        .filter((c) => this.unsuffixedFilename === c.unsuffixedFilename)
        .sort((a, b) => a.data.createdAt.getTime() - b.data.createdAt.getTime());
      const currentIdx = duplicateFilenameCards.findIndex((c) => c.id === this.id);
      return currentIdx > 0 ? `V${currentIdx + 1}` : '';
    }
    return '';
  }

  setSuffix(newSuffix: string) {
    /**
     * When suffix is updated, add filename suffix to card title formula if not already present
     */
    if (
      this.rootStore.sessionsStore?.currentSession?.automaticCardTitleSuffix &&
      this.rootStore.uiCardTitleStore.getFieldIndex(FILENAME_SUFFIX_FIELD) === -1
    ) {
      this.rootStore.uiCardTitleStore.addField(FILENAME_SUFFIX_FIELD);
      this.rootStore.uiCardTitleStore.saveFormula();
    }
    this.ref.set(
      {
        suffix: newSuffix,
      },
      { merge: true }
    );
  }

  createCommentIfNeeded() {
    const { uiCommentsStore } = this.rootStore;
    const composedComment = uiCommentsStore.composedCommentsByCardId[this.id] || '';
    const cleanComment = composedComment.trim();
    if (cleanComment) {
      (this.session as Session).createComment(this.id, cleanComment);
    }
    uiCommentsStore.setComposedComment(this.id, '');
  }

  removeRowOnly = async (moduleId: string, rowId: string) => {
    const moduleConnections = this.data.connections[moduleId];

    // If there is only one connection, make sure connection matches row we want to delete
    if (typeof moduleConnections === 'string' && moduleConnections === rowId) {
      await this.ref.update({
        [`connections.${moduleId}`]: null,
      });

      return () =>
        this.ref.update({
          [`connections.${moduleId}`]: moduleConnections,
        });
    }
    if (Array.isArray(moduleConnections)) {
      await this.ref.update({
        [`connections.${moduleId}`]: FieldValue.arrayRemove(rowId),
      });

      return () =>
        this.ref.update({
          [`connections.${moduleId}`]: FieldValue.arrayUnion(rowId),
        });
    }
  };

  removeRow = async (moduleId: string, rowId: string) => {
    const { currentSession } = this.rootStore.sessionsStore;
    if (!currentSession) return;
    const {
      data: { modules },
    } = currentSession;
    const module = modules[moduleId];
    if (!module) {
      return;
    }
    const undo = await this.removeRowOnly(moduleId, rowId);

    if (undo !== undefined) {
      this.rootStore.uiSnackbarStore.setUndoDetails(
        undo,
        `Item from ${module.name} removed from card #${this.cardNumber}`
      );
    }
  };

  get permalink() {
    return `http://${window.location.host}${this.rootStore.routerStore
      .getRoute(RouteNames.CARD)
      ?.pattern.replace(':sessionId', this.rootStore.sessionsStore.currentSessionId)
      ?.replace(':cardId', this.id)}`;
  }

  get isFocused() {
    return this.rootStore.uiCardView.selectedCardId === this.id;
  }

  focusCard() {
    this.rootStore.uiCardView.toggleCardFocus(this.id);
  }
}
