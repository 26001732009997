import { CircularProgress, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { useStore } from 'logic';
import googleLoginImage from 'images/google-signin.png';
import { ImportView } from './ImportView';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    ...theme.typography.h4,
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.primary,
  },
  subtitle: {
    ...theme.typography.subtitle1,
    fontWeight: theme.typography.fontWeightLight,
    margin: `${theme.spacing(2)} 0`,
    color: theme.palette.primary.light,
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      margin: `0 ${theme.spacing(2)}`,
    },
  },
  experience: {
    padding: `${theme.spacing(2)} 0`,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightLight,
  },
}));

export const HomeView = observer(() => {
  const styles = useStyles();
  const {
    authStore: { user, userLoading, loginWithGoogle },
  } = useStore();

  if (user) {
    return <ImportView />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <div className={styles.title}>Welcome to Creative Queue!</div>
        {userLoading ? (
          <CircularProgress />
        ) : (
          <div className={styles.actions}>
            <Link href="#" onClick={() => loginWithGoogle()}>
              <img alt="Login with Google" src={googleLoginImage} width={150} />
            </Link>
          </div>
        )}
      </div>
      <div className={styles.experience}>
        Use <a href="https://www.google.com/chrome/">Chrome Browser</a> for Best Experience
      </div>
    </div>
  );
});
