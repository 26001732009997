import { makeStyles } from '@mui/styles';
import { Box, ButtonBase, IconButton, Tooltip, lighten } from '@mui/material';
import ReplaceIcon from '@mui/icons-material/DoubleArrow';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistIcon from '@mui/icons-material/PlaylistAdd';
import FilterIcon from '@mui/icons-material/FilterList';
import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import { FormatterProps } from '@creative-kit/react-data-grid';
import { Row } from 'logic/row';
import { Highlight } from 'ui/Highlight';
import { ConfirmPopover } from 'ui/ConfirmPopover';
import { plural, withPlural } from '@creative-kit/shared';
import { DoubleArrowIcon } from 'ui/DoubleArrowIcon';

interface ModulePowerColumnFormatterProps extends FormatterProps<Row> {
  sourceId: string;
}

interface StyleProps {
  backgroundColor: string;
  hasConnections: boolean;
  isSelected: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    inset: 3,
    display: 'flex',
  },
  connectionButton: (p: StyleProps) => ({
    height: '100%',
    marginRight: 6,
    color: theme.palette.text.primary,
    border: `1px solid rgb(60, 60, 60)`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 20,
    '&:hover': {
      backgroundColor: p.backgroundColor,
      color: theme.palette.getContrastText(p.backgroundColor),
      border: 'none',
    },
  }),
  button: (p: StyleProps) => ({
    display: 'flex',
    alignItems: 'center',
    lineHeight: p.isSelected ? '31px' : '29px',
    width: '66%',
    height: '100%',
    minHeight: '30px',
    flex: 1,
    textAlign: 'center',
    padding: `0 ${theme.spacing(0.5)}`,
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: p.backgroundColor,
      color: theme.palette.getContrastText(p.backgroundColor),
      border: 'none',
    },
    color: theme.palette.text.primary,
    border: p.isSelected ? 'none' : `1px solid rgb(60, 60, 60)`,
    backgroundColor: !p.isSelected
      ? 'transparent'
      : p.hasConnections
      ? p.backgroundColor
      : lighten(p.backgroundColor, 0.6),
  }),
}));

export const ModulePowerColumnFormatter = observer((props: ModulePowerColumnFormatterProps) => {
  const {
    uiCardView: { isCardFocused, exitCardFocus },
    uiModulePowerColumnStore: { getSourceModulePowerColumn, showDetailedCounts },
    uiActiveSessionStore: { selectedCards, hasSelectedCards, deselectAllCards },
    uiSourceSearchStore: { searchesById },
    sessionsStore: { currentSession },
  } = useStore();
  const searchTerm = searchesById[props.row.sourceId];
  const powerColumn = getSourceModulePowerColumn(props.sourceId);
  const module = powerColumn?.selectedModule;
  const count = powerColumn?.getCurrentModuleLinkCount(props.row.id) || {
    inProgress: 0,
    done: 0,
    total: 0,
  };
  const hasConnections = count.total > 0;
  const isSelected = !!(module && powerColumn?.selectedRowsByModule[module.id]?.has(props.row.id));
  const styles = useStyles({
    backgroundColor: module?.color || '#000',
    hasConnections,
    isSelected,
  });
  if (!module) return null;

  const displayCount = () => {
    if (!hasConnections && !searchTerm) {
      return null;
    }

    if (showDetailedCounts) {
      return `${count.inProgress} / ${count.done}`;
    }

    if (searchTerm) {
      return (
        <Highlight
          searchWords={[searchTerm.trim()]}
          textToHighlight={count.total.toString() ?? ''}
        />
      );
    }

    return count.total;
  };

  const getPowerColumnRemoveButton = () => {
    if (!hasSelectedCards) return null;

    const cardsWithConnections = selectedCards.filter((card) =>
      card?.getConnectedRows(module.id).includes(props.row.id)
    );
    if (cardsWithConnections.length === 0) return null;

    return (
      <ConfirmPopover
        confirmText="Remove"
        style={{ marginRight: 6, width: 'calc(33% - 6px)' }}
        message={`Remove connection from ${
          selectedCards.length === 1
            ? ''
            : `${cardsWithConnections.length} of ${selectedCards.length}`
        } selected ${plural(selectedCards.length, 'asset', 'assets')}?`}
        onConfirm={() => {
          currentSession?.batchRemoveConnections({
            selectedCards: cardsWithConnections,
            rowId: props.row.id,
            moduleId: module.id,
          });
        }}
      >
        <Tooltip
          enterDelay={750}
          disableInteractive
          title="Remove Connection From Selected Assets"
          placement="left"
        >
          <IconButton className={styles.connectionButton} sx={{ width: '100%' }}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </ConfirmPopover>
    );
  };

  const getPowerColumnButton = () => {
    if (hasSelectedCards) {
      const selectedCardsTooltip = `Connect to selected ${plural(
        selectedCards.length,
        'task',
        'tasks'
      )} (${selectedCards.length})`;
      const cardsWithConnections = selectedCards.filter(
        (card) => card?.getModuleConnections(module.id).length
      ).length;
      if (module.multi === false && cardsWithConnections > 0) {
        return (
          <ConfirmPopover
            confirmText="Replace"
            style={{ marginRight: 6, width: 'calc(33% - 6px)' }}
            message={`${cardsWithConnections} of ${withPlural(
              selectedCards.length,
              'asset',
              'assets'
            )} will have connections replaced`}
            onConfirm={() => {
              currentSession?.batchAddConnections({
                selectedCards,
                rowIds: props.row.id,
                moduleId: module.id,
                position: 'end',
              });
            }}
          >
            <Tooltip
              disableInteractive
              enterDelay={750}
              title={selectedCardsTooltip}
              placement="left"
            >
              <IconButton className={styles.connectionButton} sx={{ width: '100%' }}>
                <ReplaceIcon sx={{ transform: 'rotate(180deg)' }} />
              </IconButton>
            </Tooltip>
          </ConfirmPopover>
        );
      }

      return (
        <Tooltip enterDelay={750} title={selectedCardsTooltip} placement="left">
          <IconButton
            className={styles.connectionButton}
            onClick={() => {
              currentSession?.batchAddConnections({
                selectedCards,
                rowIds: props.row.id,
                moduleId: module.id,
                position: 'end',
              });
            }}
          >
            <DoubleArrowIcon />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        disableInteractive
        enterDelay={750}
        title="Add New Asset With This Connection"
        placement="left"
      >
        <IconButton
          className={styles.connectionButton}
          onClick={() => {
            currentSession!.addConnection({
              rowIds: props.row.id,
              moduleId: module.id,
              position: 'end',
            });
          }}
        >
          <PlaylistIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const countEl = displayCount();

  return (
    <div className={styles.root}>
      {getPowerColumnButton()}
      {getPowerColumnRemoveButton()}
      <Tooltip
        disableHoverListener={!countEl}
        disableInteractive
        enterDelay={750}
        placement="left"
        title="Reveal Assets Connected to this Row"
      >
        <ButtonBase
          className={styles.button}
          onClick={(e) => {
            e.stopPropagation();
            if (hasConnections) {
              if (isCardFocused) {
                exitCardFocus();
              } else if (hasSelectedCards) {
                deselectAllCards();
              }

              powerColumn?.toggleRow(props.row.id);
            }
          }}
        >
          {!hasSelectedCards && countEl !== null && (
            <Box pr={1} alignItems="center" display="flex">
              <FilterIcon />
            </Box>
          )}
          {countEl}
        </ButtonBase>
      </Tooltip>
    </div>
  );
});
