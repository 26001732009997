import type { FirestoreSource, SourceType } from '../firestore';
import { BaseRow } from './row';

export class BaseSource {
  id: string;
  sessionId: string;
  data: FirestoreSource;
  rows: Record<string, BaseRow> = {};

  constructor(id: string, sessionId: string, data: FirestoreSource) {
    this.id = id;
    this.sessionId = sessionId;
    this.data = data;
  }

  get type(): SourceType {
    return this.data.type || 'text';
  }
}
