import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { observer } from 'mobx-react-lite';

import { useStore } from 'logic';
import { RouteNames } from 'logic/routes';
import { SessionPermissions } from '@creative-kit/shared';
import { CopiableField } from './CopiableField';

export const ShareSession = observer(() => {
  const {
    sessionsStore: { currentSession },
    uiPermissions: { canEditCurrentSession, isCurrentSessionOwner },
    routerStore,
  } = useStore();
  const [openShare, setOpenShare] = useState(false);
  const [permissionLevel, setPermissionLevel] = useState<SessionPermissions>(
    SessionPermissions.PRIVATE
  );

  useEffect(() => {
    setPermissionLevel(currentSession?.data.publicAccessLevel ?? SessionPermissions.PRIVATE);
  }, [currentSession?.data.publicAccessLevel]);

  const closeShare = () => setOpenShare(false);

  const handleSave = () => {
    currentSession?.updatePublicSessionAccessLevel(permissionLevel);
    closeShare();
  };

  if (!currentSession) {
    return null;
  }

  const shareURL = `http://${window.location.host}${routerStore
    .getRoute(RouteNames.SESSION)
    ?.pattern.replace(':sessionId', currentSession.id)}`;

  /**
   * If not session owner, show an indicator for user to know they're viewing someone else's session
   */
  if (isCurrentSessionOwner === false) {
    const nonOwnerContent = canEditCurrentSession
      ? 'Editing Shared Session'
      : 'Viewing Shared Session';
    return <Button disabled>{nonOwnerContent}</Button>;
  }
  return (
    <>
      <Button onClick={() => setOpenShare(true)}>Share</Button>
      <Dialog open={openShare} maxWidth="sm" fullWidth onClose={closeShare}>
        <DialogTitle>Share</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <CopiableField
              text={shareURL}
              alwaysShowCopy
              fullWidth
              textElement={
                <TextField
                  label="Link"
                  fullWidth
                  variant="outlined"
                  value={shareURL}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              }
            />
          </Box>
          <Box mt={2}>
            <FormControl fullWidth>
              <InputLabel id="permission-select-label">Shared Access Level</InputLabel>
              <Select
                id="permission-select"
                labelId="permission-select-label"
                label="Shared Access Level"
                value={permissionLevel}
                onChange={(e) => setPermissionLevel(e.target.value as SessionPermissions)}
              >
                <MenuItem value={SessionPermissions.VIEW}>Guest View (Account Required)</MenuItem>
                <MenuItem value={SessionPermissions.EDIT}>Group Edit (Account Required)</MenuItem>
                <MenuItem value={SessionPermissions.PRIVATE}>
                  Private (Viewable Only By You)
                </MenuItem>
              </Select>
              <FormHelperText>
                {permissionLevel === SessionPermissions.VIEW
                  ? 'Anyone with the link can view your session'
                  : 'Anyone with the link can edit your session'}
              </FormHelperText>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeShare}>Cancel</Button>
          <Button
            onClick={() => {
              handleSave();
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
