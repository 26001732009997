import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import React, { useEffect, useRef, useState } from 'react';
import { HeaderRendererProps } from '@creative-kit/react-data-grid';
import { Row } from 'logic/row';
import { IconButton, MenuItem } from '@mui/material';
import { ButtonPopover } from 'ui/ButtonPopover';

interface SourceColumnEditHeaderRendererProps extends HeaderRendererProps<Row> {
  sourceId: string;
}

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    height: '100%',
  },
  oldValue: {
    textDecoration: 'line-through',
    color: theme.palette.error.main,
  },
}));

// Active only during edit mode
export const SourceColumnEditHeaderRenderer = observer(
  (props: SourceColumnEditHeaderRendererProps) => {
    const {
      sourcesStore: { sources },
    } = useStore();
    const source = sources[props.sourceId];

    const styles = useStyles();
    const [editedName, setEditedName] = useState<string>();
    const isEditing = editedName !== undefined;
    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditedName(e.target.value);
    };

    const commitColumnName = () => {
      if (editedName) {
        source.editColumnName(props.column.key, editedName);
        stopEditing();
      }
    };

    const startEditing = () => {
      const { name } = props.column;
      const colEditedName = source.getRenamedColumnName(props.column.key);
      const originalName = typeof name === 'string' ? name : '';
      setEditedName(colEditedName || originalName);
    };

    useEffect(() => {
      if (isEditing) {
        inputRef.current?.focus();
        inputRef.current?.select();
      }
    }, [isEditing]);

    const stopEditing = () => {
      setEditedName(undefined);
    };

    const handleInputBlur = () => {
      commitColumnName();
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        commitColumnName();
      } else if (e.key === 'Escape') {
        stopEditing();
      }
    };

    const maybeEditedColumnName = source.getRenamedColumnName(props.column.key);
    const isColumnEdited = !!maybeEditedColumnName;

    if (isEditing) {
      return (
        <input
          ref={inputRef}
          className={styles.input}
          value={editedName}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyDown}
        />
      );
    }

    return (
      <div onDoubleClick={startEditing} style={{ width: '100%', padding: '0 8px' }}>
        {isColumnEdited && <span>{maybeEditedColumnName}</span>}
        <span
          className={clsx({
            [styles.oldValue]: isColumnEdited || source.isColumnMarkedForDeletion(props.column.key),
          })}
        >
          {props.column.name}
        </span>
        <ButtonPopover
          button={
            <IconButton>
              <MoreIcon />
            </IconButton>
          }
          icon={null}
          title="Actions"
        >
          <MenuItem onClick={() => source.deleteColumn(props.column.key)}>Delete</MenuItem>
        </ButtonPopover>
      </div>
    );
  }
);
