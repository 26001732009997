import { SessionModule } from '@creative-kit/shared';
import { Box, Button, ClickAwayListener, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { EditModuleDialog } from 'ui/ModuleManager/EditModuleDialog';
import { TooltipList } from 'ui/TooltipList';

const SingleConnectionCopy = (
  <>
    <Typography align="center" variant="h6">
      Single Connection Bucket
    </Typography>
    <TooltipList>
      <li>Allows you to connect 1 row at a time.</li>
      <li>Connecting new rows replaces existing connections.</li>
      <li>Information can be used in Asset Titles and Filenames.</li>
    </TooltipList>
  </>
);

const MultiConnectionCopy = (
  <>
    <Typography align="center" variant="h6">
      Multi Connection Bucket
    </Typography>
    <TooltipList>
      <li>Multiple rows can be connected simultaneously.</li>
      <li>New rows are connected after existing ones.</li>
      <li>Information can't be used in Assets Titles and Filenames.</li>
    </TooltipList>
  </>
);

const useLearnMoreStyles = makeStyles({
  tooltip: {
    maxWidth: 500,
  },
});

export const LearnMoreTooltip = () => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const styles = useLearnMoreStyles();
  const toggle = () => setOpen((v) => !v);
  return (
    <ClickAwayListener onClickAway={close}>
      <Tooltip
        onClose={close}
        open={open}
        disableFocusListener
        disableHoverListener
        classes={{ tooltip: styles.tooltip }}
        disableTouchListener
        title={
          <Box>
            {SingleConnectionCopy}
            {MultiConnectionCopy}
          </Box>
        }
      >
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={toggle}
          onKeyDown={toggle}
          role="button"
          tabIndex={0}
        >
          Learn More
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

export const ConnectionTooltip = ({ module }: { module: SessionModule }) => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const toggle = () => setOpen((v) => !v);
  const [moduleSettingsDialogOpen, setModuleSettingsDialogOpen] = useState(false);
  return (
    <ClickAwayListener onClickAway={close}>
      <>
        <Tooltip
          onClose={close}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <>
              {module.multi ? MultiConnectionCopy : SingleConnectionCopy}
              <Button
                variant="text"
                fullWidth
                onClick={() => {
                  close();
                  setModuleSettingsDialogOpen(true);
                }}
              >
                Bucket Settings
              </Button>
            </>
          }
        >
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={toggle}
            onKeyDown={toggle}
            role="button"
            tabIndex={0}
          >
            {module.multi ? 'M' : 'S'}
          </Box>
        </Tooltip>
        <EditModuleDialog
          onClose={() => setModuleSettingsDialogOpen(false)}
          open={moduleSettingsDialogOpen}
          module={module}
        />
      </>
    </ClickAwayListener>
  );
};
