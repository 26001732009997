import { action, makeObservable, observable } from 'mobx';

export class UiSourceSearchStore {
  searchesById: Record<string, string> = {};
  settingsMenuById: Record<string, boolean> = {};
  autofocusSearch: boolean = false;

  constructor() {
    makeObservable(this, {
      autofocusSearch: observable,
      setSearchFocus: action.bound,
      searchesById: observable,
      setSearchString: action.bound,
      settingsMenuById: observable,
      openSettingsMenu: action.bound,
      closeSettingsMenu: action.bound,
    });
  }

  getHasSearch = (sourceId: string) => !!this.searchesById[sourceId]?.trim();

  getSearchTerms = (sourceId: string) => {
    const terms = this.searchesById[sourceId]?.split(',');
    return terms?.map((t) => t.toLowerCase().trim()) ?? [];
  };

  setSearchString(sourceId: string, value: string) {
    this.searchesById[sourceId] = value;
  }

  setSearchFocus(value: boolean) {
    this.autofocusSearch = value;
  }

  openSettingsMenu(sourceId: string) {
    this.settingsMenuById[sourceId] = true;
  }

  closeSettingsMenu(sourceId: string) {
    this.settingsMenuById[sourceId] = false;
  }

  isSettingsMenuOpen = (sourceId: string) => !!this.settingsMenuById[sourceId];
}
