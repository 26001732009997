import { Box, Button, TextField, Typography } from '@mui/material';
import { useStore } from 'logic';
import { db } from 'logic/data';
import { useRef } from 'react';

export const WelcomeView = () => {
  const {
    authStore: { user },
  } = useStore();
  const emailRef = useRef<HTMLInputElement | null>(null);
  const addPossibleUser = (e: React.FormEvent) => {
    e.preventDefault();
    if (emailRef.current && emailRef.current.value && user) {
      db.possibleUsers.doc(emailRef.current.value).set({
        email: emailRef.current.value,
        createdBy: user.uid,
      });
      emailRef.current.value = '';
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
      }}
    >
      <Typography variant="h3" sx={{ mb: 5 }}>
        Welcome to the Creative Queue Family!
      </Typography>
      <Typography variant="h5" sx={{ mb: 5 }}>
        You're on the waitlist – Check your email for info and next steps!
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 4 }}>
        Know someone who might like Creative Queue? Drop their email below:
      </Typography>
      <Box component="form" sx={{ display: 'flex', mb: 4 }} onSubmit={addPossibleUser}>
        <TextField
          type="email"
          variant="outlined"
          inputRef={emailRef}
          placeholder="Sillyrabbit@sparkprojects.io"
          sx={{ mr: 2, minWidth: '28ch' }}
          size="small"
        />
        <Button variant="outlined" type="submit">
          Submit
        </Button>
      </Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Love,
      </Typography>
      <Typography variant="h5" sx={{ mb: 5 }}>
        Brandon & Adrian
      </Typography>
      <Typography variant="subtitle1">
        Do you have questions? Email Us:{' '}
        <a href="mailto:Sparkos@SparkProjects.io">Sparkos@SparkProjects.io</a>
      </Typography>
    </Box>
  );
};
