import { signCloudinaryUpload } from 'api/signCloudinaryUpload';
import { action, makeObservable, observable } from 'mobx';
import axios, { AxiosRequestConfig } from 'axios';
import { CloudinaryUploadResponse } from '@creative-kit/shared';
import { RootStore } from '.';

export interface UploadProgress {
  id: string;
  filename: string;
  progress: number;
  abort: () => void;
  error?: string;
}

const MAX_UPLOAD_BYTES = 10 * 1000 * 1000;

export class UiImageSourceUploads {
  private rootStore: RootStore;
  sourceUploads: Record<string, UploadProgress[]> = {};

  constructor(rootStore: RootStore) {
    makeObservable<
      this,
      'addProgressToSource' | 'updateProgress' | 'removeProgress' | 'setProgressError'
    >(this, {
      sourceUploads: observable,
      addProgressToSource: action,
      updateProgress: action,
      removeProgress: action,
      setProgressError: action,
    });

    this.rootStore = rootStore;
  }

  private addProgressToSource(sourceId: string, progress: UploadProgress) {
    this.sourceUploads[sourceId] ||= [];
    this.sourceUploads[sourceId].push(progress);
  }

  private updateProgress(sourceId: string, itemId: string, newProgress: number) {
    const item = this.sourceUploads[sourceId]?.find((i) => i.id === itemId);
    if (item) {
      item.progress = newProgress;
    }
  }

  private removeProgress(sourceId: string, itemId: string) {
    const itemIdx = this.sourceUploads[sourceId]?.findIndex((i) => i.id === itemId);
    if (itemIdx >= 0) {
      this.sourceUploads[sourceId].splice(itemIdx, 1);
    }
  }

  private setProgressError(sourceId: string, itemId: string, error: string) {
    const item = this.sourceUploads[sourceId]?.find((i) => i.id === itemId);
    if (item) {
      item.error = error;
    }
  }

  getProgress = (sourceId: string, rowId: string) =>
    this.sourceUploads[sourceId]?.find((p) => p.id === rowId);

  uploadImageToSource = (sourceId: string, imageFile: File) => {
    const source = this.rootStore.sourcesStore.sources[sourceId];
    const { sessionId } = source;

    const controller = new AbortController();
    const rowId = source.addEmptyImageRow();
    const progressItem: UploadProgress = {
      id: rowId,
      filename: imageFile.name,
      progress: 0,
      abort: () => {
        controller.abort();
        this.removeProgress(sourceId, rowId);
        if (source.rows[rowId]) {
          source.deleteRow(rowId);
        }
      },
    };
    this.addProgressToSource(sourceId, progressItem);

    if (imageFile.size > MAX_UPLOAD_BYTES) {
      this.setProgressError(sourceId, rowId, 'This file exceeds the limit of 10MB.');
      source.deleteRow(rowId);
      return rowId;
    }

    (async () => {
      try {
        const {
          data: { apiKey, cloudName, folder, signature, timestamp },
        } = await signCloudinaryUpload({
          sessionId,
          sourceId,
        });

        const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;

        const fd = new FormData();
        fd.append('file', imageFile);
        fd.append('api_key', apiKey);
        fd.append('folder', folder);
        fd.append('signature', signature);
        fd.append('timestamp', timestamp);

        const uploadConfig: AxiosRequestConfig = {
          onUploadProgress: (event) => {
            this.updateProgress(sourceId, progressItem.id, event.loaded / event.total);
          },
          signal: controller.signal,
        };

        const uploadResponse = await axios.post<CloudinaryUploadResponse>(
          uploadUrl,
          fd,
          uploadConfig
        );
        const { bytes, height, width, public_id: publicId } = uploadResponse.data;
        source.rows[rowId].setImage({
          bytes,
          height,
          width,
          originalFilename: imageFile.name,
          storage: {
            type: 'cloudinary',
            cloudName,
            publicId,
          },
        });
        this.removeProgress(sourceId, progressItem.id);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          this.setProgressError(
            sourceId,
            progressItem.id,
            err.response?.data.error?.message || 'An unknown error occurred while uploading'
          );
        } else if ('message' in err) {
          this.setProgressError(
            sourceId,
            progressItem.id,
            err.message || 'An unknown error occurred while uploading'
          );
        }
        source.deleteRow(rowId);
      }
    })();

    return rowId;
  };
}
