import makeStyles from '@mui/styles/makeStyles';
import Highlighter, { HighlighterProps } from 'react-highlight-words';

type HighlightProps = Pick<HighlighterProps, 'textToHighlight' | 'findChunks' | 'searchWords'>;

const useStyles = makeStyles({
  highlight: {
    backgroundColor: '#ffee00',
  },
});

export const Highlight = ({ searchWords, ...props }: HighlightProps) => {
  const styles = useStyles();
  return (
    <Highlighter
      autoEscape={true}
      highlightClassName={styles.highlight}
      searchWords={searchWords}
      {...props}
    />
  );
};
