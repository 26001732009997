import { Button, DialogActions, Popover } from '@mui/material';
import React, { useState } from 'react';

export const ConfirmPopover = ({
  children,
  message,
  onConfirm,
  disabled,
  style,
  confirmText = 'Ok',
}: {
  message: string;
  children?: React.ReactNode;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  style?: React.CSSProperties;
  confirmText?: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  return (
    <>
      <div
        style={{ display: 'inline-block', ...style }}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled) {
            setAnchorEl(e.currentTarget);
          }
        }}
        aria-hidden="true"
      >
        {children}
      </div>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ style: { maxWidth: 400 } }}
        style={{ display: 'flex' }}
      >
        <div style={{ padding: '16px' }}>{message}</div>
        <DialogActions>
          <Button onClick={() => setAnchorEl(null)}>Cancel</Button>
          <Button
            autoFocus
            onClick={(e) => {
              onConfirm(e);
              setAnchorEl(null);
            }}
            color="primary"
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Popover>
    </>
  );
};
