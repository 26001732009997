import { Tab } from '@mui/material';

interface TabsTitleProps {
  label: string;
}

export const TabsTitle = ({ label }: TabsTitleProps) => (
  <Tab
    disabled
    label={label}
    sx={{
      minWidth: 0,
      paddingX: 1,
      paddingY: 0,
      color: (t) => `${t.palette.text.primary} !important`,
      opacity: '1 !important',
      fontSize: 24,
      fontWeight: 'normal',
    }}
  />
);
