import { RowData } from '@creative-kit/shared';
import { action, computed, makeObservable, observable } from 'mobx';
import { ulid } from 'ulid';

export class UiModuleRowEditor {
  private _editedFieldId: string | null = null;
  private _editedFieldValues: RowData = {};
  private _editedFieldNames: RowData = {};
  private _addedFieldIds: string[] = [];

  constructor() {
    makeObservable<
      this,
      '_editedFieldId' | '_editedFieldNames' | '_editedFieldValues' | '_addedFieldIds'
    >(this, {
      _editedFieldId: observable,
      _editedFieldNames: observable,
      _editedFieldValues: observable,
      _addedFieldIds: observable,
      hasEdits: computed,
      resetEditMode: action.bound,
      addNewColumn: action.bound,
      updateColumnName: action.bound,
      updateColumnValue: action.bound,
      startEditing: action.bound,
    });
  }

  public get isEditing() {
    return !!this.editedFieldId?.length;
  }

  public get hasEdits() {
    return (
      this._addedFieldIds.length > 0 ||
      Object.keys(this._editedFieldValues).length > 0 ||
      Object.keys(this._editedFieldValues).length > 0
    );
  }

  public resetEditMode() {
    this._editedFieldId = null;
    this._editedFieldValues = {};
    this._editedFieldNames = {};
    this._addedFieldIds = [];
  }

  public startEditing(fieldId: string) {
    this._editedFieldId = fieldId;
  }

  public updateColumnName(fieldId: string, value: string) {
    this._editedFieldNames = {
      ...this.editedFieldNames,
      [fieldId]: value,
    };
  }

  public updateColumnValue(fieldId: string, value: string) {
    this._editedFieldValues = {
      ...this.editedFieldValues,
      [fieldId]: value,
    };
  }

  public addNewColumn() {
    const fieldId = ulid();
    this._addedFieldIds = [...this._addedFieldIds, fieldId];
    this.updateColumnName(fieldId, 'New Field');
  }

  public get editedFieldId() {
    return this._editedFieldId;
  }

  public get editedFieldNames() {
    return this._editedFieldNames;
  }

  public get editedFieldValues() {
    return this._editedFieldValues;
  }

  public get addedFieldIds() {
    return this._addedFieldIds;
  }
}
