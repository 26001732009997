import type { RootStore } from 'logic';
import { action, makeObservable, observable } from 'mobx';
import {
  DateField,
  Field,
  fieldEquals,
  CURR_DATE_FIELD,
  POS_NO_FIELD,
  FIELD_DATE_FORMATS,
  DateFormat,
  FilenameSettings,
  SESSION_NAME_FIELD,
  AppField,
} from '@creative-kit/shared';
import { FormulaBase } from './formula';
import { Card } from './card';

export const FIELD_SEPARATORS = [
  { value: '_', name: 'Underscore' },
  { value: '-', name: 'Dash' },
];

export const SPACE_SEPARATORS = [
  { value: '-', name: 'Dash' },
  { value: '_', name: 'Underscore' },
  { value: '', name: 'Nothing' },
];

export const CARD_ORDER_DIGITS = [
  { value: 3, name: '001' },
  { value: 2, name: '01' },
];

export class UiFilenameStore extends FormulaBase {
  private currDateField: DateField = { ...CURR_DATE_FIELD };
  previewCardIdx: number = -1;
  filenameSettings: FilenameSettings = {
    fieldSeparator: '_',
    spaceSeparator: '-',
    specialCharacterReplacement: '-',
    cardNumberWidth: 3,
    filenameCaps: true,
    disableSpecialCharacterCleanup: false,
    disableFilenameCleanup: false,
    trimSpaces: false,
    trimSpecialCharacters: false,
  };

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable<UiFilenameStore, 'currDateField'>(this, {
      filenameSettings: observable,
      currDateField: observable,
      setCurrDateFormat: action.bound,
      updateSetting: action.bound,
    });
  }

  int() {
    super.init();
    const { filenameSettings } = this.rootStore.sessionsStore.currentSession!.data;
    this.filenameSettings = {
      ...filenameSettings,
    };
  }

  get originalFormula(): Field[] {
    const { filenameFormula } = this.rootStore.sessionsStore.currentSession!.data;
    return filenameFormula;
  }

  openDialog(card?: Card): void {
    super.openDialog();
    this.previewCardIdx = card?.cardIdx ?? -1;
  }

  closeDialog(): void {
    super.closeDialog();
    this.previewCardIdx = -1;
  }

  get availableAppFields(): AppField[] {
    return [SESSION_NAME_FIELD, POS_NO_FIELD, this.currDateField].filter(
      (f1) => !this.formula.find(fieldEquals(f1))
    );
  }

  get availableDateFormats() {
    return FIELD_DATE_FORMATS.filter((f) => f !== this.currDateField.format);
  }

  updateSetting<K extends keyof FilenameSettings>(setting: K, value: FilenameSettings[K]) {
    this.filenameSettings[setting] = value;
  }

  setCurrDateFormat(format: DateFormat) {
    this.currDateField.format = format;
  }

  saveFormula = () => {
    this.rootStore.sessionsStore.currentSession!.saveFilename(
      this.cleanFormula,
      this.filenameSettings
    );
  };
}
