import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useDropzone } from 'react-dropzone';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useImportStyles } from 'ui/ImportSource/importStyles';
import { useShortcuts } from 'logic/hooks';

interface SourceCSVUploadProps {
  uploadLoading?: boolean;
  onCancel: () => void;
  onUpload: (file: File, sourceName: string, automaticModule: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  dragDropContainer: {
    color: theme.palette.text.hint,
    lineHeight: 1.25,
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: theme.spacing(4),
    padding: theme.spacing(5),
  },
  dragPrompt: {
    fontSize: 32,
    maxWidth: 500,
    marginBottom: theme.spacing(2),
  },
  clickHint: {
    fontSize: 14,
  },
  tips: {
    color: theme.palette.primary.light,
    textDecoration: 'underline',
    cursor: 'pointer',
    userSelect: 'none',
  },
}));

export const SourceCSVUpload = observer(
  ({ onCancel, onUpload, uploadLoading }: SourceCSVUploadProps) => {
    const styles = useStyles();
    const importStyles = useImportStyles();
    const [sourceName, setSourceName] = useState('');
    const [automaticModuleCreation, setAutomaticModuleCreation] = useState(true);

    const onDrop = useCallback(
      async (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        // If source name hasn't been set, update UI state to display the current file's name
        if (!sourceName) {
          setSourceName(file.name.substring(0, file.name.lastIndexOf('.')));
        }

        onUpload(file, sourceName, automaticModuleCreation);
      },
      [onUpload, sourceName, automaticModuleCreation]
    );

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      noKeyboard: true,
    });

    const shortcuts = useMemo(
      () => [
        {
          name: 'goBack',
          fn: () => onCancel(),
          combination: ['esc'],
        },
      ],
      [onCancel]
    );
    useShortcuts(shortcuts);

    return (
      <div className={importStyles.root}>
        <div className={importStyles.content}>
          <Typography variant="h4">Upload New Spreadsheet</Typography>
          <TextField
            autoFocus
            fullWidth
            type="text"
            label="Spreadsheet Name"
            value={sourceName}
            onChange={(e) => setSourceName(e.target.value)}
          />
          <Paper {...getRootProps({ className: styles.dragDropContainer })}>
            <input {...getInputProps()} accept=".csv" />
            {uploadLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              <>
                <div className={styles.dragPrompt}>Drag + Drop a CSV Spreadsheet</div>
                <div className={styles.clickHint}>Or Click To Browse Your Desktop</div>
              </>
            )}
          </Paper>
          <div className={importStyles.stepDetails}>
            Having trouble uploading? Check out our{' '}
            <Tooltip
              title={
                <ol>
                  <li>CSV format required</li>
                  <li>Columns Must Have Titles in Top Row</li>
                  <li>No merged cells in spreadsheet.</li>
                </ol>
              }
            >
              <span className={styles.tips}>Spreadsheet Tips</span>
            </Tooltip>
          </div>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={automaticModuleCreation}
                  onChange={(_, checked) => setAutomaticModuleCreation(checked)}
                />
              }
              label="Add a Bucket for this Library"
            />
          </FormGroup>
          <div className={importStyles.navigation}>
            <Button onClick={() => onCancel()}>Cancel</Button>
          </div>
        </div>
      </div>
    );
  }
);
