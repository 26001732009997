import { Button, IconButton, Modal, alpha } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { useStore } from 'logic';
import { observer } from 'mobx-react-lite';

export const FullScreenImageModal = observer(() => {
  const {
    uiImageModal: { image, clearImage, downloadImage },
  } = useStore();

  const renderContent = () => {
    if (!image) {
      return <></>;
    }

    const imageSource = new CloudinaryImage(
      image.storage.publicId,
      { cloudName: image.storage.cloudName },
      { secure: true }
    );

    return (
      <div>
        <IconButton
          sx={(theme) => ({
            position: 'fixed',
            top: theme.spacing(2),
            left: theme.spacing(2),
          })}
          onClick={clearImage}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <Button
          sx={(theme) => ({
            position: 'fixed',
            top: theme.spacing(1),
            left: '50%',
            transform: 'translate(-50%)',
          })}
          onClick={downloadImage}
          variant="outlined"
        >
          Download
        </Button>
        <img
          alt=""
          src={imageSource.toURL()}
          style={{
            maxWidth: 'calc(100vw - 40px)',
            maxHeight: 'calc(100vh - 60px)',
            position: 'relative',
            top: 20,
          }}
        />
      </div>
    );
  };

  return (
    <Modal
      open={!!image}
      onClose={clearImage}
      sx={{
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      BackdropProps={{
        sx: (theme) => ({
          background: alpha(theme.palette.background.default, 0.8),
        }),
      }}
      itemRef=""
    >
      {renderContent()}
    </Modal>
  );
});
