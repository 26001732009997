import { useDrag } from 'react-dnd';
import { Chip, Tooltip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { ItemTypes } from 'logic/dragdrop';
import { Field, getFieldLabel, isAppDateField, SessionModule } from '@creative-kit/shared';
import { useStore } from 'logic';

import { observer } from 'mobx-react-lite';

import { CurrentDateFormatMenu } from './CurrentDateFormatMenu';

interface StyleProps {
  isDragging: boolean;
  color: string;
}
const useStyles = makeStyles((theme) => ({
  root: {
    userSelect: 'none',
    position: 'relative',
    opacity: (p: StyleProps) => (p.isDragging ? 0.1 : 0.999),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chip: (p: StyleProps) => ({
    cursor: 'pointer',
    backgroundColor: p.color,
    color: theme.palette.getContrastText(p.color),
    fontWeight: 500,
  }),
}));

interface AvailableFieldProps {
  field: Field;
  module?: SessionModule;
  cancelDragField: () => void;
}
export const AvailableField = observer(
  ({ field, module, cancelDragField }: AvailableFieldProps) => {
    const {
      sourcesStore: { sources },
    } = useStore();

    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.FormulaField,
      item: {
        field,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (_item, monitor) => {
        if (!monitor.didDrop()) {
          cancelDragField();
        }
      },
    });

    const styles = useStyles({
      isDragging,
      color: module?.color || '#999',
    });

    const chipIcon = isAppDateField(field) ? <CurrentDateFormatMenu /> : undefined;

    let chip = (
      <Chip
        tabIndex={0}
        icon={chipIcon}
        className={styles.chip}
        label={
          module ? sources[module.sourceId].data.fieldNames[field.field] : getFieldLabel(field)
        }
      />
    );

    if (isAppDateField(field)) {
      chip = (
        <Tooltip title="This date is generated based on the date that a Asset is marked as “Done”">
          {chip}
        </Tooltip>
      );
    } else if (field.type === 'app' && field.field === 'POS_NO') {
      chip = (
        <Tooltip title="This is an increasing sequential number which can be used to indicate the position of a Asset in the Queue">
          {chip}
        </Tooltip>
      );
    }

    return (
      <div className={styles.root} ref={drag}>
        {chip}
      </div>
    );
  }
);
