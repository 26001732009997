import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import { useStore } from 'logic';
import { RouteNames } from 'logic/routes';
import { useForm, Controller, Control } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';
import { HelpTooltip } from 'ui/HelpTooltip';
import { TooltipList } from 'ui/TooltipList';

interface DuplicateSessionProps {
  open: boolean;
  onClose: () => void;
  sessionId: string;
}

interface DuplicateSessionFormState {
  name: string;
  sources: boolean;
  sourceRows: boolean;
  modules: boolean;
  filenameFormula: boolean;
  queueCards: boolean;
  resetCardStatus: 'true' | 'false';
}

const useStyles = makeStyles((theme) => ({
  duplicateToggle: {
    marginLeft: 0,
    justifyContent: 'space-between',
  },
  switchLabel: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.text.primary,
  },
  switchLabelText: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
  },
  cardStatusRadioGroup: {
    marginLeft: theme.spacing(3),
  },
}));

const DuplicateSwitch = ({
  label,
  control,
  name,
  infoText,
  disabled,
}: {
  control: Control<DuplicateSessionFormState>;
  name: keyof DuplicateSessionFormState;
  label: string;
  infoText: NonNullable<React.ReactNode>;
  disabled?: boolean;
}) => {
  const styles = useStyles();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
        <FormControl fullWidth margin="dense" error={!!error}>
          <FormControlLabel
            className={styles.duplicateToggle}
            labelPlacement="start"
            control={
              <Switch
                disabled={disabled}
                checked={value as boolean}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                name={name}
                color="primary"
              />
            }
            label={
              <div className={styles.switchLabel}>
                <span className={styles.switchLabelText}>{label}</span>
                <HelpTooltip>{infoText}</HelpTooltip>
              </div>
            }
          />
        </FormControl>
      )}
    />
  );
};

export const DuplicateSession = observer(({ open, onClose, sessionId }: DuplicateSessionProps) => {
  const {
    routerStore,
    sessionsStore: { sessions, duplicateSession },
  } = useStore();
  const styles = useStyles();
  const [duplicateLoading, setDuplicateLoading] = useState(false);

  const session = sessions[sessionId];
  const originalSessionName = session.data.name;
  const defaultValues: DuplicateSessionFormState = useMemo(
    () => ({
      name: `Copy of ${originalSessionName}` ?? '',
      sources: true,
      sourceRows: true,
      modules: true,
      filenameFormula: true,
      queueCards: true,
      resetCardStatus: 'false',
    }),
    [originalSessionName]
  );

  const { control, handleSubmit, watch, setValue } = useForm<DuplicateSessionFormState>({
    defaultValues,
  });
  const isSourceSelected = watch('sources');
  const isModuleSelected = watch('modules');
  const isCardSelected = watch('queueCards');

  // set some options off if duplicate source structure is off
  useEffect(() => {
    if (!isSourceSelected) {
      setValue('sourceRows', false);
      setValue('modules', false);
    }
  }, [isSourceSelected, setValue]);

  // set some options off if modules is off
  useEffect(() => {
    if (!isModuleSelected) {
      setValue('filenameFormula', false);
      setValue('queueCards', false);
    }
  }, [isModuleSelected, setValue]);

  const handleImport = ({ name }: DuplicateSessionFormState) => {
    routerStore.goTo(RouteNames.IMPORT_CSV, {
      queryParams: {
        name,
      },
    });
    onClose();
  };

  const handleDuplicateSession = async (options: DuplicateSessionFormState) => {
    setDuplicateLoading(true);
    try {
      await duplicateSession(session.id, {
        ...options,
        resetCardStatus: options.resetCardStatus === 'true',
      });
      onClose();
    } catch (e) {
      // TODO: what happens if there's an error on duplicate
    } finally {
      setDuplicateLoading(false);
    }
  };

  const onSubmit = handleSubmit(handleDuplicateSession);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Duplicate Project: {originalSessionName}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <FormControl fullWidth margin="dense">
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                <TextField
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  label="New Project Name"
                  error={!!error}
                  helperText={error?.message}
                  autoFocus
                  required
                />
              )}
            />
          </FormControl>
          <Typography variant="h5" gutterBottom>
            New Project Options
          </Typography>
          <Box pl={1}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Sources</FormLabel>
              <FormGroup>
                <DuplicateSwitch
                  name="sources"
                  label="Duplicate Source"
                  control={control}
                  infoText={
                    <>
                      <Typography align="center" variant="h6">
                        This will duplicate {originalSessionName}'s:
                      </Typography>
                      <TooltipList>
                        <li>Sources</li>
                        <li>Column headers / data fields inside of each source</li>
                      </TooltipList>
                    </>
                  }
                />
                <DuplicateSwitch
                  name="sourceRows"
                  label="Duplicate Data"
                  control={control}
                  disabled={isSourceSelected === false}
                  infoText={
                    <>
                      <Typography align="center" variant="h6">
                        This will duplicate
                      </Typography>
                      <TooltipList>
                        <li>All the rows in {originalSessionName}'s Sources</li>
                      </TooltipList>
                    </>
                  }
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Bucket</FormLabel>
              <FormGroup>
                <DuplicateSwitch
                  name="modules"
                  label="Duplicate Bucket"
                  disabled={isSourceSelected === false}
                  control={control}
                  infoText={
                    <>
                      <Typography align="center" variant="h6">
                        This will duplicate{' '}
                      </Typography>
                      <TooltipList>
                        <li>
                          The Bucket structure you created in {originalSessionName} will be matched
                          in the new Project
                        </li>
                      </TooltipList>
                    </>
                  }
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Filename</FormLabel>
              <FormGroup>
                <DuplicateSwitch
                  name="filenameFormula"
                  label="Duplicate Filename Formula"
                  disabled={isModuleSelected === false}
                  control={control}
                  infoText={
                    <>
                      <Typography align="center" variant="h6">
                        This will duplicate{' '}
                      </Typography>
                      <TooltipList>
                        <li>The current filename formula being used in {originalSessionName}</li>
                      </TooltipList>
                    </>
                  }
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Assets</FormLabel>
              <FormGroup>
                <DuplicateSwitch
                  name="queueCards"
                  label="Duplicate Assets"
                  control={control}
                  disabled={isModuleSelected === false}
                  infoText={
                    <>
                      <Typography align="center" variant="h6">
                        If on, the same Assets will be available in the new session.
                      </Typography>
                      <TooltipList>
                        <li>
                          Note: New Assets will look identical to those in {originalSessionName}
                        </li>
                      </TooltipList>
                    </>
                  }
                />
                <Controller
                  control={control}
                  name="resetCardStatus"
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                    <FormControl fullWidth margin="dense" component="fieldset" error={!!error}>
                      <FormLabel>
                        <Box display="flex">
                          <span className={styles.switchLabelText}>Asset Status Options</span>
                          <HelpTooltip>
                            <Typography align="center" variant="h6">
                              Preserve each Asset's status in {originalSessionName} in the new
                              Project
                            </Typography>
                            <TooltipList>
                              <li>
                                Assets will be in same tab as original Project:{' '}
                                {originalSessionName}
                              </li>
                            </TooltipList>
                            <Typography align="center" variant="h6">
                              Reset Assets to In Progress
                            </Typography>
                            <TooltipList>
                              <li>
                                All Assets (from any tab) will be moved to the queue of the new
                                Project in the same order they were queued up in Project{' '}
                                {originalSessionName}
                              </li>
                            </TooltipList>
                          </HelpTooltip>
                        </Box>
                      </FormLabel>
                      <RadioGroup
                        value={value}
                        aria-label="module-type"
                        name="module-type"
                        className={styles.cardStatusRadioGroup}
                      >
                        <FormControlLabel
                          value="false"
                          control={
                            <Radio
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={isCardSelected === false}
                            />
                          }
                          label="Preserve each Asset's current status"
                        />
                        <FormControlLabel
                          value="true"
                          control={
                            <Radio
                              onChange={onChange}
                              onBlur={onBlur}
                              disabled={isCardSelected === false}
                            />
                          }
                          label="Move Assets to In Progress"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </FormGroup>
            </FormControl>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {isSourceSelected === true ? (
          <Button onClick={onSubmit} color="primary" disabled={duplicateLoading}>
            {duplicateLoading ? <CircularProgress color="secondary" size={25} /> : 'Save'}
          </Button>
        ) : (
          <Button onClick={handleSubmit(handleImport)} color="primary" disabled={duplicateLoading}>
            Import
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});
